import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-flight-tracking-via-email',
  templateUrl: './flight-tracking-via-email.component.html',
  styleUrls: ['./flight-tracking-via-email.component.css']
})
export class FlightTrackingViaEmailComponent implements OnInit {

  bookings: any = [];
  flightBookingStatus: any = [];
  pickUpBooking: any;
  dropOffBooking: any;
  bookingObj: any;
  parentBooking: any;
  isDropOff: boolean = true;
  message: string = "";

  flightInfo: any;
  arrivalDate: any;
  departDate: any;
  fourthCardDisplay: boolean = false;
  firstCardDisplay: boolean = false;

  sub: any;
  bookingOid: any;

  constructor(private route: ActivatedRoute, private onGetBookingStatus: UserService) {
    this.sub = this.route.params.subscribe(params => {
      this.bookingOid = params['trackNo'];
    });
  }

  ngOnInit() {

    this.onGetBookingStatus.getBookingTrackNO(this.bookingOid).subscribe(
      response => {
        this.bookings = response.json();
        this
      }, error => {
        console.log(error);
      }
    )

    this.getFlightBooking(this.bookings);

    this.checkPickUpOrDropOff(this.bookings);

  }

  public checkPickUpOrDropOff(booking) {
    booking.forEach(element => {
      if (element.status != "Processing Flight" && element.province == this.parentBooking.province &&
        element.status != "Booking successfully complete") {
        this.pickUpBooking = element;
      } else
        if (element.status != "Processing Flight" && element.province == this.parentBooking.destinationProvince &&
          element.status != "Booking successfully complete") {
          this.dropOffBooking = element;
        }
    });

    if (this.pickUpBooking != null) {
      this.bookingObj = this.pickUpBooking;
      this.isDropOff = false;
    }
    else this.bookingObj = this.dropOffBooking;
    if (this.pickUpBooking == null && this.dropOffBooking == null) {
      this.bookingObj = this.getCompletedFlightBooking(this.bookings);
    } else {
      this.init(this.isDropOff);
    }

  }

  private getCompletedFlightBooking(bookings) {
    var returnedBooking = null;
    this.bookings.forEach(element => {
      if (element.province != null && element.destinationProvince != null
        || element.status == "Processing Flight" || element.status == "Booking successfully complete") {
        returnedBooking = element;
      }
    });
    return returnedBooking;
  }

  public init(isDropOff) {
    this.message = "Tracking number is " + this.bookingObj.trackNo;
    if (isDropOff) {
      this.determineStatusDropOff();
    } else {
      this.determineStatusPickUp();
    }
  }

  private determineStatusPickUp() {
    if (this.bookingObj.status == "Booking taken already") { }
    else if (this.bookingObj.status == "Driver in transit") { }
    else if (this.bookingObj.status == "Awaiting driver to be assigned") { }
  }

  private determineStatusDropOff() {
    // if (this.bookingObj.status == "Booking taken already") {
    if (this.bookingObj.status == "Accepted") {
      this.firstCardDisplay = true;
    } else if (this.bookingObj.status == "Driver in transit") {
      this.fourthCardDisplay = true;
    } else if (this.bookingObj.status == "Awaiting driver to be assigned") {
      this.flightInfo = this.bookingObj.flightInfo.flightNo;
      this.arrivalDate = this.bookingObj.flightInfo.arrivalDate;
      this.departDate = this.bookingObj.flightInfo.departureDate;
    }
  }

  public getFlightBooking(bookings) {
    bookings.forEach(element => {
      if (element.status == "Processing Flight") {
        this.parentBooking = element;
      }
    });
  }

}
