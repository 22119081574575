import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { LoginService } from '../../services/login.service';
import { AdminService } from '../../services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-add-retail',
  templateUrl: './add-retail.component.html',
  styleUrls: ['./add-retail.component.css']
})
export class AddRetailComponent implements OnInit {

  @ViewChild('companyForm', { static: true }) companyForm: NgForm;
  user: any = {};

  companyName: string = '';
  companyReg: string = '';
  loader: boolean = false;
  error: string = '';
  vehiclesTypeRate : any = [];
  documentMsg : string = "";
  userObject: any;

  constructor(private service : AdminService, private router : Router,
     private loginService: LoginService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    //console.log(this.user);
  }

  public onRetailCompany() {
    var token = localStorage.getItem('token');
    this.loadservice.addloader();
    var companyInfo = {
      "companyName": this.companyName,
      "companyReg": this.companyReg
    };


    //var object = JSON.parse(localStorage.getItem('userData'));


    this.loginService.company(companyInfo, this.user.token, this.user)
      .subscribe(
        (response: Response) => {
          if (response.status == 200) {
            this.companyForm.reset();
            this.loadservice.removeloader();
           this.error = "Retail company added successfully. Droppa will activate your retail soon.";
           $('#results').modal('show');
           this.router.navigate(['/user']);
          } else {
            this.loadservice.removeloader();
            this.error = "Error while adding company please contact Droppa.";
            $('#results').modal('show');
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.documentMsg = "Error while adding retail company.";
          $('#results').modal('show');
        }
      );

  }

  public close() {
    this.router.navigate(['/user']);
  }
}
