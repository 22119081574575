import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-vodacom-promo-code',
  templateUrl: './vodacom-promo-code.component.html',
  styleUrls: ['./vodacom-promo-code.component.css', '../admin-portal/admin-portal.component.css']
})
export class VodacomPromoCodeComponent implements OnInit {

  userObj: any = {};
  promoName: string = '';
  promoPrice: number = 0;
  registrationMsg: string = '';
  updateMsg: string = '';
  invalidDates: string = '';
  promoStartDate: any;
  promoEndDate: any;
  today = new Date();
  yesterday = new Date(this.today);
  public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  startDate: any;
  endDate: any;
  viewPromos: any = [];
  active: any;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.getPromo(this.userObj.token)
  }

  public getPromo(token) {
    this.adminService.getVodacomPromoCodes(token).subscribe(response => {
      this.viewPromos = response;
    }, error => {
      console.log(error);
    }, () => {

    })
  }

  public registerPromo() {
    var pickUpDate = new Date(this.startDate);
    var returnDate = new Date(this.endDate);
    let startDays = moment(returnDate).diff(moment(pickUpDate), 'days');
    let promoObj = {
      "code": this.promoName.toUpperCase(),
      "amount": this.promoPrice,
      "startDate": pickUpDate,
      "endDate": returnDate
    }
    if (startDays < 0) {
      this.invalidDates = 'Your end date cannot be before the start date. Please select the dates accordingly.';
    }
    else {
      this.invalidDates = '';
      this.adminService.saveVodacomPromoCodes(this.userObj.token, promoObj).subscribe(response => {
        this.getPromo(this.userObj.token)
        this.registrationMsg = 'Promo Code registered successfully';
      }, error => {
        console.log(error);
      }, () => {
        this.promoName = '';
        this.promoPrice = 0;
        this.startDate = '';
        this.endDate = '';
      })
    }
  }

  public updatePromo(promos, status) {
    this.adminService.updateVodacomPromoCodes(this.userObj.token, promos.code, status).subscribe(response => {
      console.log(response);
      this.updateMsg = 'Promo code successfully updated.';
      this.getPromo(this.userObj.token)
    }, error => {
      console.log(error);
    }, () => {
      console.log('Promo updated.');
    })

  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
