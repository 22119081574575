import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';

import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css',  '../sign-in/bootstrap.css']
})
export class PasswordResetComponent implements OnInit {

  username : string;

  sub : any;
  error : string = '';
  success : string = '';
  submitted : boolean = true;

  constructor(private loginService : LoginService) { }

  ngOnInit() {
  }

  public requestPassword() {
    this.submitted = false;
    this.error = '';
    this.success = '';
    this.loginService.requestPwdReset(this.username.toLowerCase().trim())
    .subscribe(
      (res : Response) => {
        if(res.status == 200) {
          this.success = "Email sent, Check your emails for verification.";
        }
        
      },
      (err)  => {
        this.submitted = false;
        this.error = "Email address doesn't exist."
      }
    )

  }
  
}
