import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpResponse } from '@angular/common/http'
import { AdminService } from '../../services/admin.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-vehicle-rate',
  templateUrl: './vehicle-rate.component.html',
  styleUrls: ['./vehicle-rate.component.css', '../admin-portal/admin-portal.component.css']
})
export class VehicleRateComponent implements OnInit {

  @ViewChild('typeRateForm', { static: true }) addVForm: NgForm;
  @ViewChild('typeRateUpdateForm', { static: true }) updateVForm: NgForm;

  vehiclesRates : any = [];
  vehicleTypeName : string;
  kilometres : number;
  capacity : number;
  basePrice: any;
  name: string;
  description: any
  user : any;
  updateKilometres :number;
  updateBasePrice : number;
  retail: any;
  textMessage : string = "";
  updatedTypeRate : any;
  updatedTypeName : string;

  constructor(private service : AdminService) { }

  ngOnInit() {
    window.scroll(0, 0);
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.retail = JSON.parse(localStorage.getItem('selectedRetail'));
    this.service.getVehicleRates(object.token).subscribe(
      (data) => {
        this.vehiclesRates = data;
      }
    );
  }

  public addVehicleType() {

    this.textMessage = "";

    var typeRate = {
      "vehicleType" : this.vehicleTypeName ,
			"kilometer" : this.kilometres ,
      "basePrice": this.basePrice,
      "capacity":this.capacity,
      "description" : this.description,
      "name" : this.name
    }
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.addTypeRate(object.token, typeRate).subscribe(
      (data) => {
        this.addVForm.reset();
        this.textMessage = "Vehicle Type rate successfully added.";
      },
      (error) => {
        this.textMessage = "Error occured, vehicle type rate not added.";
      }
    );
  }

  public updateVehicleType() {
    this.textMessage = "";

    this.updatedTypeRate.kilometer = this.updateKilometres;
    this.updatedTypeRate.basePrice = this.updateBasePrice;

    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.updateTypeRate(object.token, this.updatedTypeRate).subscribe(
      (data) => {
        this.updateVForm.reset();
        this.textMessage = "Vehicle Type rate successfully updated.";
      },
      (error) => {
        this.textMessage = "Error occured, vehicle type rate not updated.";
      }
    );
  }

  public getVehicleRate(rate){
    this.textMessage = "";
    console.log(rate);
    this.addVForm.reset();
    this.updateVForm.reset();
    this.updatedTypeRate = rate;
    this.updateKilometres = rate.kilometer;
    this.updateBasePrice = rate.basePrice;
    this.updatedTypeName = rate.vehicleType;
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}
