import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-booking-otp',
  templateUrl: './booking-otp.component.html',
  styleUrls: ['./booking-otp.component.css']
})
export class BookingOtpComponent implements OnInit {

  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';


  otp: string;
  password: string;
  username: string;
  error: any;

 
  constructor(private loginService : LoginService, private router: Router) { }

  ngOnInit() {
    this.username =  localStorage.getItem('username');
  }

  public requestPassword() {

    var data = {
      "username": this.username, 
      "resetToken": this.otp,
      "newPwd": this.password
    }

   
    this.loginService.confirmBookingOTP(data)
    .subscribe(
      (res : Response) => {
        console.log({res})
        this.router.navigateByUrl('/Booking-SignUp');
      },
      (err)  => {
        this.error = "password not reseted!."
      }
    )

  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }
}
