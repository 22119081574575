import { Component, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-flight-tracking',
  templateUrl: './flight-tracking.component.html',
  styleUrls: ['./flight-tracking.component.css']
})

export class FlightTrackingComponent implements OnInit {

  bookings: any = [];
  flightBookingStatus: any = [];
  pickUpBooking: any;
  dropOffBooking: any;
  bookingObj: any;
  parentBooking: any;
  isDropOff = false;
  message = '';

  flightInfo: any;
  arrivalDate: any;
  departDate: any;
  fourthCardDisplay = false;
  firstCardDisplay = false;

  driver: any;
  person = {
    name: '',
    surname: '',
    registrationNum: '',
    contactNum: ''
  };

  constructor(private onGetBookingStatus: UserService, private bookService: BookingService) { }

  ngOnInit() {
    this.bookings = JSON.parse(localStorage.getItem('retrievedFlightTrackBooking'));
    // console.log(this.bookings);

    // Get parent booking (the flight journey)
    this.getFlightBooking(this.bookings);

    // Get flight legs (the pick-up and drop-off journeys)
    this.bookings.forEach(element => {
      if (element.status !== 'Processing Flight' && element.province === this.parentBooking.province) {
        this.pickUpBooking = element;
      } else
        if (element.status !== 'Processing Flight' && element.province === this.parentBooking.destinationProvince) {
          this.dropOffBooking = element;
        }
    });

    // Determine which object to use (1st leg / Parent / 2nd leg)
    if (this.dropOffBooking !== undefined) {
      if (this.dropOffBooking.status !== 'Awaiting driver to be assigned' &&
        this.dropOffBooking.status !== 'Accepted' && this.dropOffBooking.isItemPicked) {
        this.bookingObj = this.dropOffBooking;
        this.isDropOff = true;
      } else {
        this.bookingObj = this.parentBooking;
        this.flightInfo = this.dropOffBooking.flightInfo.flightNo;
        this.arrivalDate = this.dropOffBooking.flightInfo.arrivalDate;
        this.departDate = this.dropOffBooking.flightInfo.departureDate;
      }
    } else {
      this.bookingObj = this.pickUpBooking;
    }

    // Get driver
    if (this.bookingObj[0].driverOid != null && this.bookingObj[0].driverOid !== undefined) {
      this.onGetBookingStatus.getTrackBookingDriver(this.bookingObj[0].driverOid).subscribe(
        (res) => {
          this.driver = res;
          this.person = {
            name: this.driver.person.firstName,
            surname: this.driver.person.surname,
            registrationNum: this.driver.vehicle.registrationNumber,
            contactNum: this.driver.person.mobile
          };
        }
      );
    }
  }

  private getCompletedFlightBooking(bookings) {
    var returnedBooking = null;

    this.bookings.forEach(element => {
      if (element.province != null && element.destinationProvince != null
        || element.status == "Processing Flight" || element.status == "Booking successfully complete") {
        returnedBooking = element;
      }
    });

    return returnedBooking;
  }

  //this Method states that the booking is complete
  private initTwo() {

    this.message = "Tracking number " + this.bookingObj.trackNo;

    //  layoutBookingCompleted.setVisibility(View.VISIBLE);
    // background();
  }

  public init(isDropOff) {

    this.message = "Tracking number is " + this.bookingObj.trackNo;

    if (isDropOff) {
      this.determineStatusDropOff();
    } else {
      this.determineStatusPickUp();
    }

    // background();
  }

  private determineStatusPickUp() {
    if (this.bookingObj.status == "Booking taken already") {
      // layoutBookingAccepted.setVisibility(View.VISIBLE);
    } else if (this.bookingObj.status == "Driver in transit") {
      //layoutPickUpAddress.setVisibility(View.VISIBLE);
      //getDriver(booking.driverOid);

    } else if (this.bookingObj.status == "Awaiting driver to be assigned") {
      // snackBar("Your booking is still awaiting driver allocation, please wait patiently.");
    }
  }

  private determineStatusDropOff() {
    if (this.bookingObj.status == "Booking taken already") {
      this.firstCardDisplay = true;
      // layoutDriverAtDropOff.setVisibility(View.VISIBLE);
    } else if (this.bookingObj.status == "Driver in transit") {
      this.fourthCardDisplay = true;
      // This is where I display the 4th card on the view
      //layoutDriverAtDropOff.setVisibility(View.VISIBLE);
      //getDriver(booking.driverOid);
    } else if (this.bookingObj.status == "Awaiting driver to be assigned") {
      // console.log(this.bookingObj.flightInfo);
      // console.log(this.bookingObj.flightInfo.flightNo);
      // console.log(this.bookingObj.flightInfo.arrivalDate);
      // console.log(this.bookingObj.flightInfo.departureDate);
      this.flightInfo = this.bookingObj.flightInfo.flightNo;
      this.arrivalDate = this.bookingObj.flightInfo.arrivalDate;
      this.departDate = this.bookingObj.flightInfo.departureDate;
      //String flightNo = "Flight No: "+ booking.getFlightInfo().getFlightNo();
      //String expectedDate = "Expected Arrival Time: "+booking.getFlightInfo().getArrivalDate().split("T")[0] + " "+booking.getFlightInfo().getArrivalDate().split("T")[1];
      //String departureDate = "Departure Time: "+booking.getFlightInfo().getDepartureDate().split("T")[0] + " "+booking.getFlightInfo().getDepartureDate().split("T")[1];

      // This iis the logic for displaying the 3rd card on the view
      //layoutDriverOnWay.setVisibility(View.VISIBLE);
      //snackBar("Your booking is still awaiting driver allocation, please wait patiently.");
    }
  }

  public getFlightBooking(bookings) {
    // BookingCreate create = null;
    // var create = null;
    bookings.forEach(element => {
      if (element.status === 'Processing Flight') {
        this.parentBooking = element;
      }
    });
  }

  mapPage = false;
  showMap = false;

  public viewBooking() {
    this.mapPage = true;
    this.showMap = true;
  }

  public cardTracking() {
    this.mapPage = false;
  }

}
