import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-all-vehicles',
  templateUrl: './all-vehicles.component.html',
  styleUrls: ['./all-vehicles.component.css',   '../admin-portal/admin-portal.component.css']
})
export class AllVehiclesComponent implements OnInit {

  loader: boolean;
  adminUser: any;
  vehicles: any = [];
  driverData: any;
  findVehicle: string = '';
  SearchQueryVehicle = '1';
  status = 'status';
  msg: any;


  totalNumberOfVehicles = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    //this.getVehicle();

    this.SearchQueryVehicle = localStorage.getItem('SearchQueryVehicle') !== null ? (localStorage.getItem('SearchQueryVehicle')) : '1';
    this.findVehicle = localStorage.getItem('findVehicle') !== null ? (localStorage.getItem('findVehicle')) : '';
    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;

    this.loadservice.addloader();
    this.getVehiclesWithPagination(1);
  }

  public getVehicle() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getAllVehicles(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.vehicles = res;
        }, error => {
          this.loadservice.removeloader();
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }



  searchTypeChanged(){
    this.findVehicle = '';
    this.getVehiclesWithPagination(0);
  }


  searchByField(){
    //console.log();
    this.getVehiclesWithPagination(1);

  }
  public getVehiclesWithPagination(searchType: number) {
    this.loadservice.addloader();
    if(searchType === 0)
      this.resetPagination();

    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    const filter = {
      fieldToFilter: this.SearchQueryVehicle,
      searchValue: this.findVehicle,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    //console.log(filter);

    this.adminService.getAllVehiclesFilter(this.adminUser.token, filter)
      .subscribe(
        (res) => {
          //console.log(res);
          this.loadservice.removeloader();
          this.vehicles = res.content;
          this.totalNumberOfVehicles = res.totalElement;
        }, error => {
          this.loadservice.removeloader();
          if (error.statusText == 'Unknown Error' || error.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public onVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    localStorage.setItem('vehicleFromAllVehicles', 'vehicleFromAllVehicles');

    localStorage.setItem('SearchQueryVehicle', this.SearchQueryVehicle);
    localStorage.setItem('findVehicle', this.findVehicle);
    localStorage.setItem('pageNumber', this.pageNumber.toString());
    localStorage.setItem('itemsPerPage', this.itemsPerPage.toString());
    this.router.navigate(['/update-vehicle']);
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }


  resetPagination(){
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getVehiclesWithPagination(1);
  }

  ngOnDestroy(){

    //console.log(this.router.getCurrentNavigation().finalUrl.toString());

    if(this.router.getCurrentNavigation().finalUrl.toString() !== '/update-vehicle') {
      localStorage.removeItem('pageNumber');
      localStorage.removeItem('itemsPerPage');
      localStorage.removeItem('SearchQueryVehicle');
      localStorage.removeItem('findVehicle');
    }
  }


}
