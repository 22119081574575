import { Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { DevelopersPortalComponent } from '../developers-portal/developers-portal.component';
import { SupportComponent } from '../developers-portal/support/support.component';
import { SignUpComponent } from '../developers-portal/sign-up/sign-up.component';
import { LogInComponent } from '../developers-portal/log-in/log-in.component';
import { ConfirmOtpComponent } from '../developers-portal/confirm-otp/confirm-otp.component';
import { DashboardComponent } from '../developers-portal/dashboard/dashboard.component';
import { ForgotPasswordComponent } from '../developers-portal/forgot-password/forgot-password.component';
import { DevelopersConfirmOtpComponent } from '../developers-portal/developers-confirm-otp/developers-confirm-otp.component';
import { AccountNotActiveComponent } from '../developers-portal/account-not-active/account-not-active.component';
import { OnlyLoggedInUsersGuard } from '../guards/onlyLoggedIn.guard';
import { AlwaysAuthGuard } from '../guards/alwaysAuth.guard';

export const FIFTH_ROUTES : Routes = [
    { path : '', component : HomeComponent},
    { path : 'developers-portal', component : DevelopersPortalComponent},
    { path : 'support', component : SupportComponent},
    { path : 'developers-dashboard', component : DashboardComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
    { path : 'accont-not-active', component : AccountNotActiveComponent},
    { path : 'developers-sign-in', component : LogInComponent},
    { path : 'developers-sign-up', component : SignUpComponent},
    { path : 'developers-confirm-otp', component : ConfirmOtpComponent},
    { path : 'forgot-password', component : ForgotPasswordComponent},
    { path : 'developers-ConfirmOtp', component : DevelopersConfirmOtpComponent}
]
