import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-terms',
  templateUrl: './mobile-terms.component.html',
  styleUrls: ['./mobile-terms.component.css']
})
export class MobileTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
