import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserLoggInService } from '../../services/loggedInUser.service';


@Component({
  selector: 'app-retail-navbar',
  templateUrl: './retail-navbar.component.html',
  styleUrls: ['./retail-navbar.component.css', './v-nav-menu.css',
  './theme-responsive.css']
})
export class RetailNavbarComponent implements OnInit {

  subscription: Subscription;
  name: string;
  userObject: any;
  retail: any;
  showSkynet=false;
  center;

  backgroundControl = 0;

  constructor(private nameService: SharedService,
    private route: Router, private userService: UserLoggInService,private userservice: UserService) {

    this.subscription = this.nameService.message.subscribe(
      (message) => {
        this.userObject = JSON.parse(localStorage.getItem('userInfo'));
        this.name = this.userObject.owner.firstName;
        //this.route.navigateByUrl('/user');
        //this.nameService.getName();
      }
    );
    // this.subscription.unsubscribe();

  }

  gobackHome() {

    if (this.userObject.owner.retailId != null) {
      this.route.navigateByUrl('/retail-profile');
    } else {
      this.route.navigateByUrl('/driver-profile');
      // console.log("driver-profile")
    }
  }


  ngOnInit() {
    this.userObject = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.userObject);
    if (this.userObject != null) {
      this.name = this.userObject.owner.firstName;
      this.getRetailObj();
    }
    this.center = window.innerWidth / 2 + 'px';
    // console.log(this.center);


  }


  public logout() {
    this.userService.clear();
    localStorage.removeItem('userInfoBooking');
    // console.log(JSON.parse(localStorage.getItem('userInfo')));
    this.route.navigateByUrl('/');
  }


  public home() {
    // console.log(JSON.parse(localStorage.getItem('userInfoBooking')));
  }

  onDeactivate() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
 }
 public getRetailObj() {
  this.userservice.retriveRetail(this.userObject.token, this.userObject.owner.retailId).subscribe(
    (res) => {
      this.retail = res.json();
      this.showSkynet = this.retail.canAssignDriver;

      if (this.showSkynet) {
        if (this.retail.name === 'Quro Medical') {
          this.backgroundControl = 3;
        } else {
          this.backgroundControl = 1;
        }
      } else {
        this.backgroundControl = 2;
      }
      // console.log(res.json());
    }
  );
 }

}
