import { Component, OnInit, ViewChild,isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Response } from '@angular/http';
import { Router } from '@angular/router';

import 'rxjs/Rx';
import { LoginService } from '../../services/login.service';
import { User } from '../../models/user';
import { Login } from '../../models/login';
import { from } from 'rxjs/observable/from';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-become-droppa',
  templateUrl: './become-droppa.component.html',
  styleUrls: ['./become-droppa.component.css']
})
export class BecomeDroppaComponent implements OnInit {

  @ViewChild('userForms', { static: true }) signupForm: NgForm;
  @ViewChild('otpForm', { static: true }) otpForm: NgForm;
  @ViewChild('infoForm', { static: true }) infoForm: NgForm;

  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';

  isDevMode=isDevMode()

  name: string;
  surname: string;
  email: string;
  phone: string;
  password: string;

  otpValidator: boolean = true;
  companyInfoValidator: boolean = true;
  documentValidator: boolean = true;
  diskExpiryDate: any;
  diskExpiryDates: any;

  OTPerror: string = "";
  numberOTP: string;
  loader: boolean = false;
  error: string;
  user: User;
  loginObj: Login;
  color: string="green";
  otp: number;

  province: string=null;
  validateProvince: string=null;
  provinceError:boolean=false;


  companyName: string;
  companyReg: string;
  make: any;
  registration: string;
  model: any;
  vinNo: string;
  Vcolor: string;
  type: any=null;

  base64ID: string = undefined;
  base64DISC: string = undefined;
  base64CIPC: string;

  idCopy;
  discCopy;
  cpicCopy;
  idName: undefined;
  diskName: undefined;
  cpicName: undefined;

  vehiclesTypeRate: any = [];

  canopy: boolean = false;
  userObject: any;
  captcha: any;
  style: any;
  documentMsg: string ;
  myIndex: any = 0
  msg: any;

  datePipe: DatePipe;
  companyInfoProvince: string;
  vatNo: any;
  done:Boolean=false;
  uploaded:Boolean=false;

  isRobot=true
  capture: any;
  UAT_SITE_KEY:String
  captchaError=""


  constructor(private loginService: LoginService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.getVehicleTypeRates();
    window.scrollTo(0, 0);
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  public checkNameValidation(firstname):Boolean{
    const namelist=firstname.split(" ");
    let isChecked=true;
    namelist.forEach(name => {
      if(!(/^[a-zA-Z]+$/.test(name))){
        isChecked=false;
      }
    });
    return isChecked;
  }

  public register() {
    this.provinceError=false;
    // this.otpValidator = false;
    this.captchaError = "";
    this.error = '';
    this.loadservice.addloader();
    this.user = new User(this.name, this.surname,
    this.email.toLowerCase().trim(), this.phone);
    this.loginObj = new Login(this.password, this.email.toLowerCase().trim());
    this.user.login = this.loginObj;
    this.numberOTP = this.phone;
    if(this.isRobot&&!this.isDevMode){
      this.captchaError = 'Please click the checkbox to varify you are a human.';
    }
    else if (this.checkNameValidation(this.name.trim()) && /^[a-zA-Z]+$/.test(this.surname.trim())) {
      /*if (this.validateProvince == "GAUTENG") {
        // $('#validateProvince').modal('show');
        this.loadservice.a()
        this.provinceError=true
      }*/
      /*else
      {

      }*/
      this.loginService.registerCompany(this.user)
        .subscribe(
          (response: any) => {
            this.color = "rgb(28, 193, 239)";
            this.error="";
            this.documentMsg="";
            this.loadservice.removeloader();
            this.companyInfoValidator=false
            this.setStep(2)
            localStorage.removeItem('userData');
            localStorage.removeItem('signedUser');
            localStorage.setItem('userData', JSON.stringify(response));
            localStorage.setItem("signedUser", JSON.stringify({
              email: this.email.toLowerCase().trim(),
              password: this.password
            }));
            $(".otp").removeClass("checkedTab");
            $(".otp").addClass("btn_default");
            $('#myTab button[href="#otp"]').tab('show');
          },
          (err) => {
            this.loadservice.removeloader();
            this.color = "red";
            this.error = "Error while registering, mobile or email already exist.";
            this.documentMsg = "Error while registering, mobile or email already exist.";
            // $('#results').modal('show');
          }
        );
    }
    else {
      this.loadservice.removeloader();
      this.documentMsg = "Name or surname should not contain special characters and numbers.";
      // $('#results').modal('show');
    }
  }

  public checkedCanopy(canopy: any) {
    if (canopy) {
      this.canopy = true;
    } else {
      this.canopy = false;
    }
  }

  public confirmOTP() {
    var object = JSON.parse(localStorage.getItem('signedUser'))
    if (this.otp != null) {
      this.loadservice.addloader();
      this.loginService.confirmOTP(this.otp, this.numberOTP)
        .subscribe(
          (response: Response) => {
            if (response.status == 200) {
              if (response.json().confirmed) {
                this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                  (response: any) => {
                    localStorage.setItem('token', response.token);
                    localStorage.removeItem('userData');
                    localStorage.setItem('userData2', response);
                    localStorage.setItem('userData', response);
                    this.userObject = response;
                    this.loadservice.removeloader();
                    this.documentValidator=false;
                    this.setStep(3)
                    // this.companyInfoValidator = false;
                    $(".info").removeClass("checkedTab");
                    $(".info").addClass("btn_default");
                    $('#myTab button[href="#companyDetails"]').tab('show');
                    this.loadservice.removeloader();
                  }
                );
              } else {
                this.loadservice.removeloader();
                this.color="red"
                this.OTPerror = "Error, the OTP number provided is invalid.";
                this.documentMsg = "Error, the OTP number provided is invalid.";
                // $('#results').modal('show');
              }

            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Error, please enter valid OTP.";
              this.documentMsg = "Error, please enter valid OTP.";
              // $('#results').modal('show');
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error occurred, please contact Droppa.";
            this.documentMsg = "Error occurred, please contact Droppa.";
            // $('#results').modal('show');
          }
        );

    } else {
      this.loadservice.removeloader();
      this.documentMsg = "Error please enter the otp.";
      // $('#results').modal('show');
    }

  }

  public companyInfo() {
    var token = localStorage.getItem('token');
    var currentDate = new Date();
    var dateValue = this.diskExpiryDate;
    var realTime = new Date(dateValue);
    var currentDate = new Date();
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var diskDate = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var dateSame = diskDate === dateFormat;
    var dateDiffer = diskDate > dateFormat;
    var dateStart = new Date(currentDate);
    var dateEnd = new Date(diskDate);
    var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

    this.loadservice.addloader();
    var comppanyInfo = {
      "companyName": this.companyName,
      "companyReg": this.companyReg,
      "province": this.province,
      "make": this.make,
      "model": this.model,
      "registration": this.registration,
      "vinNo": this.vinNo,
      "Vcolor": this.Vcolor,
      "type": this.type,
      "hasCanopy": this.canopy,
      "diskDate": diskDate,
      "vatNo": this.vatNo
    };

    if (dateSame === true || dateDiffer === true) {
      // if (this.province == "GAUTENG" && (comppanyInfo.type == "ONE_HALF_TRUCK" || comppanyInfo.type == "ONE_TON_TRUCK" || comppanyInfo.type == "MINI_VAN")) {
      //   this.companyInfoProvince = "Registration for Gauteng is currently closed.\n If you are registering a 4 ton or 8 ton, please contact Droppa on (012 880 2159) or email at info@droppa.co.za.";
      //   $('#provinceCompanyInfoCheck').modal('show');
      //   this.loadservice.a()
      // }
      // else
      if (Difference_In_Days > 7) {
          // if ((comppanyInfo.type == "ONE_HALF_TRUCK" || comppanyInfo.type == "ONE_TON_TRUCK" || comppanyInfo.type == "MINI_VAN") && comppanyInfo.province == "GAUTENG")
          // {
          //   this.msg = "Please note: We no longer accepting the selected vehicle type, Registration will open in January 2020"
          //   $('#vehicleModel').modal('show');
          //   this.loadservice.a()
          // }
          // else
          if (comppanyInfo.type == "ONE_HALF_TRUCK" && comppanyInfo.model <= 2012 || comppanyInfo.type == "ONE_TON_TRUCK" && comppanyInfo.model <= 2012) {
            this.msg = "We don't accept any vehicle made before 2012"
            $('#vehicleModel').modal('show');
            this.loadservice.removeloader()
          } else {
            this.loadservice.addloader();
            this.loginService.companyInfo(comppanyInfo, token, this.userObject)
              .subscribe(
                (response: Response) => {
                  if (response.status == 200) {
                    this.documentValidator = false;
                    this.loadservice.removeloader();
                    this.documentMsg ="";
                    this.otpValidator = false;
                    this.done=true;
                    this.setStep(4)
                    $(".docs").removeClass("checkedTab");
                    $(".docs").addClass("btn_default");
                    $('#myTab button[href="#documents"]').tab('show');
                  } else {
                    this.loadservice.removeloader();
                    this.color="red"
                    this.documentMsg = "Error while adding company please contact Droppa.";
                    // $('#results').modal('show');
                  }
                },
                (err) => {
                  this.loadservice.removeloader();
                  this.OTPerror = "Error while adding company";
                  this.documentMsg = "Error while adding company.";
                  // $('#results').modal('show');
                }
              );
            this.loadservice.removeloader();
          }
        }
        else {
          this.msg = 'License disk is expiring in less than 7 days. Please renew it first to create an account.';
          $('#vehicleModel').modal('show');
          this.loadservice.removeloader();
        }
    }
    else {
      this.msg = 'Licence disk expiry date has already passed.';
      $('#vehicleModel').modal('show');
      this.loadservice.removeloader();
    }

  }

  handleFileSelect(evt, fileType: string) {
    console.log("evt.target.files",evt.target.files)
    var files = evt.target.files;
    var file = files[0];
    if (fileType == 'ID') {
      if (files && file) {
        this.idName = file.name;
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'DISC') {
      if (files && file) {
        this.diskName = file.name;
        var reader = new FileReader();
        reader.onload = this._discFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'CPIC') {
      if (files && file) {
        this.cpicName = file.name;
        var reader = new FileReader();
        reader.onload = this._cpicFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64ID = btoa(binaryString);
  }

  _discFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64DISC = btoa(binaryString);
  }

  _cpicFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64CIPC = btoa(binaryString);
  }

  public onDocument() {
    var object = this.userObject;
    var IDData = {
      "personOId": object.owner.oid,
      "type": "ID_COPY",
      "document": this.base64ID
    };

    var CIPCData = {
      "personOId": object.owner.oid,
      "type": "CIPC_COPY",
      "document": this.base64CIPC
    };

    var DISCData = {
      "personOId": object.owner.oid,
      "type": "DISC_COPY",
      "document": this.base64DISC
    };

    if (this.base64ID != null && this.base64DISC != null) {
      this.loadservice.addloader();
      var token = localStorage.getItem('token');
      this.loginService.uploadDocs(IDData, CIPCData, DISCData, token)
        .subscribe(
          (response: Response) => {
            if (response.status == 200) {
              this.loadservice.removeloader();
              this.documentMsg = 'You have successfully completed all steps to become Droppa, goodluck!';
              this.uploaded=true;
              // $('#results').modal('show');
            } else {
              alert(this.OTPerror);
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error while adding saving documents.";
            this.documentMsg = 'Error while adding saving documents.';
            // $('#results').modal('show');
          }
        );
    } else {
      this.documentMsg = 'Please attach all documents.';
      // $('#results').modal('show');
    }
  }

  public onHome() {
    this.router.navigateByUrl('/');
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }

  handleCorrectCaptcha(event) {


    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

  public omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  public getVehicleTypeRates() {
    this.loginService.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
      }
    )
  }

  step:any=1;
  public registerpopup(){
    $("#registerDriver").modal("show");
  }
  public setStep(step){
    this.step=step;
  }
}
