import {Component, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {Router} from '@angular/router';
import {AdminService} from '../../services/admin.service';
import {AngularFireDatabase} from '@angular/fire/database';

declare var $: any;

@Component({
  selector: 'app-flight-booking-details',
  templateUrl: './flight-booking-details.component.html',
  styleUrls: ['./flight-booking-details.component.css']
})
export class FlightBookingDetailsComponent implements OnInit {

  user: any;

  flightBooking: any;
  listOfFlightBookings = [];

  retailDrivers = [];
  retailDriversToFilter = [];

  driversWithLocation = [];
  driversWithNoLocation = [];
  closeRetailDriverMapper = [];
  loadClose = true;

  driversListTitle = 'List of drivers';
  displayDriverMap = false;
  mapDriversButtonTxt = 'Show drivers map';
  mapOpen = false;

  bookingData: any;
  pickUpCoordinates = {lat: 0, lng: 0};

  bookingNotes = '';
  showNotesError = false;
  addNotesMsg = 'Please enter notes to update';

  icon = 'assets/mapmarker.png';
  package = 'assets/package.png';
  uberDriver = 'assets/uberDriver.png';

  selectedVehicleType = 'All';
  driverDetails = '';
  failedToAssignMsg = '';

  constructor(private bookingService: BookingService, private route: Router, private adminService: AdminService,
              public db: AngularFireDatabase) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.flightBooking = JSON.parse(localStorage.getItem('selectedFlightBooking'));
    console.log(this.flightBooking);

    // Get flight booking children
    this.bookingService.getBookingByTrackNum(this.flightBooking.trackNo.toUpperCase()).subscribe(
      (response: any) => {
        this.listOfFlightBookings = response.json();
        this.getDrivers(this.listOfFlightBookings);
        console.log(this.listOfFlightBookings);
      },
      (error) => {
        console.log(error);
      });
  }

  public getDrivers(listOfBookings) {
    this.bookingService.getClosedDriver(this.user.token, listOfBookings[0].oid).subscribe(
      (res) => {
        // console.log(res);
        this.retailDrivers = res;
        this.retailDriversToFilter = res;
        const driversWithLocation = res.filter(ress => ress.tracking);

        this.trackDriverLocation(driversWithLocation);
      },
      (error) => {
        console.log('error', error);
      },
    );
  }

  public trackDriverLocation(driversToCheck) {
    driversToCheck.forEach((driver) => {
      // console.log(driver);
      const cellNumber = driver.tracking.driverPhone;

      this.db.object('driverTracking/' + cellNumber).snapshotChanges().map( (res) => res.payload.val()).subscribe(
        (res: any) => {
          this.saveSubscribeData(res, driver);
        }, (error) => {
          console.log(error);
        });
    });
  }

  public saveSubscribeData(response, driver) {

    const date = new Date(driver.tracking.lastSeen);
    const currentDate = new Date();

    const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);

    const trackingData = {
      lat: response ? response.lat : parseFloat(driver.tracking.location.coordinates[0]),
      lng: response ? response.lng : parseFloat(driver.tracking.location.coordinates[1]),
      driverName: driver.tracking.driverName,
      driverPhone: driver.tracking.driverPhone,
      address: driver.tracking.location.address.substring(0, 25),
      driver: driver.driver,
      isOnline: !!response,
      status: driver.status.toLowerCase(),
      lastseen: driver.tracking.lastSeen,
      pipe: days >= 1 ? 'longDate' : 'shortTime'
    };

    this.closeRetailDriverMapper.push(trackingData);

    if (this.driversWithLocation.length === this.closeRetailDriverMapper.length) {
      this.loadClose = false;
    }
  }

  backToSameDayTab() {
    localStorage.setItem('skyNetSameDay', String(true));
    this.route.navigate(['/retail-profile']);
  }

  public selectDriver(booking) {
    this.bookingData = booking;
    this.pickUpCoordinates = {
      lat: parseFloat(this.bookingData.pickUpCoordinate.coordinates[0]),
      lng: parseFloat(this.bookingData.pickUpCoordinate.coordinates[1])
    };
    $('#listOfDrivers').modal('show');
  }

  showDriversMap() {
    this.mapOpen = true;
    this.displayDriverMap = !this.displayDriverMap;
    if (this.displayDriverMap) {
      this.driversListTitle = 'Map of drivers';
      this.mapDriversButtonTxt = 'Show drivers list';
    } else {
      this.driversListTitle = 'List of drivers';
      this.mapDriversButtonTxt = 'Show drivers map';
    }
  }

  public filterListOfDrivers(filterDropDown, driverDetails) {
    this.retailDriversToFilter = this.retailDrivers;

    if (filterDropDown !== 'All') {
      this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
        return  (driver.driver.vehicle.type.toLowerCase().includes(filterDropDown.toLowerCase())
        );
      });
    }

    this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
      return  (
        driver.driver.person.mobile.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.person.firstName.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.person.surname.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.location.toLowerCase().includes(driverDetails.toLowerCase())
      );
    });
  }

  public assignDriverOnAwaiting(driver) {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.assignDriverToBucketBooking(object.token, this.flightBooking.oid, driver.oid).subscribe(
      (res) => {
        // Refresh list
        this.bookingService.getBookingByTrackNum(this.flightBooking.trackNo.toUpperCase()).subscribe(
          (response: any) => {
            this.listOfFlightBookings = response.json();
          },
          (error) => {
            console.log(error);
          });
      }, (error) => {
        // console.log(error);
        $('#failedToAssignDriver').modal({backdrop: 'static', keyboard: false});
      }
    );
}

  public editBooking(booking) {
    // Set editable booking variables
    this.bookingData = booking;
    $('#editBooking').modal({backdrop: 'static', keyboard: false});
  }

  addNotesToBooking() {
    this.showNotesError = false;
    if (this.bookingNotes.length !== 0) {
      // Add notes to booking
      this.bookingData.notes = this.bookingNotes;
    } else {
      this.showNotesError = true;
    }
  }

}
