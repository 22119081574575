import {Component, OnInit, isDevMode} from '@angular/core';
import {ContractOwnerService} from "../../services/contract-owner.service";
import {UserInterface} from "../../interfaces/user.interface";
import {LoaderService} from "../../services/loader.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SharedService} from "../../services/shared.service";

declare var $: any

@Component({
  selector: 'app-droppa-contract-owners-details-api',
  templateUrl: './droppa-contract-owners-details-api.component.html',
  styleUrls: ['./droppa-contract-owners-details-api.component.css', '../admin-portal/admin-portal.component.css']
})

export class DroppaContractOwnersDetailsApiComponent implements OnInit {
  vehicleRegistration: any;
  deliveries: any;
  collections: any;
  deliveryManifestNumber: any;
  collectionsManifestNumber: any;

  totalNumberOfVehiclesColl: Number = 15;
  pageNumberColl = 0;
  itemsPerPageColl = 15;
  pageSizeOptionsColl: number[] = [10, 15];
  collectionParcelLogs: any;


  deliveryParcelLogs: any;
  totalNumberOfVehiclesDel: Number = 15;
  pageNumberDel = 0;
  itemsPerPageDel = 15;
  pageSizeOptionsDel: number[] = [10, 15];


  constructor(private ownerService: ContractOwnerService, private loader: LoaderService, private router: Router, private route: ActivatedRoute, private sharedService: SharedService) {
  }

  errorMsg = ""
  user: UserInterface
  remittenceTable = false

  contractOwnerTab = true

  isDevMode = isDevMode()
  isDriver = false
  addingDriverHasError = false;
  addingDriverErrorMessage = "";
  isFiance: boolean;
  searchValue: any;
  searchType: any;
  showRoutes: boolean = false;
  branchCode: any;

  vehicleReg: string;

  collectionsTab: boolean = true;
  deliveriesTab: boolean = false;

  searchWaybill: string = "";
  searchCustomerName: string = "";
  searchRouteName: string = "";
  searchHasPod: string = "";

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem("userInfo"));
    this.route.paramMap.subscribe(params => {
      this.vehicleReg = params.get('vehicleReg')
    });
    this.isFiance = this.checkIfUserIsFinance(this.user);
    this.getContractOwnersDetailsAPI();
  }

  public contractOwnerSwitch(active: string) {
    if ((active === 'collections' && this.collectionsTab) || (active === 'deliveries' && this.deliveriesTab))
      return

    this.collectionsTab = !this.collectionsTab;
    this.deliveriesTab = !this.deliveriesTab;

    console.log(`deliveries ${this.deliveriesTab} and collections ${this.collectionsTab}`);
    this.resetPagination(active);

    this.resetSearch();

    this.getContractOwnersDetailsAPI()

  }

  public search() {
    this.resetPagination('');
    this.getContractOwnersDetailsAPI();
  }

  public getContractOwnersDetailsAPI(): void {

    this.loader.addloader();
    const hasPOD: any = this.searchHasPod === "yes" ? true : this.searchHasPod === "no" ? false : null;


    const filter = {
      vehicleRegistration: this.vehicleReg,
      freightType: this.collectionsTab ? "Collection" : "Delivery",
      waybill: this.searchWaybill,
      customerName: this.searchCustomerName,
      routeName: this.searchRouteName,
      hasPOD: hasPOD,
      pageNumber: this.pageNumberColl,
      pageTotalItems: this.itemsPerPageColl
    }


    console.log(filter)

    this.ownerService.getContractOwnerApiLogDetails(this.user.token, filter).subscribe(
      (res) => {
        console.log(res)
        this.loader.removeloader();

        if (this.collectionsTab) {
          this.collectionParcelLogs = res.content;
          this.totalNumberOfVehiclesColl = res.totalElement;
        } else {
          this.deliveryParcelLogs = res.content;
          this.totalNumberOfVehiclesDel = res.totalElement;
        }
      },
      (error) => {
        this.loader.removeloader()
        console.log(error)
      },
      () => {
        this.loader.removeloader()
      }
    )
  }


  public downloadLink(fileId): void {
    const url = this.sharedService.getUrl() + "owner/skynet/billing/download/" + fileId
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  }


  getNewData(pageEvent?: any): void {
    this.pageNumberColl = pageEvent.pageIndex;
    this.itemsPerPageColl = pageEvent.pageSize;
    this.getContractOwnersDetailsAPI();
  }

  private checkIfUserIsFinance(user: any): boolean {
    for (const role of user.roles) {
      if (role.code === 'finance_user') {
        return true;
      }
    }
    return false;
  }

  private resetPagination(active: string): void {
    if (active === 'collections') {
      this.totalNumberOfVehiclesColl = 15;
      this.pageNumberColl = 0;
      this.itemsPerPageColl = 15;
    } else if (active === 'deliveries') {
      this.totalNumberOfVehiclesDel = 15;
      this.pageNumberDel = 0;
      this.itemsPerPageDel = 15;
    } else {
      this.totalNumberOfVehiclesDel = 15;
      this.pageNumberDel = 0;
      this.itemsPerPageDel = 15;
      this.totalNumberOfVehiclesColl = 15;
      this.pageNumberColl = 0;
      this.itemsPerPageColl = 15;
    }
  }

  public back(): void {
    console.log("Back");
    this.router.navigate(['admin-contract-owner-api'])
  }


  public resetSearch(): void {
    this.searchWaybill="";
    this.searchCustomerName="";
    this.searchRouteName="";
    this.searchHasPod="";
  }
}

