import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-waybill-statuses',
  templateUrl: './view-waybill-statuses.component.html',
  styleUrls: ['./view-waybill-statuses.component.css', '../admin-portal/admin-portal.component.css']
})

export class ViewWaybillStatusesComponent implements OnInit {

  status: any;
  statusEvents: any;

  constructor() { }

  ngOnInit() {
    this.status = JSON.parse(localStorage.getItem('wayBillStatuses'));
  }

}
