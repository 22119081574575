import { Injectable } from '@angular/core';
import {Http} from "@angular/http";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedService} from "./shared.service";
import {Observable} from "rxjs/Observable";

@Injectable({
  providedIn: 'root'
})
export class WhitelistingServiceService {


  constructor(private http: Http, private httpClient: HttpClient, private sharedService: SharedService) { }

  create(dto: any, token: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);

    return this.httpClient.post(this.sharedService.getUrl() + 'whitelisting', dto, { headers });
  }

  edit(dto: any, token: any): Observable<any> {
    const headers = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);

    return this.httpClient.put(this.sharedService.getUrl() + 'whitelisting/' + dto.oid , dto, { headers });
  }

  getAll(token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${token}`);

    return this.httpClient.get<any>(this.sharedService.getUrl() + 'whitelisting', { headers });
  }
}
