import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import { SharedService } from '../../services/shared.service';
declare var $: any;

@Component({
  selector: 'app-droppa-express',
  templateUrl: './droppa-express.component.html',
  styleUrls: ['./droppa-express.component.css']
})
export class DroppaExpressComponent implements OnInit {

  @ViewChild('contactForms', { static: true }) contactForms: NgForm;
  @ViewChild('submitButton', { static: false }) submitButton: ElementRef;
  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;

  addrressData: any;
  pickUpChecked = false;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  disablePickUp: boolean = false;
  dropOffChecked: boolean = false;

  datePipe: DatePipe;

  bookingTime: any;
  bookingDate: any;
  comment: string;
  labour: number = 0;
  tempAmount: number;
  loader: boolean = false;
  hour: number;

  terms: boolean;

  termsControl: boolean = false;
  today = new Date();
  yesterday = new Date(this.today);
  displayDate = new Date();

  tenderDate: any;

  msg: string;
  message: string;
  load: number = 1;
  province: string;
  theBookingDate: any;
  distance: any;
  paymentMsg: any;
  vehicle: any;
  isExpress: boolean = true;
  promoCode: string;
  canopy: boolean = false;
  mass: any;
  distanceMsg: any;

  // public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  // public minTender = new Date(this.yesterday.setDate(this.today.getDate()));
  public max = new Date(2030, 3, 21, 20, 30);
  min: Date;
  minTender: Date;

  isItemPicked: boolean = false;
  mainCityOid: string ;
  isPayed: boolean = false;
  fromSuburb: string = '';
  toSuburb: string = '';
  pickUpPCode: string = '';
  dropOffPCode: string = '';
  type: string = '';
  pickUpAmt: number = 0.0;
  airlineAmt: number = 0.0;
  dropOffAmt: number = 0.0;
  destinationProvince: string = '';

  pickUplat: any;
  pickUplng: any;

  userObj: any;
  quote: any;
  mainCenter: any;

  isBalancePositive: boolean = false;

  vehicleExpressType: any;
  transportMode: any;
  displayNewDate: any;
  dateInfo: any;
  workingDays: any = [];

  dateMessage: string;

  companyDropOffName: any;
  dropOffCompany: any;
  companyPickUpName: any;
  pickUpAddress: any;
  dropOffAddress: any;
  addressPickUpType = 'Select address type';
  addressDropOffType = 'Select address type';
  companyPickUpBuilding: any;

  complexPickUp: any;
  complexDropOff: any;

  unitPickUp: any;
  unitDropOff: any;
  parcels = 1;
  pickUpSuburb: any = null;
  dropOffSuburb: any = null;

  pickUpExtraDetails: any = [];
  dropOffExtraDetails: any = [];

  vatNo: any;
  payFastUrl: string;
  customerRef: any;
  checkingMsg = '';
  costCentreNumber: any;
  tenderSelected = false;
  tenderTime: any;

  companyNamePlaceHolder = 'Residential name (Optional)';
  companyNameDisplay = 'Residential name (Optional)';
  complexNameDisplay = 'Complex/Building name (Optional)';
  unitNumberDisplay = 'Unit no (Optional)';
  pickedValue = 'COMPLEX';

  companyDropOffNamePlaceHolder = 'Residential name (Optional)';
  companyDropOffNameDisplay = 'Residential name (Optional)';
  complexDropOffNameDisplay = 'Complex/Building name (Optional)';
  unitDropOffNumberDisplay = 'Unit no (Optional)';
  pickedDropOffValue = 'COMPLEX';
  ozowUrl = '';

  promoStatus: string;
  usedTotal: number;
  promoCodeAmnt = 0.0;
  promocodeUsed = false;
  validAddress:any


  constructor(private bookingService: BookingService, private sharedService: SharedService, private router: Router) { }

  ngOnInit() {
    //
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.addrressData = JSON.parse(localStorage.getItem('addressData'));
    this.validAddress = JSON.parse(localStorage.getItem('validAddress'));
    if (this.validAddress) {
      if (this.validAddress.toSuburb) {
        this.dropOffSuburb = this.validAddress.toSuburb;
      }
      if (this.validAddress.fromSuburb) {
        this.pickUpSuburb = this.validAddress.fromSuburb;
      }
    }
    this.parcels = this.addrressData.parcelDetails.length;
    this.pickUpAddress = this.addrressData.pickUpAddress.address;
    this.dropOffAddress = this.addrressData.dropOffAddress.address;
    this.vehicleExpressType = this.addrressData.type;
    this.transportMode = this.addrressData.transportMode;
    this.pickUplat = this.addrressData.pickUpAddress.coordinates[0];
    this.pickUplng = this.addrressData.pickUpAddress.coordinates[1];
    // console.log(this.addrressData)
    this.tempAmount = Math.floor(this.addrressData.price);
    this.payFastUrl = this.sharedService.getPayFastUrl();

    this.checkedPickup(true);
    this.checkedDropUp(true);

    // console.log(this.addrressData);

    if (this.addrressData.type === 'BUDGET_COURIER' || this.addrressData.type === 'EXPRESS_COURIER') {
      this.tenderSelected = this.addrressData.tenderSelected;
      this.dateInfo = JSON.parse(localStorage.getItem('newDateObj'));
      this.workingDays = this.dateInfo.workingDays;
      this.displayNewDate = this.dateInfo.pickUpDate;
      this.bookingDate = new Date(this.dateInfo.pickUpDate);
      this.tenderDate = new Date(new Date().setDate(new Date(this.bookingDate).getDate() + 1));
      this.getPickUpSuburb(this.addrressData.pickUpPCode);
      this.getDropOffSuburb(this.addrressData.dropOffPCode);
      this.min = this.tenderSelected ? new Date(new Date().setDate(new Date().getDate() - 1)) : new Date(this.dateInfo.pickUpDate);
      // this.min = this.tenderSelected ? new Date(new Date().setDate(new Date(this.dateInfo.pickUpDate).getDate() - 1)) : new Date(this.dateInfo.pickUpDate);
      // this.minTender = new Date(new Date().setDate(new Date(this.dateInfo.pickUpDate).getDate() - 0));
    }

    this.getPointOfInterest();
  }

  public button() {
    var header = document.getElementById("myTab");
    var btns = header.getElementsByClassName("tabs");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activee");
        current[0].className = current[0].className.replace(" activee", "");
        this.className += " activee";
      });
    }

  }

  public checkingDeliveryDates(theDateFound: Date): boolean {
    var datePipe = new DatePipe('en-US');
    let valid = false;
    const pickUpDate = moment(theDateFound).toDate();
    let day = moment(pickUpDate).format('dddd');
    if (pickUpDate.getDay() === 6 || pickUpDate.getDay() === 0) {

      const foundDisplayDate = new Date(this.displayNewDate);

      if (foundDisplayDate > pickUpDate) {
        this.bookingDate = datePipe.transform(foundDisplayDate, 'yyyy-MM-dd');
        valid = true;
      }
      this.dateMessage = "Please note that Courier service is not available on weekends, next available date is " + moment(foundDisplayDate).format('dddd') + ".";
      valid = false;
    }
    else if (!this.workingDays.includes(day)) {

      var message = "";
      for (let element of this.workingDays) {
        message = message + "" + element + ", ";
      }

      this.dateMessage = "There is no pick up in this area on, " + day + ", available pickup is on " + message;
      valid = false;
    }
    else {
      this.bookingDate = datePipe.transform(pickUpDate, 'yyyy-MM-dd');
      valid = true;
    }

    return valid;
  }

  setTenderDropOffDate() {
    this.tenderDate = new Date(new Date().setDate(new Date(this.bookingDate).getDate() + 1));
  }

  public getPointOfInterest() {
    this.bookingService.getPOI().subscribe(response => {
        for (let i = 0; i < response.length; i++) {
          let distance = this.coordinateDistance(this.pickUplat, this.pickUplng, Number.parseFloat(response[i].location.coordinates[0]), Number.parseFloat(response[i].location.coordinates[1]), 'K');
          if (distance <= 30) {
            this.mainCenter = response[i];
          }
        }
      }, error => {
      }
    )
  }

  public checkedDropUp(dropOff: any) {
    var object = JSON.parse(localStorage.getItem('userData'));

    if (dropOff) {
      this.dropOffChecked = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
    } else {
      this.dropOffChecked = false;
      /*this.dropContact.name = '';
      this.dropContact.email = '';
      this.dropContact.mobile = '';
      this.dropContact.surname = '';*/
    }
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userData'));
    if (pickUp) {
      this.pickUpChecked = true;
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
    } else {
      this.pickUpChecked = false;
      this.disablePickUp = false;
      /*this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';*/
    }
  }

  public isNumeric(val) {
    return /^-?\d+$/.test(val);
  }

  checkTenderCutOff() {
    if ((this.tenderTime.getHours() >= 18) && (this.tenderTime.getMinutes() > 0)) {
      this.tenderTime = undefined;
      this.checkingMsg = 'Tender bookings after 18:00 must be done through our call center number: 012 880 2159';
      $('#validationModal').modal('show');
    }
  }

  public goToPaymentScreen() {
    if (this.pickUpSuburb == null && (this.addrressData.type == 'BUDGET_COURIER' || this.addrressData.type == 'EXPRESS_COURIER')) {
      this.checkingMsg = 'Please select the pick up suburb to proceed with payments.';
      $('#validationModal').modal('show');
    }
    else if (this.contact.name == '') {
      this.checkingMsg = 'Please enter the name of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.contact.mobile.trim() == '') {
      this.checkingMsg = 'Please enter the number of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.contact.mobile.length != 10) {
      this.checkingMsg = 'Please enter 10 digits (no spaces) of the person at the pick up (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (!this.isNumeric(this.contact.mobile)) {
      this.checkingMsg = 'Please enter numbers, not letters or special characters (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.dropOffSuburb == null && (this.addrressData.type === 'BUDGET_COURIER' || this.addrressData.type === 'EXPRESS_COURIER')) {
      this.checkingMsg = 'Please select the drop off suburb to proceed with payments.';
      $('#validationModal').modal('show');
    }
    else if ((this.tenderSelected) && this.tenderDate === undefined) {
      this.checkingMsg = 'Please select the expected date.';
      $('#validationModal').modal('show');
    }
    else if ((this.tenderSelected) && this.tenderTime === undefined) {
      this.checkingMsg = 'Please select the time before proceeding.';
      $('#validationModal').modal('show');
    }
    else if (this.dropContact.name === '') {
      this.checkingMsg = 'Please enter the name of the person at the drop off (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.dropContact.mobile === '') {
      this.checkingMsg = 'Please enter the number of the person at the drop off (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.dropContact.mobile.length !== 10) {
      this.checkingMsg = 'Please enter 10 digits (no spaces) of the person at the drop off (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (!this.isNumeric(this.dropContact.mobile)) {
      this.checkingMsg = 'Please enter numbers, not letters or special characters (Or select the small box to indicate you will be at the pick up).';
      $('#validationModal').modal('show');
    }
    else if (this.comment == null) {
      this.checkingMsg = 'Please enter in the comment section below for any further comments (instructions or tips regarding your delivery).';
      $('#validationModal').modal('show');
    }
    else if (!this.termsControl) {
      this.checkingMsg = 'Please click the checkbox below next to the terms & conditions to proceed with the booking.';
      $('#validationModal').modal('show');
    }
    else {
      //this.paymentMsg = "Please wait while directing to Payfast. After making payments, wait to confirm your booking before exiting.";
      var object = JSON.parse(localStorage.getItem('userData'));
      var currentDate = new Date();
      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
      var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
      var datePipe = new DatePipe('en-US');
      let value = null;
      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe('en-US');
      let valueTime = null;

      if (this.tenderSelected) {
        var datePipe = new DatePipe('en-US');
        let valueTime = datePipe.transform(this.tenderTime, 'HH:mm a');
        var currentDate = new Date(this.tenderDate);
        let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
        let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
        var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + valueTime.split(' ')[0] + ':' + second + '.957Z';
        var tenderSelectedDate = dropTime;
        var valudeTender = datePipe.transform(this.tenderDate, 'yyyy-MM-dd');
      }

      if (this.addrressData.type === 'FLASH' || this.addrressData.type === 'DASH') {
        value = datePipe.transform(currentDate, 'yyyy-MM-dd');
        valueTime = datePipe.transform(currentDate, 'HH:mm a');
      } else if (this.bookingDate != null) {
        value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');
        var dateHalf2 = new Date(this.bookingDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
        valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
      } else {
        value = datePipe.transform(this.dateInfo.pickUpDate, 'yyyy-MM-dd');
        var dateHalf2 = new Date(currentDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
        valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
      }
      let isBike=localStorage.getItem('isBike')==='true'
      let mainCity=localStorage.getItem('mainCityOid');

      if(mainCity){
        this.mainCityOid=mainCity;
      }else{
        this.mainCityOid=this.mainCenter != null ? this.mainCenter.oid : null
      }

      // console.log(this.addrressData);

      var bookingData = {
        'dropOff': {
          'firstName': this.dropContact.name,
          'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
          'phone': this.dropContact.mobile,
          'email': this.dropContact.email,
          'companyName': this.dropOffCompany != null ? this.dropOffCompany : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : null,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : null
        },
        'pickUp': {
          'firstName': this.contact.name,
          'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
          'phone': this.contact.mobile,
          'email': this.contact.email,
          'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
          'complex': this.complexPickUp != null ? this.complexPickUp : null,
          'unitNo': this.unitPickUp != null ? this.unitPickUp : null
        },
        'customer': object.owner,
        'price': this.tempAmount,
        'vehicleType': this.addrressData.vehicleType.vehicleType,
        'pickUpAddress': this.addrressData.pickUpAddress.address,
        'pickUpTime': valueTime,
        'phoneNo': object.owner.mobile,
        'pickUpDate': value,
        'dropOffAddress': this.addrressData.dropOffAddress.address,
        'comment': this.comment,
        'labour': this.contact.labours,
        'timestamp': timestamp,
        'status': 'AWAITING_DRIVER',
        'pickUpCoordinate': this.addrressData.pickUpAddress,
        'dropOffCoordinate': this.addrressData.dropOffAddress,
        'load': this.parcels == 0 ? 1 : this.parcels,
        'province': this.addrressData.province != null ? this.addrressData.province : 'UNKNOWN',
        'canopy': this.canopy,
        'promotionCode': this.promoCode,
        'platform': 'web',
        "isExpress": this.isExpress,
        "distance": this.addrressData.distance,
        'itemMass': this.addrressData.mass,
        'transportMode': this.addrressData.transportMode,
        'isItemPicked': false,
        'mainCityOid': this.mainCityOid,
        'fromSuburb': this.pickUpSuburb,
        'toSuburb': this.dropOffSuburb,
        'pickUpPCode': this.addrressData.pickUpPCode,
        'dropOffPCode': this.addrressData.dropOffPCode,
        'type': this.addrressData.type,
        'pickUpAmt': this.addrressData.pickUpAmt,
        'airlineAmt': this.addrressData.airlineAmt,
        'dropOffAmt': this.addrressData.dropOffAmt,
        'destinationProvince': this.addrressData.dropOffProvince,
        'isPayed': false,
        'paymentType': null,
        "parcelDimensions": this.addrressData.parcelDetails,
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber,
        "expectedDeliveryDate": tenderSelectedDate,
        "isDashBike":isBike?true:null
      };
      localStorage.setItem('bookingDataObject', JSON.stringify(bookingData));

      // console.log(bookingData);

      var drop = {
        'name': 'Booking',
        'notes': 'be careful',
        'cost': (this.tempAmount * 100),
        'clientOid': object.owner.oid,
        'vehicleOid': '1521105441081',
        'driverOid': '575ae6bd30bc5d5017477143',
        'booking': bookingData,
        'from': {
          'coordinates': ['-26.002483', '28.099503'],
          'address': this.addrressData.pickUpAddress.address,
        },
        'to': {
          'coordinates': ['-26.002483', '28.099503'],
          'address': this.addrressData.dropOffAddress.address,
        },
        'when': dropTime,
        'atp': dropTime,
        'eta': dropTime,
        'ata': dropTime,
        'platform': 'web',
        'droplets': [
          {
            'itemOid': '575ae6bd30bc5d5017477143',
            'notes': 'new'
          }
        ]
      };

      let obect = {
        pickUpAddress: this.addrressData.pickUpAddress,
        dropOffAddress: this.addrressData.dropOffAddress,
        price: this.addrressData.price,
        vehicleType: this.addrressData.vehicleType,
        province: this.addrressData.province,
        "mass": this.addrressData.mass,
        "dropOffProvince": this.addrressData.dropOffProvince,
        "transportMode": this.addrressData.transportMode,
        "type": this.addrressData.type,
        "distance": this.addrressData.distance,
        'fromSuburb': this.pickUpSuburb,
        'toSuburb': this.dropOffSuburb,
        "pickUpProvince": this.addrressData.province,
        "pickUpPCode": this.addrressData.pickUpPCode,
        "dropOffPCode": this.addrressData.dropOffPCode,
        "pickUpAmt": this.addrressData.pickUpAmt,
        "airlineAmt": this.addrressData.airlineAmt,
        "dropOffAmt": this.addrressData.dropOffAmt,
        "parcelDetails": this.addrressData.parcelDetails,
        "tenderSelected": this.addrressData.tenderSelected,
      };
      localStorage.setItem('addressData', JSON.stringify(obect));

      if (this.addrressData.type == 'FLASH' || this.addrressData.type == 'DASH') {
        localStorage.setItem("bookingObject", JSON.stringify(drop));
        this.router.navigateByUrl('/courier-payment');
      } else {
        if (this.tenderSelected) {
          let calDiffDays = moment(value).diff(moment(valudeTender), 'days');
          if (calDiffDays >= 0) {
            this.checkingMsg = 'Please note that the Tender delivery date should be after the collection date.';
            $('#validationModal').modal('show');
          } else {
            localStorage.setItem("bookingObject", JSON.stringify(drop));
            this.router.navigateByUrl('/courier-payment');
          }
        } else {
          if (this.checkingDeliveryDates(value)) {
            localStorage.setItem("bookingObject", JSON.stringify(drop));
            this.router.navigateByUrl('/courier-payment');
          } else {
            alert(this.dateMessage);
          }
        }
      }

    }
  }

  public checkedTerms(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public openPopup() {
    var options = 'location=no,clearcache=no,toolbar=no';
    var paymentWindow = window.open('https://www.droppa.co.za/droppa/courier-terms', '_blank', options);
    return paymentWindow;
  }

  private coordinateDistance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }

  public onLoads(parcels: number) {
    this.tempAmount = (this.addrressData.price * parcels);
  }

  public onItemChange(addressPickUpType) {
    this.pickedValue = addressPickUpType;
    if (this.pickedValue === 'COMPANY') {
      this.companyNamePlaceHolder = 'Company name';
      this.companyNameDisplay = 'Company name';
      this.complexNameDisplay = 'Complex name';
      this.unitNumberDisplay = 'Unit no';
    } else if (this.pickedValue === 'COMPLEX') {
      this.companyNamePlaceHolder = 'Building name';
      this.companyNameDisplay = 'Building name';
      this.complexNameDisplay = 'Complex name';
      this.unitNumberDisplay = 'Unit no';
    } else {
      this.companyNamePlaceHolder = 'Building name (Optional)';
      this.companyNameDisplay = 'Building name (Optional)';
      this.complexNameDisplay = 'Complex name (Optional)';
      this.unitNumberDisplay = 'Unit no (Optional)';
    }
  }

  public onItemChange2(addressPickUpType) {
    this.pickedDropOffValue = addressPickUpType;
    if (this.pickedDropOffValue === 'COMPANY') {
      this.companyDropOffNamePlaceHolder = 'Company name';
      this.companyDropOffNameDisplay = 'Company name';
      this.complexDropOffNameDisplay = 'Complex name';
      this.unitDropOffNumberDisplay = 'Unit no';
    } else if (this.pickedDropOffValue === 'COMPLEX') {
      this.companyDropOffNamePlaceHolder = 'Building name';
      this.companyDropOffNameDisplay = 'Building name';
      this.complexDropOffNameDisplay = 'Complex name';
      this.unitNumberDisplay = 'Unit no';
    } else {
      this.companyDropOffNamePlaceHolder = 'Building name (Optional)';
      this.companyDropOffNameDisplay = 'Building name (Optional)';
      this.complexDropOffNameDisplay = 'Complex name (Optional)';
      this.unitDropOffNumberDisplay = 'Unit no (Optional)';
    }
  }

  public getPickUpSuburb(quote) {
    var token = localStorage.getItem('token');
    this.bookingService.postalDetails(token, quote).subscribe(
      response => {
        this.pickUpExtraDetails = response;
      }, error => {
        console.log(error);
      }
    )
  }

  public getDropOffSuburb(quote) {
    var token = localStorage.getItem('token');
    this.bookingService.postalDetails(token, quote).subscribe(
      response => {
        this.dropOffExtraDetails = response;
      }, error => {
        console.log(error);
      }
    )
  }

  public onHome() {
    this.router.navigateByUrl('/user');
  }

  displayDropSuburb: string = '';

  public checkDropSuburb(event) {
    this.displayDropSuburb = event;
  }

}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}



