import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllUsersComponent implements OnInit {

  loader: boolean;
  adminUser: any;
  users: any = [];

  usersAll:any=[];
  partner: any = [];

  searchUsers: any;
  findUser: string = '';
  searchQuery = 'FIRSTNAME';
  userData: any;

  msg: any;

  totalNumberOfUsers:number=0;
  itemsPerPage=25;
  pageNumber=0;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.users = JSON.parse(localStorage.getItem('allUsers'));
    //this.getUsers(this.adminUser.token);
    this.getUsersAll(this.adminUser.token);
  }

  onSearchedUser() {
    localStorage.setItem('searchedUser', this.userData);
    this.router.navigate(['/view-searcher-user']);
  }

  onUserSelected(user) {
    localStorage.removeItem('selectedPartner');
    localStorage.setItem('selectedUser', JSON.stringify(user));
    this.router.navigate(['/view-user']);
  }

  private getPartners(): void {
    this.users.forEach(user => {
      for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i].code === 'partner') {
          this.partner.push(user);
        }
      }
    });
  }


  public searchUser(){
    this.totalNumberOfUsers=0;
    this.itemsPerPage=25;
    this.pageNumber=0
    this.getUsersAll(this.adminUser.token);
  }

  public searchUsersOld() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findUser != null && this.searchQuery !== 'undefined') {
      if (this.searchQuery === 'mobile') {
        this.adminService.getUserByNumber(this.findUser, this.adminUser.token)
          .subscribe(
            (res) => {
              this.userData = res;
              this.partner.push(this.userData);
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Mobile number does not exist';
              this.loadservice.removeloader();
            });
      } else if (this.searchQuery === 'email') {
        this.adminService.getUserByEmail(this.adminUser.token, this.findUser)
          .subscribe(
            (res) => {
              this.userData = res;
              // this.partner.push(this.userData);
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Email does not exist';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select "Phone number" or "Email" on the select input';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please select "Phone number" on the drop-down and enter user mobile number to search';
      this.loadservice.removeloader();
    }
  }


  /*
  private getUsers(token) {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getUsers(token)
      .subscribe(
        (res) => {
          this.users = res;
          // console.log(this.users);
          this.loadservice.removeloader();
          this.getPartners();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

   */

  private getUsersAll(token) {

    console.log(this.pageNumber);
    console.log(this.itemsPerPage);
    const filter = {
      pageNumber:this.pageNumber,
      pageTotalItems:this.itemsPerPage,
      fieldToFilter:this.searchQuery,
      searchValue:this.findUser
    }

    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.adminService.getUsersAll(token,filter)
      .subscribe(
        (res) => {
          this.users = res.content;
          // console.log(this.users);
          this.loadservice.removeloader();
          //this.getPartners();
          this.totalNumberOfUsers=res.totalElement;
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getUsersAll(this.adminUser.token);
  }



}
