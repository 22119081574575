import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-view-wallet-transactions',
  templateUrl: './view-wallet-transactions.component.html',
  styleUrls: ['./view-wallet-transactions.component.css', '../admin-portal/admin-portal.component.css']
})

export class ViewWalletTransactionsComponent implements OnInit {

  details: any;
  adminUser: any;
  transactionDetails: any;
  username: any;
  surname: any;

  financeAllowed = false;
  updatedAmt: number;
  type: any;
  walletMsg: any;

  displayedBalance: number;

  constructor(private adminService: AdminService, private userService: UserService) { }

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.details = JSON.parse(localStorage.getItem('walletDetails'));
    // console.log(this.details);
    this.username = this.details.accountDTO.owner.firstName;
    this.surname = this.details.accountDTO.owner.surname;
    this.displayedBalance = this.details.amount;
    this.userTransactions();

    this.adminUser.roles.forEach(element => {
      if (element.code === 'finance_user') {
        this.financeAllowed = true;
      }
    });
  }

  public userTransactions() {
    this.adminService.getTransactions(this.adminUser.token, this.details.oid).subscribe(
      (response: any) => {
      // console.log(response);
      this.transactionDetails = response.reverse();
    }, (error) => {
        console.log(error);
    });
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  public updateWalletBalance() {
    var object = JSON.parse(localStorage.getItem('userInfo'));

    var walletBody = {
      "accountOid": this.details.accountDTO.oid,
      "amount": this.updatedAmt,
      "actionType": this.type
    }

    this.adminService.updateWallet(object.token, walletBody).subscribe(
      (response) => {
        // console.log(response);
        this.walletMsg = 'Wallet successfully updated.';

        if (this.type.toUpperCase() === 'ADD') {
          this.displayedBalance += this.updatedAmt;
        } else {
          this.displayedBalance -= this.updatedAmt;
        }

        this.details.amount = this.displayedBalance;
        // Storing wallet details in local storage in case of page refresh
        localStorage.setItem('walletDetails', JSON.stringify(this.details));

        this.userTransactions();
        this.getBalance();

        this.updatedAmt = null;
        this.type = null;
      }, (error) => {
        console.log(error);
        this.walletMsg = 'Error, unable to update/create the wallet.';
      }
    );
  }

  getBalance() {
    this.userService.retrieveCurrentBalance(this.adminUser.token, this.details.oid).subscribe(
      (response) => {
        console.log(response.json());
      },
      (error) => {
        console.log(error);
      }
    );
  }

}
