import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import * as moment from "moment";
import { BookingService } from "src/app/services/booking.service";
import { LoaderService } from "src/app/services/loader.service";
import { SharedService } from "src/app/services/shared.service";
declare var $: any;

@Component({
  selector: 'app-elite-confirm-details',
  templateUrl: './elite-confirm-details.component.html',
  styleUrls: ['./elite-confirm-details.component.css'],
})
export class EliteConfirmDetailsComponent implements OnInit {
  eliteObj: any = {};
  userObj: any;
  branches: any = [];
  companyName: any;
  dropOffName: any;
  selectedBranch: any;
  labours: number = 0;
  tempAmount: any;
  numDays: number = 1;
  VAT: any;
  totalAmt: any;
  datePicked: any;
  returnDate: any;
  comment: any;
  subtotal: any;
  branchOid: any;
  warningMsg: string = "";
  paymentMsg: string = "";

  // Payfast Payment Elements
  @ViewChild("m_id", { static: false }) merchant_id: ElementRef;
  @ViewChild("m_key", { static: false }) merchant_key: ElementRef;
  @ViewChild("return_url", { static: false }) return_url: ElementRef;
  @ViewChild("cancel_url", { static: false }) cancel_url: ElementRef;
  @ViewChild("notify_url", { static: false }) notify_url: ElementRef;
  @ViewChild("name_first", { static: false }) name_first: ElementRef;
  @ViewChild("name_last", { static: false }) name_last: ElementRef;
  @ViewChild("email_address", { static: false }) email_address: ElementRef;
  @ViewChild("cell_number", { static: false }) cell_number: ElementRef;
  @ViewChild("m_payment_id", { static: false }) m_payment_id: ElementRef;
  @ViewChild("amount", { static: false }) amount: ElementRef;
  @ViewChild("item_name", { static: false }) item_name: ElementRef;
  @ViewChild("item_description", { static: false }) item_description: ElementRef;
  @ViewChild("custom_int1", { static: false }) custom_int1: ElementRef;
  @ViewChild("confirmation_address", { static: false }) confirmation_address: ElementRef;

  // Ozow Payment Elements
  @ViewChild('SiteCode', { static: false}) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false}) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false}) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false}) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false}) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false}) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false}) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false}) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false}) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false}) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false}) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false}) HashCheck: ElementRef;

  loader: boolean = false;
  url: string;
  ozowUrl: string;
  mobileNum: any;
  userName: any;
  terms: boolean;
  termsControl: boolean = false;
  sleepOutFee: number = 0;
  numberDecimalMsg: string = "";
  eliteStartDate: any;
  eliteEndDate: any;

  checkPayFast: boolean = false;
  checkOzow: boolean = false;
  checkWallet: boolean = false;
  checkPaygate: boolean = false;
  paymentOptions = [];

  eliteType = '';

  constructor(private bookService: BookingService, private nameService: SharedService,private loadservice:LoaderService) {
    this.url = this.nameService.getPayFastUrl();
    this.ozowUrl = this.nameService.getOzowUrl();
    this.userObj = JSON.parse(localStorage.getItem("userInfo"));
  }

  ngOnInit() {
    this.getBranches();
    this.eliteObj = JSON.parse(localStorage.getItem("eliteDetails"));
    this.eliteType = this.eliteObj.data.eliteType === 'CODE_10' ? 'Code 10' : 'Code 14';
    //console.log(this.eliteObj);
    var datePipe = new DatePipe("en-US");
    // Used for display on the HTML
    this.datePicked = datePipe.transform(this.eliteObj.data.eliteStartDate, "yyyy-MM-dd");
    this.returnDate = datePipe.transform(this.eliteObj.data.eliteReturnDate, "yyyy-MM-dd");
    this.getPaymentOptions();


    this.numDays = moment(this.eliteObj.data.eliteReturnDate).diff(moment(this.eliteObj.data.eliteStartDate), "days");
    this.numDays = this.numDays + 1;
    this.tempAmount = this.eliteObj.price;
    this.totalAmt = this.eliteObj.price;
    this.subtotal = this.eliteObj.price * 0.85;
    this.VAT = this.eliteObj.price * 0.15;
    this.userName = this.userObj.owner.firstName + " " + this.userObj.owner.surname;

  }

  public getBranches() {
    this.bookService.getEliteBranches().subscribe(
      (response) => {
        this.branches = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public selectBranchOid(selectedBranch) {
    this.branchOid = selectedBranch;
  }

  /*public calcBasePrice(): number {
    let price = 0.0;
    price = this.tempAmount + this.labours * 700 * this.numDays;
    return price;
  }*/

  /*public calcVAT() {
    let vat = 0.0;
    vat = this.calcBasePrice() * 0.15;
    return vat;
  }*/

  /*labourFactor(labour): void {
    if (labour > 0) {
      this.onLabour(labour);
    } else {
      this.onLabour(labour);
    }
  }*/

  public onLabour(labour: number) {
    if (labour != null && labour >= 0) {
      this.totalAmt = this.tempAmount + (595 * labour);
      this.subtotal = this.totalAmt * 0.85;
      this.VAT = this.totalAmt * 0.15;
    }
  }

  public payOnline() {
    if (this.selectedBranch == null) {
      this.warningMsg = "Please select a branch for your hire truck.";
      $("#warning").modal("show");
    } else if (this.comment == null) {
      this.warningMsg =
        "Please enter instructions before proceeding with payment.";
      $("#warning").modal("show");
    } else if (!this.termsControl) {
      this.warningMsg =
        "Please click the checkbox below the terms & conditions header to proceed with the elite rental.";
      $("#warning").modal("show");
    } else {
      this.paymentMsg = "Please wait while we redirect you to PayFast...";

      var currentDate = new Date(this.eliteObj.data.eliteStartDate);
      let date = currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate();
      let monthNo = currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
      let hour = currentDate.getHours() <= 9 ? "0" + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? "0" + currentDate.getSeconds() : currentDate.getSeconds();
      var dropTime = currentDate.getFullYear() + "-" + monthNo + "-" + date + "T" + hour + ":" + minutes + ":" + second + ".957Z";
      this.eliteStartDate = dropTime;

      var currentDate2 = new Date(this.eliteObj.data.eliteReturnDate);
      let dateHalf2 = currentDate2.getDate() <= 9 ? "0" + currentDate2.getDate() : currentDate2.getDate();
      let monthNo2 = currentDate2.getMonth() + 1 <= 9 ? "0" + (currentDate2.getMonth() + 1) : currentDate2.getMonth() + 1;
      let hour2 = currentDate2.getHours() <= 9 ? "0" + currentDate2.getHours() : currentDate2.getHours();
      let minutes2 = currentDate2.getMinutes() <= 9 ? "0" + currentDate2.getMinutes() : currentDate2.getMinutes();
      var second2 = currentDate2.getSeconds() <= 9 ? "0" + currentDate2.getSeconds() : currentDate2.getSeconds();
      var dropTime2 = currentDate2.getFullYear() + "-" + monthNo2 + "-" + dateHalf2 + "T" + hour2 + ":" + minutes2 +  ":" + second2 + ".957Z";
      this.eliteEndDate = dropTime2;
      this.totalAmt = Math.round(this.totalAmt);

      let payObj = {
        from: {
          coordinates: ["-25.8770951", "28.1787793"],
          address: this.eliteObj.data.eliteAddress,
        },
        branchOid: this.branchOid,
        in: this.eliteStartDate,
        out: this.eliteEndDate,
        rateUnit: "DAILY",
        category: this.eliteObj.type,
        assistance: this.labours,
        includedDistance: 0,
        comments: this.comment,
        customerOid: this.userObj.owner.oid,
        total: this.totalAmt,
        platform: "web",
      };

      localStorage.setItem("paymentObj", JSON.stringify(payObj));
      this.loadservice.addloader();
      this.bookService.saveEliteVehicles(this.userObj.token, payObj).subscribe(
        (response) => {
          this.loadservice.removeloader();
          var paymentData = {
            name_first: this.userObj.owner.firstName,
            name_last: this.userObj.owner.surname,
            email_address: this.userObj.owner.email,
            cell_number: this.userObj.owner.mobile,
            m_payment_id: response.oid,
            amount: this.totalAmt,
            customerReference: this.userObj.owner.oid,
            item_name: "Elite Rental",
            item_description: "Payment For Rental Truck",
            email_confirmation: 0,
            confirmation_address: this.userObj.owner.email,
            reference: response.oid,
            paymentType: "RENTAL_PAYMENT",
          };
          this.bookService.requestPayfastPayment(paymentData, this.userObj.token).subscribe(
            (resp) => {
            this.loadservice.removeloader();
            this.redirectToPayFast(resp.json());
          }, (error) => {
            console.log(error);
            this.loadservice.removeloader();
          });
        },
        (error) => {
          this.loadservice.removeloader();
          console.log(error);
        }
      );
    }
  }

  public redirectToPayFast(response) {
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.amount.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value = response.confirmation_address;
    $("#btnMakePayment1").trigger("click");
  }

  public payWithOzow() {

    if (this.selectedBranch == null) {
      this.warningMsg = "Please select a branch for your hire truck.";
      $("#warning").modal("show");
    } else if (this.comment == null) {
      this.warningMsg =
        "Please enter instructions before proceeding with payment.";
      $("#warning").modal("show");
    } else if (!this.termsControl) {
      this.warningMsg =
        "Please click the checkbox below the terms & conditions header to proceed with the elite rental.";
      $("#warning").modal("show");
    } else {
      this.paymentMsg = "Please wait while we redirect you to Ozow...";

      var currentDate = new Date(this.eliteObj.data.eliteStartDate);
      let date = currentDate.getDate() <= 9 ? "0" + currentDate.getDate() : currentDate.getDate();
      let monthNo = currentDate.getMonth() + 1 <= 9 ? "0" + (currentDate.getMonth() + 1) : currentDate.getMonth() + 1;
      let hour = currentDate.getHours() <= 9 ? "0" + currentDate.getHours() : currentDate.getHours();
      let minutes = currentDate.getMinutes() <= 9 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
      var second = currentDate.getSeconds() <= 9 ? "0" + currentDate.getSeconds() : currentDate.getSeconds();
      var dropTime = currentDate.getFullYear() + "-" + monthNo + "-" + date + "T" + hour + ":" + minutes + ":" + second + ".957Z";
      this.eliteStartDate = dropTime;

      var currentDate2 = new Date(this.eliteObj.data.eliteReturnDate);
      let dateHalf2 = currentDate2.getDate() <= 9 ? "0" + currentDate2.getDate() : currentDate2.getDate();
      let monthNo2 = currentDate2.getMonth() + 1 <= 9 ? "0" + (currentDate2.getMonth() + 1) : currentDate2.getMonth() + 1;
      let hour2 = currentDate2.getHours() <= 9 ? "0" + currentDate2.getHours() : currentDate2.getHours();
      let minutes2 = currentDate2.getMinutes() <= 9 ? "0" + currentDate2.getMinutes() : currentDate2.getMinutes();
      var second2 = currentDate2.getSeconds() <= 9 ? "0" + currentDate2.getSeconds() : currentDate2.getSeconds();
      var dropTime2 = currentDate2.getFullYear() + "-" + monthNo2 + "-" + dateHalf2 + "T" + hour2 + ":" + minutes2 + ":" + second2 + ".957Z";
      this.eliteEndDate = dropTime2;
      this.totalAmt = Math.round(this.totalAmt);
      let payObj = {
        from: {
          coordinates: ["-25.8770951", "28.1787793"],
          address: this.eliteObj.data.eliteAddress,
        },
        branchOid: this.branchOid,
        in: this.eliteStartDate,
        out: this.eliteEndDate,
        rateUnit: "DAILY",
        category: this.eliteObj.type,
        assistance: this.labours,
        includedDistance: 0,
        comments: this.comment,
        customerOid: this.userObj.owner.oid,
        total: this.totalAmt,
        platform: "web",
      };

      localStorage.setItem("paymentObj", JSON.stringify(payObj));
      this.loadservice.addloader();
      this.bookService.saveEliteVehicles(this.userObj.token, payObj).subscribe(
        (response) => {
          this.loadservice.removeloader();
          let ozowObj = {
            CountryCode: "ZA",
            CurrencyCode: "ZAR",
            Amount: this.totalAmt,
            TransactionReference: response.oid,
            BankReference: "",
            Customer: this.userObj.owner.email,
            IsTest: true,
            paymentType: "RENTAL_PAYMENT",
          };
          this.bookService.requestOzowPayment(ozowObj, this.userObj.token).subscribe((response) => {
            this.loadservice.removeloader();
            this.redirectToOzow(response.json());
          },
          (error) => {
            console.log(error);
            this.loadservice.removeloader();
          }
        );
        },
        (error) => {
          this.loadservice.removeloader();
          console.log(error);
        }
      );
    }
  }

  private redirectToOzow(ozowBody) {
    this.SiteCode.nativeElement.value = ozowBody.SiteCode;
    this.CountryCode.nativeElement.value = ozowBody.CountryCode;
    this.CurrencyCode.nativeElement.value = ozowBody.CurrencyCode;
    this.Amount.nativeElement.value = ozowBody.Amount.toFixed(2);
    this.TransactionReference.nativeElement.value = ozowBody.TransactionReference;
    this.BankReference.nativeElement.value = ozowBody.BankReference;
    this.Customer.nativeElement.value = ozowBody.Customer;
    this.Optional1.nativeElement.value = ozowBody.Optional1;
    this.CancelUrl.nativeElement.value = ozowBody.CancelUrl;
    this.ErrorUrl.nativeElement.value = ozowBody.ErrorUrl;
    this.SuccessUrl.nativeElement.value = ozowBody.SuccessUrl;
    this.NotifyUrl.nativeElement.value = ozowBody.NotifyUrl;
    this.IsTest.nativeElement.value = ozowBody.IsTest;
    this.HashCheck.nativeElement.value = ozowBody.HashCheck;
    $("#ozowPayment").trigger("click");
  }

  public checkedTerms(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public openPopup() {
    var options = "location=no,clearcache=no,toolbar=no";
    var paymentWindow = window.open(
      "https://www.droppa.co.za/droppa/elite-terms",
      "_blank",
      options
    );
  }

  public getPaymentOptions() {
    this.bookService.listAvailPaymentOptions(this.userObj.token).subscribe(
      response => {
        this.paymentOptions = response;
        this.paymentOptions.forEach(element => {
          // console.log(element);
          if (element.optionType == 'PAYFAST') {
            this.checkPayFast = element.isActive;
          }
          else if (element.optionType == 'OZOW') {
            this.checkOzow = element.isActive;
          }
          else if (element.optionType == 'WALLET') {
            this.checkWallet = element.isActive;
          }
          else if (element.optionType == 'PAYGATE') {
            this.checkPaygate = element.isActive;
          }
        });
      },
      error => console.log(error)
    );
  }
}
