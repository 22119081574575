import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-company-bank',
  templateUrl: './company-bank.component.html',
  styleUrls: ['./company-bank.component.css']
})


export class CompanyBankComponent implements OnInit {

  @ViewChild('bankForm', { static: false }) addVForm: NgForm;
  accountNo: number;
  bankName: string;
  message: string = "";
  company: any;
  loader: boolean = false;
  bankFeedBack: string = "";
  accountName: string;
  branchCode: string;

  user: any = {};
  companyVehicles: any = [];
  vehiclesTypeRate: any = [];
  bank: any;
  isBank: boolean = false;
  error: string;

  total: number;
  page: number = 1;
  totalItems: number;
  itemsPage: number = 1;
  selectedTabID: any;
  provinceUpdate: any;

  bankDetails = {
    accountNo: "",
    bankName: "",
    accountName: "",
    branchCode: ""
  }

  constructor(private adminService: AdminService, private partnerService: UserService, private router: Router) { }

  ngOnInit() {
    this.company = JSON.parse(localStorage.getItem('selectedCompany'));
    this.getOwner();
    this.getCompany();
    this.getBankAccount();

    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
  }
  
  public updateBankDetails() {

    var object = JSON.parse(localStorage.getItem('userInfo'));
    var account = {
      "accountNo": this.accountNo,
      "bankName": this.bankName,
      "branchCode": this.branchCode,
      "accountName": this.accountName,
      "companyOid": this.company.oid
    }
    if (this.bank == null) {
      this.adminService.bankAccount(account, object.token).subscribe(
        (res) => {
          this.message = "Bank account added";
          $('#feedbackDialog').modal('show');
          this.addVForm.reset();
          this.ngOnInit();
        },
        (error) => {
          this.message = "Error while add bank account added";
          $('#feedbackDialog').modal('show');
        }
      );
    }

    else {
      var accountUpdate = {
        "oid": this.bank.oid,
        "accountNo": this.accountNo,
        "bankName": this.bankName,
        "branchCode": this.branchCode,
        "accountName": this.accountName,
        "companyOid": this.company.oid
      }
      this.adminService.updateBankAccount(accountUpdate, this.bank.oid, object.token).subscribe(
        (res) => {
          this.bankFeedBack = "Bank account updated";
          $('#feedbackDialog').modal('show');
          this.addVForm.reset();
          this.ngOnInit();
        },
        (error) => {
          this.bankFeedBack = "Error while add bank account added";
          $('#feedbackDialog').modal('show');
        }
      );
    }
  }

  private getOwner() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getOwner(object.token, this.company.ownerOid).subscribe(
      (res) => {
        this.user = res;
      }
    )
  }

  private getCompany() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.partnerService.retrieveVehicles(object.token, this.company.oid)
      .subscribe(
      (res: Response) => {
        if (res.status == 200) {
          this.companyVehicles = res.json();
        }
      }
      );

  }

  public onVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle']);
  }

  private getBankAccount() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getBankAccount(object.token, this.company.oid).subscribe(
      (res) => {
        this.bank = res;
        this.bankDetails = {
          accountNo: this.bank.accountNo,
          bankName: this.bank.bankName,
          accountName: this.bank.accountName,
          branchCode: this.bank.branchCode
        }
        // console.log(this.bankDetails);
        this.isBank = false;
      },
      (err) => {
        this.isBank = true;
      }
    )
  }

  public updateCompanyProvince() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.provinceUpdate);
    console.log(this.company.oid);
    this.adminService.updateCompanyProvice(object.token, this.company.oid, this.provinceUpdate).subscribe(
      (response) => {
        console.log(response.json());
        this.company = response;
        this.message = 'Province successfully updated.';
        localStorage.setItem('selectedCompany', JSON.stringify(response.json()));
        console.log(this.company.json());
      }, error => {
        this.message = 'Could not update province.';
        console.log(error);
      }
    )
  }

}
