import {Component, OnInit} from '@angular/core';
import {WhitelistingServiceService} from '../../services/whitelisting-service.service';

declare var $: any;
@Component({
  selector: 'app-whitelisting',
  templateUrl: './whitelisting.component.html',
  styleUrls: ['./whitelisting.component.css']
})


export class WhitelistingComponent implements OnInit {

  whitelistedCompanies: any[] = [];
  newCompany: any = {};
  selectedCompany: any = {};
  token = '';
  private userObj: any;

  constructor(private whitelistingService: WhitelistingServiceService) { }

  ngOnInit() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.token = this.userObj.token;
    this.loadWhitelistedCompanies();
  }


  loadWhitelistedCompanies() {
    this.whitelistingService.getAll(this.token).subscribe(
      (response) => {
        if ( response.error) {
            console.log('error occurs');
        } else {
          console.log(response);
          this.whitelistedCompanies = response;
        }
      }
    );
  }

  createCompany() {

    if (!this.newCompany.name || this.newCompany.name.trim() === '') {
      console.error('Company name is required');
      return;
    }

    if (!this.newCompany.retailId || this.newCompany.retailId.trim() === '') {
      console.error('Company retail Id  is required');
      return;
    }
    this.whitelistingService.create(this.newCompany, this.token).subscribe(
     (response) => {
       if (response.error) {
        console.log('error occurred');
       } else {
         this.loadWhitelistedCompanies();
       }
     }
   );
  }

  editCompany(company: any) {
    this.selectedCompany = company;
    $('#editCompanyModal').modal('show');
  }

  openModal() {
    $('#createCompanyModal').modal('show');
  }

  saveChanges() {
    if (!this.selectedCompany.name || this.selectedCompany.name.trim() === '') {
      console.error('Company name is required');
      return;
    }

    if (!this.selectedCompany.retailId || this.selectedCompany.retailId.trim() === '') {
      console.error('Company retail Id  is required');
      return;
    }

    this.whitelistingService.edit(this.selectedCompany, this.token).subscribe(
      (response) => {
        if ( response.error) {
          alert( response.error.message );
        } else {
          this.loadWhitelistedCompanies();
          $('#editCompanyModal').modal('hide');
        }
      });
  }

  onLogoChange($event: any) {

    // @ts-ignore
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {

        const base64String = reader.result as string;
        this.selectedCompany.logo = base64String.replace(/^data:image\/[a-z]+;base64,/, '') ;
      };
      reader.readAsDataURL(file);
    }
  }

}
