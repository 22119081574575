import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Response } from '@angular/http';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-confirming-otp',
  templateUrl: './confirming-otp.component.html',
  styleUrls: ['./confirming-otp.component.css']
})
export class ConfirmingOtpComponent implements OnInit {

  email: string;
  otp: number;
  sub: any;
  oid: string;
  error: string = '';
  success: string = '';
  messege: string;

  constructor(private route: ActivatedRoute, private loginService: LoginService) {

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.oid = params['oid'];
      this.email = params['email'];
    });
  }

  onSubmit() {
    this.error = '';
    this.success = '';

    this.loginService.activate(this.otp, this.oid, this.email)
      .subscribe(
        (response: Response) => {
          if (response.status == 200) {
            if (response.json().confirmed) {
              this.success = "Your account is activated successful.";
            } 
            else {
              this.error = "Account not active, verify the code.";
            }
          } 
          else {
            this.error = "Error occured, please contact Droppa Team.";
          }
        },
        (err) => {
          this.error = "Error occured, please contact Droppa Team.";
        }
      );
  }
}
