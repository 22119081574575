import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.css', '../admin-portal/admin-portal.component.css']
})
export class BookingPaymentComponent implements OnInit {

  // booking: any = [];
  bookings : any = [];
  retailBookings : any = [];

  bookingDetails : any = [];
  admin : any = {};
  loader : boolean = false;
  name : string;
  invoiceObject: any;
  messege: string;

  company : any;

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
   // this.bookings = JSON.parse(localStorage.getItem('selectedBundleBooking')).bookings;
    //this.name = JSON.parse(localStorage.getItem('selectedBundleBooking')).companyName;
    //this.getBookingIds();
    this.company = JSON.parse(localStorage.getItem('selectedBookingCompany'));
    this.name = this.company.name;
    this.getBookings();
  }

  public getBookings() {
    this.adminService.getCompanyBookings(this.admin.token, this.company.oid).subscribe(
      (res) => {
        this.bookings = res.bookings;
        this.retailBookings = res.bucketBookings;
      }, (error) => {
        this.loadservice.removeloader();
        console.log(error);
      }
    );
  }
  public totalPrice(price){
    // (book.price - (book.price*0.15))-(book.price*0.2)
    const vatTotal=price-(price*0.15);
    return vatTotal-(vatTotal*0.2);
  }
  public paymentStatus(book){
    this.loadservice.addloader();
    this.adminService.updatePayedBookings(this.admin.token, book.oid).subscribe(
      (res) => {
        this.getBookings();
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public paymentBucketStatus(book) {
    this.loadservice.addloader();
    this.adminService.updatePayedBucketBookings(this.admin.token, book.oid).subscribe(
      (res) => {
        this.getBookings();
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public viewBooking(booking : any) {
    localStorage.setItem('selectedBooking', JSON.stringify(booking));
    this.router.navigate(['/view-booking']);
  }

  public getUpdatedBookings(driverOid : string) {
    this.adminService.getUpdatedBookings(this.admin.token, driverOid).subscribe(
      (res) => {
        this.bookings = res;
      }, (error) => {
      }
    );
  }

  public getSum() : number {
    let sum = 0;
    for(let i = 0; i < this.bookings.length; i++) {
      sum += (this.bookings[i].price  - (this.bookings[i].price*0.15));
    }

    for(let i = 0; i < this.retailBookings.length; i++) {
      sum += (this.retailBookings[i].price  - (this.retailBookings[i].price*0.15));
    }
    return sum;
  }

  public getBookingIds() {
    for(let x = 0; x < this.bookings.length; x++) {
      var details = {
          "bookingId" : this.bookings[x].booking.oid ,
          "dropId" : this.bookings[x].booking.dropOid
      };
      this.bookingDetails.push(details);
    }
  }

  public sendInviceToDrivers() {
    var invoiceObject = {
        "bookingIds": this.bookingDetails
      }
    this.adminService.sendDriversInvoice(this.admin.token, invoiceObject ).subscribe(
      (res) => {
        this.bookings = res;
        this.messege = 'Sent successfully.';
        // $('#confirm').modal('show');
        this.router.navigate(['/bundle-bookings']);

        localStorage.setItem('message', this.messege);
      }, (error) => {
        this.messege = 'Not sent.';
        // $('#feedback').modal('show');
        localStorage.setItem('message', this.messege);
      }
    );
  }

  public makePayments() {
    var pay = {
      "bookingIds" : this.bookingDetails
    }
    this.adminService.updateBookingsPayments(this.admin.token, pay).subscribe(
      (res) => {
        this.router.navigate(['/bundle-bookings']);
      }
    )
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
