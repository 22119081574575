import { Component, OnInit } from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {UserService} from '../../services/user.service';
import {LoaderService} from '../../services/loader.service';

declare var $: any;

@Component({
  selector: 'app-insured-bookings',
  templateUrl: './insured-bookings.component.html',
  styleUrls: ['./insured-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class InsuredBookingsComponent implements OnInit {

  adminUser: any;
  insuredBookings = [];
  tempInsuredBookings = [];
  retailers = [];

  searchQuery = '';
  searchTrackNumber = '';
  pudoBookingBeingTracked: any;
  viewPudoTracking = false;

  insuredBookingBeingViewed: any;
  pudoBookingBeingViewedIndex = -1;
  viewInsuredBookingDetails = false;

  showCompleteStatus = false;
  pudoFeedbackMsg = '';


  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private bookingService: BookingService, private userService: UserService, private loader: LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getAllInsuredBookings(0);
  }

  public getAllInsuredBookings(searchType: number) {

    this.loader.addloader();

    if(searchType == 0)
      this.resetPaging();

    const filter = {
      trackNo: this.searchTrackNumber,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    console.log(filter);

    this.bookingService.getAllInsuredBookings(this.adminUser.token, filter).subscribe(
      (response1: any) => {
        this.insuredBookings = response1.content;
        this.totalNumberOfBookings = response1.totalElement;
        this.tempInsuredBookings = this.insuredBookings;
        this.loader.removeloader();
      },
      (error1) => {
        this.loader.removeloader();
        console.log(error1);
      }
    );
  }

  public searchBookings() {
    this.insuredBookings = this.tempInsuredBookings;
    if (this.searchTrackNumber.trim() !== '') {
      this.insuredBookings = this.insuredBookings.filter(
        (item: any) => item.trackNo.toUpperCase().includes(this.searchTrackNumber.toUpperCase())
      );
    }
  }

  public showPudoTable() {
    window.scrollTo(0, 0);
    this.viewInsuredBookingDetails = false;
  }

  public showInsuredBookingDetails(booking, index) {
    this.insuredBookingBeingViewed = booking;
    this.viewInsuredBookingDetails = true;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getAllInsuredBookings(1);
  }

  resetPaging(){
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

}
