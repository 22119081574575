import {DatePipe, formatNumber} from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Response} from '@angular/http'
import {Router} from '@angular/router';
import * as moment from 'moment';
import {Parcel} from 'src/app/models/parcel';
import {BookingService} from 'src/app/services/booking.service';
import {SharedService} from 'src/app/services/shared.service';
import {AdminService} from "../../services/admin.service";
import {AngularFireDatabase} from '@angular/fire/database';
import {LoaderService} from 'src/app/services/loader.service';

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-retail-multiple-drop-no-tag',
  templateUrl: './retail-multiple-drop-no-tag.component.html',
  styleUrls: ['./retail-multiple-drop-no-tag.component.css']
})
export class RetailMultipleDropNoTagComponent implements OnInit {

  @ViewChild('merchant_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('merchant_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('signature', { static: false }) signature: ElementRef;
  @ViewChild('payFast', { static: false }) payFastForm: ElementRef;
  @ViewChild('addressFormVailidater', { static: false }) addressFormVailidater: ElementRef;

  // Ozow Payment Elements
  @ViewChild('SiteCode', { static: false}) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false}) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false}) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false}) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false}) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false}) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false}) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false}) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false}) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false}) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false}) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false}) HashCheck: ElementRef;

  public userSettings2: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off address.',
    geoTypes: ['address', 'establishment']
  };

  url: string;
  userToken: any;
  retail: any;
  bookingData: any;
  canPayWithEFT: any;
  canAssignOwnDriver: boolean;
  bucketBooking: any;
  vehicle: string;
  vehicleTypeRate: any;
  isExpressBooking: any;
  bookingTime: any;
  bookingDate: any;
  noBookings: number = 1;
  booking: any;

  selectedBooking: any;

  pickUp1 = false;
  pickUpFirstName: any;
  pickUpSurname: any;
  pickUpPhone: any;
  pickUpMobile: any;
  pickupCompanyName: any;
  pickupComplex: any;
  pickUpUnitNo: any;
  pickUpPCode: any;
  pickUpAmt: any;
  pickUpAutoComplete: any;
  pickUpProvince: any;
  pickUpNotMsg: string;
  pickUpAddress: string;
  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;
  pickUpCoordinate = [];
  pickUpCoordinateLocation: any = {};
  fromSuburb: any;
  companyPickUpName: any;
  complexPickUp: any;
  unitPickUp: any;
  departureCoordinate: any = {};

  bucketBookingObjPickUpAddress: string;
  bucketBookingObjPickUpCoordinateLocation: any;
  bucketBookingObjPickUpLocation: string;
  bucketBookingObjPickUpFirstname: string;
  bucketBookingObjPickUpMobile: string;
  bucketBookingObjPickUpEmail = '';
  bucketBookingObjPickUpChecked = false;
  bucketBookingObjPickPrice: number;
  bucketBookingObjPick2: boolean;
  bucketBookingObjCommentPick: string;

  secondPickUpChecked = false;

  dropContactFirstname: any;
  dropContactSurname: any;
  dropContactPhone: any;
  dropCompanyName: any;
  dropComplex: any;
  dropUnitNo: any;
  dropOffAddress: string;
  dropOffNotMsg: string = '';
  dropOffNotFound: boolean;
  dropOfflocation: string;
  dropOffCoordinate = [];
  dropOffCoordinateLocation: any = {};
  destinationCoordinate: any = {};

  dropOff1: boolean = false;
  dropOffAutoComplete: any;
  dropOffAmt: any;
  toSuburb: any;
  dropOffPCode: any;
  destinationProvince: any;
  dropOffProvince: any;
  newDropOffSuburb: any;
  dropOffLat: number;
  dropOffLong: number;
  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;

  bucketBookingObjDropOffAddress: string;
  bucketBookingObjDropOffCoordinateLocation: any;
  bucketBookingObjDropOfflocation: string;
  bucketBookingObjDropOffFirstname: string;
  bucketBookingObjDropOffSurname: string;
  bucketBookingObjDropOffMobile: string;
  bucketBookingObjDropOffEmail = '';
  bucketBookingObjDropOffChecked = false;
  bucketBookingObjDropPrice: number;
  bucketBookingObjDrop2: boolean;
  bucketBookingObjBookOID: string;
  bucketBookingObjCommentDrop: string;
  bucketBookingObjNumb: number;

  secondDropOffChecked: boolean = false;

  vehicleType: any;
  tempAmount: any;
  comment: any;
  bucketStatus: any;
  numberOfLabours: any;
  mass: any;

  airlineAmt: any;

  listOfBucketBookingObjs: any;
  bookingBucketList: any;
  type: any;
  transportMode: any;

  dayOfTheWeek: any;
  dayOfTheMonth: any;
  mainCenter: any;

  newPickUpSuburb: any;

  customerRef: any;
  costCentreNumber: any;

  parcelPrice = 0.0;
  price = 0.0;

  msgAddress: string;
  msgComment: string;
  msgPickUpName: string;
  msgDropOffName: string;
  msgPickUpMobile: string;
  msgDropOffMobile: string;
  msgItems: string;
  msgSuburb: string;
  tenderErrorMsg = '';
  priceMessage: string;
  msgStatus: any;
  goThroughMsg: any;
  errorMessage: string;
  message: string;
  paymentMsg: any;

  distance: any;
  totalMass: any;
  customer_reference: any;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;
  parcelDims = [];
  assignStatus = false;
  location: any = {};

  loader = false;

  totalExpressPrice: number;
  distanceOfTrip: number;
  massBookingObjElement: any;

  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;

  expressCourier: number = 0;
  budgetCourier: number = 0;
  tenderDocument: number = 0;
  parcels: any = 1;
  usedTotal: number;
  currentAmt: any;
  allowLoaderToShow: boolean = false
  bookingPrice: any;
  msg: string = '';
  numOfBoxes: any;
  parcelName: string = '';
  pracel: Parcel;
  pracels: Parcel[] = [];
  numOfParcels: any;

  massMsg: string = '';
  breadthMsg: string = '';
  heightMsg: string = '';
  lengthMsg: string = '';
  checkingMsg: string = '';
  checkMsg: string = '';

  pickUp2 = false;
  secondPickUplocation: string;
  secondPickUpFirstname: string;
  secondPickUpMobile: string;
  secondPickPrice: number;
  commentSecondPick: string;

  dropOff2 = false;
  secondDropOfflocation: string;
  secondDropOffFirstname: string;
  secondDropOffMobile: string;
  secondDropPrice: number;
  commentSecondDrop: string;

  pickUp3 = false;
  thirdPickUplocation: string;
  thirtPickUpFirstname: string;
  thirdPickUpMobile: string;
  thirdPickUpChecked = false;
  thirdPickPrice: number;
  commentThirdPick: string;

  dropOff3 = false;
  thirdDropOfflocation: string;
  thirtDropOffFirstname: string;
  thirdDropOffMobile: string;
  thirdDropOffChecked = false;
  thirdDropPrice: number;
  commentThirdDrop: string;

  err: any;

  isBalancePositive = false;
  secondDrop2: boolean;
  disablePickUpAddress = true;
  disableDropOffAddress = true;

  bucketBookingPrice: number;
  standardRateApplied: string;

  savedAddresses: any = [];
  promptPostalCode: any;
  promptedDropOffPCode: any;
  sameMassParcels = false;
  numberOfPracels = [];

  isTenderDocumentSelected = {
    tenderSelected: false
  };
  today = new Date();
  public min = new Date(this.today.setDate(this.today.getDate()));
  public max = new Date(2025, 3, 21, 20, 30);
  tenderDate: any;
  expectedDeliveryDate: any;

  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;
  displayPickUpAddress: any;

  enteredPickUpAddressMsg = '';
  enteredPickUpSuburbMsg = '';
  enteredPickUpPostalCodeMsg = '';
  enteredPickUpProvinceMsg = '';
  enteredPickUpSuccessMsg = '';

  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;
  displayDropOffAddress: any;

  enteredDropOffAddressMsg: string = '';
  enteredDropOffSuburbMsg: string = '';
  enteredDropOffPostalCodeMsg: string = '';
  enteredDropOffProvinceMsg: string = '';
  enteredDropOffSuccessMsg: string = '';

  pickUpExtraDetails: any = [];
  dropOffExtraDetails: any = [];
  tenderTime: any;
  hour: number;

  bookingType: string;

  promoCode = '';
  promoCodeMsg = '';
  promoCodeValid: boolean;
  promoCodeAmount = 0;

  mapOpen=false;
  switchPromoBtn = false;
  pickUpCoordinates = {
    lat: 0,
    lng: 0
  };
  loadClose=true;
  closeRetailDriver=[]
  allDrivers = [];
  closeRetailDriverMapper=[]
  driversNoLocation=[]
  retailDrivers = [];
  retailDriversToFilter = [];
  driverDetails = '';
  collectionException
  deliveryException

  icon = 'assets/mapmarker.png';
  package = 'assets/package.png';

  selectedVehicleType = 'All';
  allocatedDriver: any;

  checkPayFast = false;
  checkOzow = false;
  checkWallet = false;
  checkPaygate = false;

  ozowUrl: string;

  surbCheck={fromSuburbList:[],toSuburbList:[],pickUpPCodeList:[],dropOffPCodeList:[]}
  fromSuburbList:any[]=[];
  toSuburbList:any[]=[];
  pickUpPCodeList:any[]=[];
  dropOffPCodeList:any[]=[];

  usedGoogleAddressPickUp = false;
  usedGoogleAddressDropOff = false;

  constructor(private router: Router, private sharedService: SharedService, private bookService: BookingService,
              private adminService: AdminService, public db: AngularFireDatabase,private loadservice:LoaderService) {
    this.url = this.sharedService.getPayFastUrl();
    this.ozowUrl = this.sharedService.getOzowUrl();
  }

  ngOnInit() {
    this.vehicle = localStorage.getItem('vehicleType');
    this.retail = JSON.parse(localStorage.getItem('retail'));
    // console.log(this.retail);
    this.userToken = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingData = JSON.parse(localStorage.getItem('BookingData'));
    // console.log(this.bookingData);
    this.bucketBooking = JSON.parse(localStorage.getItem('bucketBooking'));
    this.selectedBooking = JSON.parse(localStorage.getItem('selectedBooking'));
    this.allocatedDriver = JSON.parse(localStorage.getItem('allocatedDriver'));
    // console.log(this.bucketBooking.failedCollectionType);
    if (this.bucketBooking.failedCollectionType) {
      const lastIndex = this.bucketBooking.bookings.length - 1;
      const b = this.bucketBooking.bookings[lastIndex];
      // console.log(this.bucketBooking.failedCollectionType);
      if (this.bucketBooking.failedCollectionType === 'DELIVERY') {
        const index = b.deliveryException.length - 1;
        this.deliveryException = b.deliveryException[index].reason;
      } else if (this.bucketBooking.failedCollectionType === 'COLLECTION') {
        const index = b.deliveryException.length - 1;
        this.collectionException = b.deliveryException[index].reason;
      }
    }


    if (!this.bookingData) {
      this.bookingData = this.bucketBooking.bookings[0];
    }

    this.pickUpCoordinates = {
      lat: parseFloat(this.bookingData.pickUpCoordinate.coordinates[0]),
      lng: parseFloat(this.bookingData.pickUpCoordinate.coordinates[1])
    };

    this.bookingType = this.bucketBooking.type;
    this.bucketStatus = this.bucketBooking.status;
    this.isTenderDocumentSelected = JSON.parse(localStorage.getItem('isTenderSelected'));
    this.canPayWithEFT = this.retail.canInvoice;
    this.canAssignOwnDriver = this.retail.canAssignDriver ? this.retail.canAssignDriver : false;
    this.vehicleTypeRate = this.retail.typeRates;
    this.getBucketbook(this.userToken.token, this.bucketBooking.oid);
    this.getSavedAddress(this.userToken.token, this.userToken.oid);

    // this.getDrivers(this.userToken.token, this.retail.oid);
    if (this.retail.canAssignDriver) {
      this.getCloseDriver();
    }

    this.getPaymentOptions();
  }

  public getPaymentOptions() {
    this.bookService.listAvailPaymentOptions(this.userToken.token).subscribe(response => {
        response.forEach(element => {
          if (element.optionType == 'PAYFAST') {
            this.checkPayFast = element.isActive;
          }
          else if (element.optionType == 'OZOW') {
            this.checkOzow = element.isActive;
          }
          else if (element.optionType == 'WALLET') {
            this.checkWallet = element.isActive;
          }
          else if (element.optionType == 'PAYGATE') {
            this.checkPaygate = element.isActive;
          }
        });
      },
      error => {console.log(error)}
    );
  }

  // Saved Addresses
  public toggleMap() {
    this.mapOpen = !this.mapOpen;
  }
  public getSavedAddress(token, personId) {
    this.bookService.retrieveAddresses(token, personId).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }, () => {

      }
    );
  }

  public getDrivers(token, retailOid) {
    this.adminService.getretailDrivers(token, retailOid).subscribe(
      (res) => {
        // console.log(res);
        this.retailDrivers = res;
        this.retailDriversToFilter = res;
      }, (err) => {
        // Do nothing
      }
    );
  }
  fake=[]
  public saveSubcribeData(response,driver){
    // console.log("refresh",driver.tracking.driverName);



    this.fake.push(driver)
    // this.closeRetailDriverMapper.push(driver)

    let date = new Date(driver.tracking.lastSeen);
    let currentDate = new Date();

    let days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);

    const trackingData={
      lat:response?response.lat:parseFloat(driver.tracking.location.coordinates[0]),
      lng:response?response.lng:parseFloat(driver.tracking.location.coordinates[1]),
      driverName:driver.tracking.driverName,
      driverPhone:driver.tracking.driverPhone,
      address:driver.tracking.location.address.substring(0,25),
      driver:driver.driver,
      isOnline:response?true:false,
      status:driver.status.toLowerCase(),
      lastseen:driver.tracking.lastSeen,
      pipe:days>=1?"longDate":"shortTime"
    }
    const newList=this.closeRetailDriverMapper.filter(d=>d.driverPhone!=trackingData.driverPhone)

    this.closeRetailDriverMapper=newList;

    // console.log(this.closeRetailDriverMapper," === VS ++",newList)

    this.closeRetailDriverMapper.push(trackingData)

    if(this.closeRetailDriver.length==this.closeRetailDriverMapper.length){
      this.loadClose=false
    }
  }
   public   trackDriverLocation (driversTocheck){
    driversTocheck.forEach((driver)=>{
      const cellNumber=driver.tracking.driverPhone

      this.db.object('driverTracking/'+ cellNumber).snapshotChanges().map( (res) => res.payload.val())
       .subscribe(
        (res:any)=>{
          this.saveSubcribeData(res,driver)
      },(error)=>{
        console.log(error);
      })
    })

  }
  public filterListOfDrivers(filterDropDown, driverDetails) {
    this.retailDriversToFilter = this.retailDrivers;

    if (filterDropDown !== 'All') {
      this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
        return  (driver.vehicle.type.toLowerCase().includes(filterDropDown.toLowerCase())
        );
      });
    }

    this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
      return  (
        driver.person.mobile.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.person.firstName.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.person.surname.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.location.toLowerCase().includes(driverDetails.toLowerCase())
        );
    });
  }

  public selectPickUpSavedAddress() {
    $('#showPickUpSavedAddressModal').modal('show');
  }

  public selectDropOffSavedAddress() {
    $('#showDropOffSavedAddressModal').modal('show');
  }

  public selectPickUpAddress(selectedAddress) {
    this.disablePickUpAddress = false;
    this.bucketBookingObjPickUpAddress = selectedAddress.address;
    this.pickUpProvince = selectedAddress.province;
    this.fromSuburb = selectedAddress.suburb;
    this.pickUpPCode = selectedAddress.postalCode;
    this.selectPickUpDropDownSuburb(selectedAddress.postalCode);
    // Address used for display
    this.pickUpAddress = selectedAddress.address;
    if ((selectedAddress.latitude === 0 || selectedAddress.latitude == null) &&
      (selectedAddress.longitude === 0 || selectedAddress.longitude == null)) {
      this.pickUpCoordinate = ['-25.97521', '28.13954'];
    } else {
      this.pickUpCoordinate = [selectedAddress.longitude, selectedAddress.latitude];
    }
    this.bucketBookingObjPickUpCoordinateLocation = {
      coordinates: this.pickUpCoordinate,
      address: this.bucketBookingObjPickUpAddress
    };
  }

  public selectDropOffAddress(selectedAddress) {
    this.disableDropOffAddress = false;
    this.bucketBookingObjDropOffAddress = selectedAddress.address;
    this.dropOffProvince = selectedAddress.province;
    this.toSuburb = selectedAddress.suburb;
    this.dropOffPCode = selectedAddress.postalCode;
    this.selectDropOffDropDownSuburb(selectedAddress.postalCode);
    // Address used for display
    this.dropOffAddress = selectedAddress.address;
    if ((selectedAddress.latitude === 0 || selectedAddress.latitude == null) &&
      (selectedAddress.longitude === 0 || selectedAddress.longitude == null)) {
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
    } else {
      this.dropOffCoordinate = [selectedAddress.longitude, selectedAddress.latitude];
    }
    this.bucketBookingObjDropOffCoordinateLocation = {
      coordinates: this.dropOffCoordinate,
      address: this.bucketBookingObjDropOffAddress
    };
  }

  //Saved Addresses
  public getCloseDriver(){
    this.bookService.getClosedDriver(this.userToken.token, this.bucketBooking.oid).subscribe(
      (res)=>{
        this.allDrivers = res;
        // console.log(res);
        const trackedDriver=res.filter(ress=>ress.tracking)
        const nottrackedDriver=res.filter(ress=>!ress.tracking)
        this.driversNoLocation=nottrackedDriver
        this.getDriverFromCloseDrivers(res)
        this.closeRetailDriver=trackedDriver

        this.trackDriverLocation(trackedDriver);

      },
      (error)=>{
        console.log("error",error)
      },

    )
  }
  public getDriverFromCloseDrivers(list){
    let storeRetailDrivers=[]
    let storeRetailDriversToFilter=[]
    list.forEach(d=>{
      storeRetailDrivers.push(d.driver)
      storeRetailDriversToFilter.push(d.driver)
    })
    this.retailDrivers = storeRetailDrivers;
    // console.log(this.retailDrivers);
    this.retailDriversToFilter = storeRetailDriversToFilter;
  }
  public getBucketbook(token: string, bucketOid: string) {
    this.bookService.getBucketBook(token, bucketOid).subscribe(
      (res: Response) => {
        localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
        localStorage.setItem('bucketBooking', JSON.stringify(res));
        // console.log(res);
        this.booking = res;
        this.isExpressBooking = this.booking.vehicleType;
        this.bookingTime = this.booking.time;
        this.noBookings = this.booking.bookings.length;
        const datePipe = new DatePipe('en-US');
        this.bookingDate = datePipe.transform(this.booking.date, 'yyyy-MM-dd');
      },
      (error) => {
        console.log(error);
      }, () => {
        const lastIndexOfBucket: [] = this.booking.bookings;
        if (lastIndexOfBucket.length > 1) {
          this.setBucketBookingData(lastIndexOfBucket.length - 1);
        } else {
          this.setBucketBookingData(0);
        }
      }
    );
  }
  lastBooking:any
  public setBucketBookingData(lastIndexOfBucket: number) {
    // console.log(this.booking.bookings[lastIndexOfBucket]);
    this.lastBooking = this.booking.bookings[lastIndexOfBucket];
    this.pickUpAddress = this.booking.bookings[lastIndexOfBucket].pickUpAddress;
    this.pickUpCoordinateLocation = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate;
    this.pickUpLong = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[1];
    this.pickUpLat = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[0];
    this.departureCoordinate = { lat: this.pickUpLat, lng: this.pickUpLong };
    this.pickUpPCode = this.booking.bookings[lastIndexOfBucket].pickUpPCode;
    this.fromSuburb = this.booking.bookings[lastIndexOfBucket].fromSuburb;
    this.pickUpProvince = this.booking.bookings[lastIndexOfBucket].province;

    this.pickUpFirstName = this.booking.bookings[lastIndexOfBucket].pickUp.firstName;
    this.pickUpSurname = this.booking.bookings[lastIndexOfBucket].pickUp.lastName;
    this.pickUpPhone = this.booking.bookings[lastIndexOfBucket].pickUp.phone;

    this.pickupCompanyName = this.booking.bookings[lastIndexOfBucket].pickUp.companyName;
    this.pickUpUnitNo = this.booking.bookings[lastIndexOfBucket].pickUp.unitNo;
    this.pickupComplex = this.booking.bookings[lastIndexOfBucket].pickUp.complex;

    // this.pickUpMobile = this.booking.bookings[lastIndexOfBucket].pickUp.phone;

    this.pickUpAmt = this.booking.bookings[lastIndexOfBucket].pickUpAmt;
    this.airlineAmt = this.booking.bookings[lastIndexOfBucket].airlineAmt;
    this.dropOffAmt = this.booking.bookings[lastIndexOfBucket].dropOffAmt;

    this.dropOffAddress = this.booking.bookings[lastIndexOfBucket].dropOffAddress;
    this.dropOfflocation = this.booking.bookings[lastIndexOfBucket].dropOffAddress;
    this.dropOffCoordinateLocation = this.booking.bookings[lastIndexOfBucket].dropOffCoordinate;
    this.dropOffLong = this.booking.bookings[lastIndexOfBucket].dropOffCoordinate.coordinates[1];
    this.dropOffLat = this.booking.bookings[lastIndexOfBucket].dropOffCoordinate.coordinates[0];
    this.destinationCoordinate = { lat: this.dropOffLat, lng: this.dropOffLong };
    this.dropOffPCode = this.booking.bookings[lastIndexOfBucket].dropOffPCode;
    this.toSuburb = this.booking.bookings[lastIndexOfBucket].toSuburb;

    this.dropContactFirstname = this.booking.bookings[lastIndexOfBucket].dropOff.firstName;
    this.dropContactSurname = this.booking.bookings[lastIndexOfBucket].dropOff.lastName;
    this.dropContactPhone = this.booking.bookings[lastIndexOfBucket].dropOff.phone;

    this.dropCompanyName = this.booking.bookings[lastIndexOfBucket].dropOff.companyName;
    this.dropUnitNo = this.booking.bookings[lastIndexOfBucket].dropOff.unitNo;
    this.dropComplex = this.booking.bookings[lastIndexOfBucket].dropOff.complex;

    this.vehicleType = this.booking.bookings[lastIndexOfBucket].vehicleType;
    this.tempAmount = this.booking.priceAfterPromo ? this.booking.priceAfterPromo : this.booking.price;
    this.comment = this.booking.bookings[lastIndexOfBucket].comment;

    this.bucketStatus = this.booking.status;
    this.numberOfLabours = this.bookingData != null ? this.bookingData.labour : 0;

    this.mass = this.booking.bookings[lastIndexOfBucket].itemMass;

    this.listOfBucketBookingObjs = this.booking.bookings;
    this.bookingBucketList = this.listOfBucketBookingObjs.length;
    this.type = this.booking.bookings[lastIndexOfBucket].type;
    this.transportMode = this.booking.bookings[lastIndexOfBucket].transportMode;

    this.expectedDeliveryDate = this.booking.bookings[lastIndexOfBucket].expectedDeliveryDate;
  }

  public onAddPickUp() {
    if (this.bucketBooking.status === 'BUCKET_AWAITING') {
      this.pickUp1 = true;
      this.dropOff1 = false;
    }
  }

  public onAddDrop() {
    if (this.bucketBooking.status === 'BUCKET_AWAITING') {
      this.pickUp1 = false;
      this.dropOff1 = true;
    }
  }

  public cancelCreation() {
    if (this.bucketBooking.status === 'BUCKET_AWAITING') {
      this.pickUp1 = false;
      this.dropOff1 = false;
      this.price = 0;
      this.parcelPrice = 0;
    }
  }

  public onAddSecondPick() {
    if (this.bucketBooking.status === 'BUCKET_AWAITING') {
      this.clearBucketBookingObj();
    }
    this.parcelPrice = 0.00;
    this.price = 0.00;
  }

  public onAddSecondDrop() {
    if (this.bucketBooking.status === 'BUCKET_AWAITING') {
      this.clearBucketBookingObj();
    }
    this.parcelPrice = 0.00;
    this.price = 0.00;
  }

  public clearBucketBookingObj() {
    if (this.dropOffAutoComplete !== undefined) {
      this.dropOffAutoComplete.data.active = false;
    }
    this.bucketBookingObjPickUpAddress = undefined;
    this.bucketBookingObjPickUpCoordinateLocation = undefined;
    this.bucketBookingObjPickUpFirstname = undefined;
    this.bucketBookingObjPickUpEmail = '';
    this.bucketBookingObjPickUpMobile = undefined;
    this.bucketBookingObjPickUpChecked = false;
    this.bucketBookingObjPickPrice = 0.00;
    this.bucketBookingObjPick2 = false;
    this.bucketBookingObjCommentPick = undefined;
    this.pickupCompanyName = undefined;
    this.complexPickUp = undefined;
    this.unitPickUp = undefined;
    this.pickUpProvince = this.booking.bookings[0].province;

    this.bucketBookingObjDropOffAddress = undefined;
    this.bucketBookingObjDropOffCoordinateLocation = undefined;
    this.bucketBookingObjDropOfflocation = undefined;
    this.bucketBookingObjDropOffFirstname = undefined;
    this.bucketBookingObjDropOffEmail = '';
    this.bucketBookingObjDropOffMobile = undefined;
    this.bucketBookingObjDropOffChecked = false;
    this.bucketBookingObjDropPrice = 0.00;
    this.bucketBookingObjDrop2 = false;
    this.bucketBookingObjCommentDrop = undefined;
    this.companyDropOffName = undefined;
    this.complexDropOff = undefined;
    this.unitDropOff = undefined;

    this.customerRef = undefined;
    this.costCentreNumber = undefined;

    this.parcelDims = [];

    this.disablePickUpAddress = true;
    this.enteredPickUpAddress = undefined;
    this.enteredPickUpSuburb = undefined;
    this.enteredPickUpPostalCode = undefined;
    this.enteredPickUpProvince = undefined;
    this.pickUpExtraDetails = [];
    if (this.booking.isInBound) { this.fromSuburb = undefined; }

    this.disableDropOffAddress = true;
    this.enteredDropOffAddress = undefined;
    this.enteredDropOffSuburb = undefined;
    this.enteredDropOffPostalCode = undefined;
    this.enteredDropOffProvince = undefined;
    this.dropOffExtraDetails = [];
    this.toSuburb = undefined;

    this.userSettings = {
      showRecentSearch: false,
      geoCountryRestriction: ['ZA'],
      inputPlaceholderText: 'Enter drop-Off address.',
      geoTypes: ['address', 'establishment'],
      inputString: ''
    };
  }

  checkPickUpPostalCode(selectedData: any) {

    // New code for getting a suburb based on a postal code
    this.resetPickUp()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb1.toUpperCase())
      this.fromSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.fromSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.fromSuburbList.push(suburb2.toUpperCase())
    }


    this.pickUpPCode = null;
    // New code for getting a suburb based on a postal code

    // Old code for getting postal code and suburb list
    this.pickUpAutoComplete = selectedData;
    if (!selectedData.response) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] === 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            } else if (selectedData.data.address_components[x].types[a] === 'postal_code') {
              this.pickUpPCode = selectedData.data.address_components[x].long_name;
              if (this.pickUpPCode === '2999') {
                this.pickUpPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              this.selectPickUpDropDownSuburb(this.pickUpPCode);
            } else if (selectedData.data.address_components[x].types[a] === 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }

      if (this.pickUpPCode == null) {
        this.promptPostalCode = 'Please enter your area postal code below';
        $('#pickUpPostcalCode').modal('show');
      } else {
        // New code for getting a suburb based on a postal code
        this.pickUpPCodeList.push(this.dropOffPCode);
        // New code for getting a suburb based on a postal code
      }

      this.bucketBookingObjPickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [
        selectedData.data.geometry.location.lat,
        selectedData.data.geometry.location.lng
      ];
      this.departureCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng
      };
      this.bucketBookingObjPickUpCoordinateLocation = {
        coordinates: this.pickUpCoordinate,
        address: this.pickUpAddress
      };
      // Old code for getting postal code and suburb list

      // New code for getting a suburb based on a postal code
      this.surbCheck.fromSuburbList = this.fromSuburbList;
      this.surbCheck.toSuburbList = this.fromSuburbList;
      this.surbCheck.pickUpPCodeList = this.pickUpPCodeList;
      this.surbCheck.dropOffPCodeList = this.pickUpPCodeList;

      this.usedGoogleAddressPickUp = true;
      this.validateAddress(true);
      // New code for getting a suburb based on a postal code
    }
  }

  resetPickUp(){
    this.fromSuburbList=[];
    this.pickUpPCodeList=[];
  }

  autoCompleteBucketBookingObj(selectedData: any) {

    // New code for getting a suburb based on a postal code
    this.resetDropOff()
    if(!selectedData)return
    let suburb1 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['locality', 'political']))[0];

    if(suburb1){
      suburb1= suburb1.short_name;
    }

    let suburb2 = selectedData.data.address_components
      .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1', 'political']))[0];

    if(!suburb2){
      suburb2 = selectedData.data.address_components
        .filter(f => JSON.stringify(f.types) === JSON.stringify(['sublocality_level_1','sublocality', 'political']))[0];
    }
    if(suburb2){
      suburb2= suburb2.short_name;
    }
    let suburbToCheck;
    //   .short_name;

    if(suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb1.toUpperCase())
      this.toSuburbList.push(suburb2.toUpperCase())
    }else if(suburb1&&!suburb2){
      suburbToCheck=suburb1;
      this.toSuburbList.push(suburb1.toUpperCase())
    }else if(!suburb1&&suburb2){
      suburbToCheck=suburb2;
      this.toSuburbList.push(suburb2.toUpperCase())
    }


    this.dropOffPCode = null;
    this.promptedDropOffPCode = null;
    // New code for getting a suburb based on a postal code

    // Old code for getting postal code and suburb list
    this.dropOffAutoComplete = selectedData;
    if (!selectedData.response) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              if (this.dropOffPCode === '2999') {
                this.dropOffPCode = '0299';
              }
              if (this.pickUpPCode === '3640') {
                this.pickUpPCode = '3610';
              }
              this.selectDropOffDropDownSuburb(this.dropOffPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }

      if (this.dropOffPCode == null) {
        this.promptPostalCode = 'Please enter your area postal code below';
        $('#dropOffPostcalCode').modal('show');
      } else {
        // New code for getting a suburb based on a postal code
        this.dropOffPCodeList.push(this.dropOffPCode);
        // New code for getting a suburb based on a postal code
      }

      this.bucketBookingObjDropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.destinationCoordinate = {
        lat: selectedData.data.geometry.location.lat,
        lng: selectedData.data.geometry.location.lng
      };
      this.bucketBookingObjDropOffCoordinateLocation = {
        "coordinates": this.dropOffCoordinate,
        "address": this.dropOffAddress
      };
      // Old code for getting postal code and suburb list

      // New code for getting a suburb based on a postal code
      this.surbCheck.fromSuburbList = this.toSuburbList;
      this.surbCheck.toSuburbList = this.toSuburbList;
      this.surbCheck.pickUpPCodeList = this.dropOffPCodeList;
      this.surbCheck.dropOffPCodeList = this.dropOffPCodeList;

      this.usedGoogleAddressDropOff = true;
      this.validateAddress(false);
      // New code for getting a suburb based on a postal code

    }
  }

  resetDropOff(){
    this.toSuburbList=[];
    this.dropOffPCodeList=[];
  }

  validAddress:any={fromSuburb:null,pickUpPCode:null,toSuburb:null,dropOffPCode:null};

  validateAddress(pickUp: boolean) {
    if (!(this.usedGoogleAddressPickUp || this.usedGoogleAddressDropOff)) {
      this.validAddress.fromSuburb = this.enteredPickUpSuburb;
      this.validAddress.pickUpPCode = this.enteredPickUpPostalCode.trim();
      this.validAddress.toSuburb = this.enteredDropOffSuburb;
      this.validAddress.dropOffPCode = this.enteredDropOffPostalCode.trim();
      // localStorage.setItem('validAddress', JSON.stringify(this.validAddress));
      return;
    }
    this.loadservice.addloader();
    this.bookService.validateAddress(this.surbCheck).subscribe(
      response => {
        this.loadservice.removeloader();
        const r = response.json();
        if (pickUp && r.fromSuburbList && r.fromSuburbList.length > 0) {
          this.validAddress.fromSuburb = r.fromSuburbList[r.fromSuburbList.length - 1];
          this.validAddress.pickUpPCode = r.pickUpPCodeList[0];
          this.fromSuburb = this.validAddress.fromSuburb;
          this.surbCheck.fromSuburbList = [this.fromSuburb];
          this.surbCheck.pickUpPCodeList = [this.validAddress.pickUpPCode];
        }
        if (!pickUp && r.toSuburbList && r.toSuburbList.length > 0) {
          this.validAddress.toSuburb = r.toSuburbList[r.toSuburbList.length - 1];
          this.validAddress.dropOffPCode = r.dropOffPCodeList[0];
          this.toSuburb = this.validAddress.toSuburb;
          this.surbCheck.toSuburbList = [this.toSuburb];
          this.surbCheck.dropOffPCodeList = [this.validAddress.dropOffPCode];
        }
      }, error => {
        this.loadservice.removeloader();
        pickUp ? this.fromSuburb = undefined : this.toSuburb = undefined;
        pickUp ? this.surbCheck.fromSuburbList = [] : this.surbCheck.toSuburbList = [];
        pickUp ? this.surbCheck.pickUpPCodeList = [] : this.surbCheck.dropOffPCodeList = [];
        console.log(error);

      }, () => {
        this.loadservice.removeloader();
      }
    );
  }

  clickedDropOffpCode() {
    this.selectDropOffDropDownSuburb(this.promptedDropOffPCode);
    this.dropOffPCode = this.promptedDropOffPCode;
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                }
              }
            }
          }
        }
      }
    );
  }

  public getDropOffSuburb(postalCode) {
    this.bookService.getDropOffSuburb(postalCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  getPickUpQuote() {
    const datePipe = new DatePipe('en-US');
    const valudeTender = datePipe.transform(this.tenderDate, 'yyyy-MM-dd');
    const calDiffDays = moment(this.bookingDate).diff(moment(valudeTender), 'days');
    if (this.type === 'EXPRESS_COURIER' || this.type === 'BUDGET_COURIER') {
      if (this.bucketBookingObjPickUpAddress === '' || this.bucketBookingObjPickUpAddress == null) {
        this.msgAddress = 'Please enter your address';
      } else if (this.bucketBookingObjPickUpFirstname === '' || this.bucketBookingObjPickUpFirstname == null) {
        this.msgPickUpName = 'Please fill in the name';
      } else if (this.bucketBookingObjPickUpMobile === '' || this.bucketBookingObjPickUpMobile == null) {
        this.msgPickUpMobile = 'Please enter in your numbers.';
      } else if (this.bucketBookingObjPickUpMobile.length !== 10) {
        this.msgPickUpMobile = 'Please make sure the entered number isn\'t less than 10 digits.';
      } else if (this.bucketBookingObjCommentPick === '' || this.bucketBookingObjCommentPick == null) {
        this.msgComment = 'Please fill in the comments (Instructions...)';
      } else if (this.parcelDims.length <= 0) {
        this.msgItems = 'Please fill in the dimensions before getting a quote';
      } else if (this.toSuburb === '' || this.toSuburb == null) {
        this.msgSuburb = 'Please select the suburb before proceeding';
      } else if ((this.expectedDeliveryDate != null) && this.tenderDate === undefined) {
        this.tenderErrorMsg = 'Please enter the date & time for the tender documents';
      } else if ((this.expectedDeliveryDate != null) && this.tenderTime === undefined) {
        this.tenderErrorMsg = 'Please enter the date & time for the tender documents';
      } else if ((this.expectedDeliveryDate != null) && calDiffDays > 0) {
        alert('Please note that the Tender delivery date should be after the collection date.');
      } else {
        this.getDroppaPricings();
        this.msgStatus = '';
        this.msgAddress = '';
        this.msgComment = '';
        this.msgPickUpName = '';
        this.msgPickUpMobile = '';
        this.goThroughMsg = '';
        this.checkingMsg = '';
        this.msgItems = '';
        this.msgSuburb = '';
        this.disablePickUpAddress = false;
        this.tenderErrorMsg = '';
      }
    } else {
      if (this.bucketBookingObjPickUpAddress === '' || this.bucketBookingObjPickUpAddress == null) {
        this.msgAddress = 'Please enter your address';
      } else if (this.bucketBookingObjPickUpFirstname === '' || this.bucketBookingObjPickUpFirstname == null) {
        this.msgPickUpName = 'Please fill in the name';
      } else if (this.bucketBookingObjPickUpMobile === '' || this.bucketBookingObjPickUpMobile == null) {
        this.msgPickUpMobile = 'Please enter in your numbers.';
      } else if (this.bucketBookingObjPickUpMobile.length !== 10) {
        this.msgPickUpMobile = 'Please make sure the entered number isn\'t less than 10 digits.';
      } else if (this.bucketBookingObjCommentPick === '' || this.bucketBookingObjCommentPick == null) {
        this.msgComment = 'Please fill in the comments (Instructions...)';
      } else {
        this.getDroppOffQuote();
        this.disablePickUpAddress = false;
        this.msgStatus = '';
        this.msgAddress = '';
        this.msgComment = '';
        this.msgDropOffName = '';
        this.msgDropOffMobile = '';
        // this.disableAddresses = false;
      }
    }
  }

  public getBothQuote() {
    var datePipe = new DatePipe("en-US");
    var valudeTender = datePipe.transform(this.tenderDate, 'yyyy-MM-dd');
    let calDiffDays = moment(this.bookingDate).diff(moment(valudeTender), 'days');
    if (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER') {
      if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
        this.msgAddress = "Please enter your address";
      }
      else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
        this.msgDropOffName = "Please fill in the name";
      }
      else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
        this.msgDropOffMobile = "Please enter in your numbers.";
      }
      else if (this.bucketBookingObjDropOffMobile.length != 10) {
        this.msgDropOffMobile = "Please make sure the entered number isn't less than 10 digits.";
      }
      else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
        this.msgComment = "Please fill in the comments (Instructions...)";
      }
      else if (this.parcelDims.length <= 0) {
        this.msgItems = "Please fill in the dimensions before getting a quote";
      }
      else if (this.toSuburb == '' || this.toSuburb == null) {
        this.msgSuburb = "Please select the suburb before proceeding";
      }
      else if ((this.expectedDeliveryDate != null) && this.tenderDate == undefined) {
        this.tenderErrorMsg = "Please enter the date & time for the tender documents";
      }
      else if ((this.expectedDeliveryDate != null) && this.tenderTime == undefined) {
        this.tenderErrorMsg = "Please enter the date & time for the tender documents";
      }
      else if ((this.expectedDeliveryDate != null) && calDiffDays > 0) {
        alert('Please note that the Tender delivery date should be after the collection date.');
      }
      else {
        this.getDroppaPricings();
        this.msgStatus = "";
        this.msgAddress = "";
        this.msgComment = "";
        this.msgDropOffName = "";
        this.msgDropOffMobile = "";
        this.goThroughMsg = "";
        this.checkingMsg = "";
        this.msgItems = "";
        this.msgSuburb = "";
        this.disableDropOffAddress = false;
        this.tenderErrorMsg = "";
      }
    }
    else {
      if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
        this.msgAddress = "Please enter your address";
      }
      else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
        this.msgDropOffName = "Please fill in the name";
      }
      else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
        this.msgDropOffMobile = "Please enter in your numbers.";
      }
      else if (this.bucketBookingObjDropOffMobile.length != 10) {
        this.msgDropOffMobile = "Please make sure the entered number isn't less than 10 digits.";
      }
      else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
        this.msgComment = "Please fill in the comments (Instructions...)";
      }
      else {
        this.getDroppOffQuote();
        this.disableDropOffAddress = false;
        this.msgStatus = "";
        this.msgAddress = "";
        this.msgComment = "";
        this.msgDropOffName = "";
        this.msgDropOffMobile = "";
        // this.disableAddresses = false;
      }
    }
  }

  public getDroppaPricings() {

    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.bucketBookingObjDropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.parcelDims,
      "platform": "web",
    }
    this.loadservice.addloader()
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        this.loadservice.removeloader();
        // If Tender document price is selected from previous screen
        if (this.expectedDeliveryDate !== null) {
          if (response.json().tenderDocAmount > 0) {
            this.tenderDocument = Math.round(response.json().tenderDocAmount);
            this.parcelPrice = this.tenderDocument;
          }
        }
        // If Tender document not selected from previous screen
        else {
          if (response.json().expressCourier > 0) {
            this.loadservice.removeloader()
            this.isCourierExpress = false;
            this.expressCourier = response.json().expressCourier;
            this.parcelPrice = Math.round(this.expressCourier);
          }
          if (response.json().expressBudgetAmt > 0) {
            this.loadservice.removeloader()
            this.isExpressBudget = this.allowLoaderToShow;
            this.budgetCourier = response.json().expressBudgetAmt;
            this.parcelPrice = (Math.round(this.budgetCourier) * this.parcels);
          }
        }

      }, error => {
        this.loadservice.removeloader()
        console.log(error);
      }, () => { }
    );

  }

  public getDroppOffQuote() {

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };

    if(this.retail.circleRouteRate){
      console.log("using circle rate")
      if(this.pickUp1){
        var departure={
          lat:this.lastBooking.pickUpCoordinate.coordinates[0],
          lng:this.lastBooking.pickUpCoordinate.coordinates[1]
        }
        data = {
          "destination": this.departureCoordinate,
          "departure": departure
        };
      }else if(this.dropOff1){
        var departure2={
          lat:this.lastBooking.dropOffCoordinate.coordinates[0],
          lng:this.lastBooking.dropOffCoordinate.coordinates[1]
        }
        data = {
          "destination": this.destinationCoordinate,
          "departure": departure2
        };
      }
    }
    // console.log(data);
    this.loadservice.addloader();
    this.bookService.getDistance(data).subscribe(
      (response) => {
        // console.log(response.json());
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);
        var object = JSON.parse(localStorage.getItem('userInfo'));
        this.bookService.getRetailVehicleRate(this.retail.oid, this.vehicleType)
          .subscribe(
            (res: Response) => {
              var bookingData = {
                "distance": this.distance,
                "retailOid": this.retail.oid,
                "totalPrice": 0.0,
                "vehicleType": this.vehicleType
              };
              this.loadservice.removeloader()
              this.bookService.getRetailbookingPrice(bookingData, object.token)
                .subscribe(
                  (response: Response) => {
                    this.bookingPrice = response.json();
                    this.price = Math.round(this.bookingPrice.totalPrice);
                  },
                  (err) => {
                    console.log(err);
                    this.loadservice.removeloader()
                  }
                );
            },
            (err) => {
              console.log(err);
              this.loadservice.removeloader();
              this.msg = err.json().message;
              $('#results').modal('show');
            }
          )
      });

  }

  disableAddresses: boolean = true;

  public enterPickUpAddress() {
    $('#addressPopUp1').modal('show');
  }

  public enterDropOffAddress() {
    $('#addressPopUp2').modal('show');
  }

  public selectedPickUpSuburb(fromSuburb) {
    this.fromSuburb = fromSuburb;
  }

  public selectedDropOffSuburb(toSuburb) {
    this.toSuburb = toSuburb;
  }

  public selectPickUpDropDownSuburb(postalCode) {
    if ((this.pickUpPCode != null && this.pickUpPCode !== undefined) && (this.type === 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.bookService.postalDetails(this.userToken.token, postalCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  public selectDropOffDropDownSuburb(postalCode) {
    if ((this.dropOffPCode != null && this.dropOffPCode != undefined) && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.bookService.postalDetails(this.userToken.token, postalCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public getPickUpExtraDetails(quote) {
    this.pickUpPCode = quote;
    if ((this.pickUpPCode != null || this.pickUpPCode !== undefined) && this.pickUpPCode.toString().length === 4) {
      this.bookService.postalDetails(this.userToken.token, this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.pickUpExtraDetails = [];
    }
  }

  public getDropOffExtraDetails(quote) {
    this.dropOffPCode = quote;
    if ((this.dropOffPCode != null || this.dropOffPCode != undefined) && this.dropOffPCode.toString().length == 4) {
      this.bookService.postalDetails(this.userToken.token, this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public capturedPickUpAddress() {

    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress === '') {
      this.enteredPickUpAddressMsg = 'Please enter the address';
    } else if (this.enteredPickUpSuburb == null || this.enteredPickUpSuburb === '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    } else if (this.enteredPickUpPostalCode == null || this.enteredPickUpPostalCode === '') {
      this.enteredPickUpPostalCodeMsg = 'Please enter the postal code';
    } else if (this.enteredPickUpProvince == null || this.enteredPickUpProvince === '') {
      this.enteredPickUpProvinceMsg = 'Please enter the province';
    } else {
      this.disablePickUpAddress = false;
      this.enteredPickUpSuccessMsg = 'Address successfully captured.';

      this.fromSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode;
      this.pickUpProvince = this.enteredPickUpProvince;

      this.bucketBookingObjPickUpAddress = this.enteredPickUpAddress.concat(', ' + this.fromSuburb)
        .concat(', ' + this.pickUpPCode)
        .concat(', ' + this.pickUpProvince);

      this.pickUpCoordinate = ['-25.97521', '28.13954'];
      this.bucketBookingObjPickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.bucketBookingObjPickUpAddress };

    }

  }

  public closeCapturedPickUpAddress() {
    this.enteredPickUpAddressMsg = '';
    this.enteredPickUpSuburbMsg = '';
    this.enteredPickUpPostalCodeMsg = '';
    this.enteredPickUpProvinceMsg = '';
  }

  public capturedDropOffAddress() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    }
    else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    }
    else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    }
    else {

      this.disableDropOffAddress = false;
      this.enteredDropOffSuccessMsg = 'Address successfully captured.'

      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;

      this.bucketBookingObjDropOffAddress = this.enteredDropOffAddress.concat(', ' + this.toSuburb)
        .concat(', ' + this.dropOffPCode)
        .concat(', ' + this.dropOffProvince);

      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.bucketBookingObjDropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.bucketBookingObjDropOffAddress };

    }

  }

  public closeCapturedDropOffAddress() {
    this.enteredDropOffAddressMsg = '';
    this.enteredDropOffSuburbMsg = '';
    this.enteredDropOffPostalCodeMsg = '';
    this.enteredDropOffProvinceMsg = '';
  }

  // Dims
  public enterDimensions() {
    $('#enterDimensions').modal('show');
  }

  public addDimensions() {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (this.parcel_mass == null || this.parcel_mass == '') {
        this.massMsg = 'Please enter the mass.';
      }
      else if (this.parcel_breadth == null || this.parcel_breadth == '') {
        this.breadthMsg = 'Please enter the breadth.';
      }
      else if (this.parcel_height == null || this.parcel_height == '') {
        this.heightMsg = 'Please enter the height.';
      }
      else if (this.parcel_length == null || this.parcel_length == '') {
        this.lengthMsg = 'Please enter the length.';
      }
      else if (this.parcel_mass > 70) {
        this.massMsg = 'The max for mass is 70KG with courier service.';
      }
      else if (this.parcel_breadth > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.breadthMsg = 'The max for breadth is 150cm with courier service.';
      }
      else if (this.parcel_height > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.heightMsg = 'The max for height is 150cm with courier service.';
      }
      else if (this.parcel_length > 300 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.lengthMsg = 'The max for length is 300cm with courier service.';
      }
      else if (this.parcel_mass == 0) {
        this.massMsg = 'The mass can not be 0.';
      }
      else if (this.parcel_breadth == 0) {
        this.breadthMsg = 'The breadth can not be 0.';
      }
      else if (this.parcel_height == 0) {
        this.heightMsg = 'The mass height not be 0.';
      }
      else if (this.parcel_length == 0) {
        this.lengthMsg = 'The mass length not be 0.';
      }
      else if (this.parcel_mass < 0) {
        this.massMsg = 'The mass can not be a negative number.';
      }
      else if (this.parcel_breadth < 0) {
        this.breadthMsg = 'The breadth can not be a negative number.';
      }
      else if (this.parcel_height < 0) {
        this.heightMsg = 'The height can not be a negative number.';
      }
      else if (this.parcel_length < 0) {
        this.lengthMsg = 'The length can not be a negative number.';
      }
      else if (this.parcelDims.length >= 30) {
        this.checkingMsg = 'You can only add 30 parcels for Courier Service.';
      }
      else if (this.sameMassParcels && (this.numOfParcels == 0 || this.numOfParcels == null || this.numOfParcels == '')) {
        this.checkMsg = 'Please enter the number of parcels before proceeding.';
      }
      else {
        let courierObj = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass
        };
        this.parcelDims.push(courierObj);
        this.calculateParcelDimensions();
        this.parcel_length = '';
        this.parcel_height = '';
        this.parcel_mass = '';
        this.parcel_breadth = '';
      }
    }
  }

  exceededParcelsMsg: string = "";
  public addDifferentDimensions() {
    this.numberOfPracels = new Array(this.numOfParcels);
    if (this.parcel_mass == null || this.parcel_mass == '') {
      this.massMsg = 'Please enter the mass.';
    }
    else if (this.parcel_breadth == null || this.parcel_breadth == '') {
      this.breadthMsg = 'Please enter the breadth.';
    }
    else if (this.parcel_height == null || this.parcel_height == '') {
      this.heightMsg = 'Please enter the height.';
    }
    else if (this.parcel_length == null || this.parcel_length == '') {
      this.lengthMsg = 'Please enter the length.';
    }
    else if (this.parcel_mass > 70) {
      this.massMsg = 'The max for mass is 70KG with courier service.';
    }
    else if (this.parcel_breadth > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.breadthMsg = 'The max for breadth is 150cm with courier service.';
    }
    else if (this.parcel_height > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.heightMsg = 'The max for height is 150cm with courier service.';
    }
    else if (this.parcel_length > 300 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.lengthMsg = 'The max for length is 300cm with courier service.';
    }
    else if (this.parcel_mass == 0) {
      this.massMsg = 'The mass can not be 0.';
    }
    else if (this.parcel_breadth == 0) {
      this.breadthMsg = 'The breadth can not be 0.';
    }
    else if (this.parcel_height == 0) {
      this.heightMsg = 'The mass height not be 0.';
    }
    else if (this.parcel_length == 0) {
      this.lengthMsg = 'The mass length not be 0.';
    }
    else if (this.parcel_mass < 0) {
      this.massMsg = 'The mass can not be a negative number.';
    }
    else if (this.parcel_breadth < 0) {
      this.breadthMsg = 'The breadth can not be a negative number.';
    }
    else if (this.parcel_height < 0) {
      this.heightMsg = 'The height can not be a negative number.';
    }
    else if (this.parcel_length < 0) {
      this.lengthMsg = 'The length can not be a negative number.';
    }
    else if (this.parcelDims.length >= 30) {
      this.checkingMsg = 'You can only add 30 parcels for Courier Service.';
    }
    else if (this.sameMassParcels && (this.numOfParcels == 0 || this.numOfParcels == null || this.numOfParcels == '')) {
      this.checkMsg = 'Please enter the number of parcels before proceeding.';
    }
    else if (parseInt(this.numOfParcels) > 150) {
      this.exceededParcelsMsg = "You may only process 150 parcels.";
    }
    else {
      this.numberOfPracels = new Array(this.numOfParcels);
      for (let x = 0; x < this.numberOfPracels.length; x++) {
        let courierObj = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass
        };
        this.parcelDims.push(courierObj);;
      }
      this.calculateParcelDimensions();
      this.parcel_length = '';
      this.parcel_height = '';
      this.parcel_mass = '';
      this.parcel_breadth = '';
      this.exceededParcelsMsg = '';
      $("#enterDimensions").modal("hide");
    }

  }

  public removeDimensions(item) {
    this.parcelDims.splice(item, 1);
  }

  public calculateParcelDimensions() {
    var totalParcelMass = 0;

    this.parcelDims.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });

    this.totalMass = totalParcelMass;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      }
      else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      }
      else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      }
      else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      }
      else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      }
      else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      }
      else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      }
      else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      }
      else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }
  }

  public modelHeightChanged(parcel_height) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      }
      else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      }
      else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      }
      else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      }
      else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }
  }

  public modelLengthChanged(parcel_length) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_length > 300) {
        this.lengthMsg = "Length for parcel cannot exceed 300cm";
      }
      else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      }
      else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      }
      else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      }
      else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }
  }

  public modelParcelExceedsChanged(numOfParcels) {
    if (numOfParcels > 150) {
      this.exceededParcelsMsg = "Number of parcels cannot exceed 150.";
    } else if (numOfParcels == 0) {
      this.exceededParcelsMsg = "Parcels cannot be 0.";
    } else if (numOfParcels < 0) {
      this.exceededParcelsMsg = "Parcels cannot be a negative number.";
    } else if (numOfParcels == null) {
      this.exceededParcelsMsg = "Parcels cannot be empty.";
    } else if (numOfParcels < 150) {
      this.exceededParcelsMsg = " ";
    }
  }
  // Dims

  // Booking Action
  public addingBucketBookingObj(inbound: boolean) {

    if (this.parcelPrice || this.price != 0) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      var token = object.token;
      var timestamp = new Date().valueOf();

      var bookingData = {
        "dropOff": {
          "firstName": this.bucketBookingObjDropOffFirstname ? this.bucketBookingObjDropOffFirstname : this.dropContactFirstname,
          "lastName": this.bucketBookingObjDropOffFirstname ? this.bucketBookingObjDropOffFirstname : this.dropContactFirstname,
          "phone": this.bucketBookingObjDropOffMobile ? this.bucketBookingObjDropOffMobile : this.dropContactPhone,
          "email": this.bucketBookingObjDropOffEmail === '' ? object.owner.email : this.bucketBookingObjDropOffEmail,
          'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : this.dropComplex,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : this.dropUnitNo
        },
        "pickUp": {
          "firstName": this.bucketBookingObjPickUpFirstname ? this.bucketBookingObjPickUpFirstname : this.pickUpFirstName,
          "lastName": this.bucketBookingObjPickUpFirstname ? this.bucketBookingObjPickUpFirstname : this.pickUpFirstName,
          "phone": this.bucketBookingObjPickUpMobile ? this.bucketBookingObjPickUpMobile : this.pickUpPhone,
          "email": this.bucketBookingObjPickUpEmail === '' ? object.owner.email : this.bucketBookingObjPickUpEmail,
          'companyName': this.pickupCompanyName != null ? this.pickupCompanyName : object.owner.firstName,
          'complex': this.pickupComplex != null ? this.pickupComplex : this.pickupComplex,
          'unitNo': this.pickUpUnitNo != null ? this.pickUpUnitNo : this.pickUpUnitNo
        },
        "customer": object.owner,
        "price": this.price !== 0 ? this.price : this.parcelPrice,
        "vehicleType": this.vehicleType,
        "pickUpAddress": this.bucketBookingObjPickUpAddress ? this.bucketBookingObjPickUpAddress : this.pickUpAddress,
        "pickUpTime": this.bookingTime,
        "phoneNo": object.owner.mobile,
        "pickUpDate": this.booking.bookings[0].pickUpDate,
        "dropOffAddress": this.bucketBookingObjDropOffAddress ? this.bucketBookingObjDropOffAddress : this.dropOffAddress,
        "comment": this.bucketBookingObjCommentPick ? this.bucketBookingObjCommentPick : this.bucketBookingObjCommentDrop,
        "labour": 0,
        "timestamp": timestamp,
        "status": "BUCKET",
        "dropFloors": 0,
        "pickUpFloors": 0,
        "pickUpCoordinate": this.bucketBookingObjPickUpCoordinateLocation ? this.bucketBookingObjPickUpCoordinateLocation : this.pickUpCoordinateLocation,
        "dropOffCoordinate": this.bucketBookingObjDropOffCoordinateLocation ? this.bucketBookingObjDropOffCoordinateLocation : this.dropOffCoordinateLocation,
        "load": this.parcels,
        "canopy": false,
        "dropOid": this.bookingData.dropOid,
        "distance": 0,
        "province": this.pickUpProvince,
        'transportMode': this.transportMode,
        'type': this.type,
        "itemMass": this.totalMass,
        'isItemPicked': false,
        'mainCityOid': this.mainCenter != null ? this.mainCenter.oid : null,
        'fromSuburb': this.fromSuburb,
        'toSuburb': this.toSuburb,
        'pickUpPCode': this.pickUpPCode,
        'dropOffPCode': this.dropOffPCode,
        'pickUpAmt': this.pickUpAmt,
        'airlineAmt': this.airlineAmt,
        'dropOffAmt': this.dropOffAmt,
        'destinationProvince': this.dropOffProvince,
        'isPayed': false,
        'paymentType': "ONLINE_PAY",
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber,
        "parcels": null,
        'isExpress': true,
        "parcelDimensions": this.parcelDims,
        "expectedDeliveryDate": null
      };

      console.log(bookingData)

      // Checks if the tender document was selected
      if (this.expectedDeliveryDate != null) {
        var datePipe = new DatePipe("en-US");
        let valueTime = datePipe.transform(this.tenderTime, 'HH:mm a');
        var currentDate = new Date(this.tenderDate);
        let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
        let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
        var second = currentDate.getSeconds() <= 9 ? "0" + currentDate.getSeconds() : currentDate.getSeconds();
        var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + valueTime.split(" ")[0] + ':' + second + '.957Z';
        var tenderSelectedDate = dropTime;
      }

      if (tenderSelectedDate != null || tenderSelectedDate != undefined) {
        bookingData.expectedDeliveryDate = tenderSelectedDate;
      }

      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }

      if (this.type == 'DEDICATED') {
        bookingData.isExpress = false;
      }
      var addbookingObj = {
        "bucketBookingOid": this.booking.oid,
        "booking": bookingData,
        isInBound: inbound
      };
      // console.log(addbookingObj);
      this.loadservice.addloader();
      this.bookService.addToBucketsBook(addbookingObj, token).subscribe(
        (res: Response) => {
          this.loadservice.removeloader();
          this.msg = 'Booking successfully added to the bucket!';
          $('#Bookingresults').modal('show');
          this.onAddSecondDrop();
          this.getBucketbook(token, this.booking.oid);
          this.bucketBookingObjDropOffChecked = true;
        },
        (error: Response) => {
          console.log(error);
          this.loadservice.removeloader();
          this.err = error;
          this.msg = this.err.error.message != null ? this.err.error.message : 'Error occured while making a booking, Please try again.';
          $('#Bookingresults').modal('show');
        }
      );
    } else {
      this.priceMessage = "Please click the get quote button & get a price to add an element.";
      $('#priceModal').modal('show');
    }

  }

  public removeBucketBookingObj(i) {
    this.loadservice.addloader();
    this.bookService.removeFromBucketsBook(this.booking.oid, this.booking.bookings[i].oid, this.userToken.token).subscribe(
      (res) => {
        this.msg = "Booking deleted from the Bucket!";
        $('#removeElement').modal('show');
        this.loadservice.removeloader();
        this.clearFields();
        this.getBucketbook(this.userToken.token, this.booking.oid);
      }, (error) => {
        console.log(error);
        $('#removeElement').modal('show');
        this.msg = "Booking  not deleted!";
        this.loadservice.removeloader();
      }
    )
  }

  private clearFields() {
    this.dropOff3 = false;
    this.thirdDropOfflocation = '';
    this.thirtDropOffFirstname = '';
    this.thirdDropOffMobile = '';
    this.thirdDropOffChecked = false;
    this.thirdDropPrice = 0.00;
    this.commentThirdDrop = '';

    this.dropOff2 = false;
    this.secondDropOfflocation = '';
    this.secondDropOffFirstname = '';
    this.secondDropOffMobile = '';
    this.secondDropOffChecked = false;
    this.secondDropPrice = 0.00;
    this.commentSecondDrop = '';

    this.parcelPrice = 0.00;
    this.price = 0.00;
    this.savedAddresses = [];
    this.dropOffAddress = '';

    this.sameMassParcels = false;
    this.numOfParcels = '';
    this.tenderDate = undefined;
    this.tenderTime = undefined;
  }

  public cancelBacketBook() {
    this.loadservice.addloader();
    this.bookService.cancelBucket(this.userToken.token, this.booking.oid).subscribe(
      (res) => {
        this.msg = "Booking cancelled.";
        $('#bookingCancelled').modal('show');
        this.getBucketbook(this.userToken.token, this.booking.oid);
        this.loadservice.removeloader();
        this.router.navigateByUrl('/retail-profile');
      }, (error) => {
        console.log(error);
        this.msg = "Could not cancel booking.";
        this.loadservice.removeloader();
        $('#bookingCancelled').modal('show');
      }
    )
  }

  public onHome() {
    // this.updateBookingPrices();
    // this.router.navigateByUrl('/retail-profile');
    this.router.navigateByUrl('retail-load-successful/' + this.bucketBooking.oid + '/' + this.bucketBooking.price + '/move_items');
  }
  // Booking Action

  standardRatePrice: any;

  // Payments
  assignDriverError="The driver already has a booking to attend to within the time range."
  public confirmBooking() {
    this.assignStatus = false;
    this.assignDriverError=""
    // Assign a driver to bucket
    if (this.driverAssignedToBooking != null) {
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.assignDriverToBucketBooking(object.token, this.bucketBooking.oid, this.driverAssignedToBooking.oid).subscribe(
        (res) => {
          // console.log(res);

          this.monthlyInvoice();
        }, (error) => {
          this.assignStatus = true;
          console.log(error.error);
          this.assignDriverError=error.error.message
        }
      );
    } else {
      this.monthlyInvoice();
    }
    // Assign a driver to bucket

  }

  public assignDriverOnAwaiting() {
    this.assignStatus = false;
    if (this.driverAssignedToBooking != null) {
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.assignDriverToBucketBooking(object.token, this.bucketBooking.oid, this.driverAssignedToBooking.oid).subscribe(
        (res) => {
          this.onHome();
        }, (error) => {
          this.assignStatus = true;
          console.log(error);
        }
      );
    } else {
    //  Do nothing
    }
  }

  public monthlyInvoice() {


          // this.assignStatus = 'Driver successfully assigned to booking.';

          let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
          var data = {
            "comments": this.comment,
            "labour": this.numberOfLabours,
            "bookingOid": this.bucketBooking.oid,
            "recurrence": null
          };

          this.loadservice.addloader();
          this.bucketBookingPrice = this.tempAmount - this.promoCodeAmount;
          this.bookService.pushBucketsBook(data, myToken).subscribe(
            (res) => {
              this.bucketBooking = res;
              this.updateBookingPrices();

              if (this.bucketBooking.type === 'DEDICATED') {
                this.retail.typeRates.forEach(element => {
                  if (element.vehicleType === this.booking.vehicleType) {
                    this.bucketBooking.price = element.standardRate;
                  }
                });

                this.standardRatePrice = this.bucketBooking.price > this.tempAmount ? this.bucketBooking.price : this.tempAmount;

                /*if (this.bucketBooking.price > this.tempAmount) {
                  this.standardRatePrice = this.bucketBooking.price;
                } else {
                  this.standardRatePrice = this.tempAmount;
                }*/

                const standardPrice = Math.round(this.standardRatePrice);
                this.standardRateApplied = 'Please note standard rate of R ' + formatNumber(standardPrice, 'en-ZA', '1.2-2') + ' with 15% VAT will be applied.';

              }

              /*if (this.bucketBooking.price > this.bucketBookingPrice) {
                // Removing VAT per Rhulani's instructions
                this.standardRatePrice = this.bucketBooking.price / 1.15
                this.standardRateApplied = "Please note standard rate  of " + " " + "R" + formatNumber(this.standardRatePrice, 'en-ZA', '1.2-2') + " " + "with 15% VAT will be applied."
              }*/
              this.loadservice.removeloader();
              this.msg = 'Bucket Booking successfully created.';
              $('#pushBooking').modal('show');
              this.sendNewRetailInvoices();
              localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
            },
            (error) => {
              console.log(error);
              this.msg = error.error.message;
              this.loadservice.removeloader()
              $('#pushBooking').modal('show');
            }
          );



  }
  public checkStandardRatePrice() {
    if (this.bucketBooking.type === 'DEDICATED') {
      this.retail.typeRates.forEach(element => {
        if (element.vehicleType === this.booking.vehicleType) {
          this.bucketBooking.price = element.standardRate;
        }
      });

      this.standardRatePrice = this.bucketBooking.price > this.tempAmount ? this.bucketBooking.price : this.tempAmount;

      const standardPrice = Math.round(this.standardRatePrice);
      this.loadservice.removeloader();

      this.updatePrice(standardPrice);
      if (this.bucketBooking.price > this.tempAmount) {
        this.tempAmount = Math.round(this.standardRatePrice * 1.15);
        this.standardRateApplied = 'Please note standard rate of R' + formatNumber(standardPrice, 'en-ZA', '1.2-2') + ' with 15% VAT will be applied.';
        this.msg = '';
        $('#paywithCardEFT').modal('show');
      } else {
        // this.tempAmount = Math.round(this.standardRatePrice * 1.15);
        this.onPostBucketBookingPaymentCard();
      }
    } else {
      this.loadservice.removeloader();
      this.onPostBucketBookingPaymentCard();
    }
  }

  public updateBookingPrices() {
    const myToken = JSON.parse(localStorage.getItem('userInfo')).token;

    if (this.promoCodeAmount === 0) {
      this.bookService.updateBookingsPrice(this.bucketBooking.oid, myToken).subscribe(
        (res) => {
          // console.log(res);
        }, (error) => {
          console.log(error);
        }
      );
    } else {
      const data = {
        promoCode: this.promoCode.toUpperCase(),
        promoPrice: this.promoCodeAmount,
        bucketOid: this.bucketBooking.oid
      };
      this.bookService.updateBucketPromoPriceCardPayment(data, myToken).subscribe(
        (res) => {
          // console.log(res);
        },
        (errr) => {
          console.log(errr);
        }
      );
    }
  }

  public updatePrice(price) {


    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    var totalBucketPrice = (price + (this.numberOfLabours * 100)) * 1.15;
    var updatedBucketPrice = Math.round(totalBucketPrice);



    /*var data2 = {
      promoCode: this.promoCode,
      "promoPrice": this.promoCodeAmount,
      "bookingOid": this.bucketBooking.oid
    };
    this.bookService.updateBucketPromoPriceCardPayment(data2, myToken).subscribe(
      (res) => {
        console.log(res);
      },(error) => {
        console.log(error);
      }
    );*/

    // console.log(updatedBucketPrice);

    if (this.promoCodeAmount === 0) {
      /*var data1 = {
        price: updatedBucketPrice,
        bookingOid: this.bucketBooking.oid
      };*/
      // console.log("data 1", data1);
      this.bookService.updateBucketPriceCardPayment(this.bucketBooking.oid, myToken).subscribe(
        (res) => {
          // console.log(res);
        }, (error) => {
          console.log(error);
        }
      );
    } else {
      var data2 = {
        promoCode: this.promoCode,
        promoPrice: this.promoCodeAmount,
        bookingOid: this.bucketBooking.oid
      };
      this.bookService.updateBucketPromoPriceCardPayment(data2, myToken).subscribe(
        (res) => {
          // console.log(res);
        }, (error) => {
          console.log(error);
        }
      );
    }
  }

  public onPostBucketBookingPaymentCard() {
    $('#paywithCardEFT').modal('hide');
    $('#paymentOptions').modal('show');
  }

  payWithPayfast() {
    this.loadservice.addloader();
    const bookingPrice = this.tempAmount - this.promoCodeAmount;
    /*var bookingPrice = 0.0;

    if (this.bucketBooking.price > this.tempAmount) {
      var totalBucketPrice = (this.standardRatePrice + (this.numberOfLabours*100)) *1.15;
      bookingPrice = Math.round(totalBucketPrice);
    }else{
      bookingPrice = this.tempAmount - this.promoCodeAmount;
    }*/

    var userPaymentData = JSON.parse(localStorage.getItem('userInfo'));
    var gotoCheckOut = false;
    var cardPaymentData = {
      "name_first": userPaymentData.owner.firstName,
      "name_last": userPaymentData.owner.surname,
      "email_address": userPaymentData.owner.email,
      "cell_number": userPaymentData.owner.mobile,
      "m_payment_id": this.bucketBooking.oid,
      "amount": bookingPrice,
      "customerReference": userPaymentData.owner.oid,
      "item_name": "BUCKET BOOKING",
      "item_description": "Transporting multiple Items from ",
      "email_confirmation": userPaymentData.owner.email,
      "confirmation_address": userPaymentData.owner.email,
      "reference": this.bucketBooking.oid,
      "paymentType": "BUCKET_BOOKING_PAYMENT"
    };
    this.bookService.requestBucketPaymentPayFast(cardPaymentData, this.userToken.token).subscribe((res: Response) => {

      const successfulPaymentData = res.json();

      this.updateBookingPrices();
      localStorage.setItem('paidByRetail', 'true');
      this.merchant_id.nativeElement.value = successfulPaymentData.merchant_id;
      this.merchant_key.nativeElement.value = successfulPaymentData.merchant_key;
      this.return_url.nativeElement.value = successfulPaymentData.return_url;
      this.cancel_url.nativeElement.value = successfulPaymentData.cancel_url;
      this.notify_url.nativeElement.value = successfulPaymentData.notify_url;
      this.name_first.nativeElement.value = successfulPaymentData.name_first;
      this.name_last.nativeElement.value = successfulPaymentData.name_last;
      this.email_address.nativeElement.value = successfulPaymentData.email_address;
      this.m_payment_id.nativeElement.value = successfulPaymentData.m_payment_id;
      this.amount.nativeElement.value = Math.round(successfulPaymentData.amount);
      this.item_name.nativeElement.value = successfulPaymentData.item_name;
      this.item_description.nativeElement.value = successfulPaymentData.item_description;
      this.confirmation_address.nativeElement.value = successfulPaymentData.confirmation_address;
      this.custom_int1.nativeElement.value = successfulPaymentData.custom_int1;
      localStorage.setItem('paymentReference', successfulPaymentData.m_payment_id);
      gotoCheckOut = true;
    }, (error) => {
      console.log(error);
      gotoCheckOut = false;
    }, () => {

      if (gotoCheckOut) {
        this.payFastForm.nativeElement.submit();
      } else {
        this.loadservice.removeloader();
      }
    });

  }

  payWithOzow() {
    this.loadservice.addloader();
    const bookingPrice = this.tempAmount - this.promoCodeAmount;
    var userPaymentData = JSON.parse(localStorage.getItem('userInfo'));
    let ozowObj = {
      "CountryCode": "ZA",
      "CurrencyCode": "ZAR",
      "Amount": bookingPrice,
      "TransactionReference": this.bucketBooking.oid,
      "BankReference": "",
      "Customer": userPaymentData.owner.email,
      "IsTest": true,
      "paymentType": "BUCKET_BOOKING_PAYMENT"
    };
    this.bookService.requestOzowPayment(ozowObj, this.userToken.token).subscribe(
      (response) => {
        this.updateBookingPrices();
        const successfulPaymentData = response.json();
        localStorage.setItem('paidByRetail', 'true');
        this.SiteCode.nativeElement.value = successfulPaymentData.SiteCode;
        this.CountryCode.nativeElement.value = successfulPaymentData.CountryCode;
        this.CurrencyCode.nativeElement.value = successfulPaymentData.CurrencyCode;
        this.Amount.nativeElement.value = successfulPaymentData.Amount.toFixed(2);
        this.TransactionReference.nativeElement.value = successfulPaymentData.TransactionReference;
        this.BankReference.nativeElement.value = successfulPaymentData.BankReference;
        this.Customer.nativeElement.value = successfulPaymentData.Customer;
        this.Optional1.nativeElement.value = successfulPaymentData.Optional1;
        this.CancelUrl.nativeElement.value = successfulPaymentData.CancelUrl;
        this.ErrorUrl.nativeElement.value = successfulPaymentData.ErrorUrl;
        this.SuccessUrl.nativeElement.value = successfulPaymentData.SuccessUrl;
        this.NotifyUrl.nativeElement.value = successfulPaymentData.NotifyUrl;
        this.IsTest.nativeElement.value = successfulPaymentData.IsTest;
        this.HashCheck.nativeElement.value = successfulPaymentData.HashCheck;
        $("#ozowPayment").trigger("click");
    }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
    });

  }

  walletToHome = false;

  public payWithWallet() {
    var walletBody = {
      "comments": this.comment,
      "labour": this.numberOfLabours,
      "bookingOid": this.bucketBooking.oid,
      "recurrence": {
        "period": null,
        "fromDate": null,
        "toDate": null
      }
    };
    this.loadservice.addloader();
    this.bucketBookingPrice = this.tempAmount - this.promoCodeAmount;
    this.bookService.checkAvailBalance(this.userToken.token, this.retail.owner.userAccountOid, this.bucketBooking.price).subscribe(
      response => {
        this.isBalancePositive = response.isBalancePositive;
        if (this.isBalancePositive) {

          this.bookService.authWallet(this.userToken.token, walletBody).subscribe(
            resp => {
              // console.log(resp);
              this.bucketBooking = resp;
              this.updateBookingPrices();

              /*if (this.bucketBooking.type === 'DEDICATED') {
                this.retail.typeRates.forEach(element => {
                  if (element.vehicleType === this.booking.vehicleType) {
                    this.bucketBooking.price = element.standardRate;
                  }
                });

                this.standardRatePrice = this.bucketBooking.price > this.tempAmount ? this.bucketBooking.price / 1.15 : this.tempAmount;

                /!*if (this.bucketBooking.price > this.tempAmount) {
                  this.standardRatePrice = this.bucketBooking.price;
                } else {
                  this.standardRatePrice = this.tempAmount;
                }*!/

                const standardPrice = Math.round(this.standardRatePrice);
                this.standardRateApplied = 'Please note standard rate of R ' + formatNumber(standardPrice, 'en-ZA', '1.2-2') + ' with 15% VAT will be applied.';

              }*/

              /*if (this.bucketBooking.price > this.bucketBookingPrice) {
                // Removing VAT per Rhulani's instructions
                this.standardRatePrice = this.bucketBooking.price / 1.15
                this.standardRateApplied = "Please note standard rate  of " + " " + "R" + formatNumber(this.standardRatePrice, 'en-ZA', '1.2-2') + " " + "with 15% VAT will be applied."
              }*/

              this.loadservice.removeloader();
              this.msg = 'Bucket Booking successfully created.';
              this.walletToHome = true;
              $('#pushBooking').modal('show');
              this.sendNewRetailInvoices();
            }, err => {
              this.loadservice.removeloader();
              console.log(err);
              this.onHome();
            }, () => {
            }
          );
        } else {
          this.loadservice.removeloader();
          this.msg = 'Your wallet has insufficient funds. Please credit your wallet to proceed with the transaction.';
          $('#walletPayment').modal('show');
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    );
  }
  // Payments

  // Invoice templates
  public sendNewRetailInvoices() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.invoiceRetailBucketCustomer(object.token, this.bucketBooking.trackNo).subscribe(
      (response) => {
      }, (error) => {
        console.log(error);
      }
    );
  }
  // Invoice templates

  public goToPayment() {
    this.router.navigateByUrl('/multiple-drop-payment');
  }

  public processPromoCode() {
    this.promoCodeMsg = '';
    if (this.promoCode == null || this.promoCode === '') {
      this.promoCodeMsg = 'Please enter a promo code.';
      // $('#promoActive').modal('show');
    } else {
      const object = JSON.parse(localStorage.getItem('userInfo'));

      const usePromoCodeDTO = {
        code:	this.promoCode.toUpperCase(),
        promoType: '',
        targetAmount: this.bucketBooking.price,
        retailOid: this.retail.oid,
      };

      if (this.booking.type === 'EXPRESS_COURIER' || this.booking.type === 'BUDGET_COURIER' ) {
        usePromoCodeDTO.promoType = 'COURIER';
      } else if (this.booking.type === 'FLASH') {
        usePromoCodeDTO.promoType = 'FLASH';
      }

      // console.log(object);
      // console.log(usePromoCodeDTO);

      // Test code
      /*this.promoCodeAmount = 100;
      this.switchPromoBtn = true;
      this.promoCodeValid = true;
      this.promoCodeMsg = 'Promo code of R' + this.promoCodeAmount + ' applied.';*/

      this.loadservice.addloader();
      this.bookService.checkRetailPromo(object.token, usePromoCodeDTO).subscribe(
        (response: any) => {
        // console.log(response);
        this.promoCodeAmount = response.amount;
        this.bucketBooking.promotionCode = this.promoCode;
        this.loadservice.removeloader();
        // Switch between the blue and red buttons for applying promo
        this.switchPromoBtn = true;

        this.promoCodeValid = true;
        this.promoCodeMsg = 'Promo code of R' + this.promoCodeAmount + ' applied.';
        // $('#promoActive').modal('show');
      }, error => {
        // this.bucketBooking.promotionCode = this.promoCode;
        this.promoCodeValid = false;
        this.promoCodeMsg = error.error.message;
        if (this.promoCodeMsg === null || this.promoCodeMsg === undefined) {
          this.promoCodeMsg = 'Promo code does not exist.';
        }
        this.loadservice.removeloader();
        // $('#promoActive').modal('show');
      });
    }
  }

  public removePromoCode() {
    this.promoCodeMsg = '';
    this.switchPromoBtn = false;
    this.promoCode = '';
    this.promoCodeValid = false;
    this.promoCodeAmount = 0;
    this.bucketBooking.promotionCode = null;
  }

  public assignOwnDriver() {
    /*if(this.bucketStatus==='AWAITING_DRIVER'){
      this.assignDriverOnAwaiting()
      return
    }*/
    $('#listOfDrivers').modal('show');
  }

  driverAssigned = false;
  driverAssignedToBooking: any;

  public assignSelectedDriver(driver) {
    this.driverAssigned = true;
    this.driverAssignedToBooking = driver;
    this.mapOpen=false
    //if map open close it
  }

  public removeAssignedDriver() {
    this.driverAssigned = false;
    this.driverAssignedToBooking = null;
  }

  driversListTitle = 'List of drivers';
  displayDriverMap = false;
  mapDriversButtonTxt = 'Show drivers map';

  showDriversMap() {
    this.mapOpen = true;
    this.displayDriverMap = !this.displayDriverMap;
    if (this.displayDriverMap) {
      this.driversListTitle = 'Map of drivers';
      this.mapDriversButtonTxt = 'Show drivers list';
    } else {
      this.driversListTitle = 'List of drivers';
      this.mapDriversButtonTxt = 'Show drivers map';
    }
  }

  taxInvoiceButtonText = 'Email tax invoice';
  disableTaxInvoiceButton = false;

  downloadTaxInvoice(trackNo) {
    this.taxInvoiceButtonText = 'Email tax invoice';
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.downloadTaxInvoiceForRetail(object.token, trackNo).subscribe(
      (response) => {
        console.log(response);
        this.taxInvoiceButtonText = 'Email sent';
        this.disableTaxInvoiceButton = true;
      }, (error) => {
        console.log(error);
        this.taxInvoiceButtonText = 'Email tax invoice';
        this.disableTaxInvoiceButton = false;
      }
    );
  }
}


interface firebaseObj{
  bearing: Number,
  lat: Number,
  lng: Number,
  tripStarted: boolean
}
