import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Response } from '@angular/http'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HaversineService } from 'ng2-haversine';
import { Parcel } from 'src/app/models/parcel';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedService } from 'src/app/services/shared.service';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-retail-multiple-drop',
  templateUrl: './retail-multiple-drop.component.html',
  styleUrls: ['./retail-multiple-drop.component.css']
})
export class RetailMultipleDropComponent implements OnInit {

  url: string;
  userToken: any;
  retail: any;
  bookingData: any;
  canPayWithEFT: any;
  bucketBooking: any;
  vehicle: string;
  vehicleTypeRate: any;
  isExpressBooking: any;
  bookingTime: any;
  bookingDate: any;
  noBookings: number = 1;
  booking: any;

  pickUpNotMsg: string;
  pickUpAddress: string;
  dropOffAddress: string;
  dropOffNotMsg: string = '';
  dropOffNotFound: boolean;
  dropOfflocation: string;

  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;

  pickUpCoordinate = [];
  dropOffCoordinate = [];

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};
  departureCoordinate: any = {};
  destinationCoordinate: any = {};

  @ViewChild('merchant_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('merchant_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('signature', { static: false }) signature: ElementRef;
  @ViewChild('payFast', { static: false }) payFastForm: ElementRef;
  @ViewChild('addressFormVailidater', { static: false }) addressFormVailidater: ElementRef;

  bucketBookingObjDropOffAddress: string;
  bucketBookingObjDropOffCoordinateLocation: any;
  bucketBookingObjDropOfflocation: string;
  bucketBookingObjDropOffFirstname: string;
  bucketBookingObjDropOffMobile: string;
  bucketBookingObjDropOffChecked: boolean = false
  bucketBookingObjDropPrice: number;
  bucketBookingObjDrop2: boolean;
  bucketBookingObjBookOID: string;
  bucketBookingObjCommentDrop: string;
  bucketBookingObjNumb: number;

  secondDropOffChecked: boolean = false;

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off address.',
    geoTypes: ['address', 'establishment']
  };

  pickupCompanyName: any;
  pickupComplex: any;
  pickUpUnitNo: any;
  firstName: any;
  phone: any;
  mobile: any;
  surname: any;
  dropContactFirstname: any;
  dropContactPhone: any;
  vehicleType: any;
  tempAmount: any;
  comment: any;
  bucketStatus: any;
  numberOfLabours: any;
  mass: any;
  pickUpPCode: any;
  pickUpAmt: any;
  airlineAmt: any;
  dropOffAmt: any;
  fromSuburb: any;
  listOfBucketBookingObjs: any;
  bookingBucketList: any;
  type: any;
  transportMode: any;
  pickUpProvince: any;

  dropOff1: boolean = false;

  dropOffAutoComplete: any;

  dayOfTheWeek: any;
  dayOfTheMonth: any;
  mainCenter: any;
  toSuburb: any;
  dropOffPCode: any;
  destinationProvince: any;
  dropOffProvince: any;
  newDropOffSuburb: any;
  dropOffLat: number;
  dropOffLong: number;
  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;
  customerRef: any;
  costCentreNumber: any;

  parcelPrice: number = 0.0;
  price: number = 0.0;

  msgAddress: string;
  msgComment: string;
  msgDropOffName: string;
  msgDropOffMobile: string;
  msgItems: string;
  msgSuburb: string;
  secondDrop2: boolean;

  missingTextMsg: string = '';
  massMsg: string = '';
  breadthMsg: string = '';
  heightMsg: string = '';
  lengthMsg: string = '';
  checkingMsg: string = '';

  promptPostalCode: any;
  promptedDropOffPCode: any;
  msgStatus: any;
  goThroughMsg: any;
  disableAddresses: boolean = true;
  distance: any;
  totalMass: any;
  customer_reference: any;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;
  parcelDims = [];

  location: any = {};

  loader: boolean = false;

  totalExpressPrice: number;
  distanceOfTrip: number;

  errorMessage: string;
  message: string;

  massBookingObjElement: any;
  priceMessage: string;
  paymentMsg: any;
  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;
  expressCourier: number = 0;
  budgetCourier: number = 0;
  tenderDocument: number = 0;
  parcels: any = 1;
  usedTotal: number;
  currentAmt: any;
  courierQuotedTotal: number = 0.0
  allowLoaderToShow: boolean = false
  bookingPrice: any;
  msg: string = '';
  numOfBoxes: any;
  parcelName: string = '';
  pracel: Parcel;
  pracels: Parcel[] = [];
  numOfParcels: any;

  dropOff2: boolean = false;
  secondDropOfflocation: string;
  secondDropOffFirstname: string;
  secondDropOffMobile: string;
  secondDropPrice: number;
  commentSecondDrop: string;

  dropOff3: boolean = false;
  thirdDropOfflocation: string;
  thirtDropOffFirstname: string;
  thirdDropOffMobile: string;
  thirdDropOffChecked: boolean = false
  thirdDropPrice: number;
  commentThirdDrop: string;

  bucketBookingPrice: number;
  standardRateApplied: string;
  // Pick up
  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;
  displayDropOffAddress: any;

  enteredDropOffAddressMsg: string = '';
  enteredDropOffSuburbMsg: string = '';
  enteredDropOffPostalCodeMsg: string = '';
  enteredDropOffProvinceMsg: string = '';
  enteredDropOffSuccessMsg: string = '';

  dropOffExtraDetails: any = [];

  err: any;
  isBalancePositive: boolean = false;

  savedAddresses: any = [];
  today = new Date();
  public min = new Date(this.today.setDate(this.today.getDate()));
  public max = new Date(2025, 3, 21, 20, 30);
  tenderDate: any;
  expectedDeliveryDate: any;
  isTenderDocumentSelected = {
    tenderSelected: false
  };
  tenderErrorMsg: string = '';
  tenderTime: any;

  constructor(private router: Router, private sharedService: SharedService, private _haversineService: HaversineService, private bookService: BookingService,private loadservice:LoaderService) {
    this.url = this.sharedService.getPayFastUrl();
  }

  ngOnInit() {
    this.userToken = JSON.parse(localStorage.getItem('userInfo'));
    this.retail = JSON.parse(localStorage.getItem('retail'));
    this.bookingData = JSON.parse(localStorage.getItem('BookingData'));
    console.log(this.bookingData);
    this.canPayWithEFT = this.retail.canInvoice;
    this.bucketBooking = JSON.parse(localStorage.getItem('bucketBooking'));
    console.log(this.bucketBooking);
    this.vehicle = localStorage.getItem('vehicleType');
    this.isTenderDocumentSelected = JSON.parse(localStorage.getItem("isTenderSelected"));
    this.vehicleTypeRate = this.retail.typeRates;
    this.getBucketbook(this.userToken.token, this.bucketBooking.oid);
    this.getSavedAddress(this.userToken.token, this.userToken.oid);
  }

  //Saved Addresses
  public getSavedAddress(token, personId) {
    this.bookService.retrieveAddresses(token, personId).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }
    )
  }

  public selectDropOffSavedAddress() {
    $('#showDropOffSavedAddressModal').modal('show');
  }

  public selectDropOffAddress(selectedAddress) {
    this.disableAddresses = false;
    // Address used on the logic side
    this.bucketBookingObjDropOffAddress = selectedAddress.address;
    this.dropOffProvince = selectedAddress.province;
    this.toSuburb = selectedAddress.suburb;
    this.dropOffPCode = selectedAddress.postalCode;
    // Address used for display
    this.dropOffAddress = selectedAddress.address;
    if ((selectedAddress.latitude == 0 || selectedAddress.latitude == null) && (selectedAddress.longitude == 0 || selectedAddress.longitude == null)) {
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
    }
    this.bucketBookingObjDropOffCoordinateLocation = { "coordinates": this.dropOffCoordinate, "address": this.bucketBookingObjDropOffAddress };
  }
  //Saved Addresses

  public getBucketbook(token: string, bucketOid: string) {
    this.bookService.getBucketBook(token, bucketOid)
      .subscribe(
        (res: Response) => {
          localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
          localStorage.setItem('bucketBooking', JSON.stringify(res));
          this.booking = res;
          this.isExpressBooking = this.booking.vehicleType;
          this.bookingTime = this.booking.time;
          this.noBookings = this.booking.bookings.length;
          var datePipe = new DatePipe('en-US');
          let value = datePipe.transform(this.booking.date, 'yyyy-MM-dd');
          this.bookingDate = value;
        },
        (error) => {
          console.log(error);
        }, () => {

          var lastIndexOfBucket: [] = this.booking.bookings
          if (lastIndexOfBucket.length > 1) {
            this.setBucketBookingData(lastIndexOfBucket.length - 1)
          } else {
            this.setBucketBookingData(0)
          }

        }
      );
  }

  public setBucketBookingData(lastIndexOfBucket: number) {
    this.pickupCompanyName = this.booking.bookings[lastIndexOfBucket].pickUp.companyName;
    this.pickupComplex = this.booking.bookings[lastIndexOfBucket].pickUp.complex;
    this.pickUpUnitNo = this.booking.bookings[lastIndexOfBucket].pickUp.unitNo;
    this.firstName = this.booking.bookings[lastIndexOfBucket].pickUp.firstName;
    this.phone = this.booking.bookings[lastIndexOfBucket].pickUp.phone;
    this.mobile = this.booking.bookings[lastIndexOfBucket].contact
    this.surname = this.booking.bookings[lastIndexOfBucket].pickUp.lastName;
    this.dropContactFirstname = this.booking.bookings[lastIndexOfBucket].dropOff.firstName;
    this.dropContactPhone = this.booking.bookings[lastIndexOfBucket].dropOff.phone;
    this.pickUpAddress = this.booking.bookings[lastIndexOfBucket].pickUpAddress;
    this.dropOfflocation = this.booking.bookings[lastIndexOfBucket].dropOffAddress;
    this.vehicleType = this.booking.bookings[lastIndexOfBucket].vehicleType;
    this.tempAmount = this.booking.price;
    this.comment = this.booking.bookings[lastIndexOfBucket].comment;
    this.pickUpCoordinateLocation = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate;
    this.bucketStatus = this.booking.status
    this.numberOfLabours = this.bookingData != null ? this.bookingData.labour : 0
    this.pickUpLong = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[1];
    this.pickUpLat = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[0];
    this.departureCoordinate = { "lat": this.pickUpLat, "lng": this.pickUpLong };
    this.mass = this.booking.bookings[lastIndexOfBucket].itemMass;
    this.pickUpPCode = this.booking.bookings[lastIndexOfBucket].pickUpPCode;
    this.pickUpAmt = this.booking.bookings[lastIndexOfBucket].pickUpAmt;
    this.airlineAmt = this.booking.bookings[lastIndexOfBucket].airlineAmt;
    this.dropOffAmt = this.booking.bookings[lastIndexOfBucket].dropOffAmt;
    this.fromSuburb = this.booking.bookings[lastIndexOfBucket].fromSuburb;
    this.listOfBucketBookingObjs = this.booking.bookings;
    this.bookingBucketList = this.listOfBucketBookingObjs.length;
    this.expectedDeliveryDate = this.booking.bookings[lastIndexOfBucket].expectedDeliveryDate;
    this.type = this.booking.bookings[lastIndexOfBucket].type;
    this.transportMode = this.booking.bookings[lastIndexOfBucket].transportMode;
    this.pickUpProvince = this.booking.bookings[lastIndexOfBucket].province;
    this.expectedDeliveryDate = this.booking.bookings[lastIndexOfBucket].expectedDeliveryDate;
  }

  public onAddDrop() {
    if (this.bucketBooking.status == "BUCKET_AWAITING") {
      this.dropOff1 = true;
    }
  }

  public onAddSecondDrop() {
    if (this.bucketBooking.status == "BUCKET_AWAITING") {
      this.clearBucketBookingObj();
    }
    this.parcelPrice = 0.00;
    this.price = 0.00;
  }

  public clearBucketBookingObj() {
    if (this.dropOffAutoComplete != undefined) {
      this.dropOffAutoComplete.data.active = false;
    }
    this.bucketBookingObjDropOffAddress = '';
    this.bucketBookingObjDropOfflocation = '';
    this.bucketBookingObjDropOffFirstname = ''
    this.bucketBookingObjDropOffMobile = '';
    this.bucketBookingObjDropOffChecked = false;
    this.bucketBookingObjDropPrice = 0.00;
    this.bucketBookingObjDrop2 = false;
    this.bucketBookingObjCommentDrop = '';
    this.customerRef = '';
    this.costCentreNumber = '';
    this.companyDropOffName = '';
    this.complexDropOff = '';
    this.unitDropOff = '';
    this.pracels = [];
    this.parcelDims = [];
    this.disableAddresses = true;
    this.enteredDropOffAddress = '';
    this.enteredDropOffSuburb = '';
    this.enteredDropOffPostalCode = '';
    this.enteredDropOffProvince = '';
    this.dropOffExtraDetails = [];
    this.toSuburb = '';

    this.userSettings = {
      showRecentSearch: false,
      geoCountryRestriction: ['ZA'],
      inputPlaceholderText: 'Enter drop-Off address.',
      geoTypes: ['address', 'establishment'],
      inputString: ''
    };

  }

  autoCompleteBucketBookingObj(selectedData: any) {
    this.dropOffAutoComplete = selectedData;
    if (!selectedData.response) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {
            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              this.selectDropOffDropDownSuburb(this.dropOffPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }

      if (this.dropOffPCode == null) {
        this.promptPostalCode = "Please enter your area postal code below";
        $('#dropOffPostcalCode').modal('show');
      }

      this.bucketBookingObjDropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.bucketBookingObjDropOffCoordinateLocation = {
        "coordinates": this.dropOffCoordinate,
        "address": this.dropOffAddress
      };
    }
  }

  clickedDropOffpCode() {
    this.selectDropOffDropDownSuburb(this.promptedDropOffPCode);
    this.dropOffPCode = this.promptedDropOffPCode;
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                }
              }
            }
          }
        }
      }
    );
  }

  public getDropOffSuburb(postalCode) {
    this.bookService.getDropOffSuburb(postalCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  public getBothQuote() {
    var datePipe = new DatePipe("en-US");
    var valudeTender = datePipe.transform(this.tenderDate, 'yyyy-MM-dd');
    let calDiffDays = moment(this.bookingDate).diff(moment(valudeTender), 'days');

    if (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER') {
      if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
        this.msgAddress = "Please enter your address";
      }
      else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
        this.msgDropOffName = "Please fill in the name";
      }
      else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
        this.msgDropOffMobile = "Please enter in your numbers.";
      }
      else if (this.bucketBookingObjDropOffMobile.length != 10) {
        this.msgDropOffMobile = "Please make sure the entered number isn't less than 10 digits.";
      }
      else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
        this.msgComment = "Please fill in the comments (Instructions...)";
      }
      else if (this.pracels.length <= 0) {
        this.msgItems = "Please fill in the items before getting a quote";
      }
      else if (this.toSuburb == '' || this.toSuburb == null) {
        this.msgSuburb = "Please select the suburb before proceeding";
      }
      else if ((this.expectedDeliveryDate != null) && calDiffDays > 0) {
        alert('Please note that the Tender delivery date should be after the collection date.');
      }
      else {
        this.getDroppaPricings();
        this.msgStatus = "";
        this.msgAddress = "";
        this.msgComment = "";
        this.msgDropOffName = "";
        this.msgDropOffMobile = "";
        this.goThroughMsg = "";
        this.checkingMsg = "";
        this.msgItems = "";
        this.disableAddresses = false;
        this.tenderErrorMsg = "";
      }
    }
    else {
      if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
        this.msgAddress = "Please enter your address";
      }
      else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
        this.msgDropOffName = "Please fill in the name";
      }
      else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
        this.msgDropOffMobile = "Please enter in your numbers.";
      }
      else if (this.bucketBookingObjDropOffMobile.length != 10) {
        this.msgDropOffMobile = "Please make sure the entered number isn't less than 10 digits.";
      }
      else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
        this.msgComment = "Please fill in the comments (Instructions...)";
      }
      else if (this.pracels.length <= 0) {
        this.msgItems = "Please fill in the items before getting a quote";
      }
      else {
        this.getDroppOffQuote();
        this.msgStatus = "";
        this.msgAddress = "";
        this.msgComment = "";
        this.msgDropOffName = "";
        this.msgDropOffMobile = "";
        this.msgItems = "";
        this.disableAddresses = false;
      }
    }
  }

  onSelectedParcel(numOfParcels): void {
    this.numOfParcels = numOfParcels;
  }

  public enterItems() {
    $('#enterDimensions').modal('show');
  }

  public getDroppaPricings() {

    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.bucketBookingObjDropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.parcelDims,
      "platform": "web",
    }
    this.loadservice.addloader()
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {

                // If Tender document price is selected from previous screen
                if (this.expectedDeliveryDate !== null) {
                  if (response.json().tenderDocAmount > 0) {
                    this.tenderDocument = Math.round(response.json().tenderDocAmount);
                    this.parcelPrice = this.tenderDocument;

                  }
                }
                // If Tender document not selected from previous screen
                else {
                  if (response.json().expressCourier > 0) {
                    this.loadservice.addloader()
                    this.isCourierExpress = false;
                    this.expressCourier = response.json().expressCourier;
                    this.courierQuotedTotal = Math.round(this.expressCourier)
                    this.parcelPrice = Math.round(this.expressCourier);
                  }
                  if (response.json().expressBudgetAmt > 0) {
                    this.loadservice.addloader()
                    this.isExpressBudget = this.allowLoaderToShow;
                    this.budgetCourier = response.json().expressBudgetAmt;
                    this.courierQuotedTotal = Math.round(this.budgetCourier)
                    this.parcelPrice = (Math.round(this.budgetCourier) * this.parcels);
                  }
                }

      }, error => {
        this.loadservice.addloader()
        console.log(error);
      }, () => { }
    );

  }

  public getDroppOffQuote() {

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };
    this.bookService.getDistance(data).subscribe(
      (response) => {
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);
        localStorage.setItem('distance', this.distance + '');
        var object = JSON.parse(localStorage.getItem('userInfo'));
        this.bookService.getRetailVehicleRate(this.retail.oid, this.vehicleType)
          .subscribe(
            (res: Response) => {
              var bookingData = {
                "distance": this.distance,
                "retailOid": this.retail.oid,
                "totalPrice": 0.0,
                "vehicleType": this.vehicleType
              };
              this.loadservice.addloader()
              this.bookService.getRetailbookingPrice(bookingData, object.token)
                .subscribe(
                  (response: Response) => {
                    this.bookingPrice = response.json();
                    this.price = this.bookingPrice.totalPrice;
                    this.loadservice.removeloader()
                  },
                  (err) => {
                    console.log(err);
                    this.loadservice.removeloader()
                  }
                );
            },
            (err) => {
              console.log(err);
              this.loadservice.removeloader();
              this.msg = err.json().message;
              $('#results').modal('show');
            }
          )
      });

  }

  public addItems() {

    if (this.bucketBooking.type != 'DEDICATED') {
      // If its Courier
      if (this.numOfBoxes == null || this.numOfBoxes == '') {
        this.missingTextMsg = 'Please enter the number of boxes.';
      }
      else if (this.parcel_mass == null || this.parcel_mass == '') {
        this.massMsg = 'Please enter the mass.';
      }
      else if (this.parcel_breadth == null || this.parcel_breadth == '') {
        this.breadthMsg = 'Please enter the breadth.';
      }
      else if (this.parcel_height == null || this.parcel_height == '') {
        this.heightMsg = 'Please enter the height.';
      }
      else if (this.parcel_length == null || this.parcel_length == '') {
        this.lengthMsg = 'Please enter the length.';
      }
      else if (this.parcel_mass > 70) {
        this.massMsg = 'The max for mass is 70KG with courier service.';
      }
      else if (this.parcel_breadth > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.breadthMsg = 'The max for breadth is 150cm with courier service.';
      }
      else if (this.parcel_height > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.heightMsg = 'The max for height is 150cm with courier service.';
      }
      else if (this.parcel_length > 150 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.lengthMsg = 'The max for length is 150cm with courier service.';
      }
      else if (this.parcel_mass == 0) {
        this.massMsg = 'The mass can not be 0.';
      }
      else if (this.parcel_breadth == 0) {
        this.breadthMsg = 'The breadth can not be 0.';
      }
      else if (this.parcel_height == 0) {
        this.heightMsg = 'The mass height not be 0.';
      }
      else if (this.parcel_length == 0) {
        this.lengthMsg = 'The mass length not be 0.';
      }
      else if (this.parcel_mass < 0) {
        this.massMsg = 'The mass can not be a negative number.';
      }
      else if (this.parcel_breadth < 0) {
        this.breadthMsg = 'The breadth can not be a negative number.';
      }
      else if (this.parcel_height < 0) {
        this.heightMsg = 'The height can not be a negative number.';
      }
      else if (this.parcel_length < 0) {
        this.lengthMsg = 'The length can not be a negative number.';
      }
      else if (this.pracels.length >= 8) {
        this.checkingMsg = 'You can only add 8 parcels for Courier Service.';
      }
      else {

        // If checkbox is not selected for same parcels
        let courierObj = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass
        };
        this.parcelDims.push(courierObj);
        this.pracels.push(
          this.pracel = {
            parcel_length: this.parcel_length,
            parcel_breadth: this.parcel_breadth,
            parcel_height: this.parcel_height,
            parcel_mass: this.parcel_mass,
            customer_reference: this.customer_reference,
            costCenter: this.cost_center_num,
            parcel_number: this.pracels.length,
            noBox: this.numOfBoxes
          }
        );

        this.calculateTotalItemMass();
        this.cost_center_num = '';
        this.customer_reference = '';
        this.parcel_length = '';
        this.parcel_height = '';
        this.parcel_mass = '';
        this.parcel_breadth = '';
        this.numOfBoxes = '';

      }

    }
    else {
      // If its Dedicated
      if (this.numOfBoxes == null || this.numOfBoxes == '') {
        this.missingTextMsg = 'Please enter the number of boxes.';
      }
      else if (this.parcel_mass == null || this.parcel_mass == '') {
        this.massMsg = 'Please enter the mass.';
      }
      else if (this.parcel_breadth == null || this.parcel_breadth == '') {
        this.breadthMsg = 'Please enter the breadth.';
      }
      else if (this.parcel_height == null || this.parcel_height == '') {
        this.heightMsg = 'Please enter the height.';
      }
      else if (this.parcel_length == null || this.parcel_length == '') {
        this.lengthMsg = 'Please enter the length.';
      }
      else if (this.parcel_mass == 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.massMsg = 'The mass can not be 0.';
      }
      else if (this.parcel_breadth == 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.breadthMsg = 'The breadth can not be 0.';
      }
      else if (this.parcel_height == 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.heightMsg = 'The mass height not be 0.';
      }
      else if (this.parcel_length == 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.lengthMsg = 'The mass length not be 0.';
      }
      else if (this.parcel_mass < 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.massMsg = 'The mass can not be a negative number.';
      }
      else if (this.parcel_breadth < 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.breadthMsg = 'The breadth can not be a negative number.';
      }
      else if (this.parcel_height < 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.heightMsg = 'The mass height can not be a negative number.';
      }
      else if (this.parcel_length < 0 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.lengthMsg = 'The mass length can not be a negative number.';
      }
      else if (this.pracels.length >= 8 && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
        this.checkingMsg = 'You can only add 8 parcels for Courier Service.';
      }
      else {
        this.pracels.push(
          this.pracel = {
            parcel_length: this.parcel_length,
            parcel_breadth: this.parcel_breadth,
            parcel_height: this.parcel_height,
            parcel_mass: this.parcel_mass,
            customer_reference: this.customer_reference,
            costCenter: this.cost_center_num,
            parcel_number: this.pracels.length + 1,
            noBox: this.numOfBoxes
          }
        );

        this.cost_center_num = '';
        this.customer_reference = '';
        this.parcel_length = '';
        this.parcel_height = '';
        this.parcel_mass = '';
        this.parcel_breadth = '';
        this.numOfBoxes = '';
      }
    }
  }

  public removeItems(item) {
    this.pracels.splice(item, 1);
  }

  public calculateTotalItemMass() {
    var totalParcelMass = 0;

    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });

    this.totalMass = totalParcelMass;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      }
      else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      }
      else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      }
      else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      }
      else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      }
      else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      }
      else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      }
      else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      }
      else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }
  }

  public modelHeightChanged(parcel_height) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      }
      else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      }
      else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      }
      else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      }
      else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }
  }

  public modelLengthChanged(parcel_length) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_length > 150) {
        this.lengthMsg = "Length for parcel cannot exceed 150";
      }
      else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      }
      else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      }
      else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      }
      else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }
  }

  public cancelBacketBook() {
    this.loadservice.addloader();
    this.bookService.cancelBucket(this.userToken.token, this.booking.oid).subscribe(
      (res) => {
        this.msg = "Booking cancelled.";
        $('#bookingCancelled').modal('show');
        this.getBucketbook(this.userToken.token, this.booking.oid);
        this.router.navigateByUrl('/retail-profile');
      }, (error) => {
        console.log(error);
        this.msg = "Could not cancel booking.";
        $('#bookingCancelled').modal('show');
      }
    )
  }

  private clearFields() {
    this.dropOff3 = false;
    this.thirdDropOfflocation = '';
    this.thirtDropOffFirstname = '';
    this.thirdDropOffMobile = '';
    this.thirdDropOffChecked = false;
    this.thirdDropPrice = 0.00;
    this.commentThirdDrop = '';

    this.dropOff2 = false;
    this.secondDropOfflocation = '';
    this.secondDropOffFirstname = '';
    this.secondDropOffMobile = '';
    this.secondDropOffChecked = false;
    this.secondDropPrice = 0.00;
    this.commentSecondDrop = '';

    this.parcelPrice = 0.00;
    this.price = 0.00;
    this.courierQuotedTotal = 0.0
    this.tenderDate = undefined;
    this.tenderTime = undefined;
  }

  public onHome() {
    this.router.navigateByUrl('/retail-profile');
  }

  public addingBucketBookingObj() {

    if (this.parcelPrice || this.price != 0) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      var token = object.token;
      var timestamp = new Date().valueOf();

      var bookingData = {
        "dropOff": {
          "firstName": this.bucketBookingObjDropOffFirstname,
          "lastName": this.bucketBookingObjDropOffFirstname,
          "phone": this.bucketBookingObjDropOffMobile,
          "email": object.owner.email,
          'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : null,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : null
        },
        "pickUp": {
          "firstName": this.firstName,
          "lastName": this.surname != null ? this.surname : object.owner.surname,
          "phone": this.phone,
          "email": object.owner.email,
          'companyName': this.pickupCompanyName != null ? this.pickupCompanyName : object.owner.firstName,
          'complex': this.pickupComplex != null ? this.pickupComplex : null,
          'unitNo': this.pickUpUnitNo != null ? this.pickUpUnitNo : null
        },
        "customer": object.owner,
        "price": this.price != 0 ? this.price : this.parcelPrice,
        "vehicleType": this.vehicleType,
        "pickUpAddress": this.pickUpAddress,
        "pickUpTime": this.bookingTime,
        "phoneNo": object.owner.mobile,
        "pickUpDate": this.booking.bookings[0].pickUpDate,
        "dropOffAddress": this.bucketBookingObjDropOffAddress,
        "comment": this.bucketBookingObjCommentDrop,
        "labour": 0,
        "timestamp": timestamp,
        "status": "BUCKET",
        "dropFloors": 0,
        "pickUpFloors": 0,
        "pickUpCoordinate": this.pickUpCoordinateLocation,
        "dropOffCoordinate": this.bucketBookingObjDropOffCoordinateLocation,
        "load": this.parcels,
        "canopy": false,
        "dropOid": null,
        "distance": 0,
        "province": this.dropOffProvince,
        'transportMode': this.transportMode,
        'type': this.type,
        "itemMass": this.totalMass,
        'isItemPicked': false,
        'mainCityOid': this.mainCenter != null ? this.mainCenter.oid : null,
        'fromSuburb': this.fromSuburb,
        'toSuburb': this.toSuburb,
        'pickUpPCode': this.pickUpPCode,
        'dropOffPCode': this.dropOffPCode,
        'pickUpAmt': this.pickUpAmt,
        'airlineAmt': this.airlineAmt,
        'dropOffAmt': this.dropOffAmt,
        'destinationProvince': this.dropOffProvince,
        'isPayed': false,
        'paymentType': "ONLINE_PAY",
        "customerReference": this.customer_reference,
        "costCenter": this.cost_center_num,
        "parcels": null,
        'isExpress': true,
        "parcelDimensions": this.parcelDims,
        "expectedDeliveryDate": tenderSelectedDate
      };

      // Checks if the tender document was selected
      if (this.expectedDeliveryDate != null) {
        var datePipe = new DatePipe("en-US");
        let valueTime = datePipe.transform(this.tenderTime, 'HH:mm a');
        var currentDate = new Date(this.tenderDate);
        let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
        let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
        var second = currentDate.getSeconds() <= 9 ? "0" + currentDate.getSeconds() : currentDate.getSeconds();
        var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + valueTime.split(" ")[0] + ':' + second + '.957Z';
        var tenderSelectedDate = dropTime;
      }

      if (tenderSelectedDate != null ||tenderSelectedDate != undefined) {
        bookingData.expectedDeliveryDate = tenderSelectedDate;
      }

      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }

      if (this.type == 'DEDICATED') {
        bookingData.isExpress = false;
      }

      var addbookingObj = {
        "bucketBookingOid": this.booking.oid,
        "booking": bookingData
      };
      this.loadservice.addloader();
      this.bookService.addToBucketsBook(addbookingObj, token)
        .subscribe(
          (res: Response) => {
            this.loadservice.removeloader();
            this.msg = 'Booking successfully added to the bucket!';
            $('#Bookingresults').modal('show');
            this.getBucketbook(token, this.booking.oid);
            this.bucketBookingObjDropOffChecked = true;
          },
          (error: Response) => {
            console.log(error);
            this.loadservice.removeloader();
            this.err = error;
            this.msg = this.err.error.message != null ? this.err.error.message : 'Error occured while making a booking, Please try again.';
            $('#Bookingresults').modal('show');
          }
        );
    } else {
      this.priceMessage = "Please click the get quote button & get a price to add an element.";
      $('#priceModal').modal('show');
    }

  }

  public removeBucketBookingObj(i) {
    this.loadservice.addloader();
    this.bookService.removeFromBucketsBook(this.booking.oid, this.booking.bookings[i].oid, this.userToken.token).subscribe(
      (res) => {
        this.msg = "Booking deleted from the Bucket!";
        $('#removeElement').modal('show');
        this.loadservice.removeloader();
        this.clearFields();
        this.getBucketbook(this.userToken.token, this.booking.oid);
      }, (error) => {
        console.log(error);
        $('#removeElement').modal('show');
        this.msg = "Booking  not deleted!";
        this.loadservice.removeloader();
      }
    )
  }

  standardRatePrice: any;

  // Payments
  public confirmBooking() {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    var data = {
      "comments": this.comment,
      "labour": this.numberOfLabours,
      "bookingOid": this.bucketBooking.oid,
      "recurrence": null
    };
    this.loadservice.addloader();
    this.bucketBookingPrice = this.tempAmount
    this.bookService.pushBucketsBook(data, myToken).subscribe(
      (res) => {
        this.bucketBooking = res;
        if (this.bucketBooking.price > this.bucketBookingPrice) {
          // Removing VAT per Rhulani's instructions
          this.standardRatePrice = this.bucketBooking.price / 1.15;
          this.standardRateApplied = "Please note standard rate  of " + " " + "R" + this.standardRatePrice  + " " + "with 15% VAT will be applied."
        }
        this.loadservice.removeloader();
        this.msg = "Bucket Booking successfully created.";
        $('#pushBooking').modal('show');
        this.sendNewRetailInvoices();
        localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
      },
      (error) => {
        console.log(error);
        this.msg = error.error.message;
        this.loadservice.removeloader()
        $('#pushBooking').modal('show');
      }
    );
  }

  public onPostBucketBookingPaymentCard() {

    var userPaymentData = JSON.parse(localStorage.getItem('userInfo'));
    var gotoCheckOut: boolean = false
    var cardPaymentData = {
      "name_first": userPaymentData.owner.firstName,
      "name_last": userPaymentData.owner.surname,
      "email_address": userPaymentData.owner.email,
      "cell_number": userPaymentData.owner.mobile,
      "m_payment_id": this.bucketBooking.oid,
      "amount": this.tempAmount,
      "customerReference": userPaymentData.owner.oid,
      "item_name": "BUCKET BOOKING",
      "item_description": "Transporting multiple Items from ",
      "email_confirmation": userPaymentData.owner.email,
      "confirmation_address": userPaymentData.owner.email,
      "reference": this.bucketBooking.oid,
      "paymentType": "BUCKET_BOOKING_PAYMENT"
    }

    this.bookService.requestBucketPaymentPayFast(cardPaymentData, this.userToken.token).subscribe((res: Response) => {

      let successfulPaymentData = res.json();
      this.merchant_id.nativeElement.value = successfulPaymentData.merchant_id;
      this.merchant_key.nativeElement.value = successfulPaymentData.merchant_key;
      this.return_url.nativeElement.value = successfulPaymentData.return_url;
      this.cancel_url.nativeElement.value = successfulPaymentData.cancel_url;
      this.notify_url.nativeElement.value = successfulPaymentData.notify_url;
      this.name_first.nativeElement.value = successfulPaymentData.name_first;
      this.name_last.nativeElement.value = successfulPaymentData.name_last;
      this.email_address.nativeElement.value = successfulPaymentData.email_address;
      this.m_payment_id.nativeElement.value = successfulPaymentData.m_payment_id;
      this.amount.nativeElement.value = successfulPaymentData.amount;
      this.item_name.nativeElement.value = successfulPaymentData.item_name;
      this.item_description.nativeElement.value = successfulPaymentData.item_description;
      this.confirmation_address.nativeElement.value = successfulPaymentData.confirmation_address;
      this.custom_int1.nativeElement.value = successfulPaymentData.custom_int1;
      localStorage.setItem('paymentReference', successfulPaymentData.m_payment_id);
      gotoCheckOut = true

    }, (error) => {
      console.log(error);
      this.loadservice.removeloader();
      gotoCheckOut = false
    }, () => {

      if (gotoCheckOut) {
        this.payFastForm.nativeElement.submit()
      } else {

      }
    });

  }

  walletToHome = false;

  public payWithWallet() {
    var walletBody = {
      "comments": this.comment,
      "labour": this.numberOfLabours,
      "bookingOid": this.bucketBooking.oid,
      "recurrence": {
        "period": null,
        "fromDate": null,
        "toDate": null
      }
    }
    this.loadservice.addloader();
    this.bucketBookingPrice = this.tempAmount;
    this.bookService.checkAvailBalance(this.userToken.token, this.retail.owner.userAccountOid, this.bucketBooking.price).subscribe(
      response => {
        this.isBalancePositive = response.isBalancePositive;
        if (this.isBalancePositive) {
          this.bookService.authWallet(this.userToken.token, walletBody).subscribe(
            response => {
              this.loadservice.removeloader();
              if (this.bucketBooking.price > this.bucketBookingPrice) {
                // Removing VAT per Rhulani's instructions
                this.standardRatePrice = this.bucketBooking.price / 1.15
                this.standardRateApplied = "Please note standard rate  of " + " " + "R" + this.standardRatePrice  + " " + "with 15% VAT will be applied."
              }
              this.msg = 'Bucket Booking successfully created.';
              this.walletToHome = true;
              $('#pushBooking').modal('show');
              this.sendNewRetailInvoices();
            }, error => {
              this.loadservice.removeloader();
              console.log(error);
            }, () => {
            }
          );
        }
        else {
          this.loadservice.removeloader();
          this.msg = 'Your wallet has insufficient funds. Please credit your wallet to proceed with the transaction.';
          $('#walletPayment').modal('show');
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    )

  }

  // Payments

  public sendNewRetailInvoices() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.invoiceRetailBucketCustomer(object.token, this.bucketBooking.trackNo).subscribe(
      (response) => {
      }, (error) => {
        console.log('Error inside the attach invoice block');
        console.log(error);
      }
    );
  }

  public enterDropOffAddress() {
    $('#addressPopUp2').modal('show');
  }

  public selectedDropOffSuburb(toSuburb) {
    this.toSuburb = toSuburb;
  }

  public selectDropOffDropDownSuburb(postalCode) {
    if ((this.dropOffPCode != null && this.dropOffPCode != undefined) && (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER')) {
      this.bookService.postalDetails(this.userToken.token, postalCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public getDropOffExtraDetails(quote) {
    this.dropOffPCode = quote;

    if ((this.dropOffPCode != null || this.dropOffPCode != undefined) && this.dropOffPCode.toString().length == 4) {
      this.bookService.postalDetails(this.userToken.token, this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }

  }

  public capturedDropOffAddress() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    }
    else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    }
    else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    }
    else {

      this.disableAddresses = false;
      this.enteredDropOffSuccessMsg = 'Address successfully captured.'
      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;
      this.bucketBookingObjDropOffAddress = this.enteredDropOffAddress
        .concat(', ' + this.toSuburb)
        .concat(', ' + this.dropOffPCode)
        .concat(', ' + this.dropOffProvince);
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.bucketBookingObjDropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.bucketBookingObjDropOffAddress };
    }

  }

  public closeCapturedDropOffAddress() {
    this.enteredDropOffAddressMsg = '';
    this.enteredDropOffSuburbMsg = '';
    this.enteredDropOffPostalCodeMsg = '';
    this.enteredDropOffProvinceMsg = '';
  }

}
