import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTableResults'
})
export class FilterTableResultsPipe implements PipeTransform {

  transform(items: any[], field: string, value1: string, value2: string, value3: string): any {
    let filterArray = items;
    let holdingArray = [];
    let finalReturnArray = [];
    if (!items) {
      return [];
    }
    if (!field || !value1 || !value2 || !value3) {
      return items;
    } else {
      // Filter items in terms of value1 and store it on another array
      holdingArray = filterArray.filter(singleItem =>
        singleItem[field].toLowerCase().includes(value1.toLowerCase()));

      // Put them in final array
      finalReturnArray = holdingArray;

      // Get original array of items
      filterArray = items;

      // Filter the array and store it in another array
      holdingArray = filterArray.filter(singleItem =>
        singleItem[field].toLowerCase().includes(value2.toLowerCase()));

      // Put them in final array
      finalReturnArray = finalReturnArray.concat(holdingArray);

      filterArray = items;

      holdingArray = filterArray.filter(singleItem =>
        singleItem[field].toLowerCase().includes(value3.toLowerCase()));

      // Put them in final array
      finalReturnArray = finalReturnArray.concat(holdingArray);

      return finalReturnArray;
    }
  }
}
