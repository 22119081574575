import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {DatePipe} from '@angular/common';
import {Router} from '@angular/router';
import {BookingService} from 'src/app/services/booking.service';
import {LoaderService} from 'src/app/services/loader.service';
import {SharedService} from "../../services/shared.service";

declare var $: any;

@Component({
  selector: 'app-view-boooking',
  templateUrl: './view-boooking.component.html',
  styleUrls: ['./view-boooking.component.css']
})
export class ViewBoookingComponent implements OnInit {

  book: any;
  expressBooking: any;
  driver: any;
  driverMobile: string;
  valid = false;
  foundDriver: any;
  err = '';
  assignStatus = '';
  errorMessage: string;
  bookingTime: any;
  bookingDate: any;
  message: string;
  messege: string;
  provinceUpdate: string;
  vehicleType: string;
  page = 1;
  msg: string;
  messageProvince: string;
  bookingData: any;
  selectedTabID: any;

  pickingUp = {
    name: '',
    surname: '',
    phone: '',
    email: ''
  };
  doneMsg: string;
  wayBillNum: any;
  wayBillStatus: any;
  flightInfo: any;
  departTime: any;
  arrivalTime: any;
  invoiceMessage: any;
  expressItem = false;
  number = 2_000_000_000;
  displayVehicleBtn = false;

  bookingPrice: any;
  bookingPriceMsg = '';
  loader = false;
  superRole = false;
  pickUpVerificationType = '';
  DropOffVerificationType = '';
  users: any = [];
  userDetails: any;
  viewUserDisabled  = false;

  showDashChangeBtn = false;

  waybillWithUpdate = '';
  trackNumberToUpdate = '';

  bookingStatus = 'Driver in transit';
  changeBookingStatusMsg = '';
  showChangeBookingStatusMsg = false;
  bookingStatusChangeSuccess = false;
   uberDropOffTimeAt = '';
   uberPickTimeAt = '';

  uberqoute: any = {};
  pickUp_age: number;
  dropOff_age: number;
  uberResponse;
  uberResponseLinda: any = {};
  // tslint:disable-next-line:variable-name
  uber_pickup_verification: any = {};
  // tslint:disable-next-line:variable-name
  uber_dropoff_verification: any = {};

  uberError = '';
  uberTrackingLink = '';
  ubertitle = 'Uber Quote';
  isType = false;
  uberDeliveryDetail;
  pickUpPOD = '';
  dropOffPOD = '';

  showMessage = false;
  wayBillMessage = '';
  newWaybill = false;
  addingWaybill = false;

  modalTitle = 'Error';
  modalError = 'Error';

  waybillDownloadLink = '';

  constructor(private adminService: AdminService, private router: Router,
              private bookingService: BookingService, private loadservice: LoaderService, private sharedServices: SharedService) {
    this.book = JSON.parse(localStorage.getItem('selectedBooking'));
    this.isType = (this.book.type === 'DASH' || this.book.type === 'FLASH');
    if (this.book.isExpress === true) {
      this.expressItem = true;
    }
    if (this.book.type === 'DASH' || this.book.type === 'DEDICATED') {
      this.displayVehicleBtn = true;
    }
    this.bookingDate = this.book.pickUpDate;
    const TimeSeparatorIndex = this.book.pickUpTime.indexOf(':');
    const hour = parseInt(this.book.pickUpTime.substring(0, TimeSeparatorIndex));
    const minutes = this.book.pickUpTime.substring(TimeSeparatorIndex + 1, 5);
    this.bookingTime = hour + ':' + minutes + ':00';
    this.waybillDownloadLink = sharedServices.getUrl() + 'plugins/waybill/download/pdf/';
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    // console.log(this.book);
    this.getDelivery();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.book.driverOid != null) {
      this.adminService.getDriver(object.token, this.book.driverOid).subscribe(
        (res) => {
          this.driver = res;
        }
      );
    }
    /*if (this.book.waybillResponse != null) {
      this.getWayBillStatus();
    }*/
    this.showDashChangeBtn = this.book.isDashBike && this.book.type === 'DASH';
    object.roles.forEach(element => {
      if (element.code === 'super_admin') {
        this.superRole = true;
      }
    });
    /*for (let i = 0; i < 15; i++) {
      this.book.parcelDimensions.push(this.book.parcelDimensions[0]);
    }*/
  }

  public cancelBookingModal() {
    if (this.book.uberResponse) {
      $('#cancelUberBooking').modal('show');
    } else {
      $('#cancelMyBooking').modal('show');
    }
  }

  public cancelUberDelivery() {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    console.log(this.book.oid);
    this.bookingService.cancelUberDelivery(user.token, this.book.oid).subscribe(
      (response) => {
        this.book = response.json();
        localStorage.setItem('selectedBooking', JSON.stringify(this.book));
        this.getDelivery();
        this.loadservice.removeloader();
      },
      (error) => {
        console.log(error.json());
        this.errorModal('Error cancelling uber delivery', error.json().message);
        this.loadservice.removeloader();
      }
    );
  }

  public viewUberPOD() {
    this.getUberPOD();
    $('#viewPodModel').modal('show');
  }

  public getUberPOD() {
    const user = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.bookingService.getUberPOD(user.token, this.book.oid).subscribe(
      (response) => {
        this.dropOffPOD = 'data:image/png;base64,' + response.json().dropOffPOD;
        this.pickUpPOD = 'data:image/png;base64,' + response.json().pickUpPOD;
        console.log(this.pickUpPOD);
        this.loadservice.removeloader();
      }, (error) => {
        console.log(error.json());
        const resposeError = error.json();
        this.loadservice.removeloader();
      });
  }

  public getDelivery() {
    if (this.book) {
      if (this.book.uberResponse) {
        const user = JSON.parse(localStorage.getItem('userInfo'));
        this.loadservice.addloader();
        this.bookingService.getUberDeliveryDetails(user.token, this.book.oid).subscribe(
          (response) => {
            this.uberDeliveryDetail = response.json();

            this.loadservice.removeloader();
          },
          (error) => {
            console.log(error.json());
            const resposeError = error.json();
            this.loadservice.removeloader();
          }
        );
      }
    }
  }

  public trackUber() {
    window.open(this.uberTrackingLink, '_blank');
  }

  public createUberDelivery() {
    this.loadservice.addloader();
    const user = JSON.parse(localStorage.getItem('userInfo'));
    const mani = [];

    const dropOffContact = this.book.dropOff;
    const pickUpContact = this.book.pickUp;

    switch (this.pickUpVerificationType) {
      case 'identification':
        this.uber_pickup_verification = {
          identification : {
            min_age : this.pickUp_age
          }
        };
        break;
      case 'barcodes':
        this.uber_pickup_verification = {

        };
        break;
      default:
        this.uber_pickup_verification = {};
    }
    switch (this.DropOffVerificationType) {
      case 'picture':
        this.uber_dropoff_verification = {
          picture: true
        };
        break;
      case 'identification':
        this.uber_dropoff_verification = {
          identification : {
            min_age : this.dropOff_age
          }
        };
        break;
      case 'pincode':
        this.uber_dropoff_verification = {
          pincode : {
            enabled: true
          }
        };
        break;
      case 'barcodes':
        this.uber_dropoff_verification = {

        };
        break;
      default:
        this.uber_dropoff_verification = {};
    }
    const uberdeliveryObj
      = {
      dropoff_address: this.book.dropOffAddress,
      dropoff_name: dropOffContact.firstName + ' ' + dropOffContact.lastName,
      dropoff_phone_number: '+27' + dropOffContact.phone.substring(1),
      manifest_items: mani,
      pickup_address: this.book.pickUpAddress,
      pickup_name: pickUpContact.firstName + ' ' + pickUpContact.lastName,
      pickup_phone_number: '+27' + pickUpContact.phone.substring(1),
      dropoff_business_name: dropOffContact.companyName ? dropOffContact.companyName : dropOffContact.phone,
      dropoff_latitude: parseFloat(this.book.dropOffCoordinate.coordinates[0]),
      dropoff_longitude: parseFloat(this.book.dropOffCoordinate.coordinates[1]),
      dropoff_notes: this.book.comment,
      manifest_reference: this.book.trackNo,
      // "manifest_total_value":100000,
      pickup_business_name: pickUpContact.companyName ? pickUpContact.companyName : pickUpContact.phone,
      pickup_latitude: parseFloat(this.book.pickUpCoordinate.coordinates[0]),
      pickup_longitude: parseFloat(this.book.pickUpCoordinate.coordinates[1]),
      pickup_verification: this.uber_pickup_verification,
      dropoff_verification: this.uber_dropoff_verification,
      pickup_notes: this.book.comment,
    };
    console.log(JSON.stringify(uberdeliveryObj));
    this.bookingService.
    getUberdelivery(user.token, uberdeliveryObj, this.book.oid).subscribe(
      (response) => {

        console.log(response);
        // this.book = response.json();s

        this.uberResponseLinda = response.json();

        localStorage.setItem('selectedBooking', JSON.stringify(this.book));
        this.uberTrackingLink = this.uberResponseLinda.tracking_url;
        this.getDelivery();
        this.loadservice.removeloader();
        $('#SuccessUberCreated').modal('show');
      },
      (error) => {
        const resposeError = error.json();
        this.errorModal('Error creating uber delivery', resposeError.message);
        this.loadservice.removeloader();
      }
    );
      /*this.bookingService.getBookingContactPerson(user.token, this.book.oid).subscribe(
        (response)=>{
          const contacts=response.json()
          const dropOffContact=contacts.dropOffContact
          const pickUpContact=contacts.pickUpContact

          const uberdeliveryObj={
            "dropoff_address":this.book.dropOffAddress,
            "dropoff_name":dropOffContact.firstName+" "+dropOffContact.lastName,
            "dropoff_phone_number":"+27"+dropOffContact.phone.substring(1),
            "manifest_items":mani,
            "pickup_address":this.book.pickUpAddress,
            "pickup_name":pickUpContact.firstName+" "+pickUpContact.lastName,
            "pickup_phone_number":"+27"+pickUpContact.phone.substring(1),
            "dropoff_business_name":dropOffContact.companyName?dropOffContact.companyName:dropOffContact.phone,
            "dropoff_latitude":parseFloat(this.book.dropOffCoordinate.coordinates[0]),
            "dropoff_longitude":parseFloat(this.book.dropOffCoordinate.coordinates[1]),
            "dropoff_notes":this.book.comment,
            "manifest_reference":this.book.trackNo,
            // "manifest_total_value":100000,
            "pickup_business_name":pickUpContact.companyName?pickUpContact.companyName:pickUpContact.phone,
            "pickup_latitude":parseFloat(this.book.pickUpCoordinate.coordinates[0]),
            "pickup_longitude":parseFloat(this.book.pickUpCoordinate.coordinates[1]),
            "pickup_notes":this.book.comment,
            "pickup_verification":this.book.trackNo,
            "requires_dropoff_signature":false,
            "tip":0,
          }

          this.bookingService.getUberdelivery(user.token, uberdeliveryObj,this.book.oid).subscribe(
            (response)=>{
              this.book=response.json()
              localStorage.setItem('selectedBooking',JSON.stringify(this.book));
              this.getDelivery()
              this.loadservice.removeloader()
              $('#successmodal').modal('show')
            },
            (error)=>{
              console.log(error.json());
              const resposeError=error.json()
              this.errorModal("Error creating uber delivery",resposeError.message)
              this.loadservice.removeloader()
            }
          )
        },
        (error)=>{
          console.log(error.json());
          const resposeError=error.json()
          this.errorModal("Error getting contact person",resposeError.message)
          this.loadservice.removeloader()
        }
      )*/
  }

  public getUberQuote() {
    this.uberError = '';
    this.loadservice.addloader();
    this.uberqoute = null;
    const quoterequestbody = {
        dropoff_address: this.book.dropOffAddress,
        pickup_address: this.book.pickUpAddress,
        dropoff_latitude: parseFloat(this.book.dropOffCoordinate.coordinates[0]),
        dropoff_longitude: parseFloat(this.book.dropOffCoordinate.coordinates[1]),
        dropoff_phone_number: '+27' + this.book.phoneNo.substring(1),
        pickup_latitude: parseFloat(this.book.pickUpCoordinate.coordinates[0]),
        pickup_longitude: parseFloat(this.book.pickUpCoordinate.coordinates[1])

    };
    const user = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingService.getuberquote(user.token, quoterequestbody).subscribe(
      response => {
        const resJson = response.json();
        if (resJson.currency) {
          this.uberqoute = response.json();

        } else {
          this.uberError = 'please varify the addresses to get accurate uber quotes. (NB: use google) ';
        }
        $('#exampleModalCenter').modal('show');
        this.loadservice.removeloader();
      }, error => {
        const err = error.json();
        console.log(err);
        this.uberError = 'Uber Error: ' + err.message;
        this.errorModal('Error Getting Quote', this.uberError);
        this.loadservice.removeloader();
      }, () => {
        this.loadservice.removeloader();
      }
    );

  }

  public errorModal(title, error) {
    this.modalTitle = title;
    this.modalError = error;
    $('#errorModal').modal('show'); //
  }

  cancelBooking() {
    const cancelObj = {
      status: 'INVALID',
      bookingOid: this.book.oid
    };
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.cancelBooking(object.token, cancelObj).subscribe(
      (res) => {
        this.errorMessage = 'Booking Cancelled.' + this.book.isPaidViaWallet ? ' Wallet Refunded.' : '';
        $('#cancelBooking').modal('show');
      }, error => {
        console.log(error);
        this.errorMessage = error.error.message;
        $('#cancelBooking').modal('show');
      }
    );
  }

  public turnToDash() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.turnSwiftToBucket(object.token, this.book.trackNo).subscribe(
      (res) => {
        this.doneMsg = 'moved to bucket';
        $('#completeDialog').modal('show');
      }, error => {
        console.log(error);
        this.doneMsg = error.error.message;
        $('#completeDialog').modal('show');
      }
    );
  }

  findDriver() {
    this.err = '';
    this.valid = false;
    if (this.driverMobile != null) {
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getDriverByMobile(object.token, this.driverMobile).subscribe(
        (res) => {
          this.valid = true;
          this.foundDriver = res;
          this.err = this.foundDriver.vehicle != null ? '' : 'Driver doesn\'t have a vehicle.';
        }, (error) => {
          console.log(error);
          this.err = error.error.message;
        }
      );
    } else {
      alert('Please enter mobile numbers.');
    }
  }

  assignNow() {
    const bookingData = {
      status: 'RESERVED',
      bookingOid: this.book.oid,
      driverOid: this.foundDriver.oid
    };

    // console.log(bookingData);

    if (this.foundDriver != null) {
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.assignDriverBooking(object.token, bookingData).subscribe(
        (res) => {
          this.assignStatus = 'Driver successfully assigned to booking.';
          localStorage.setItem('selectedBooking', JSON.stringify(res));
        }, (error) => {
          console.log(error.error.message);
          this.assignStatus = error.error.message;
        }
      );
    } else {
      alert('Driver details missing..');
    }
  }

  public completeBooking() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.completeBooking(object.token, this.book.oid).subscribe(
      (res) => {
        this.doneMsg = 'Booking completed by admin.';
        this.book = res;
        localStorage.setItem('selectedBooking', JSON.stringify(res));
        $('#completeDialog').modal('show');
      }
    );
  }

  public endFlightTrip() {
    const departureCurrentDate = new Date(this.departTime);
    const date = departureCurrentDate.getDate() <= 9 ? '0' + departureCurrentDate.getDate() : departureCurrentDate.getDate();
    const monthNo = (departureCurrentDate.getMonth() + 1) <= 9 ? '0' + (departureCurrentDate.getMonth() + 1) : (departureCurrentDate.getMonth() + 1);
    const hour = departureCurrentDate.getHours() <= 9 ? '0' + departureCurrentDate.getHours() : departureCurrentDate.getHours();
    const minutes = departureCurrentDate.getMinutes() <= 9 ? '0' + departureCurrentDate.getMinutes() : departureCurrentDate.getMinutes();
    const second = departureCurrentDate.getSeconds() <= 9 ? '0' + departureCurrentDate.getSeconds() : departureCurrentDate.getSeconds();
    this.departTime = departureCurrentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    const arrivalCurrentDate = new Date(this.arrivalTime);
    const date2 = arrivalCurrentDate.getDate() <= 9 ? '0' + arrivalCurrentDate.getDate() : arrivalCurrentDate.getDate();
    const monthNo2 = (arrivalCurrentDate.getMonth() + 1) <= 9 ? '0' + (arrivalCurrentDate.getMonth() + 1) : (arrivalCurrentDate.getMonth() + 1);
    const hour2 = arrivalCurrentDate.getHours() <= 9 ? '0' + arrivalCurrentDate.getHours() : arrivalCurrentDate.getHours();
    const minutes2 = arrivalCurrentDate.getMinutes() <= 9 ? '0' + arrivalCurrentDate.getMinutes() : arrivalCurrentDate.getMinutes();
    const second2 = arrivalCurrentDate.getSeconds() <= 9 ? '0' + arrivalCurrentDate.getSeconds() : arrivalCurrentDate.getSeconds();
    this.arrivalTime = arrivalCurrentDate.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + hour2 + ':' + minutes2 + ':' + second2;

    const bookingData = {
      bookingOid: this.book.oid,
      flightNo: this.flightInfo,
      ewaybill: this.book.trackNo,
      departureDate: this.departTime,
      arrivalDate: this.arrivalTime
    };

    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.completeLegFlight(object.token, bookingData).subscribe(
      response => {
        this.errorMessage = 'Flight leg successfully ended.';
      }, error => {
        console.log(error);
        this.errorMessage = 'Error, could not end the first leg of the flight.';
        $('#flightBooking').modal('show');
      }
    );
  }

  public createBooking() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.adminService.book(this.book, object.token).subscribe(
      (res) => {
        this.errorMessage = 'Booking created by admin.';
        this.loadservice.removeloader();
        $('#errorDialog').modal('show');
      }, (err) => {
        console.log(err);
        this.errorMessage = err.error.message;
        this.loadservice.removeloader();
        $('#errorDialog').modal('show');
      }
    );
  }

  public cancelUberModalPopUp() {
    $('#cancelWarningModal').modal('show');
  }

  public viewUberDeliveryPopUp() {
    const user = JSON.parse(localStorage.getItem('userInfo'));

    this.bookingService.getUberDeliveryByBookingOid(user.token,  this.book.oid).subscribe((response) => {
      this.uberResponseLinda = response.json();
      this.uberTrackingLink = this.uberResponseLinda.tracking_url;

      $('#viewDeliveryModal').modal('show');

    },
      (error) => {
        const resposeError = error.json();
        this.errorModal('Error creating uber delivery', resposeError.message);
        this.loadservice.removeloader();
      }
      );
  }

  public updateBookingPrice() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    const newPrice = {
      newPrice: this.bookingPrice
    };
    this.loadservice.addloader();
    this.adminService.updateBookingPrice(object.token, this.book.oid, newPrice).subscribe(
      response => {
        this.bookingPriceMsg = 'Booking price successfully updated.';
        console.log(response);
        this.book = response;
        localStorage.setItem('selectedBooking', JSON.stringify(response));
        this.loadservice.removeloader();
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }
    );
  }

  public recreateBooking() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.rebook(this.book.oid, object.token).subscribe(
      (res) => {
        this.errorMessage = 'Booking created by admin.';
        $('#errorDialog').modal('show');
      }, error => {
        console.log(error);
        this.errorMessage = error.error.message;
        $('#errorDialog').modal('show');
      }
    );
  }

  public updateTimes() {
    this.book = JSON.parse(localStorage.getItem('selectedBooking'));

    const datePipe = new DatePipe('en-US');
    const value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');

    // const datePipe = new DatePipe('en-US');
    const TimeSeparatorIndex = this.bookingTime.indexOf(':');
    const hour = parseInt(this.bookingTime.substring(0, TimeSeparatorIndex));
    const minutes = this.bookingTime.substring(TimeSeparatorIndex + 1, 5);
    const date = new Date();

    date.setHours(hour);
    date.setMinutes(minutes);

    const valueTime = datePipe.transform(date, 'HH:mm a');
    const object = JSON.parse(localStorage.getItem('userInfo'));

    if (hour >= 7 && hour <= 18) {
      const bookingUpdate = {
        date: value,
        time: valueTime,
        bookingOid: this.book.oid
      };
      this.adminService.updateBooking(bookingUpdate, object.token).subscribe(
        (response) => {
          this.message = 'Booking is updated successfully.';
          this.book.pickUpTime = response.pickUpTime;
          this.book.pickUpDate = response.pickUpDate;

        }, (err) => {
          console.log(err);
          this.message = err.error.message;
        }
      );
    } else {
      this.message = 'Working hours are from 07:00 AM to 18:59 PM.';
    }
  }

  public updateProvince() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.updateProvince(object.token, this.book.oid, this.provinceUpdate).subscribe(
      (response) => {
        this.messageProvince = 'Province updated.';
        this.book = response;
        localStorage.setItem('selectedBooking', JSON.stringify(response));
      }, (error) => {
        this.messageProvince = 'Province not updated.';
      }
    );
  }

  public updateVehicle() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.updateVehicleFromAdminPortal(object.token, this.book.oid, this.vehicleType).subscribe(
      (response) => {
        this.messege = 'Vehicle updated.';
        this.book = response;
        localStorage.setItem('selectedBooking', JSON.stringify(response));
      }, (error) => {
        this.messege = 'Not updated.';
      }, () => {
      }
    );
  }

  public getWayBillStatus() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.wayBillNum = this.book.waybillResponse.waybillNumber;
    this.adminService.retrieveWayBillStatus(object.token, this.wayBillNum).subscribe(
      (response) => {
        this.wayBillStatus = response;
        localStorage.setItem('wayBillStatuses', JSON.stringify(this.wayBillStatus));
        this.router.navigateByUrl('/waybill-statuses');
      }, (error) => {
        console.log(error);
      }
    );
  }

  viewWayBillStatus() {
    if (this.wayBillStatus.length !== 0) {
      localStorage.setItem('wayBillStatuses', JSON.stringify(this.wayBillStatus));
      this.router.navigateByUrl('/waybill-statuses');
    } else {
      console.log('button');
      alert('There is currently no waybill events to view.');
    }
  }

  startTracking(trackNumber: any) {
    this.loadservice.addloader();
    this.adminService.getBookingByTrackNumber(trackNumber).subscribe(
      (response3) => {
        this.loadservice.removeloader();
        if (response3.error) {
          console.log(response3.error);
        } else {
          if (response3.track_link) {
            const externalTracking = document.createElement('a');
            externalTracking.href = response3.track_link;
            externalTracking.target = '_blank';
            externalTracking.click();
            externalTracking.remove();
          } else if (response3.events) {
            this.wayBillStatus = response3.events;
            localStorage.setItem('wayBillStatuses', JSON.stringify(this.wayBillStatus));
            this.router.navigateByUrl('/waybill-statuses');
          } else {
            // this.router.navigateByUrl('/tracking/' + trackNumber);
          }
        }
      }, (error) => {
        this.loadservice.removeloader();
        console.log(error);
      });
  }

  public sendCustomerInvoice() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.invoiceCustomer(object.token, this.book.trackNo).subscribe(
      (response) => {
        this.invoiceMessage = 'Invoice successfully sent.';
        $('#invoiceTax').modal('show');
      }, (error) => {
        console.log(error);
        this.invoiceMessage = 'Error, could not send invoice.';
        $('#invoiceTax').modal('show');
      }
    );
  }

  public viewUser() {
    this.loadservice.addloader();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getUserDetails(object.token, this.book.customer.email).subscribe(
      response => {
        this.loadservice.removeloader();
        localStorage.setItem('selectedUser', JSON.stringify(response));
        this.router.navigateByUrl('/view-user');
      }, error => {
        console.log(error);
        this.loadservice.removeloader();
      }
    );
  }

  addWaybill() {
    this.showMessage = false;
    if (this.waybillWithUpdate === '') {
      this.showMessage = true;
      this.wayBillMessage = 'Ensure that you enter the Waybill Number';
    } else {
      this.addingWaybill = true;
      const object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.addUpdateWayBill(object.token, this.book.trackNo, this.waybillWithUpdate).subscribe(
        (response) => {
          // console.log(response);
          this.showMessage = true;
          this.wayBillMessage = 'Successfully added waybill number';
          this.newWaybill = true;
          this.addingWaybill = false;
          this.adminService.updateWaybillTrackingStatus(object.token, this.waybillWithUpdate).subscribe(
            (resp) => {
            //   Do nothing
            },
            (err) => {
              console.log(err);
          });
        },
        (error) => {
          console.log(error);
          this.showMessage = true;
          this.wayBillMessage = error.error.message;
          this.addingWaybill = false;
        }
      );
    }
  }

  closeAddWaybill() {
    this.showMessage = false;
    this.wayBillMessage = '';
  }

  updateBookingStatus() {
    this.changeBookingStatusMsg = '';
    this.showChangeBookingStatusMsg = false;
    const user = JSON.parse(localStorage.getItem('userInfo'));

    const bookingStatusData = {
      status: '',
      bookingOid: this.book.oid,
      driverOid: this.driver === undefined ? 'uberDriver' : this.driver.oid
    };

    if (this.bookingStatus !== 'Collected') {
      switch (this.bookingStatus) {
        case 'Waybill Created' : bookingStatusData.status = 'CREATED_WAYBILL'; break;
        case 'Driver in transit' : bookingStatusData.status = 'IN_TRANSACT'; break;
        case 'Booking successfully complete' : bookingStatusData.status = 'COMPLETE'; break;
      }

      this.adminService.changeBookingStatus(user.token, bookingStatusData).subscribe(
        (response: any) => {
          this.changeBookingStatusMsg = 'Status updated to ' + '"' + response.status + '"';
          this.showChangeBookingStatusMsg = true;
          this.bookingStatusChangeSuccess = true;
        },
        error => {
          // console.log(error);
          this.changeBookingStatusMsg = error.error.message;
          this.showChangeBookingStatusMsg = true;
        }
      );
    } else {
      this.adminService.changeBookingIsItemPickedStatus(user.token, this.book.oid).subscribe(
        response => {
          this.changeBookingStatusMsg = 'Status updated to "Collected"';
          this.showChangeBookingStatusMsg = true;
          this.bookingStatusChangeSuccess = true;
        },
        error => {
          // console.log(error);
          this.changeBookingStatusMsg = error.error.message;
          this.showChangeBookingStatusMsg = true;
        }
      );
    }
  }
}
