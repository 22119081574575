import { Component, OnInit } from '@angular/core';

import * as firebase from 'firebase/app';
import { AngularFireDatabase } from 'angularfire2/database';

import { AdminService } from '../../services/admin.service';

declare var $: any;

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css', '../admin-portal/admin-portal.component.css']
})
export class RequestViewComponent implements OnInit {

  requestList: any = [];
  requets : any;


  constructor() { }

  ngOnInit() {
    this.requets = firebase.database().ref('requests');
    this.requets.on('value', (dataSnapshot) => {
      //this.persons = [];

      dataSnapshot.forEach((childSnapshot) => {
        let request_data;
        request_data = childSnapshot.val();
       // console.log(request_data);
        this.requestList.push(request_data);
      });
      //console.log(this.requestList);
      this.requestList.reverse();
      //console.log(this.requestList);

    });
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
