import {Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserLoggInService } from '../../services/loggedInUser.service';

declare var $: any;

@Component({
  selector: 'app-client-nav',
  templateUrl: './client-nav.component.html',
  styleUrls: ['./client-nav.component.css', '../navbar/v-nav-menu.css', '../navbar/theme-responsive.css']
})

export class ClientNavComponent implements OnInit {

  // subscription: Subscription;
  name: string;
  userObject: any;
  title = '';

  constructor(private nameService: SharedService, private route: Router, private userService: UserLoggInService) {

    /*this.subscription = this.nameService.message.subscribe((message) => {
        this.userObject = JSON.parse(localStorage.getItem('userInfo'));
        this.name = this.userObject.owner.firstName;
      }
    );*/
  }

  ngOnInit() {
    this.userObject = JSON.parse(localStorage.getItem('userInfo'));

    if (this.userObject != null) {
      this.name = this.userObject.owner.firstName;
      this.title = this.userObject.owner.firstName;
    } else {
      this.title = 'Login';
    }
  }

  public logout() {
    this.userService.clear();
    localStorage.removeItem('userInfoBooking');
    this.route.navigateByUrl('/client-login');
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '252px';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  collapse() {
    $(document).ready(function () {
      $('nav').find('li').on('click', 'a', function () {
        $('.navbar-collapse.in').collapse('hide');
      });
    });
  }
}
