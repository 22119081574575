import { Component, OnInit } from '@angular/core';

import { SharedService } from '../../services/shared.service';
import {Router} from '@angular/router';

import { UserLoggInService } from '../../services/loggedInUser.service';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.css', './v-nav-menu.css',
  './theme-responsive.css']
})
export class AdminNavbarComponent implements OnInit {

  constructor(private nameService : SharedService,
    private route : Router, private userService : UserLoggInService) { }

  ngOnInit() {
  }

  public logout() {
    this.userService.clear();
    // console.log(JSON.parse(localStorage.getItem('userInfo')));
    this.route.navigateByUrl('/');
  }
}
