import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Response } from '@angular/http';

declare var $: any;

import { from } from 'rxjs/observable/from';

import { AdminService } from '../../services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.css']
})
export class AddCompanyComponent implements OnInit {

  @ViewChild('companyForm', { static: true }) compaanyForm: NgForm;
  user: any = {};

  companyName: string = '';
  companyReg: string = '';
  make: string = '';
  province : string;
  model: string = '';
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  vehicleType: string = '';
  loader: boolean = false;
  error: string = '';
  canopy : boolean = false;
  vehiclesTypeRate : any = [];

  constructor(private service : AdminService, private router : Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.getVehicleTypeRates();
  }

  public onRegisterCompany() {

    var token = localStorage.getItem('token');
    var object = JSON.parse(localStorage.getItem('userInfo'));

    this.loadservice.addloader();
    var comppanyInfo = {
      "companyName": this.companyName,
      "companyReg": this.companyReg,
      "province" : this.province,
      "make": this.make,
      "model": this.model,
      "registration": this.registration,
      "vinNo": this.vinNo,
      "Vcolor": this.Vcolor,
      "type": this.vehicleType,
      "hasCanopy" : this.canopy
    };

    this.service.companyInfo(comppanyInfo, this.user, object.token)
      .subscribe(
      (response: Response) => {
        this.compaanyForm.reset();
        if (response != null) {
          this.loadservice.removeloader();
          this.router.navigateByUrl('/driver-profile');
          this.error = "Company and vehicle successfully added to the user.";
        } else {
          this.loadservice.removeloader();
          $('#results').modal('show');
          this.error = "Error while adding company please contact Droppa.";
        }
      },
      (err) => {
        // console.log(err);
        this.loadservice.removeloader();
        $('#results').modal('show');
        this.error = "Error while adding company";
      }
      );
  }

  public checkedCanopy(canopy: any) {
    if (canopy) {
      this.canopy = true;
    } else {
      this.canopy = false;
    }
  }

  public getVehicleTypeRates() {
    this.service.vehicleTypeRates().subscribe(
      (res) => {
        this.vehiclesTypeRate = res;
        //console.log(this.vehicleTypeRate);
      }
    )
  }

}
