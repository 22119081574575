import { Component, OnInit } from '@angular/core';
import {Advert} from "../../models/advert";
import {Router} from "@angular/router";

@Component({
  selector: 'app-create-advert',
  templateUrl: './create-advert.component.html',
  styleUrls: ['./create-advert.component.css']
})
export class CreateAdvertComponent implements OnInit {

  name: string;
  type: string;
  creationDate: Date;
  startDate: Date;
  endDate: Date;
  lastEditDate: Date;
  title: string;
  message: string;
  filePath: any;
  fileExtension: string;
  fileType: string;
  fileName: string;
  dateDeleted: Date;

  adverts: Advert[];

  showError = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.creationDate = new Date();
    this.startDate = new Date();
    this.endDate = new Date();
    this.lastEditDate = new Date();
    if (localStorage.getItem('adverts') !== 'adverts') {
      this.adverts = JSON.parse(localStorage.getItem('adverts'));
    }
  }

  submitAdvert() {

    if (this.name === undefined || this.type === undefined || this.title === undefined || this.message === undefined || this.filePath === undefined) {
      this.showError = true;
    } else {
      const file = {fileType: 'image', filePath: this.filePath};
      const advert = new Advert(
        this.name, this.type, this.title, this.message, this.creationDate, this.startDate, this.endDate,
        file, this.lastEditDate, null);

      if (this.adverts) {
        this.adverts.push(advert);
      } else {
        this.adverts = [advert];
      }
      // console.log(this.adverts);
      localStorage.setItem('adverts', JSON.stringify(this.adverts));
      this.router.navigate(['/marketing']);
    }
  }
}
