import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import { UserService } from 'src/app/services/user.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-skynet-tracking-waybill',
  templateUrl: './skynet-tracking-waybill.component.html',
  styleUrls: ['./skynet-tracking-waybill.component.css']
})
export class SkynetTrackingWaybillComponent implements OnInit {

  sub: any;
  waybillNum:any;
  status:any=[];
  loader:boolean=true;
  msg:any="";
  msgError:any="";
  downloadResponse:string;
  downloadResponseError:string;
  noStatus:boolean=false;


  constructor(private route: ActivatedRoute,private bookService: BookingService,private user: UserService,private loadservice:LoaderService) {
    this.sub = this.route.params.subscribe(params => {
      this.waybillNum = params['waybillNo'];
    });
  }

  ngOnInit() {
    this.getWayBill();
  }

  public getWayBill(){
    this.bookService.retrieveWayBillStatus(this.waybillNum).subscribe((res)=>{
      this.msg="";
      this.msgError="";
      if(res.length===0){
        this.msg="No results for this waybill :"+this.waybillNum+".";
        this.loadservice.removeloader();
      }else{
        this.status=res;
        this.loadservice.removeloader();
      }
    },(error)=>{
        console.log(error)
        this.msgError=error
        this.loadservice.removeloader();
    })
  }
  public downloadPOD(){
    this.loadservice.removeloader();
    this.downloadResponse="";
    this.downloadResponseError="";
    this.noStatus=false;
    this.user.getDocument(this.waybillNum).subscribe(
      response => {
        if(response.errorCode==="0"){
          this.downloadResponse=response.errorDescription;
          window.open(response.imageURL)
          this.noStatus=true;
        }else{
          this.downloadResponseError=response.errorDescription;
        }
        this.loadservice.removeloader();
      }, error => {
        this.loadservice.removeloader();
        this.noStatus=true;
        this.downloadResponseError=""
        console.log(error);
      }
    )
  }

}
