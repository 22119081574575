import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-retails-payment',
  templateUrl: './retails-payment.component.html',
  styleUrls: ['./retails-payment.component.css','../admin-portal/admin-portal.component.css']
})
export class RetailsPaymentComponent implements OnInit {

  bookings : any = [];
  admin : any = {};
  loader : boolean = false;

  constructor(private adminService: AdminService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.adminService.getBucketBooking(this.admin.token).subscribe(
      (res) => {
        this.loadservice.removeloader();
        this.bookings = res;
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public paymentStatus(book){
    this.loadservice.addloader();
    this.adminService.updatePayedBucketBookings(this.admin.token, book.booking.oid).subscribe(
      (res) => {
        this.ngOnInit();
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
