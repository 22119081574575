import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-driver-bookings',
  templateUrl: './driver-bookings.component.html',
  styleUrls: ['./driver-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class DriverBookingsComponent implements OnInit {

  bookings : any = [];
  admin : any = {};
  driver : any  = {};

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.driver = JSON.parse(localStorage.getItem('selectedDriverBooking'));

    this.adminService.getDriversBooking(this.driver.oid, this.admin.token).subscribe(
      (res) => {
        this.bookings = res;
        // console.log(this.bookings);
      }
    )
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
