import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { BookingService } from '../../services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-bucket-booking-review',
  templateUrl: './bucket-booking-review.component.html',
  styleUrls: ['./bucket-booking-review.component.css']
})
export class BucketBookingReviewComponent implements OnInit {

  bucketBooking : any = {};
  msg : string = "";
  comments : string = "";
  labour : number;
  loader : boolean = false;
  total: number;
  page: number = 1;
  today: number = Date.now();

  constructor(private userService : UserService,
     private route : Router, private bookService : BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.bucketBooking = JSON.parse(localStorage.getItem('selectedRetailBucketBooking'));
    // console.log(this.bucketBooking);
  }

  public  showToggle() {
    if(this.bucketBooking.status == "BUCKET_AWAITING") {
      $('#myBankModals').modal('show');
    } else{
      this.msg = "Bucket has been Successfull Added!";
      $('#Bookingresults').modal('show');

    }
  }

  public confirmBooking() {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.labour = 1;
    var data = {
      "comments" : this.comments,
      "labour" : this.labour,
      "bookingOid" : this.bucketBooking.oid
    };


       $('#myBankModals').modal('hide');
        this.loadservice.addloader();
        this.bookService.pushBucketsBook(data, myToken).subscribe(
          (res) => {
            this.loadservice.removeloader();
            this.msg = "Bucket Booking successfully created.";
            $('#Bookingresults').modal('show');
            localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
            this.bucketBooking = res;
          },
          (error) => {
            this.msg = error.error.message;//"Error while deleting Booking";
            //console.log(error);
            this.loadservice.removeloader();
            //console.log(error);
            $('#Bookingresults').modal('show');
          }
        );

  }

  public addBooking() {
    this.route.navigate(['/add-bucket-booking']);
  }

  public remove(bookingId: string) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.bookService.removeFromBucketsBook(this.bucketBooking.oid, bookingId, myToken).subscribe(
      (res) => {
        this.msg = "Booking ref deleted from you bucket";
        localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
        this.bucketBooking = res;
      }, (error) => {
        this.msg = "Error while deleting Booking";
      }
    )
  }

}
