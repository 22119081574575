import { Routes } from '@angular/router';
import { HomeComponent } from '../home/home.component';
import { RetailTypeRateComponent } from '../user-portal/retail-type-rate/retail-type-rate.component';
import { RetailBookComponent } from '../user-portal/retail-book/retail-book.component';
import { RetailPromocodeComponent } from '../user-portal/retail-promocode/retail-promocode.component';
import { ConfirmRetailBookingComponent } from '../user-portal/confirm-retail-booking/confirm-retail-booking.component';
import { AddRetailComponent } from '../user-portal/add-retail/add-retail.component';
import { BucketBookingReviewComponent } from '../user-portal/bucket-booking-review/bucket-booking-review.component';
import { AddBucketBookingComponent } from '../user-portal/add-bucket-booking/add-bucket-booking.component';
import { RetailProfileComponent } from '../user-portal/retail-profile/retail-profile.component';
import { RetailBookReviewComponent } from '../user-portal/retail-book-review/retail-book-review.component';
import { OnlyLoggedInUsersGuard } from '../guards/onlyLoggedIn.guard';
import { AlwaysAuthGuard } from '../guards/alwaysAuth.guard';
import { RetailCheckoutComponentComponent } from '../user-portal/RetailCheckoutComponent/RetailCheckoutComponent.component'
import { CreateRetailBookingComponent } from '../user-portal/create-retail-booking/create-retail-booking.component';
import { CreateRetailBookingWithTagComponent } from '../user-portal/create-retail-booking-with-tag/create-retail-booking-with-tag.component';
import { RetailMultipleDropComponent } from '../user-portal/retail-multiple-drop/retail-multiple-drop.component';
import { RetailMultipleDropNoTagComponent } from '../user-portal/retail-multiple-drop-no-tag/retail-multiple-drop-no-tag.component';
import { RetailConfirmWalletBookingComponent } from '../home/retail-confirm-wallet-booking/retail-confirm-wallet-booking.component';
import { RetailRentalViewDetailsComponent } from '../user-portal/retail-rental-view-details/retail-rental-view-details.component';
import { RetailSelectPaymentNoTagComponent } from '../user-portal/retail-select-payment-no-tag/retail-select-payment-no-tag.component';
import {FlightBookingDetailsComponent} from '../user-portal/flight-booking-details/flight-booking-details.component';
import {TermsEliteComponent} from "../home/terms-elite/terms-elite.component";
import {PudoRetailTermsComponent} from '../user-portal/pudo-retail-terms/pudo-retail-terms.component';

export const FOURTH_ROUTES: Routes = [
    { path: '', component: HomeComponent },
    { path: 'retail-booking', component: RetailBookComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-typeRate', component: RetailTypeRateComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-promo', component: RetailPromocodeComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'confirm-retail-booking', component: ConfirmRetailBookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'add-retail', component: AddRetailComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'bucket-booking-review', component: BucketBookingReviewComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'add-bucket-booking', component: AddBucketBookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-profile', component: RetailProfileComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-checkout', component: RetailCheckoutComponentComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-book-review', component: RetailBookReviewComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'create-retail-book', component: CreateRetailBookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'create-retail-booking', component: CreateRetailBookingWithTagComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'multiple-drops', component: RetailMultipleDropComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'multiple-drop-booking', component: RetailMultipleDropNoTagComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'retail-successful-wallet-payment/:value/:type', component : RetailConfirmWalletBookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'view-details', component: RetailRentalViewDetailsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'multiple-drop-payment', component: RetailSelectPaymentNoTagComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'flight-booking-details', component: FlightBookingDetailsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
    { path: 'pudo-terms', component: PudoRetailTermsComponent },
];
