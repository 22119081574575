import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
declare var $: any;

@Component({
  selector: 'app-retail-confirm-wallet-booking',
  templateUrl: './retail-confirm-wallet-booking.component.html',
  styleUrls: ['./retail-confirm-wallet-booking.component.css']
})
export class RetailConfirmWalletBookingComponent implements OnInit {

  userToken: any;
  invoiceOption: boolean;
  invoiceControl: boolean = false;

  pickUpAddress: any;
  dropOffAddress: any;
  loader: boolean = false;
  addrressData: any;

  constructor(private bookingService: BookingService, private route: ActivatedRoute, private routing: Router) { }

  ngOnInit() {
    this.userToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.addrressData = JSON.parse(localStorage.getItem('bookingDataObject')).booking;
    // console.log(this.addrressData);
  }

  public attachInvoiceOrNot() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.invoiceControl = true;
    this.bookingService.invoiceRetailBucketCustomer(object.token, this.addrressData.trackNo).subscribe(
      (response) => {
        // console.log('success body hit inside attach invoice');
      }, (error) => {
        // console.log('Error inside the attach invoice block');
        console.log(error);
      }
    );
  }

  public finish() {
    this.attachInvoiceOrNot();
    $('#finishModal').modal('show');
  }

}
