import { Injectable, isDevMode } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class SharedService {
  username;
  totalAmt: number;
  public message = new Subject<string>();

  // UAT_SITE_KEY="6LedRlckAAAAAHQXQMtgNWNI60Od1iVZ5SliEKnO"
  // UAT_SECRETE_KEY="6LedRlckAAAAABPl_BFor6YXfGxprfumfXmbUjxn"

  // PROD_SITE_KEY="6LdrUlckAAAAAL1OKWxoJRlSmirhZ5-8hK-VvEzH"
  // PROD_SECRETE_KEY="6LdrUlckAAAAAFOPvW_7_8zuL5SnJbqQRJTVe-Y7"

  // OLD_KEY="6LeIuzcUAAAAADNy6QtwVgJTejll2FHlZqssTChF"

  serverUrl: string = isDevMode() ? 'https://droppergroup.co.za/droppa/services/' : 'https://oxy.droppa.co.za/droppa/services/';
  // If server certificate is fine: https://droppergroup.co.za/droppa/services/
  // If server certificate issue: http://88.99.94.84:8080/droppa/services/
  // http://54.77.32.220:8080/droppa/services/

  payFastUrl: string = isDevMode() ? 'https://sandbox.payfast.co.za/eng/process' : 'https://www.payfast.co.za/eng/process';

  ozowUrl = 'https://pay.ozow.com';

  SITE_KEY: string = isDevMode() ? '6LedRlckAAAAAHQXQMtgNWNI60Od1iVZ5SliEKnO' : '6LdrUlckAAAAAL1OKWxoJRlSmirhZ5-8hK-VvEzH';

  constructor(private http: HttpClient) {
    this.username = '';
    this.totalAmt = 0.0;
  }

  public getSiteKey(): string {
    return this.SITE_KEY;
  }

  public getOzowUrl(): string {
    return this.ozowUrl;
  }

  public setName(name: string) {
    this.username = name;
  }

  public setTotalAmount(price: number) {
    this.totalAmt = price;
  }

  public getPayFastUrl(): string {
    return this.payFastUrl;
  }

  public getUrl(): string {
    return this.serverUrl;
  }

  public getTotalAmt(): number {
    return this.totalAmt;
  }

  public getName(): string {
    return this.username;
  }

  // @dminS3cret
  setMessage(value: string) {
    this.message.next(value);
  }

  getMessage() {
    return this.message;
  }
}
