import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;
@Component({
  selector: 'app-custom-messages',
  templateUrl: './custom-messages.component.html',
  styleUrls: ['./custom-messages.component.css', '../admin-portal/admin-portal.component.css']
})
export class CustomMessagesComponent implements OnInit {

  content: any;
  condition: any;
  adminUser: any;
  sentMessage: any;
  retrieveMessage: any = [];
  message: any;
  findMessage: any;
  messageRetrieved: any;
  errorMessage: any;

  normalMssgTab=true
  alertMssgTab=false

  itemsPage=10
totalItems=15

  type
  title
  alrtmessage

  savedAlerts

  selectedViews

  @ViewChild('', { static: false }) searchForm: NgForm;

  constructor(private adminService: AdminService, private router: Router,private loaderService:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
   this.getAlerts();
   // this.searchForMessages();
  }

  public sendMessages() {
    const MessageData = {
      message: this.content,
      type: this.condition
    };

    this.adminService.sendMessage(this.adminUser.token, MessageData).subscribe(
      (response) => {
        this.sentMessage = response;
        this.content = '';
        this.message = 'Message saved successfully.';
      }, (error) => {
        this.message = 'Message not saved.';
      }
    );
  }

  public searchForMessages() {
    this.adminService.getMessages(this.adminUser.token, this.findMessage).subscribe(
      (response) => {
        this.messageRetrieved = response;
        this.retrieveMessage.push(this.messageRetrieved);
        // this.userData = res;
        // this.partner.push(this.userData);
      }, (error) => {
        this.errorMessage = 'Message not found';
      }
    );
  }

  public onSelectedMessage(messages) {
    localStorage.setItem('userInfo', JSON.stringify(this.adminUser));
    localStorage.setItem('customMessages', JSON.stringify(messages));
    this.router.navigate(['/update-custom-mesages']);
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
  public normalMssTabSwitch(){
    this.normalMssgTab=true
    this.alertMssgTab=false
  }
  public alertMssTabSwitch(){
    this.normalMssgTab=false
    this.alertMssgTab=true
  }
  public selectAlertViewers(views:[]){
    this.selectedViews=views
    $("#viewersModal").modal("show")
  }
  public deleteAlert(selectedAlert:any){
    this.loaderService.addloader()
    this.adminService.deleteAlert(this.adminUser.token,selectedAlert.oid).subscribe(res=>{
      this.getAlerts();
      this.loaderService.removeloader()
    },(error)=>{
      console.log(error);
      this.loaderService.removeloader()
    })
  }
  public getAlerts(){
    this.loaderService.addloader()
    this.adminService.getAlerts(this.adminUser.token).subscribe(res=>{
      this.savedAlerts=res.json()
      console.log(res.json());

      this.loaderService.removeloader()
    },(error)=>{
      console.log(error);
      this.loaderService.removeloader()
    })
  }
  public sendAlert(){
    const alert={
      "message":this.alrtmessage,
      "title":this.title,
      "type":this.type
    }
    this.loaderService.addloader()
    this.adminService.saveAlert(this.adminUser.token,alert)
    .subscribe(res=>{
      this.getAlerts();
      this.alrtmessage=null
      this.title=null
      this.type=null
      this.loaderService.removeloader()
    },(error)=>{
      console.log(error);
      this.loaderService.removeloader()
    })

  }

}
