import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filters'
})
export class FiltersPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any[] {
    if (!items) {
      return [];
    }
    if (!field || !value) {
      return items;
    }
    return items.filter(singleItem => {

      if (singleItem[field]) {
        return singleItem[field].toLowerCase().includes(value.toLowerCase());
      } else {
        return singleItem.owner[field].toLowerCase().includes(value.toLowerCase());
      }

    });
  }
}
