import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-wallet-successful-load',
  templateUrl: './wallet-successful-load.component.html',
  styleUrls: ['./wallet-successful-load.component.css']
})
export class WalletSuccessfulLoadComponent implements OnInit {

  user: any;
  sub: any;
  paymentOid: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
  }

  public redirect() {
    if (this.user.owner.retailId !== null) {
      this.router.navigateByUrl('/retail-profile');
    }
    else {
      this.router.navigateByUrl('/user');
    }
  }

}
