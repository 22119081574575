import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-view-rentals',
  templateUrl: './view-rentals.component.html',
  styleUrls: ['./view-rentals.component.css']
})
export class ViewRentalsComponent implements OnInit {

  dateStart: any;
  dateEnd: any;
  userObj: any;
  rentalObj: any;
  message: string = '';
  branchName: any;
  total: any;
  VAT: any;
  subTotal: any;
  numDays: any;
  loader: boolean = false;
  userName: any;

  constructor(private adminService: AdminService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.rentalObj = JSON.parse(localStorage.getItem('selectedRental'));
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.dateStart = moment(this.rentalObj.in).format('dddd, MMMM Do YYYY');
    this.dateEnd = moment(this.rentalObj.out).format('dddd, MMMM Do YYYY');
    this.getBranches();
    var pickUpDate = new Date(this.rentalObj.in);
    var dropOffDate = new Date(this.rentalObj.out);
    this.numDays = moment(dropOffDate).diff(moment(pickUpDate), 'days');
    if (this.numDays == 0) {
      this.numDays = this.numDays + 1;
    }
    this.userName = this.rentalObj.customer.firstName + " " + this.rentalObj.customer.surname;
  }

  public sendRentalBooking() {
    this.loadservice.addloader();
    this.adminService.pushEliteRental(this.userObj.token, this.rentalObj.oid).subscribe(response => {
      this.loadservice.removeloader();
      this.message = 'Rental has been authorised';
      $('#rentalModal').modal('show');
    }, error => {
      console.log(error);
      this.loadservice.removeloader();
      this.message = 'Could not authorise the rental.';
      $('#rentalModal').modal('show');
    }, () => {
      console.log('finally');
      this.loadservice.removeloader();
      $('#rentalModal').modal('show');
    }
    )
  }

  public getBranches() {
    this.loadservice.addloader();
    this.adminService.getEliteBranches().subscribe(response => {
      this.loadservice.removeloader();
      response.forEach(element => {
        if (element.oid == this.rentalObj.branchOid) {
          this.branchName = element.name;
        }
      });
    }, error => {
      this.loadservice.removeloader();
      console.log(error);
      console.log('error block');
    })
  }

  public backToRentals() {
    this.router.navigate(['/Rental-Elite']);
  }

}
