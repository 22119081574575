import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-all-express-bookings',
  templateUrl: './all-express-bookings.component.html',
  styleUrls: ['./all-express-bookings.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllExpressBookingsComponent implements OnInit {

  loader: boolean;

  allBookings: any;
  bookingData: any
  expressBookings: any = [];
  newBookings: any = [];
  adminUser: any;
  searchQueryStatus: string;
  searchUsers: any;

  status: string = 'status';
  platforms: string = 'platform';
  msg: any;

  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));

    this.pageNumber = localStorage.getItem('pageNumber') !== null ? Number(localStorage.getItem('pageNumber')) : 0;
    this.itemsPerPage = localStorage.getItem('itemsPerPage') !== null ? Number(localStorage.getItem('itemsPerPage')) : 25;

    this.getExpressBookings();
    window.scrollTo(0, 0);
  }

  public getExpressBookings() {
    this.loadservice.addloader();

    const filter = {
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

    //console.log(filter);

    // this.adminService.getExpressBookings(this.adminUser.token).subscribe(
    //   res => {
    //     this.expressBookings = res;
    //     this.totalNumberOfBookings = res.length;
    //     console.log(this.expressBookings);
    //     this.loadservice.removeloader();
    //   },
    //   err => {
    //     console.log(err);
    //     this.loadservice.removeloader();
    //     if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
    //       this.router.navigate(['/logon']);
    //     }
    //   }
    // );

    this.adminService.getExpressBookingsPagination(this.adminUser.token, filter).subscribe(
      res => {
        this.expressBookings = res.content;
        this.totalNumberOfBookings = res.totalElement;
        this.loadservice.removeloader();
      },
      err => {
        // console.log(err);
        this.loadservice.removeloader();
        if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
          this.router.navigate(['/logon']);
        }
      }
    );



    // The commented code below is used when the above fails merely to view a table
    /*this.adminService.getAllBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.r();
          this.expressBookings = res.reverse();
          this.expressBookings.filter((bookings) => bookings.vehicleType.includes('EXPRESS_COURIER'));
        // .filter((item: any) => item.status.toUpperCase().includes(this.searchQueryStatus.toUpperCase()));
          console.log(this.expressBookings);
        },
        (err) => {
          this.loadservice.r();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );*/
  }



  public onBooking(book) {
    localStorage.setItem('selectedBooking', JSON.stringify(book));
    localStorage.setItem('pageNumber', String(this.pageNumber));
    localStorage.setItem('itemsPerPage', String(this.itemsPerPage));
    this.router.navigate(['/express-booking']);
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getExpressBookings();
  }

  ngOnDestroy(){
    if(this.router.getCurrentNavigation().finalUrl.toString() !== '/express-booking'){
      localStorage.removeItem('pageNumber');
      localStorage.removeItem('itemsPerPage');
    }

  }

}
