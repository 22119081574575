import { Component, OnInit } from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {UserService} from '../../services/user.service';
import {LoaderService} from '../../services/loader.service';
import {SharedService} from "../../services/shared.service";
import {Router} from "@angular/router";
import {AdminService} from "../../services/admin.service";

declare var $: any;

@Component({
  selector: 'app-admin-pudo-individual',
  templateUrl: './admin-pudo-individual.component.html',
  styleUrls: ['./admin-pudo-individual.component.css', '../admin-portal/admin-portal.component.css']
})
export class AdminPudoIndividualComponent implements OnInit {

  adminUser: any;
  pudoBookings = [];
  tempPudoBookings = [];
  retailers = [];

  searchQuery = '';
  searchTrackNumber = '';
  pudoBookingBeingTracked: any;
  viewPudoTracking = false;

  pudoBookingBeingViewed: any;
  pudoBookingBeingViewedIndex = -1;
  viewPudoBookingDetails = false;

  showCompleteStatus = false;
  pudoFeedbackMsg = '';

  totalNumberOfBookings = 0;
  pageNumber = 0;
  itemsPerPage = 25;
  pageSizeOptions: number[] = [10, 25];

  waybillDownloadLink = '';

  pudoTrackNumberToCancel = '';
  position = -1;

  constructor(private adminService: AdminService, private bookingService: BookingService, private userService: UserService,
              private loader: LoaderService, private sharedServices: SharedService, private router: Router) {
    this.waybillDownloadLink = sharedServices.getUrl() + 'pudo/download/waybill/';
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getAllPudoBookings(1);
  }

  public getAllPudoBookings(searchType: number) {
    this.loader.addloader();

    if (searchType === 0) {
      this.resetPagination();
    }

    const filter = {
      trackNo: this.searchTrackNumber,
      pageNumber: this.pageNumber,
      pageTotalItems: this.itemsPerPage
    };

  //  console.log(filter);

    this.bookingService.getAllIndividualPudoBookings(this.adminUser.token, filter).subscribe(
      (response1: any) => {
        this.pudoBookings = response1.content;
        this.totalNumberOfBookings = response1.totalElement;
        this.tempPudoBookings = this.pudoBookings;

        this.loader.removeloader();
      },
      (error1) => {
        this.loader.removeloader();
        console.log(error1);
      }
    );
  }

  resetPagination() {
    this.pageNumber = 0;
    this.itemsPerPage = 25;
  }

  public completeBooking(transactionId) {
    this.bookingService.completePudoBooking(this.adminUser.token, transactionId).subscribe(
      (response1: any) => {
        this.showCompleteStatus = true;
        this.pudoBookings.splice(this.pudoBookingBeingViewedIndex, 1, response1);
        this.pudoFeedbackMsg = 'Booking marked as complete';
        $('#pudoRefresh').modal('show');

        this.loader.removeloader();
      },
      (error1) => {
        console.log(error1);
        this.loader.removeloader();
      }
    );
  }

  // public searchBookings() {
  //   // this.pudoBookings = this.tempPudoBookings;
  //   //
  //   // if (this.searchTrackNumber.trim() !== '') {
  //   //   this.pudoBookings = this.pudoBookings.filter(
  //   //     (item: any) => item.transactionId.toUpperCase().includes(this.searchTrackNumber.toUpperCase())
  //   //   );
  //   // }
  //   this.getAllPudoBookings();
  // }

  public showPudoTracking(booking) {
    this.pudoBookingBeingTracked = booking;
    this.viewPudoTracking = true;
  }

  public showPudoTable() {
    window.scrollTo(0, 0);
    this.pudoBookingBeingTracked = {};
    this.viewPudoTracking = false;
    this.viewPudoBookingDetails = false;
    this.showCompleteStatus = false;
  }

  public refreshPudoBooking(pudoBooking, index) {
    if (pudoBooking.status === 'COMPLETE') {
      return;
    }
    this.loader.addloader();
    if (pudoBooking.wayBillNumber) {
      this.bookingService.getPudoIndividualBookingEvents(this.adminUser.token, pudoBooking.wayBillNumber).subscribe(
        (response1) => {
          // console.log(response1);
          this.pudoBookings.splice(index, 1, response1);
          this.loader.removeloader();
        },
        (error1) => {
          console.log(error1);
          this.pudoFeedbackMsg = error1.error.message;
          this.loader.removeloader();
          $('#pudoRefresh').modal('show');
        }
      );
    } else {
      this.bookingService.getPudoBookingWaybill(this.adminUser.token, pudoBooking.transactionId).subscribe(
        (response1) => {
          // console.log(response1);
          this.pudoBookings.splice(index, 1, response1);
          this.loader.removeloader();
        },
        (error1) => {
          console.log(error1);
          this.pudoFeedbackMsg = error1.error.message;
          this.loader.removeloader();
          $('#pudoRefresh').modal('show');
        }
      );
    }
  }

  public showPudoDetails(booking, index) {
    this.pudoBookingBeingViewed = booking;
    this.pudoBookingBeingViewedIndex = index;
    this.viewPudoBookingDetails = true;
  }

  public setPudoBookingToCancel(transactionId, index) {
    this.pudoTrackNumberToCancel = transactionId;
    this.position = index;
  }

  public cancelPudoBooking() {
    this.loader.addloader();
    this.bookingService.cancelPudoBooking(this.adminUser.token, this.pudoTrackNumberToCancel, ).subscribe(
      (resp) => {
        this.getAllPudoBookings(1);
      },
      (error1) => {
        console.log(error1);
        this.pudoFeedbackMsg = error1.error.message;
        this.loader.removeloader();
        $('#pudoRefresh').modal('show');
      }
    );
  }

  getNewData(pageEvent?: any) {
    this.pageNumber = pageEvent.pageIndex;
    this.itemsPerPage = pageEvent.pageSize;
    this.getAllPudoBookings(1);
  }

  public viewUser() {
    this.loader.addloader();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getUserDetails(object.token, this.pudoBookingBeingViewed.customerEmail).subscribe(
      response => {
        this.loader.removeloader();
        localStorage.setItem('selectedUser', JSON.stringify(response));
        this.router.navigateByUrl('/view-user');
      }, error => {
        console.log(error);
        this.loader.removeloader();
      }
    );
  }

}
