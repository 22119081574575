import { Routes, RouterModule } from '@angular/router';
import { OnlyLoggedInUsersGuard } from '../guards/onlyLoggedIn.guard';
import { AlwaysAuthGuard } from '../guards/alwaysAuth.guard';
import { HomeComponent } from '../home/home.component';
import { AdminPortalComponent } from '../admin/admin-portal/admin-portal.component';
import { EditPartnerComponent } from '../admin/edit-partner/edit-partner.component';
import { ViewUserComponent } from '../admin/view-user/view-user.component';
import { ViewPromoCodeComponent } from '../admin/view-promo-code/view-promo-code.component';
import { RequestViewComponent } from '../admin/request-view/request-view.component';
import { InvoiceAdminComponent } from '../admin/invoice-admin/invoice-admin.component';
import { QuoteAdminComponent } from '../admin/quote-admin/quote-admin.component';
import { VehicleRateComponent } from '../admin/vehicle-rate/vehicle-rate.component';
import { ViewBoookingComponent } from '../admin/view-boooking/view-boooking.component';
import { UpdateVehicleComponent } from '../admin/update-vehicle/update-vehicle.component';
import { CompanyBankComponent } from '../admin/company-bank/company-bank.component';
import { ViewDriverComponent } from '../admin/view-driver/view-driver.component';
import { BankAccountsComponent } from '../admin/bank-accounts/bank-accounts.component';
import { DriverBookingsComponent } from '../admin/driver-bookings/driver-bookings.component';
import { BookingPaymentComponent } from '../admin/booking-payment/booking-payment.component';
import { RetailViewComponent } from '../admin/retail-view/retail-view.component';
import { ViewRetailBookingsComponent } from '../admin/view-retail-bookings/view-retail-bookings.component';
import { RetailsPaymentComponent } from '../admin/retails-payment/retails-payment.component';
import { BundleBookingsComponent } from '../admin/bundle-bookings/bundle-bookings.component';
import { ViewSearchedUserComponent } from '../admin/view-searched-user/view-searched-user.component';
import { SendBulkSmsComponent } from '../admin/send-bulk-sms/send-bulk-sms.component';
import { ViewVouchersComponent } from '../admin/view-vouchers/view-vouchers.component';
import { ViewBucketBookingsComponent } from '../admin/view-bucket-bookings/view-bucket-bookings.component';
import { UpdatedriverappComponent } from '../admin/updatedriverapp/updatedriverapp.component';
import { CustomMessagesComponent } from '../admin/custom-messages/custom-messages.component';
import { UpdateCustomMessagesComponent } from '../admin/update-custom-messages/update-custom-messages.component';
import { DriverTrackingComponent } from '../admin/driver-tracking/driver-tracking.component';
import { RetailInvoicesComponent } from '../admin/retail-invoices/retail-invoices.component';
import { RetailInvoiceReviewComponent } from '../admin/retail-invoice-review/retail-invoice-review.component';
import { APIDevelopersPageComponent } from '../admin/api-developers-page/api-developers-page.component';
import { DriverRatingsComponent } from '../admin/driver-ratings/driver-ratings.component';
import { ViewApiUserComponent } from '../admin/view-api-user/view-api-user.component';
import { AllBookingsComponent } from '../admin/all-bookings/all-bookings.component';
import { AllUsersComponent } from '../admin/all-users/all-users.component';
import { AllBucketBookingsComponent } from '../admin/all-bucket-bookings/all-bucket-bookings.component';
import { AllRetailsComponent } from '../admin/all-retails/all-retails.component';
import { AllVehiclesComponent } from '../admin/all-vehicles/all-vehicles.component';
import { AllDriversComponent } from '../admin/all-drivers/all-drivers.component';
import { DroppaStatsComponent } from '../admin/droppa-stats/droppa-stats.component';
import { AllExpressBookingsComponent } from '../admin/all-express-bookings/all-express-bookings.component';
import { ViewExpressBookingComponent } from '../admin/view-express-booking/view-express-booking.component';
import { WarehousesComponent } from '../admin/warehouses/warehouses.component';
import { WalletsComponent } from '../admin/wallets/wallets.component';
import { ViewWaybillStatusesComponent } from '../admin/view-waybill-statuses/view-waybill-statuses.component';
import { ViewWalletTransactionsComponent } from '../admin/view-wallet-transactions/view-wallet-transactions.component';
import { DumpsComponent } from '../admin/dumps/dumps.component';
import { OutstandingRetailInvoicesComponent } from '../admin/outstanding-retail-invoices/outstanding-retail-invoices.component';
import { ViewOutstandingRetailBookingInvoicesComponent } from '../admin/view-outstanding-retail-booking-invoices/view-outstanding-retail-booking-invoices.component';
import { AllRentalsComponent } from '../admin/all-rentals/all-rentals.component';
import { ViewRentalsComponent } from '../admin/view-rentals/view-rentals.component';
import { VodacomPromoCodeComponent } from '../admin/vodacom-promo-code/vodacom-promo-code.component';
import { WebHooksComponent } from '../admin/web-hooks/web-hooks.component';
import { WebHookDetailsComponent } from '../admin/web-hook-details/web-hook-details.component';
import { UploadCsvComponent } from '../admin/upload-csv/upload-csv.component';
import { PaymentGatewayPageComponent } from '../admin/payment-gateway-page/payment-gateway-page.component';
import { AdminActivitiesComponent } from '../admin/admin-activities/admin-activities.component';
import { OperationsComponent } from '../admin/operations/operations.component';
import { OldSpreadsheetComponent } from '../admin/old-spreadsheet/old-spreadsheet.component';
import { ViewSpreadsheetComponent } from '../admin/view-spreadsheet/view-spreadsheet.component';
import { BusinessReportsComponent } from '../admin/business-reports/business-reports.component';
import { MarketingComponent } from '../admin/marketing/marketing.component';
import { CreateAdvertComponent } from '../admin/create-advert/create-advert.component';
import { ViewAdvertDetailsComponent } from '../admin/view-advert-details/view-advert-details.component';
import { EditAdvertComponent } from '../admin/edit-advert/edit-advert.component';
import { AdminSearchResutsComponent } from '../admin/admin-search-resuts/admin-search-resuts.component';
import { AllPartnersComponent } from '../admin/all-partners/all-partners.component';
import { ViewPartnerComponent } from '../admin/view-partner/view-partner.component';
import { ConfigureSkynetRateComponent } from '../admin/configure-skynet-rate/configure-skynet-rate.component';
import {SkynetPortalComponent} from '../admin/skynet-portal/skynet-portal.component';
import {UberBookingsComponent} from "../admin/uber-bookings/uber-bookings.component";
import {UberBookingDetailsComponent} from "../admin/uber-booking-details/uber-booking-details.component";
import {AdminOwnerFinancialComponent} from '../admin/admin-owner-financial/admin-owner-financial.component';
import {DroppaContractOwnersComponent} from "../admin/droppa-contract-owners/droppa-contract-owners.component";
import {DetailedContractOwnerComponent} from "../admin/detailed-contract-owner/detailed-contract-owner.component";
import {PudoBookingsComponent} from "../admin/pudo-bookings/pudo-bookings.component";
import {InsuredBookingsComponent} from "../admin/insured-bookings/insured-bookings.component";
import {AdminPudoIndividualComponent} from "../admin/admin-pudo-individual/admin-pudo-individual.component";
import {AdminIntegratedCompanyComponent} from "../admin/admin-integrated-company/admin-integrated-company.component";
import {WhitelistingComponent} from "../admin/whitelisting/whitelisting.component";
import {DroppaContractOwnersComponentApi} from "../admin/droppa-contract-owners-api/droppa-contract-owners.component-api";
import {DroppaContractOwnersDetailsApiComponent} from "../admin/droppa-contract-owners-details-api/droppa-contract-owners-details-api.component";
import {DeliveryOptionsComponent} from '../admin/delivery-options/delivery-options.component';

export const THIRD_ROUTES: Routes = [
  { path : '', component : HomeComponent},
  { path : 'admin-home', component : AdminPortalComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'edit-partner', component : EditPartnerComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-user', component : ViewUserComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'promo-codes', component : ViewPromoCodeComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'requests', component : RequestViewComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'invoice-admin', component : InvoiceAdminComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'quote-admin', component : QuoteAdminComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'vehicle-rate', component : VehicleRateComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-booking', component : ViewBoookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'update-vehicle', component : UpdateVehicleComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'company-bank', component : CompanyBankComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-driver', component : ViewDriverComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'bank-account', component : BankAccountsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'driver-bookings', component : DriverBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'bookings-payment', component : BookingPaymentComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'retail-review', component : RetailViewComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-retail-bookings', component : ViewRetailBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'retails-payment', component : RetailsPaymentComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'bundle-bookings', component : BundleBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-searcher-user', component : ViewSearchedUserComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'send-bulk-sms', component : SendBulkSmsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-vouchers', component: ViewVouchersComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-bucket-bookings', component: ViewBucketBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'update-driver-app', component: UpdatedriverappComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'custom-messages', component: CustomMessagesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'update-custom-mesages', component: UpdateCustomMessagesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'driver-tracking', component: DriverTrackingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'retail-invoices', component: RetailInvoicesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'retail-invoices-review', component: RetailInvoiceReviewComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'developers-sight', component: APIDevelopersPageComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'drivers-rating', component: DriverRatingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-ApiUser', component: ViewApiUserComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-bookings', component: AllBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-bucket-bookings', component: AllBucketBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-users', component: AllUsersComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-retails', component: AllRetailsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-drivers', component: AllDriversComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-vehicles', component: AllVehiclesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'droppa-Stats', component: DroppaStatsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-express-bookings', component: AllExpressBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'express-booking', component: ViewExpressBookingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'points-of-interest', component: WarehousesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'transactions', component: WalletsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'waybill-statuses', component: ViewWaybillStatusesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'user-wallet-details', component: ViewWalletTransactionsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'dumps', component: DumpsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'outstanding-retail-invoices', component: OutstandingRetailInvoicesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'view-outstanding-retail-invoices', component: ViewOutstandingRetailBookingInvoicesComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'Rental-Elite', component: AllRentalsComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'view-rentals', component: ViewRentalsComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'vodacom-promo', component: VodacomPromoCodeComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'web-hooks', component: WebHooksComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'web-hook-events', component: WebHookDetailsComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'csv-bookings', component: UploadCsvComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'payment-gateway', component: PaymentGatewayPageComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'admin-activities', component: AdminActivitiesComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'operations', component: OperationsComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'old-spreadsheets', component : OldSpreadsheetComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-spreadsheet', component : ViewSpreadsheetComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'business-reports', component : BusinessReportsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'marketing', component: MarketingComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard] },
  { path : 'create-advert', component: CreateAdvertComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-advert-details', component: ViewAdvertDetailsComponent, canActivate : [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'edit-advert', component: EditAdvertComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'admin-search-resuts', component: AdminSearchResutsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-partners', component: AllPartnersComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'view-partner', component: ViewPartnerComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'skynet-portal', component: SkynetPortalComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'admin-owner-financial', component: AdminOwnerFinancialComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'uber-bookings', component: UberBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'uber-booking-details', component: UberBookingDetailsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'admin-contract-owner', component: DroppaContractOwnersComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'detailed-contract-owner/:ownerId', component: DetailedContractOwnerComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
// { path : 'configure-skynet-rates', component: ConfigureSkynetRateComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'admin-pudo-bookings', component: PudoBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path : 'all-insured-bookings', component: InsuredBookingsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path: 'individual-pudo-bookings', component: AdminPudoIndividualComponent , canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path: 'integrated-company', component: AdminIntegratedCompanyComponent , canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  {path: 'whitelisting', component: WhitelistingComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path: 'admin-contract-owner-api', component: DroppaContractOwnersComponentApi, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path: 'admin-contract-owner-details-api/:vehicleReg', component: DroppaContractOwnersDetailsApiComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard]},
  { path: 'delivery-options', component: DeliveryOptionsComponent, canActivate: [OnlyLoggedInUsersGuard, AlwaysAuthGuard] }
];
