import { Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { NgForm } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-retail-view',
  templateUrl: './retail-view.component.html',
  styleUrls: ['./retail-view.component.css']
})

export class RetailViewComponent implements OnInit {
   errorMessage: any;
   constructor(private adminService: AdminService, private service: UserService, private route: Router, private loadservice: LoaderService) {}

  @ViewChild('typeRateForm', { static: false }) addVForm: NgForm;
  @ViewChild('typeRateUpdateForm', { static: false }) updateVForm: NgForm;
  @ViewChild('statementForm', { static: false }) mainStatementForm: NgForm;
  @ViewChild('invoiceForm', { static: false }) mainInvoiceForm: NgForm;
  @ViewChild('vatForm', { static: false }) addVatForm: NgForm;
  @ViewChild('csvReader', { static: false }) csvReader: any;
  @ViewChild('branchForm', { static: false}) branchForm: NgForm;

  permittedOid = '5ca1c05f2874342e58d3d933';
  retail: any = {};
  user: any = {};
  adminUser: any = {};
  message = '';

  vehicleType: string;
  vihicleName: string;
  kilometres: number;
  capacity: number;
  basePrice: number;
  standardRate: number;
  radius: number;
  description: string;
  returnAmt: number;

  updateKilometres: number;
  vehicleTypeOid: string;
  updateBasePrice: number;
  UpdateDescription: string;
  updateCapacity: number;
  textMessage = '';
  updatedTypeRate: any;
  updatedVihicleType: string;
  updateVihicleName: string;
  updateStandardRate: number;
  updateRadius: number;
  updateReturnAmount: number;
  name: string;
  loader = false;

  branchPage = 1;

  total: number;
  page = 1;

  totalItems: number;
  itemsPage = 1;

  totalNmber: number;
  pageNumber = 1;

  driverPage = 1;
  totalDrivers: number;

  isBank: any;
  ownerMobile: any;
  assignStatus: any;
  driverMobile: any;
  err = '';
  retailer: any;
  valid = false;
  validManager = false;
  foundDriver: any;
  idNo: string;
  vehicleOid: string;
  drivers: any = {};
  driverOid: any;
  vehicles: any = [];
  driver: any = {};
  history: any = {};
  retailDrivers = [];
  retailHistory = [];
  confirmDelete: any;
  userMobile: string;
  invoices: string;
  messege: string;
  activeMessege: string;
  suspendMessege: string;

  bookingDate: any;
  retailUser = [];
  statementMsg: any;
  bookingStartDate: any;
  bookingEndDate: any;

  statementStartDate: any;
  statementEndDate: any;

  mobile: string;
  vatMessage: string;
  vatNumber: any;

  setBookingDate: any;
  messageAllInvoices: any;
  selectedTabID: any;
  skynet: any = 'SKYNET';
  canInvoice: any;
  canDims = true;

  enteredPickUpAddress = '';
  enteredPickUpSuburb = '';
  enteredPickUpPostalCode = '';
  enteredCity = '';
  successMsg: any;
  retailMsg = '';
  accountManagerDetails: any;

  csvArr: any = [];
  public records: any[] = [];
  disableUploadButton = false;
  type: any;
  ifFleetVehicles = false;
  ifRentalVehicles = false;
  getVehicleTypeRates: any = [];
  updateType: any;

  retailBranches: any = [];
  removeBranchMsg = '';

  startDate: any;
  endDate: any;
  foundUser: any;
  errorMsg = '';
  userAssigned = false;
  branchName = '';
  branchPhoneNum = '';
  branchEmail = '';
  branchMsg = '';
  canAddParcels: any;
  canAddParcelsMsg = '';

  permissions = false;
  typeRatesOid: any;
  VehicleToRemove: any;
  resMsg: any;
  errMsg: any;
  removedSuccess = false;
  personToEmail = 'c7955a664176888deeaa617ca3bad85d616de2a4';

  title = 'Bookings';
  showBookings = false;
  showUsers = false;
  showInvoice = false;
  showUploadCSV = false;
  showUploadCSVQuotes = false;
  public recordsQuotes: any[] = [];
  showBranches = false;
  showReports = false;
  showPromo = false;
  showDrivers = false;
  showVehicles = false;

  promoValid = true;
  promoStartDate: any;
  promoEndDate: any;
  promoCode = '';
  promoType = 'COURIER';
  targetAmount: string = null;
  hasTarget = false;
  promoAmount = '';
  promoCount = '';
  promoStatus = 'INACTIVE';
  promoAllowed = false;

  // hasPromo = false;
  enablePromoEdit = false;

  // samplePromo: any;

  feedback = '';

  backNavi = '';

  paymentTypes = [
    'ONLINE_PAY',
    'WALLET_PAY',
    'INVOICE'
  ];
  paymetype = 'INVOICE';

  paymentTermTypes = [
    {
      name: 'monthly',
      type: 'MONTHLY_TERM'
    },
    {
      name: 'weekly',
      type: 'WEEKLY_TERM'
    },
    {
      name: 'daily',
      type: 'DAILY_TERM'
    }
  ];

  courierProviders = [
    {
      name: 'Skynet',
      providerName: 'SKYNET'
    },
    {
      name: 'Courier Guy',
      providerName: 'COURIER-GUY'
    },
    {
      name: 'DSV',
      providerName: 'DSV'
    }
  ];

  newRate = false;

  highPriority = false;
  superUserRole = false;
   showDiscountRates: boolean;
   accountDiscountRate: any;
   selectedDiscount: any = '';

  assignDriverMsg = 'Enable assign own driver';
  canAssignOwnDriver = true;



  errorReadingCSV = '';
  fileRecordsMax = 50;
  isValid = false;

  noReport = '';

  retailPromotions: any = [];

  ownerOpened = false;
  companyOpened = false;
  selectCourierProvider = '';

  ngOnInit() {

    this.retail = JSON.parse(localStorage.getItem('selectedRetail'));
    this.getPriority();
    this.newRate = this.retail.circleRouteRate;
    // console.log(this.retail);
    this.backNavi = JSON.parse(localStorage.getItem('retailFromAllBucketTable'));
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminUser.roles.forEach(element => {
      if (element.code === 'super_admin') {
        this.superUserRole = true;
      }
    });
    this.permissions = this.permittedOid === this.adminUser.oid;
    this.getRetailManager();
    this.driver = JSON.parse(localStorage.getItem('selectedDriver'));
    this.user = this.retail.owner;
    this.canInvoice = this.retail.canInvoice;
    this.canAddParcels = this.retail.canAddParcels;
    this.viewBranches();
    if (!this.canInvoice) {
      this.retailMsg = 'Assign retail pay';
    } else {
      this.retailMsg = 'Remove retail pay';
    }

    if (!this.canAddParcels) {
      this.canAddParcelsMsg = 'Allow items';
    } else {
      this.canAddParcelsMsg = 'Remove items';
    }

    this.onGetRetailHistory();
    this.showBookings = true;
    this.getDiscountRates();
  }

  getPriority() {
    if (JSON.parse(localStorage.getItem('selectedRetail')) !== null) {
      this.highPriority = JSON.parse(localStorage.getItem('selectedRetail')).priority;
    }
  }

  changePriority() {
    const changePriorityData = {
      priority: !this.highPriority,
      retail_id: this.retail.oid
    };
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.changeRetailPriority(this.adminUser.token, changePriorityData)
      .subscribe((response: any) => {
        this.retail.priority = response.priority;
        localStorage.setItem('selectedRetail', JSON.stringify(response));
        this.getPriority();
      }, (error) => {
      });
  }

  public selectedVehicleToRemove(vehicle: any) {
    this.removedSuccess = false;
    this.VehicleToRemove = vehicle;
  }

  public removeVehicleByOid() {

    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.errMsg = '';
    this.resMsg = '';
    const data = {
      oldtyperate: this.VehicleToRemove.oid,
      newtyperate: this.typeRatesOid,
      retailOid: this.retail.oid
    };
    this.adminService.removeTypeRate(this.adminUser.token, data).subscribe(
      response => {
        localStorage.setItem('selectedRetail', JSON.stringify(response.json()));
        this.retail = response.json();
        this.enteredPickUpAddress = '';
        this.enteredPickUpSuburb = '';
        this.enteredCity = '';
        this.enteredPickUpPostalCode = '';
        this.removedSuccess = true;
        this.resMsg = 'Rate successfuly updated';
        this.getTypeRates();
      }, error => {
        console.log(error);
        this.errMsg = 'An error occured while performing this oparation. Please varify : Vehicle types is th same, Typerate is avalible and Typerate belong to the retail.';
      }, () => {

      }
    );
  }

  public getTypeRates() {
    this.title = 'Vehicles';
    this.showVehicles = true;
    this.showBookings = false;
    this.showUsers = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    this.getVehicleTypeRates = this.retail.typeRates;
  }

  public setRetailData() {
    this.retail = JSON.parse(localStorage.getItem('selectedRetail'));
    this.canInvoice = this.retail.canInvoice;
    if (!this.canInvoice) {
      this.retailMsg = 'Assign Retail Pay';
    } else {
      this.retailMsg = 'Remove Retail Pay';
    }
  }

  public enterManualAddress() {
    $('#addressPopUp').modal('show');
  }

  public captureEnteredAddress() {

    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    const addressBody = {
      addressLine1: this.enteredPickUpAddress,
      locality: this.enteredPickUpSuburb,
      city: this.enteredCity,
      postalCode: this.enteredPickUpPostalCode
    };
    this.adminService.captureAddressOnRetail(this.adminUser.token, this.retail.oid, addressBody).subscribe(
      response => {
        localStorage.setItem('selectedRetail', JSON.stringify(response.json()));
        this.retail = response.json();
        this.enteredPickUpAddress = '';
        this.enteredPickUpSuburb = '';
        this.enteredCity = '';
        this.enteredPickUpPostalCode = '';
        this.successMsg = 'Address added successfully';
      }, error => {
        console.log(error);
        this.successMsg = 'Error';
      }, () => {

      }
    );
  }

  public moveToPartner() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.changeFromRetailToParter(this.adminUser.token, this.retail.owner.oid).subscribe(
      response => {
        $('#movePartnerModal').modal('show');
        this.message = 'Retail successfully removed.';
      }, error => {
        console.log(error);
        $('#movePartnerModal').modal('show');
        this.message = 'Error.';
      }
    );
  }

  public authDimensionsTag() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    const bodyTag = {
      retailOid: this.retail.oid,
      canInvoice: this.canDims
    };
    this.loadservice.addloader();
    this.adminService.retailItemsTag(this.adminUser.token, bodyTag).subscribe(
      response => {
        // console.log(response);
        // $('#resultsDim').modal('show');
        // console.log(response.canAddParcels);
        this.canAddParcels = response.canAddParcels;
        if (response.canAddParcels) {
          this.message = 'Items tag successfully removed.';
        } else {
          this.message = 'Items tag successfully assigned.';
        }
        this.loadservice.removeloader();
      }, error => {
        // console.log(error);
        $('#resultsDim').modal('show');
        this.message = 'There is an error while assigning a tag.';
        this.loadservice.removeloader();
      }
    );
  }

  public assignRetailInvoiceTag() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.canInvoice = !this.canInvoice;
    const bodyTag = {
      retailOid: this.retail.oid,
      canInvoice: this.canInvoice
    };
    this.adminService.retailTag(this.adminUser.token, bodyTag).subscribe(
      response => {
        localStorage.setItem('selectedRetail', JSON.stringify(response));
        this.setRetailData();
        $('#results').modal('show');
        if (response.canInvoice) {
          this.message = 'Retail invoice tag successfully assigned.';
        } else {
          this.message = 'Retail invoice tag successfully removed.';
        }
      }, error => {
        console.log(error);
        $('#results').modal('show');
        this.message = 'There is an error while assigning a tag.';
      }
    );
  }

  public canAssignDriver() {
    this.adminService.canAssignOwnDriver(this.adminUser.token, this.retail.oid).subscribe(
      (response: any) => {
        console.log(response.canAssignDriver);
        // const resp = response;
        if (response.canAssignDriver) {
          this.assignDriverMsg = 'Disable assign own driver';
          this.canAssignOwnDriver = false;
        } else {
          this.assignDriverMsg = 'Enable assign own driver';
          this.canAssignOwnDriver = true;
        }
      }, (error) => {
        // Do nothing
      }
    );
  }
  public assignRetailSkyNetTag() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    const skynetRetailBody = {
      targetID: this.retail.oid,
      tag: this.skynet
    };
    this.adminService.skynetRetail(this.adminUser.token, skynetRetailBody).subscribe(
      response => {
        $('#results').modal('show');
        this.message = 'Retail successfully assigned SkyNet tag.';
      }, error => {
        console.log(error);
        $('#results').modal('show');
        this.message = 'Error occured. Was not able to assign a tag.';
      }, () => {

      }
    );
  }

  public activateRetail() {
    $('#assignAccountManager').modal('show');
  }

  public activate() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.activateRetail(this.adminUser.token, this.retail.oid)
      .subscribe((response: Response) => {
        this.retail = response;
        localStorage.setItem('selectedRetail', JSON.stringify(this.retail));
        $('#activateUser').modal('show');
        this.activeMessege = 'Retail successfully activated.';
      }, (error) => {
        $('#activateUser').modal('show');
        this.activeMessege = 'Retail not activated.';
      });
  }

  public suspendRetail() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.suspendRetail(this.adminUser.token, this.retail.oid)
      .subscribe((response: Response) => {
        this.retail = response;
        localStorage.setItem('selectedRetail', JSON.stringify(this.retail));
        $('#suspendUser').modal('show');
        this.suspendMessege = 'Retail successfully suspended.';
      }, (error) => {
        $('#suspendUser').modal('show');
        this.suspendMessege = 'Retail not suspended.';
      });
  }

  public copyReatilBookings() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.copyBookings(this.adminUser.token, this.mobile, this.retail.oid)
      .subscribe((response: Response) => {
        $('#activateUser').modal('show');
        this.messege = 'Retail bookings successfully copied';
      }, (error) => {
        $('#activateUser').modal('show');
        this.messege = 'Retail bookings Not copied';
      }
      );
  }



  public onGetRetailDrivers() {
    this.title = 'Drivers';
    this.showDrivers = true;
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getretailDrivers(this.adminUser.token, this.retail.oid)
      .subscribe(
        (res) => {
          this.retailDrivers = res;
        }, (err) => {
        }, () => {
        }
      );
  }

  public onGetRetailHistory() {
    this.loadservice.addloader();
    this.title = 'Bookings';
    this.showBookings = true;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getRetailHistory(this.adminUser.token, this.retail.oid)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.retailHistory = res.reverse();
          // console.log(this.retailHistory);
        }, (err) => {
          this.loadservice.removeloader();
          console.log(err);
        }, () => {
        }
      );
  }

  public viewRetailBookings(bookings) {
    localStorage.setItem('bookings', JSON.stringify(bookings));
    this.route.navigateByUrl('/view-bucket-bookings');
  }

  public deleteRetailDriver(driverData) {
    const admin = JSON.parse(localStorage.getItem('userInfo'));
    const deleteData = {
      retailOid: this.retail.oid,
      driverOid: this.confirmDelete.oid
    };

    this.adminService.deleteAccount(admin.token, deleteData).subscribe(
      (res) => {
      }, (error) => {
      }, () => {

      }
    );
  }

  public onShowConfirmDel(driverData) {
    this.confirmDelete = driverData;
    $('#confirmDelete').modal('show');
    this.message = 'Are you sure?';
  }

  public selectedVehicle(type) {
    type = this.type;
    if (type == 'FLEET') {
      this.ifFleetVehicles = true;
      this.ifRentalVehicles = false;
    } else if (type == 'RENTAL') {
      this.ifFleetVehicles = false;
      this.ifRentalVehicles = true;
    }
  }

  public addVehicleType() {
    this.textMessage = '';
    const typeRate = {
      vehicleType: this.vehicleType,
      kilometer: this.kilometres,
      basePrice: this.basePrice,
      capacity: 0,
      ownerOid: this.retail.oid,
      description: this.description,
      name: this.vihicleName,
      standardRate: this.standardRate,
      radius: this.radius,
      returnAmt: this.returnAmt,
      type: this.type
    };
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.addTypeRate(object.token, typeRate).subscribe(
      (data) => {
        this.retail = data;
        this.addVForm.reset();
        this.message = 'Vehicle Type rate successfully added.';
        this.getTypeRates();
      },
      (error) => {
        console.log(error);
        this.message = error.error.message;
        this.addVForm.reset();
      }
    );
  }

  public getRetails() {
    const admin = JSON.parse(localStorage.getItem('userInfo'));
    this.service.retriveRetail(admin.token, this.retail.owner.retailId).subscribe(
      (res) => {
        this.retail = res.json();
        localStorage.setItem('selectedRetail', JSON.stringify(this.retail));
      }
    );
  }

  public sendInvoice() {
    const admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.sendRetailInvoice(admin.token, this.retail.oid).subscribe(
      (res) => {
        this.invoices = res;
        this.assignStatus = 'Invoice successfuly sent!';
        $('#invoiceSent').modal('show');
      }, (error) => {
        this.assignStatus = (this.assignStatus);
      }
    );
  }

  public sendInvoiceByDate() {
    const currentDate = new Date(this.bookingStartDate);
    const date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    const monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    const hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    const minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    const second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    const dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
    this.bookingStartDate = dropTime;

    const currentDate2 = new Date(this.bookingEndDate);
    const date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    const monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    const hour2 = currentDate2.getHours() <= 9 ? '0' + currentDate2.getHours() : currentDate2.getHours();
    const minutes2 = currentDate2.getMinutes() <= 9 ? '0' + currentDate2.getMinutes() : currentDate2.getMinutes();
    const second2 = currentDate2.getSeconds() <= 9 ? '0' + currentDate2.getSeconds() : currentDate2.getSeconds();
    const dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + hour2 + ':' + minutes2 + ':' + second2;
    this.bookingEndDate = dropTime2;

    const admin = JSON.parse(localStorage.getItem('userInfo'));

    const retailDate = {
      retailOid: this.retail.oid,
      startDate: this.bookingStartDate,
      endDate: this.bookingEndDate
    };

    this.adminService.sendRetailInvoiceByDate(admin.token, retailDate).subscribe(
      (response) => {
        this.invoices = response;
        this.assignStatus = 'Invoice successfuly sent!';
        $('#invoiceDateSent').modal('show');
      }, (error) => {
        console.log(error);
        this.assignStatus = 'Error';
        $('#invoiceDateSent').modal('show');
      }
    );
  }

  public getVehicleRate(rate) {
    this.textMessage = '';
    this.addVForm.reset();
    this.vehicleTypeOid = rate.oid;
    this.updatedVihicleType = rate.vehicleType;
    this.updateKilometres = rate.kilometer;
    this.updateBasePrice = rate.basePrice;
    this.updateCapacity = rate.capacity;
    this.UpdateDescription = rate.description;
    this.updateVihicleName = rate.name;
    this.updateStandardRate = rate.standardRate;
    this.updateRadius = rate.radius;
    this.retail.oid = rate.ownerOid;
    this.returnAmt = rate.returnAmt;
    this.updatedTypeRate = rate;
    this.updateType = rate.type;
  }

  public updateVehicleType() {
    this.textMessage = '';
    this.updatedTypeRate.kilometer = this.updateKilometres;
    this.updatedTypeRate.basePrice = this.updateBasePrice;
    this.updatedTypeRate.standardRate = this.updateStandardRate;
    this.updatedTypeRate.radius = this.updateRadius;
    this.updatedTypeRate.returnAmt = this.updateReturnAmount;
    this.updatedTypeRate.type = this.updateType;
    const object = JSON.parse(localStorage.getItem('userInfo'));
    // console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
    // console.log(this.updatedTypeRate)
    // console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++")
    // return
    this.service.updateTypeRate(object.token, this.updatedTypeRate).subscribe(
      (data) => {
        this.updateVForm.reset();
        this.message = 'Vehicle Type rate successfully updated.';
      },
      (error) => {
        this.message = 'Error occured, vehicle type rate not updated.';
        this.updateVForm.reset();
      }
    );
  }

  public assignNow() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.changeRetailOwner(this.retail.oid, this.ownerMobile, object.token).subscribe(
      (res) => {
        this.assignStatus = 'Retail owner changed';
      }, (error) => {
        this.assignStatus = 'Error changing retail owner.';
      }
    );
  }

  public getUserRetail() {
    this.title = 'Users';
    this.showUsers = true;
    this.showBookings = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.getRetailUser(object.token, this.retail.owner.retailId).subscribe(
      (res) => {
        this.retailUser = res;
        // console.log(this.retailUser);
      }
    );
  }

  public assignDriver() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.foundDriver != null) {
      this.adminService.assignToRetail(object.token, this.foundDriver.oid, this.retail.oid).subscribe(
        (res) => {
          this.assignStatus = 'Driver successfully assigned to retail.';
        }, (err) => {
          this.assignStatus = 'Unexpected error occurred while assigning driver';
        }
      );
    } else {
      this.assignStatus = 'This driver has already been assigned to the retail.';
    }
  }

  public getVehicleOid(vehicleOid: string) {
    this.vehicleOid = vehicleOid;
  }

  public getDrivers(token, driverOid) {
    this.service.retrieveDrivers(token, driverOid)
      .subscribe(
        (res: Response) => {
          if (res.status === 200) {
            this.drivers.push(res.json());
          }
        },
        (err) => {
        }
      );
  }

  public assignUserRetail() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.service.assignNow(object.token, this.userMobile, this.retail.owner.retailId).subscribe(
      (res) => {
        this.assignStatus = res.firstName + ' ' + res.surname + ' added to retail';
      }, (error) => {
        this.assignStatus = error.error.message; // "Error here";
      }
    );
  }

  public findDriver() {
    this.valid = false;
    this.err = '';
    this.foundDriver = {};
    const object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.idNo != null && this.idNo.length === 10) {
      this.service.checkDriver(object.token, this.idNo)
        .subscribe(
          (res: Response) => {
            if (res.status === 200) {
              this.valid = true;
              this.foundDriver = res.json();
            }
          },
          (err) => {
            this.err = err.json().message;
          }
        );
    }
  }

  public sendStatement() {
    const admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.sendStatement(admin.token, this.retail.oid).subscribe(
      (response) => {
        this.statementMsg = 'Statement successfuly sent!';
        $('#statementSent').modal('show');
      }, (error) => {
        this.statementMsg = 'Not sent!';
        $('#statementSent').modal('show');
      }, () => {
      }
    );
  }

  public sendStatementsByDate() {

    const admin = JSON.parse(localStorage.getItem('userInfo'));
    const currentDate = new Date(this.statementStartDate);
    const date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    const monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    const hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    const minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    const second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    const dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
    this.statementStartDate = dropTime;

    const currentDate2 = new Date(this.statementEndDate);
    const date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    const monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    const hour2 = currentDate2.getHours() <= 9 ? '0' + currentDate2.getHours() : currentDate2.getHours();
    const minutes2 = currentDate2.getMinutes() <= 9 ? '0' + currentDate2.getMinutes() : currentDate2.getMinutes();
    const second2 = currentDate2.getSeconds() <= 9 ? '0' + currentDate2.getSeconds() : currentDate2.getSeconds();
    const dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + hour2 + ':' + minutes2 + ':' + second2;
    this.statementEndDate = dropTime2;

    const statementBody = {
      retailOid: this.retail.oid,
      startDate: this.statementStartDate,
      endDate: this.statementEndDate
    };

    const end = this.statementEndDate;
    const start = this.statementStartDate;

    const dateEnd = new Date(end);
    const dateStart = new Date(start);

    const Difference_In_Time = dateEnd.getTime() - dateStart.getTime();

    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    if (Difference_In_Days >= 90) {
      this.adminService.sendStatementByDate(admin.token, statementBody).subscribe(
        (response) => {
          this.statementMsg = 'Statement successfuly sent.';
          $('#statementDateSent').modal('show');
        }, (error) => {
          this.statementMsg = 'Not sent.';
          $('#statementDateSent').modal('show');
        }, () => {
        }
      );
    } else {
      this.statementMsg = 'Please select a range of 3 months or more.';
    }


  }

  public addVATToRetail() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.AddVATNumber(object.token, this.retail.oid, this.vatNumber).subscribe(
      (response) => {
        localStorage.setItem('selectedRetail', JSON.stringify(response.json()));
        this.retail = response.json();
        this.user = this.retail.owner;
        this.vatMessage = 'VAT number successfully added onto the retail.';
        this.addVatForm.reset();
      }, (error) => {
        this.vatMessage = 'Could not add VAT number to retail.';
        this.addVatForm.reset();
      }
    );
  }

  public setDateInvoiceRetail() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    const currentDate = new Date(this.setBookingDate);
    const date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    const monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    const hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    const minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    const second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    const dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
    this.setBookingDate = dropTime;

    const retailInvoiceBody = {
      date: this.setBookingDate,
      retailOid: this.retail.oid
    };

    this.adminService.sendInvoiceByDate(object.token, retailInvoiceBody).subscribe(
      response => {
        $('#statementDateSent').modal('show');
        this.messageAllInvoices = 'Invoices successfully sent.';
      }, error => {
        $('#statementDateSent').modal('show');
        this.messageAllInvoices = 'Invoices not sent.';
      }
    );
  }

  public confirmSelectedDate() {
    $('#confirmInvoiceDates').modal('show');
  }

  public confirmStatementDate() {
    $('#confirmStatementDates').modal('show');
  }

  public importantDate() {
    $('#confirmImportantDate').modal('show');
  }

  public findUser() {
    this.validManager = false;
    const admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getUserByNumber(this.userMobile, admin.token).subscribe(
      response => {
        this.validManager = true;
        this.foundUser = response;
      }, error => {
        console.log(error);
        this.errorMsg = error.error.message;
      }
    );
  }

  public assignManager() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.assignAccountManager(this.adminUser.token, this.retail.oid , this.foundUser.owner.oid).subscribe(
      response => {
        this.userAssigned = true;
        localStorage.setItem('selectedRetail', JSON.stringify(response.json()));
        this.assignStatus = 'Manager ' + this.foundUser.owner.firstName + ' ' + this.foundUser.owner.surname + ' successfully assinged.';
      }, error => {
        console.log(error);
      }, () => {

      }
    );
  }
  // Checks if file uploaded is csv file
  isValidCSVFile(file: any) {
    return file.name.endsWith('.csv');
  }

  fileReset() {
    this.csvReader.nativeElement.value = '';
    this.records = [];
    this.csvArr = [];
    this.disableUploadButton = false;
  }

  // Button to upload the CSV file & display the booking details
  public uploadListener($event: any): void {
    const files = $event.srcElement.files;
    if (this.isValidCSVFile(files[0])) {
      const input = $event.target;
      const reader = new FileReader();
      reader.readAsText(input.files[0]);
      reader.onload = () => {
        const csvData = reader.result;
        const csvRecordsArray = (csvData as string).split(/\r\n|\n/);
        const headersRow = this.getHeaderArray(csvRecordsArray);
        this.records = this.getDataRecordsArrayFromCSVFile(csvRecordsArray, headersRow.length);
        console.log(this.records);
        // If there table is populated, disable the upload button
        if (this.records.length > 0 && this.records.length <= this.fileRecordsMax) {
          this.disableUploadButton = true;
          this.errorReadingCSV = '';
        } else {
          this.errorReadingCSV = 'File has either too few or too many records or is improperly formatted';
        }
      };
      reader.onerror = function() {
        console.log('error is occured while reading file!');
      };
    } else {

      // this.errorReadingCSV = 'Please import valid .csv file before proceeding.';
      this.fileReset();
      alert('Please import valid .csv file before proceeding.');
    }
  }


  getHeaderArray(csvRecordsArr: any) {
    const headers = (csvRecordsArr[0] as string).split(',');
    const headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headerLength: any) {
    for (let i = 1; i < csvRecordsArray.length; i++) {
      const curruntRecord = (csvRecordsArray[i] as string).split(',');
      if (curruntRecord.length == headerLength) {
        const csvRecord: CSVBookingRecord = new CSVBookingRecord();
        if (curruntRecord[0].replace(/"/g, '').trim() != '' && curruntRecord[1].replace(/"/g, '').trim() != '') {
          // Pick Up values
          csvRecord.pickUpFirstName = curruntRecord[0].replace(/"/g, '').trim();
          csvRecord.pickUpLastname = curruntRecord[1].replace(/"/g, '').trim();
          csvRecord.pickUpPhone = curruntRecord[2].replace(/"/g, '').trim();
          csvRecord.pickUpEmail = curruntRecord[3].replace(/"/g, '').trim();
          csvRecord.pickUpCompanyName = curruntRecord[4].replace(/"/g, '').trim();
          csvRecord.pickUpComplex = curruntRecord[5].replace(/"/g, '').trim();
          csvRecord.pickUpUnitNo = curruntRecord[6].replace(/"/g, '').trim();

          // Drop Off values
          csvRecord.dropOffFirstName = curruntRecord[7].replace(/"/g, '').trim();
          csvRecord.dropOffLastname = curruntRecord[8].replace(/"/g, '').trim();
          csvRecord.dropOffPhone = curruntRecord[9].replace(/"/g, '').trim();
          csvRecord.dropOffEmail = curruntRecord[10].replace(/"/g, '').trim();
          csvRecord.dropOffCompanyName = curruntRecord[11].replace(/"/g, '').trim();
          csvRecord.dropOffComplex = curruntRecord[12].replace(/"/g, '').trim();
          csvRecord.dropOffUnitNo = curruntRecord[13].replace(/"/g, '').trim();

          // csvRecord.pickUpAddress = curruntRecord[14].replace(/"/g, '').trim().replace(/\*/g, ",");
          // csvRecord.dropOffAddress = curruntRecord[15].replace(/"/g, '').trim().replace(/\*/g, ",");

          // csvRecord.pickUpAddress = curruntRecord[14].replace(/"/g, '').trim().replace(/' '/g, ",");
          // csvRecord.dropOffAddress = curruntRecord[15].replace(/"/g, '').trim().replace(/' '/g, ",");

          csvRecord.pickUpAddress = curruntRecord[14].replace(/"/g, '').trim();
          csvRecord.dropOffAddress = curruntRecord[15].replace(/"/g, '').trim();

          csvRecord.comment = curruntRecord[16].replace(/"/g, '').trim();
          csvRecord.province = curruntRecord[17].replace(/"/g, '').trim();
          csvRecord.type = curruntRecord[18].replace(/"/g, '').trim();
          csvRecord.itemMass = Number(curruntRecord[19].trim());

          csvRecord.parcel_length = Number(curruntRecord[20].replace(/"/g, '').trim());
          csvRecord.parcel_breadth = Number(curruntRecord[21].replace(/"/g, '').trim());
          csvRecord.parcel_height = Number(curruntRecord[22].replace(/"/g, '').trim());
          csvRecord.parcel_mass = Number(curruntRecord[23].replace(/"/g, '').trim());

          csvRecord.fromSuburb = curruntRecord[24].replace(/"/g, '').trim().toUpperCase();
          csvRecord.toSuburb = curruntRecord[25].replace(/"/g, '').trim().toUpperCase();
          csvRecord.pickUpPCode = curruntRecord[26].replace(/"/g, '').trim(); // "0002";//
          csvRecord.dropOffPCode = curruntRecord[27].replace(/"/g, '').trim(); // "0002";//
          this.csvArr.push(csvRecord);
        }
      }
    }
    return this.csvArr;
  }

  public assignEmail(event): void {
    this.personToEmail = event.target.value;
  }
  public downloadTemplate() {
    this.loadservice.addloader();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.downloadTemplate(object.token)
      .subscribe(
        res => {
          // console.log(res)
          if (!res.file) {
            this.isValid = true;
          } else {
            this.records = [];
            this.errorReadingCSV = 'there was an error in the uploaded file,please use the downloaded file to fix the errors.';
            const file = 'data:' + res.applicationType + ';base64,' + res.file;
            const a = document.createElement('a');
            a.href = file;
            a.download = res.fileName;
            a.click();
            a.remove();
          }

          this.loadservice.removeloader();
        },
        error => {

          this.loadservice.removeloader();
          alert('Your CSV file exceeds the allowed number of bookings (50 Max).');
        }, () => {
          this.loadservice.removeloader();
        }
      );
  }
  public validateCSV() {
    this.loadservice.addloader();
    const object = JSON.parse(localStorage.getItem('userInfo'));
    const objectToSend = {records: this.records};
    this.adminService.validateCSVBooking(object.token, objectToSend)
      .subscribe(
        res => {
          // console.log(res)
          if (!res.file) {
            this.isValid = true;
          } else {
            this.records = [];
            this.errorReadingCSV = 'there was an error in the uploaded file,please use the downloaded file to fix the errors.';
            const file = 'data:' + res.applicationType + ';base64,' + res.file;
            const a = document.createElement('a');
            a.href = file;
            a.download = res.fileName;
            a.click();
            a.remove();
          }

          this.loadservice.removeloader();
        },
        error => {

          this.loadservice.removeloader();
          alert('Your CSV file exceeds the allowed number of bookings (50 Max).');
        }, () => {
          this.loadservice.removeloader();
        }
      );
  }

  public createBooking(): void {
    // console.log(this.records);
    if (this.records.length > this.fileRecordsMax) {
      alert('Your CSV file exceeds the allowed number of bookings (50 Max).');
    } else if (this.personToEmail.length < 1) {
      alert('Please select a person to email');
    } else {
      this.loadservice.addloader();
      // console.log(this.personToEmail);
      const object = JSON.parse(localStorage.getItem('userInfo'));
      const objectToSend = {records: this.records, email: this.personToEmail, paymentType: this.paymetype};
      // console.log( this.records);
      // console.log('email' + this.personToEmail);
      this.adminService.createCSVBooking(object.token, this.retail.owner.oid, objectToSend).subscribe(
        bookingResponse => {

          this.loadservice.removeloader();
          alert('Bookings created.');
        },
        error => {
          console.log(error);
          this.loadservice.removeloader();
        }, () => {
          this.loadservice.removeloader();
          this.fileReset();
        }
      );
    }
  }

  public createQuotes(): void {
    // console.log(this.records);
    if (this.records.length > this.fileRecordsMax) {
      alert('Your CSV file exceeds the allowed number of bookings (50 Max).');
    } else if (this.personToEmail.length < 1) {
      alert('Please select a person to email');
    } else {
      this.loadservice.addloader();
      // console.log(this.personToEmail);
      const object = JSON.parse(localStorage.getItem('userInfo'));
      const objectToSend = {records: this.records, email: this.personToEmail, paymentType: this.paymetype};
      console.log( this.records[0].type);
      // console.log('email' + this.personToEmail);
      const typeService = this.records[0].type;
      this.adminService.createCSVQuotes(object.token, this.retail.owner.oid, objectToSend, this.selectCourierProvider, typeService ).subscribe(
        bookingResponse => {
          this.loadservice.removeloader();
          console.log(bookingResponse);
          this.recordsQuotes = bookingResponse;
          this.showUploadCSV = false;
          this.showUploadCSVQuotes = true;
        },
        error => {
          console.log(error);
          this.loadservice.removeloader();
        }, () => {
          // this.loadservice.removeloader();
          // this.fileReset();
        }
      );
    }
  }

  public downloadQuotes() {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    console.log('this.recordsQuotesthis.recordsQuotes', this.recordsQuotes);
    this.adminService.downloadCsvQuotes(this.recordsQuotes, object.token).subscribe(
      bookingResponse => {
        this.loadservice.removeloader();
      },
      error => {
        this.loadservice.removeloader();
      }, () => {
        this.loadservice.removeloader();
      }
    );
  }

  public upLoadBookingQuotes() {

    const object = JSON.parse(localStorage.getItem('userInfo'));
    console.log('this.recordsQuotesthis.recordsQuotes', this.recordsQuotes);
    this.adminService.createCSVQuotesBooking(object.token, this.retail.owner.oid, this.recordsQuotes).subscribe(
      bookingResponse => {
        this.loadservice.removeloader();
        console.log(bookingResponse);
        // this.recordsQuotes = bookingResponse;
        // this.showUploadCSV = false;
        // this.showUploadCSVQuotes = true;
      },
      error => {
        console.log(error);
        this.loadservice.removeloader();
      }, () => {
        // this.loadservice.removeloader();
        // this.fileReset();
      }
    );
  }

  public uploadNote(): void {
    // $('#uploads').modal('show');
    this.title = 'CSV Bookings';
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = true;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
  }

  public addBranchToRetail() {
    const retailBranchDTO = {
      name: this.branchName,
      phone: this.branchPhoneNum,
      email: this.branchEmail
    };
    this.adminService.addRetailBranch(this.adminUser.token, this.retail.oid, retailBranchDTO).subscribe(response => {
      this.branchMsg = 'Branch successfully added to retail';
      this.retail = response;
      this.viewBranches();
    }, error => {
      console.log(error);
      this.branchMsg = error.error.message + ' already exists.';
    }, () => {
      this.branchForm.reset();
    });
  }

  public deleteBranch(branch) {
    this.adminService.deleteBranch(this.adminUser.token, this.retail.oid, branch.name).subscribe(response => {
      this.removeBranchMsg = 'Branch successfully removed';
      this.retail = response;
      $('#branchModal').modal('show');
    }, error => {
      console.log(error);
    }, () => {
      this.viewBranches();
    });
  }

  public viewBranches() {
    this.title = 'Branches';
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = true;
    this.showReports = false;
    this.showPromo = false;
    this.retailBranches = this.retail.branches;
  }

  public close() {
    this.removeBranchMsg = '';
    $('#branchModal').modal('hide');
  }

  public closeAddBranchModal() {
    this.branchMsg = '';
    $('#addBranch').modal('hide');
  }

  public downloadReport() {
    this.noReport = '';
    const adminUser = JSON.parse(localStorage.getItem('userInfo'));
    const currentDate = new Date(this.startDate);
    const date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    const monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    const dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.startDate = dropTime;

    const currentDate2 = new Date(this.endDate);
    const date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    const monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    const dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.endDate = dropTime2;

    const dateObj = {
      startDate: this.startDate,
      endDate: this.endDate
    };

    this.loadservice.addloader();
    if (dateObj.startDate.toString() == 'NaN-NaN-NaNT00:00:00' || dateObj.endDate.toString() == 'NaN-NaN-NaNT23:23:59' || this.endDate == undefined || this.startDate == undefined) {
      this.message = 'Please select start & end date to download the spreadsheet.';
      $('#emptyWallet').modal('show');
      this.loadservice.removeloader();
    } else {
      this.service.downloadSpreadsheet(adminUser.token, dateObj, this.retail.oid).subscribe(
        response => {
          // console.log(response.json());
          if (response.json().bookingsDump.length > 0) {
            this.loadservice.removeloader();
            // console.log(response.json());
            this.downloadBookingFile(response.json().bookingsDump);
            if (this.canInvoice) {
              // if retail has tag
              if (response.json().length > 0) {
                this.downloadDumpsFile(response.json().parcelDump);
              }
            }
            this.message = 'Spreadsheet successfully downloaded.';
            $('#spreadsheetDownload').modal('show');
          } else {
            this.loadservice.removeloader();
            this.noReport = 'There are no reports for the selected date range.';
          }
        }, error => {
          this.loadservice.removeloader();
          console.log(error);
          this.message = 'Could not download spreadsheet.';
          $('#emptyWallet').modal('show');
        }, () => {

        }
      );
    }
  }

  downloadBookingFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value);
    // console.log(Object.keys(data[0]));/
    const header = Object.keys(data[0]);
    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    const currentDate = new Date();
    const datePipe = new DatePipe('en-US');
    const value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    a.download = value + '-' + 'bookingData.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadDumpsFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    const currentDate = new Date();
    const datePipe = new DatePipe('en-US');
    const value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    a.download = value + '-' + 'itemDetails.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public getRetailManager() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getOwner(this.adminUser.token, this.retail.accountManagerOid).subscribe((res) => {
      this.accountManagerDetails = res.firstName + ' ' + res.surname;
    }, (error) => {
      console.log(error);
    });
  }

  showPromoSetup() {
    this.title = 'Promo';
    this.enablePromoEdit = true;
    this.showPromo = true;
    this.showInvoice = false;
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showDiscountRates = false;

    // console.log(this.showPromoSetupCount);
    // console.log(this.retail.oid);
    this.adminService.getRetailPromo(this.adminUser.token, this.retail.oid).subscribe(
      (response: any) => {
        // console.log(response);
        this.retailPromotions = response.reverse();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  showInvoiceAndStatement() {
    this.title = 'Invoice & Statement';
    this.showInvoice = true;
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    this.showDiscountRates = false;
  }

  showReportOption() {
    this.title = 'Reports';
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = true;
    this.showPromo = false;
    this.showDiscountRates = false;
  }
  showDiscountRateOption() {
    this.title = 'Discount Rates';
    this.showBookings = false;
    this.showUsers = false;
    this.showVehicles = false;
    this.showDrivers = false;
    this.showInvoice = false;
    this.showUploadCSV = false;
    this.showBranches = false;
    this.showReports = false;
    this.showPromo = false;
    this.showDiscountRates = true;
  }

  public createPromoCode() {

    if (this.hasTarget && this.targetAmount === '' && this.promoCode === '' && this.promoAmount === '' && this.promoCount === '') {
      this.promoValid = false;
      this.feedback = 'Missing target amount';
      return;
    }

    this.promoValid = true;

    const currentDate = new Date(this.promoStartDate);
    const date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    const monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    const hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    const minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    const second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    const dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second + '.312Z';

    const currentDate2 = new Date(this.promoEndDate);
    const date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    const monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    const hour2 = currentDate2.getHours() <= 9 ? '0' + currentDate2.getHours() : currentDate2.getHours();
    const minutes2 = currentDate2.getMinutes() <= 9 ? '0' + currentDate2.getMinutes() : currentDate2.getMinutes();
    const second2 = currentDate2.getSeconds() <= 9 ? '0' + currentDate2.getSeconds() : currentDate2.getSeconds();
    const dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + hour2 + ':' + minutes2 + ':' + second2 + '.312Z';

    const promoCodeDTO = {
      retailId: this.retail.oid,
      code: this.promoCode.toUpperCase(),
      amount: this.promoAmount,
      // count: this.promoCount,
      startDate: dropTime,
      endDate: dropTime2,
      promoType: this.promoType,
      targetAmount: this.hasTarget ? this.targetAmount : null,
      timesUsable: this.promoCount
    };

    // console.log(promoCodeDTO);

    this.adminService.createRetailPromo(this.adminUser.token, promoCodeDTO, this.retail.oid).subscribe(
      response => {
        // console.log(response);/
        // console.log(this.retail);
        this.feedback = 'Promo Code created successfully';
        // this.promoForm.reset();
        this.adminService.getRetailPromo(this.adminUser.token, this.retail.oid).subscribe(
          (res: any) => {
            // console.log(res);
            this.retailPromotions = res.reverse();
            this.enableEditPromoCode();
          },
          (err) => {
            console.log(err);
          }
        );
      },
      error => {
        console.log(error);
      }, () => {
        console.log('Finally');
    });
  }

  enableEditPromoCode() {
    this.enablePromoEdit = !this.enablePromoEdit;
    this.promoAmount = '';
    this.promoCode = '';
    this.targetAmount = null;
    this.promoCount = '';

  }

  public changePromoStatus(status, promoCodeIndex) {
    // console.log(this.retailPromotions[promoCodeIndex].promoType);

    this.adminService.allowRetailPromo(this.adminUser.token, this.retailPromotions[promoCodeIndex].code, status, this.retail.oid, this.retailPromotions[promoCodeIndex].promoType).subscribe(
      (response) => {
        // console.log(response);
        this.adminService.getRetailPromo(this.adminUser.token, this.retail.oid).subscribe(
          (re: any) => {
            // console.log(re);
            this.retailPromotions = re.reverse();
          },
          (er) => {
            console.log(er);
          }
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }

  public changeArrow(buttonName) {
    if (buttonName === 'owner') {
      this.ownerOpened = !this.ownerOpened;
    } else {
      this.companyOpened = !this.companyOpened;
    }
  }
  public addRetailPaymentTerms(form: NgForm) {
    // console.log(form)
    // console.log(this.retail)
    const paymentDetails = form.value;
    paymentDetails.retailOid = this.retail.oid;
    this.loadservice.addloader();
    this.adminService.addRetailPaymentTerms(this.adminUser.token, paymentDetails)
      .subscribe(
        (res) => {
          this.retail = res;
          localStorage.setItem('selectedRetail', JSON.stringify(res));
        },
        (error) => {
          console.log(error);
        },
        () => {
          this.loadservice.removeloader();
        }
        );
  }


  getDiscountRates() {
    this.loadservice.addloader();
    this.adminService.getRetailDiscountsRates(this.retail.oid, this.adminUser.token).subscribe(
        (response) => {
              this.accountDiscountRate = response;
              this.loadservice.removeloader();
        },  (error) => {
          this.loadservice.removeloader();
        }, () => {
          this.loadservice.removeloader();
        }
      );
  }

  toggleNewRate() {
    this.loadservice.addloader();
    this.adminService.changeCircleRate(this.adminUser.token, this.retail.oid)
      .subscribe(
        (res) => {
          this.retail = res;
          localStorage.setItem('selectedRetail', JSON.stringify(res));
          this.newRate = !this.newRate;
          this.loadservice.removeloader();
        },
        (error) => {
          console.log(error);
          this.loadservice.removeloader();
        },
        () => {
          this.loadservice.removeloader();
        }
      );

  }
  public assignProvider(event): void {
    console.log(event);
    this.selectCourierProvider = event.target.value;
  }

  changeStatus(discount: any) {
    this.selectedDiscount = discount;
    $('#discountModal').modal('show');
  }

  saveChanges() {
  }

  createDiscount(discountForm: NgForm) {
    if (discountForm.valid) {
      const object = {
        type: discountForm.value.type,
        amount: discountForm.value.amount,
        isPercentage: discountForm.value.isPercentage === '' ? false : discountForm.value.isPercentage,
        isNegative: discountForm.value.isNegative === '' ? false : discountForm.value.isNegative,
        status: discountForm.value.status,
        startDate: discountForm.value.startDate,
        endDate: discountForm.value.endDate,
        retail_id: this.retail.oid
      };

      this.loadservice.addloader();

      this.adminService.createDiscount(this.adminUser.token, object).subscribe(
        (response) => {
        this.getDiscountRates();
        this.loadservice.removeloader();
      } , (error) => {
          this.errorMessage = error.error.message;
          discountForm.reset();
          $('#createDiscountModal').modal('hide');
          $('#errorModal').modal('show');
          this.loadservice.removeloader();

        }, () => {
          this.loadservice.removeloader();
        }
      );
    }
  }
}

export class CSVBookingRecord {
  // PickUp details
  public pickUpFirstName: any;
  public pickUpLastname: any;
  public pickUpPhone: any;
  public pickUpEmail: any;
  public pickUpCompanyName: any;
  public pickUpComplex: any;
  public pickUpUnitNo: any;

  // drop Off details
  public dropOffFirstName: any;
  public dropOffLastname: any;
  public dropOffPhone: any;
  public dropOffEmail: any;
  public dropOffCompanyName: any;
  public dropOffComplex: any;
  public dropOffUnitNo: any;

  public pickUpAddress: any;
  public dropOffAddress: any;

  public comment: any;
  public province: any;
  public type: any;
  public itemMass: number;

  public parcel_length: number;
  public parcel_breadth: number;
  public parcel_height: number;
  public parcel_mass: number;

  public fromSuburb: any;
  public toSuburb: any;
  public pickUpPCode: any;
  public dropOffPCode: any;

}
