import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parcel-details',
  templateUrl: './parcel-details.component.html',
  styleUrls: ['./parcel-details.component.css']
})

export class ParcelDetailsComponent implements OnInit {

  massObj: any;
  widthObj: any;
  heightObj: any;
  lengthObj: any;
  numOfParcels: any;

  constructor() { }

  ngOnInit() {
    this.numOfParcels = localStorage.getItem('parcels');
    console.log('Number of parcels are ' + this.numOfParcels);
  }

  public confrimDetails() {
    
  }

}
