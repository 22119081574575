import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {Router} from '@angular/router';
import {UserService} from "../../services/user.service";

@Component({
  selector: 'app-all-partners',
  templateUrl: './all-partners.component.html',
  styleUrls: ['./all-partners.component.css', '../admin-portal/admin-portal.component.css']
})
export class AllPartnersComponent implements OnInit {

  adminUser: any;
  users: any = [];
  partners: any = [];
  partnersTemp: any = [];
  company: any;
  companies: any = [];

  loader: boolean;

  itemToFind: any;
  searchQuery = 'name';

  phoneNumber: string = null;
  foundCompany: any;

  constructor(private adminService: AdminService, private userService: UserService, private router: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    // this.getAllUsers(this.adminUser.token);
    this.getAllPartners(this.adminUser.token);
    //  this.partnersTemp = this.partners;
  }

  private getAllPartners(token) {
    this.adminService.getAllCompanies(token).subscribe(
      (response) => {
        this.partners = response;
        this.partnersTemp = response;
      }
    );
  }

  private getAllUsers(token) {
    this.loader = true;
    this.adminService.getAllUsers(token).subscribe(
      (response) => {
        this.users = response.reverse();
        // this.loader = false;
        this.getPartners();
      },
      (error) => {
        this.loader = false;
        if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
          this.router.navigate(['/logon']);
        }
      }
    );
  }

  private getPartners() {
    this.users.forEach(user => {
      for (let i = 0; i < user.roles.length; i++) {
        if (user.roles[i].code === 'partner') {
          this.getPartnerDetails(user);
        }
      }
    });
    this.loader = false;
  }

  private getPartnerDetails(partner) {
    this.userService.retrieveCompany(this.adminUser.token, partner.owner.oid).subscribe(
      (response) => {
        this.company = response.json()[0];
        // append company object to partner object
        partner = {...partner, company: this.company};
        this.partners.push(partner);
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
          this.router.navigate(['/logon']);
        }
      }
    );
  }

  public showTabByRole(role): boolean {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result = false;
    userInfo.roles.map(r => {
      if (role === r.code) {
        result = true;
      }
    });
    return result;
  }

  public searchPartner() {
    if (this.phoneNumber && this.phoneNumber != null) {
      this.adminService.searchCompanyByMobile(this.phoneNumber, this.adminUser.token).subscribe(
        (response) => {
          this.foundCompany = response;
        }

      );
    }
  }

  public showPartnerDetails(partner) {
    localStorage.setItem('partner', JSON.stringify(partner));
    this.router.navigate(['/view-partner']);
  }

  public filterPartnerTable() {
    this.partners = this.partnersTemp;
    if (this.itemToFind !== null && this.itemToFind.trim() !== '') {
      this.partners = this.partners.filter((partner) => {
        if (partner) {
          return partner[this.searchQuery].toLowerCase().includes(this.itemToFind.toLowerCase());
        }
      });

    }
  }

}
