import { UserHomeComponent } from '../user-portal/user-home/user-home.component';
import { HomeNavigateComponent } from '../home/home-navigate/home-navigate.component';
import { AdminHomeComponent } from '../admin/admin-home/admin-home.component';
import { RetailHomeComponent } from '../user-portal/retail-home/retail-home.component';
import { DevelopersPortalNavigateComponent } from '../developers-portal/developers-portal-navigate/developers-portal-navigate.component';
import { MAIN_ROUTES } from './home-routing.module';
import { SECOND_ROUTES } from './user-routing.module';
import { THIRD_ROUTES } from './admin-routing.module';
import { FOURTH_ROUTES } from './retail-routing.module';
import { FIFTH_ROUTES } from './developers-portal-routing.module';
import { ConfirmMobileComponent } from '../home/confirm-mobile/confirm-mobile.component';
import { NotFoundComponent } from '../home/404/404.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import {MobileFaqComponent} from '../mobile-faq/mobile-faq.component';
import { SIXTH_ROUTES } from './client-routing.module';
import { ClientProfileNavigationComponent } from '../user-portal/client-profile-navigation/client-profile-navigation.component';
import {MobileTermsComponent} from '../mobile-terms/mobile-terms.component';

const APP_ROUTES: Routes = [
    { path: '', redirectTo: '/', pathMatch: 'full', },
    { path: 'mobileAboutFAQ', component: MobileFaqComponent },
    { path: 'mobileTerms', component: MobileTermsComponent },
    { path: '', component: HomeNavigateComponent, data: { title: 'Home Views' }, children: MAIN_ROUTES },
    { path: '', component: UserHomeComponent, data: { title: 'User Portal Views' }, children: SECOND_ROUTES },
    { path: '', component: AdminHomeComponent, data: { title: 'Admin Portal Views' }, children: THIRD_ROUTES },
    { path: '', component: RetailHomeComponent, data: { title: 'Retail Portal Views' }, children: FOURTH_ROUTES },
    { path: '', component: DevelopersPortalNavigateComponent, data: { title: 'developers Portal Views' }, children: FIFTH_ROUTES },
    // Client (for Franchise) route. Currently disabled as it is not yet in use
    /*{ path: '', component: ClientProfileNavigationComponent, data: { title: 'Client Portal Views' }, children: SIXTH_ROUTES },*/
    // { path: 'confirm-mobile', component: ConfirmMobileComponent},
    { path: '**', component: NotFoundComponent }
];

export const routing: ModuleWithProviders = RouterModule.forRoot(APP_ROUTES);
