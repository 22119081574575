import {Component, OnInit} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import {element} from "protractor";
import {LoaderService} from 'src/app/services/loader.service';

@Component({
  selector: 'app-track-my-booking',
  templateUrl: './track-my-booking.component.html',
  styleUrls: ['./track-my-booking.component.css']
})
export class TrackMyBookingComponent implements OnInit {

  bookingOID: any; // track number
  searchError: string;
  booking: any = [];
  message: any;
  subscription: Subscription;
  loader = false;

  constructor(private route: Router, private bookService: BookingService, private loadservice: LoaderService) {
  }

  ngOnInit() {
    this.bookingOID = null;
  }

  public btn() {
    /*
      use to download way bill of a booking using bookingOid
    */
    const url = this.bookService.downloadWaybill('63552419651ac9006ae01337');
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    URL.revokeObjectURL(url);
  }

  public btns() {
    /*
      use to download way bill of a booking using bookingOid
    */
    const data = {
      bookingOids: ['63593464651ac9006cdf73fb', '6359358c651ac9006c33f363', '6359415a651ac9006c357a90', '63594bad651ac9006c4f0972']
    };
    this.bookService.downloadWaybills(data).subscribe(
      response => {
        const url = response.json().downloadPdfURL;
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        URL.revokeObjectURL(url);
      },
      error => {

      });
  }

  public trackBooking() {
    this.searchError = null;
    this.message = null;
    this.loadservice.addloader();
    if (this.bookingOID != null && this.bookingOID.trim().length > 0) {
      this.bookingOID = this.bookingOID.trim();

      if (this.bookingOID.toLowerCase().includes('pudo')) {

        this.bookService.getPudoBooking(this.bookingOID).subscribe(
          (response1) => {
            localStorage.setItem('retrievedPudoBooking', JSON.stringify(response1));
            this.loadservice.removeloader();
            this.route.navigate(['/internal-tracking']);
          },
          (error1) => {
            console.log(error1);
            this.message = error1.error.message;
            this.loadservice.removeloader();
          }
        );

      } else if (this.bookingOID.length > 10) {
        // read service for waybill
        this.bookService.retrieveWayBillStatus(this.bookingOID).subscribe(
          response => {
            if (response.length === 0) {

              this.loadservice.removeloader();
              this.searchError = 'No result for this waybill number ' + this.bookingOID;

            } else {

              localStorage.setItem('waybillNumber', this.bookingOID);
              localStorage.setItem('retrievedWaybillNumber', JSON.stringify(response));
              this.loadservice.removeloader();
              this.route.navigate(['/skynet-tracking']);

            }
          },
          error => {
            this.loadservice.removeloader();
            console.log(error);
            this.loadservice.removeloader();
            this.message = error.error.message;
          },
          () => {
            this.loadservice.removeloader();
            this.bookingOID = '';
          }
        );
      } else {
        // read service for normal bookings
        this.bookService.getBookingByTrackNum(this.bookingOID.toUpperCase()).subscribe(
          (response) => {

            this.booking = response.json();

            if (this.booking.length === 1) {
              if (this.booking[0].type === 'EXPRESS_COURIER' || this.booking[0].type === 'BUDGET_COURIER') {

                localStorage.setItem('retrievedTrackBooking', JSON.stringify(response.json()));
                this.loadservice.removeloader();
                this.route.navigate(['/skynet-tracking']);

              } else {
                if (this.booking[0].status === 'Awaiting driver to be assigned' || this.booking[0].status === 'AWAITING_DRIVER' ||
                  this.booking[0].status === 'BUCKET' || this.booking[0].status === 'Bucket Booking') {

                  this.message = 'Your booking has not been assigned a driver yet.';
                  this.loadservice.removeloader();

                } else if (this.booking[0].status === 'Accepted' || this.booking[0].status === 'RESERVED' ||
                  this.booking[0].status === 'Driver in transit' || this.booking[0].status === 'IN_TRANSACT' ||
                  this.booking[0].status === 'Booking successfully complete' || this.booking[0].status === 'COMPLETE') {

                  localStorage.setItem('retrievedTrackBooking', JSON.stringify(response.json()));
                  this.loadservice.removeloader();
                  this.route.navigate(['/internal-tracking']);

                } else {

                  this.loadservice.removeloader();
                  this.message = 'This booking is cancelled';

                }
              }
            } else if (this.booking.length === 2) {

              const firstLeg = this.booking.filter(booking => booking.status !== 'Processing Flight');
              this.loadservice.removeloader();

              if (firstLeg[0].status !== 'Awaiting driver to be assigned' && firstLeg[0].status !== 'AWAITING_DRIVER') {

                localStorage.setItem('retrievedFlightTrackBooking', JSON.stringify(response.json()));
                this.route.navigate(['/flight-tracking']);

              } else {

                this.message = 'Your booking has not been assigned a driver yet.';

              }
            } else if (this.booking.length === 3) {

              this.loadservice.removeloader();
              localStorage.setItem('retrievedFlightTrackBooking', JSON.stringify(response.json()));
              this.route.navigate(['/flight-tracking']);

            } else {

              this.loadservice.removeloader();

            }

            this.bookingOID = '';
          }, (error) => {
            console.log(error);
            if (error.status === 400) {
              // this.message = 'The track number is Invalid.';
              this.bookService.getBucketByTrackNum(this.bookingOID.toUpperCase()).subscribe((res) => {
                // console.log('AAAA');
                if (res.status === 'Booking taken already' || res.status === 'RESERVED' ||
                  res.status === 'Driver in transit' || res.status === 'IN_TRANSACT' ||
                  res.status === 'Booking successfully complete' || res.status === 'COMPLETE') {
                  // $('#trackBooking').modal('hide');
                  localStorage.setItem('retrievedTrackBooking', JSON.stringify(res.bookings));
                  this.loadservice.removeloader();
                  this.route.navigate(['Track-Driver-Retail/', res.trackNo]);
                } else {
                  this.loadservice.removeloader();
                  this.message = 'This booking has not been pushed out yet.';
                }
              }, (err) => {
                this.loadservice.removeloader();
                this.message = err.error.message;
              });
            } else {
              this.loadservice.removeloader();
              this.message = error.error.message;
            }
          }
        );
      }

    } else {
      this.loadservice.removeloader();
      this.message = 'Please enter a tracking / waybill number.';
    }
  }
}
