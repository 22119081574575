import {Component, OnInit, HostListener} from '@angular/core';
import {BookingService} from '../../services/booking.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SharedService} from "../../services/shared.service";
import {LoaderService} from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-confirm-order',
  templateUrl: './confirm-order.component.html',
  styleUrls: ['./confirm-order.component.css']
})
export class ConfirmOrderComponent implements OnInit {

  addrressData = {
    dropOffAddress: '',
    pickUpAddress: '',
    customerName: '',
    customer: {
      email: '',
      firstName: '',
      surname: ''
    },
    phoneNo: '',
    trackNo: '',
    status: ''
  };

  loader: boolean = false;
  invoiceOption: boolean;
  invoiceControl: boolean = false;
  bookingOid: any;

  companyName: any;
  vatNo: number;
  companyAddress: any;

  service: string;
  waybillDownloadLink: string;

  payment = 'DROPPA';
  paidByRetail = false;

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    console.log('Back button pressed');
  }

  constructor(private bookService: BookingService, private route: ActivatedRoute, private router: Router,
              private sharedServices: SharedService, private loadservice: LoaderService) {
    this.route.params.subscribe(params => {
      this.bookingOid = params['dropOid'];
    });
    this.service = sharedServices.getUrl();
  }

  ngOnInit() {
    this.payment = localStorage.getItem('Payment');
    this.paidByRetail = localStorage.getItem('paidByRetail') === 'true';
    if (this.payment == "PUDO") {
      this.bookService.getPudoBooking(this.bookingOid).subscribe(
        (response: any) => {
          console.log(response);
          this.addrressData.pickUpAddress = response.fromAddress.Addressline1.concat(response.fromAddress.Addressline2);
          this.addrressData.dropOffAddress = response.toAddress.Addressline1.concat(response.toAddress.Addressline2);
          this.addrressData.phoneNo = response.senderPhone;
          this.addrressData.trackNo = response.transactionId;
          this.addrressData.status = response.status;
          this.addrressData.customerName = response.receiverCompanyName;
          this.addrressData.customer.email = response.senderEmail;
          this.addrressData.customer.firstName = response.senderName.split(" ")[0];
          this.addrressData.customer.surname = response.senderName.split(" ")[1];
        }, error => {
          console.log(error);
          console.log('Error block');
        }, () => {
        }
      );
    } else {
      this.bookService.getBookingDetails(this.bookingOid).subscribe(
        response => {
          // console.log(response);
          this.addrressData = response;
        }, error => {
          console.log(error);
          console.log('Error block');
        }, () => {
        }
      );
    }

    this.waybillDownloadLink = this.service + 'plugins/waybill/download/pdf/';
  }

  public attachInvoiceOrNot(invoiceOption) {
    if (invoiceOption) {
      this.invoiceControl = true;
      // console.log(this.invoiceControl)
      // var token = localStorage.getItem('token');
      // this.bookService.invoiceCustomer(token, this.addrressData.trackNo).subscribe(
      //   (response) => {

      //   }, (error) => {
      //     console.log(error);
      //   }
      // );
    } else {
      this.invoiceControl = false;
      console.log(this.invoiceControl)
    }
  }

  companyNameMsg: string = '';
  companyAddressMsg: string = '';
  vatNoMsg: string = '';

  public modelCompanyName(companyName) {
    if (this.invoiceControl == true) {
      if (companyName == null || companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      } else {
        this.companyNameMsg = '';
      }
    }
  }

  public modelVATNo(vatNo) {
    if (this.invoiceControl == true) {
      if (vatNo == null || vatNo == '') {
        this.vatNoMsg = 'Please enter the VAT No.';
      } else {
        this.vatNoMsg = '';
      }
    }
  }

  public modelCompanyAddress(companyAddress) {
    if (this.invoiceControl == true) {
      if (companyAddress == null || companyAddress == '') {
        this.companyAddressMsg = 'Please enter the company address.';
      } else {
        this.companyAddressMsg = '';
      }
    }
  }

  public sendOptionalInvoice() {
    var token = localStorage.getItem('token');

    if (this.invoiceControl) {
      if (this.companyName == null || this.companyName == '') {
        this.companyNameMsg = 'Please enter the company name.';
      } else if (this.vatNo == null || this.vatNo == undefined || this.vatNo.toString() == "") {
        this.vatNoMsg = 'Please enter the VAT No.';
      } else if (this.companyAddress == null) {
        this.companyAddressMsg = 'Please enter the company address.';
      } else {
        var invoiceObj = {
          "companyName": this.companyName,
          "address": this.companyAddress,
          "vatNo": this.vatNo,
          "toNames": this.addrressData.customer.firstName + ' ' + this.addrressData.customer.surname,
          "toEmail": this.addrressData.customer.email,
          "toPhone": this.addrressData.phoneNo,
          "trackNo": this.addrressData.trackNo
        }
        this.loadservice.addloader();
        this.bookService.newInvoiceCustomer(token, invoiceObj).subscribe(
          response => {
            this.loadservice.removeloader();
          }, (error) => {
            console.log(error);
            this.loadservice.removeloader();
          }, () => {
            this.router.navigateByUrl('/user');
            // $('#optionalInvoice').modal('show');
          }
        )
      }
    } else {
      var invoiceObj = {
        "companyName": this.companyName,
        "address": this.companyAddress,
        "vatNo": this.vatNo,
        "toNames": this.addrressData.customerName,
        "toEmail": this.addrressData.customer.email,
        "toPhone": this.addrressData.phoneNo,
        "trackNo": this.addrressData.trackNo
      }
      this.loadservice.addloader();
      this.bookService.newInvoiceCustomer(token, invoiceObj).subscribe(
        response => {
          this.loadservice.removeloader();
        }, (error) => {
          console.log(error);
          this.loadservice.removeloader();
        }, () => {
          this.paidByRetail ? this.router.navigateByUrl('/retail-profile') : this.router.navigateByUrl('/user');
          // $('#optionalInvoice').modal('show');
        }
      )
    }


  }

  public onHome() {
    this.paidByRetail ? this.router.navigateByUrl('/retail-profile') : this.router.navigateByUrl('/user');
  }

  // public confirmBooking() {
  //   $('#results').modal('show');
  // }

}
