import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-business-reports',
  templateUrl: './business-reports.component.html',
  styleUrls: ['./business-reports.component.css','../admin-portal/admin-portal.component.css']
})
export class BusinessReportsComponent implements OnInit {

  adminUser:any;
  loader:boolean=false;
  bookings:any=[];
  allBookings:any=[];
  searchBooking:any;
  msg:any;
  startDate;
  endDate;
  datesOfReport:any;
  isSearch:boolean=false;
  public searchQueryStatus: string;
  constructor(private router: Router,private adminService: AdminService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.adminUser=JSON.parse(localStorage.getItem('userInfo'));
    this.getAllRetailBooking()
  }
  public cancelSearch(){
    this.isSearch = false;
    this.getAllRetailBooking()
  }
  public getAllRetailBooking(){
    this.loadservice.addloader();
    this.adminService.getAllRetailBookings(this.adminUser.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.bookings = res.reverse();
          this.allBookings = res.reverse();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }


  tempBookings() {
    this.bookings = this.allBookings;
  }
  onDateChange(){
    this.tempBookings();

      if(this.startDate&&!this.endDate)
      {
        this.bookings = this.bookings.filter((item: any) => item.bookingCreatedDate>this.changeDateFormat(this.startDate));
      }else if(this.startDate&&this.endDate)
      {
        this.bookings = this.bookings.filter((item: any) => item.bookingCreatedDate>this.changeDateFormat(this.startDate)&&item.bookingCreatedDate<this.changeDateFormat(this.endDate));
        this.datesOfReport = {
          'startDate': this.changeDateFormat(this.startDate),
          'endDate': this.changeDateFormat(this.endDate)
        };
      }
      console.log(this.bookings)
  }
  searchByDateBtn(){
    this.loadservice.addloader();
    this.isSearch = true;
    if(!this.startDate||!this.endDate)return
    this.adminService.getRetailBookingsByDate(this.adminUser.token,this.datesOfReport)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.bookings = res.reverse();
          this.allBookings = res.reverse();
        },
        (err) => {
          console.log(err)
          this.isSearch = false;
          this.loadservice.removeloader();
          return
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }
  searchBookingBtn(){
    this.loadservice.addloader();
    if(!this.startDate||!this.endDate)return
    this.adminService.getRetailBookingsByDate(this.adminUser.token,this.datesOfReport)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.bookings = res.reverse();
          this.allBookings = res.reverse();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }
  searchBookings() {
    this.tempBookings();
    if(this.startDate){
      this.bookings = this.bookings.filter((item: any) => item.bookingCreatedDate<this.changeDateFormat(this.startDate));
    }else if(this.startDate&&this.endDate){
        this.bookings = this.bookings.filter((item: any) => item.bookingCreatedDate>this.changeDateFormat(this.startDate)&&item.bookingCreatedDate<this.changeDateFormat(this.endDate));
    }
    if (this.searchBooking && this.searchBooking.trim() !== '') {

      this.bookings = this.bookings.filter((item: any) => item.retailname.toUpperCase().match(this.searchBooking.toUpperCase()));

    } else if (this.bookings.length==0) {

      this.bookings = this.bookings.filter((item: any) => item.accountManagerFullName.toUpperCase().match(this.searchBooking.toUpperCase()));

    } else {
      this.msg = '...';
      this.loadservice.removeloader();
    }
  }
  public changeDateFormat(dateToFormat){
    var currentDate = new Date(dateToFormat);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var time = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    return time;

    // var currentDate2 = new Date(this.bucketEndDate);
    // let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    // let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    // var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    // this.bucketEndDate = dropTime2;
  }
}
