import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-elite-prices',
  templateUrl: './elite-prices.component.html',
  styleUrls: ['./elite-prices.component.css']
})
export class ElitePricesComponent implements OnInit {

  loader = false;
  eliteObj: any = {};
  fridgePrices: any = [];
  bringFridePrices: boolean = false;
  normalTrucks: any = [];
  bringNormalPrices: boolean = false;
  sleepOutFee: any;
  tempAmount: any;
  vatAmt: any;
  baseAmt: any;
  type: any;
  fridgeMsg = '';

  constructor(private bookService: BookingService, private router: Router, private loadservice: LoaderService) { }

  ngOnInit() {
    this.eliteObj = JSON.parse(localStorage.getItem('eliteData'));
    const quoteBody = {
      startDate: this.eliteObj.eliteStartDate,
      distance: this.eliteObj.kilometersToTravel,
      Code: this.eliteObj.eliteType,
      returnDate: this.eliteObj.eliteReturnDate
    };
    this.getNewQuote(quoteBody);
  }

  public getNewQuote(body) {
    this.loadservice.addloader();
    this.bookService.getEliteQuotes2(body).subscribe(
      response => {
        // console.log(response);
        this.loadservice.removeloader();
        if (this.eliteObj.eliteType === 'CODE_10') {
          this.fridgePrices = response.fridges;
          this.bringFridePrices = true;
          this.fridgeMsg = 'Code 10 Rental Trucks';
        } else {
          this.normalTrucks = response.fridges;
          this.bringNormalPrices = true;
          this.fridgeMsg = 'Code 14 Rental Trucks';
        }

      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    );
  }

  public selectFirstNormalTruck(truck) {
    this.baseAmt = Math.floor(truck.amount);
    this.vatAmt = Math.floor(truck.vatAmt);
    this.tempAmount = Math.floor(truck.totalAmt);
    this.type = truck.type;
    this.sleepOutFee = truck.sleepOutFee;

    const fridgeTruckObj = {
      type: this.type,
      basePrice: this.baseAmt,
      vat: this.vatAmt,
      price: this.tempAmount,
      data: this.eliteObj,
      sleepAmountFee: this.sleepOutFee
    };

    this.loadservice.removeloader();
    if ((localStorage.getItem('userInfoBookingLogin') != null && localStorage.getItem('userInfoBooking') != null)) {
      localStorage.setItem('eliteDetails', JSON.stringify(fridgeTruckObj));
      this.login(JSON.parse(localStorage.getItem('userInfoBookingLogin')));
    } else {
      localStorage.setItem('eliteObj', JSON.stringify(fridgeTruckObj));
      this.router.navigate(['/Elite-Sign-Up']);
    }
  }

  private login(object: any) {
    this.bookService.login(object.pwd, object.username).subscribe(
      (response: any) => {
        localStorage.setItem('userData', JSON.stringify(response));
        localStorage.setItem('token', response.token);
        localStorage.setItem('userInfo', JSON.stringify(response));
        localStorage.removeItem('userInfoBookingLogin');
        localStorage.removeItem('userInfoBooking');
        this.router.navigate(['/Elite-Confirm-Details']);
        window.scrollTo(0, 0);
      }
    );
  }
}
