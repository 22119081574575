import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-reset-skynet-users-passwords',
  templateUrl: './reset-skynet-users-passwords.component.html',
  styleUrls: ['./reset-skynet-users-passwords.component.css']
})
export class ResetSkynetUsersPasswordsComponent implements OnInit {

  sub: any;
  accountOid: any;
  error: string = '';
  success: string = '';
  newPassword: any;

  constructor(private route: ActivatedRoute, private loginService: LoginService) {
    this.sub = this.route.params.subscribe(params => {
      this.accountOid = params['accountOid'];
    });
  }

  ngOnInit() {

  }

  public confirmPassword() {

    this.error = '';
    this.success = '';

    var resetDetails = {
      "resetToken": this.accountOid,
      "newPwd": this.newPassword
    };

    this.loginService.resetSkynetPwd(resetDetails)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.success = 'Password reset successful, please login.';
          } else {
            this.error = 'Error occured, Contact Droppa.';
          }
        },
        (err) => {
          console.log(err)
          this.error = 'Error occured, Contact Droppa.';
        }
      )


  }

}
