import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { DatePipe } from '@angular/common';
import { BookingService } from '../../services/booking.service';
import { UserService } from '../../services/user.service';

import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-view-booking',
  templateUrl: './view-booking.component.html',
  styleUrls: ['./view-booking.component.css']
})
export class ViewBookingComponent implements OnInit {
  loader: boolean = false;
  book: any;
  booking: any;
  bookingTime: any;
  pickUpTime: any;
  bookingDate: any;
  driver: any;
  driverMobile: string;
  valid: boolean = false;
  foundDriver: any;
  err: string = "";
  assignStatus: string = "";
  errorMessage: string;
  message: string;
  wayBillStatus: string | any;

  bookingComplete: boolean = false;
  isBookingCanceled: boolean = false;
  isBookingExpressOrBudget: boolean = false;
  canBookingViewWaybillStatus: boolean = false;
  canSendInvoice: boolean = false;

  today: Date;
  yesterday: Date;
  futureLimit: Date;

  constructor(private adminService: AdminService, private bookingServe: BookingService, private route: Router,
  private userService: UserService,private loadservice:LoaderService) {

  }

  ngOnInit() {

    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.book = JSON.parse(localStorage.getItem('selectedBooking'));

    this.today = new Date();
    this.yesterday = new Date(
      this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1, this.today.getHours(), this.today.getMinutes()
    );
    this.futureLimit = new Date(2030, 3, 21, 20, 30);

    // this.bookingComplete = this.book.status === 'Awaiting driver to be assigned' && 'Booking taken already' ? true : false;
    this.bookingComplete = this.book.status === 'Awaiting driver to be assigned' && 'Accepted' ? true : false;
    this.bookingDate = this.book.pickUpDate;
    const TimeSeparatorIndex = this.book.pickUpTime.indexOf(':');
    const hour = parseInt(this.book.pickUpTime.substring(0, TimeSeparatorIndex));
    const minutes = this.book.pickUpTime.substring(TimeSeparatorIndex + 1, 5);
    this.bookingTime = hour + ':' + minutes + ':00';
    this.pickUpTime = this.book.pickUpTime.substring(0, 5);

    if (this.book.driverOid != null) {
      this.adminService.getDriver(object.token, this.book.driverOid).subscribe(
        (res) => {
          this.driver = res;
        }
      );
    }

    /*  */
    if ((this.book.status === "Awaiting payment") || (this.book.status === "Accepted") || (this.book.status === "Awaiting driver to be assigned")) {
    // if ((this.book.status === "Awaiting payment") || (this.book.status === "Booking taken already") || (this.book.status === "Awaiting driver to be assigned")) {
      this.isBookingCanceled = true;
    }

    if (this.book.type === "BUDGET_COURIER" && this.book.type === "EXPRESS_COURIER") {
      this.isBookingExpressOrBudget = true;
    }

    if ((this.book.status === "Waybill Created") || (this.book.status === "Driver in transit")) {
      this.canBookingViewWaybillStatus = true;
    }

    if (this.book.waybillResponse !== null) {
      this.wayBillStatus = this.book.waybillResponse;
    }
  }

  viewayBillStatuses(): void {

    if (this.wayBillStatus.length !== 0 && (this.wayBillStatus !== null) || (this.wayBillStatus !== undefined)) {

      this.userService.retrieveWayBillStatus(this.wayBillStatus.waybillNumber)
      .subscribe((waybillResults) => {
        localStorage.setItem('wayBillStatuses', JSON.stringify(waybillResults));
        this.route.navigateByUrl('/view-customer-waybill');
      });

    } else {
      alert('There is currently no waybill events to view.');
    }

  }

  public updateTimes() {

    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');
    var datePipe = new DatePipe("en-US");
    let TimeSeparatorIndex = this.bookingTime.indexOf(":");
    let hour = parseInt(this.bookingTime.substring(0, TimeSeparatorIndex));
    let minutes = this.bookingTime.substring(TimeSeparatorIndex + 1, 5);
    let date = new Date();

    date.setHours(hour);
    date.setMinutes(minutes);

    let valueTime = datePipe.transform(date, 'HH:mm a');
    var object = JSON.parse(localStorage.getItem('userInfo'));

    var bookingUpdate = {
      "date": value,
      "time": valueTime,
      "bookingOid": this.book.oid
    };

    this.adminService.updateBooking(bookingUpdate, object.token).subscribe(
      (res) => {
        this.message = "Booking is updated successfully.";
      }, (err) => {
        this.message = "Unknown Error while updating booking.";
        console.log(err);
      }
    );

  }

  public deleteBooking() {
    this.loadservice.addloader();
    var user = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingServe.cancelBooking(this.book.oid, user.token)
      .subscribe(
        (res) => {
          this.loadservice.removeloader();
          this.errorMessage = 'Booking cancelled.';
          $('#cancelbookingModal').modal('show');
        },
        (err) => {
          console.log(err);
          this.loadservice.removeloader();
          this.errorMessage = err.error.message;
          $('#cancelbookingModal').modal('show');
        }
      );
  }

  public sendCustomerInvoice() {
    $('#newInvoiceTax').modal('show');
  }

  companyName: any;
  vatNo: number;
  companyAddress: any;

  companyNameMsg: string = '';
  companyAddressMsg: string = '';
  vatNoMsg: string = '';
  sentTaxMsg: string = '';

  public modelCompanyName(companyName) {
    if (companyName == null || companyName == '') {
      this.companyNameMsg = 'Please enter the company name.';
    }
    else {
      this.companyNameMsg = '';
    }
  }

  public modelVATNo(vatNo) {
    if (vatNo == null || vatNo == '') {
      this.vatNoMsg = 'Please enter the VAT No.';
    }
    else {
      this.vatNoMsg = '';
    }
  }

  public modelCompanyAddress(companyAddress) {
    if (companyAddress == null || companyAddress == '') {
      this.companyAddressMsg = 'Please enter the company address.';
    }
    else {
      this.companyAddressMsg = '';
    }
  }

  public sendOptionalInvoice() {
    var token = localStorage.getItem('token');

    var invoiceObj = {
      "companyName": this.companyName,
      "address": this.companyAddress,
      "vatNo": this.vatNo,
      "toNames": this.book.customerName,
      "toEmail": this.book.customer.email,
      "toPhone": this.book.phoneNo,
      "trackNo": this.book.trackNo
    }
    this.loadservice.addloader();
    this.bookingServe.newInvoiceCustomer(token, invoiceObj).subscribe(
      response => {
        console.log(response);
        this.sentTaxMsg = 'Tax invoice successfully sent to your email.';
        this.loadservice.removeloader();
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader();
      }, () => {
        $('#optionalInvoice').modal('show');
      }
    )
  }

  updateBooking() {
    this.errorMessage = null;
    const existingDate = new Date(this.bookingDate);
    const currentDate = new Date();
    const currentDateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    const existingDateFormat = existingDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

    const dateSame = existingDateFormat === currentDateFormat;
    const dateDiffer = existingDateFormat > currentDateFormat;

    const datePipe = new DatePipe('en-US');

    const timeValue = datePipe.transform(this.book.pickUpTime, 'HH:mm a');
    // const dateValue = datePipe.transform(this.bookingDate, 'dd-MM-yyyy');

    const timeSeparatorIndex = timeValue.indexOf(':');
    const hour = parseInt(timeValue.substring(0, timeSeparatorIndex), 10);
    // const minutes = timeValue.substring(timeSeparatorIndex + 1);

    if (dateSame === true || dateDiffer === true) {
      if (hour >= 7 && hour <= 17) {
        const user = JSON.parse(localStorage.getItem('userInfo'));
        const data = {
          oid: this.book.oid,
          date: this.book.pickUpDate,
          time: this.book.pickUpTime,
        };
        this.bookingServe.updateBook(data, user.token).subscribe(
            (respose) => {
              // console.log(respose);
              localStorage.setItem('selectedBooking', JSON.stringify(respose));
              this.bookingDate = respose.pickUpDate;
              this.bookingTime = respose.pickUpTime;
              this.loadservice.removeloader();
              this.errorMessage = 'Booking updated.';
              // $('#errorDialog').modal('show');
            },
            (err) => {
              // console.log(err);
              this.errorMessage = 'Error. Booking update failed.';
              // $('#errorDialog').modal('show');
            }
          );
      } else {
        this.errorMessage = 'Working hours are from 07:00 to 17:59.';
        // $('#errorDialog').modal('show');
      }
    } else {
      this.errorMessage = 'Invalid pick up date. The date has already passed.';
      // $('#errorDialog').modal('show');
    }
  }
}
