import { Component, OnInit, AfterViewChecked, ViewChild ,isDevMode} from '@angular/core';
import { NgForm, NgModel, PatternValidator } from '@angular/forms';
import { ReCaptchaComponent } from 'angular2-recaptcha';
import { Observable } from 'rxjs/Observable';
import { Response } from '@angular/http';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/Rx';

import { User } from '../../models/user';
import { Login } from '../../models/login';
import { Company } from '../../models/company';

declare var $: any;

import { LoginService } from '../../services/login.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css', '../../css/style.css'],
  providers: [LoginService]
})
export class RegistrationComponent implements OnInit {

  @ViewChild(ReCaptchaComponent, { static: true }) captcha: ReCaptchaComponent;

  @ViewChild('userForm', { static: true }) signupForm: NgForm;
  @ViewChild('otpForm', { static: true }) otppForm: NgForm;
  @ViewChild('infoForm', { static: true }) infoForm: NgForm;
  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';
  firstName: string = '';
  email: string = '';
  surname: string = '';
  pwd: string = '';
  phone: string = '';
  otp: number;
  jsonObj: string = '';
  phoneNo: string = '';
  registerSubscription: Subscription;
  error: string = '';
  OTPerror: string = '';
  user: User;
  login: Login;
  company: Company;

  capture: any;

  base64ID: string;
  base64DISC: string;
  base64CIPC: string;

  idCopy;
  discCopy;
  cpicCopy;

  documentMsg: string = 'You are one step left to become a Droppa, Please submit required documents!.';
  diskName: string;
  idName: string;
  cpicName: string = '';

  companyName: string = '';
  companyReg: string = '';
  make: string = '';
  model: string = '';
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  type: string = '';
  loader: boolean = false;
  isDevMode=isDevMode()

  constructor(private loginService: LoginService,private loadservice:LoaderService) {
  }

  ngOnInit() {
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }


  onChange(files) {
  }

  public onSubmit() {

    var recaptcha = $("#recaptcha").val();
    this.loadservice.addloader();
    this.user = new User(this.firstName, this.surname, this.email.toLowerCase().trim(), this.phone);
    this.login = new Login(this.pwd, this.email.toLowerCase().trim());
    this.user.login = this.login;
    var pass = this.pwd.split('');
    this.phoneNo = this.phone;
    this.loginService.register(this.user)
      .subscribe(
        (response: any) => {
          localStorage.removeItem('userData');
          localStorage.removeItem('signedUser');
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem("signedUser", JSON.stringify({ email: this.email.toLowerCase().trim(), password: this.pwd }));
          this.loadservice.removeloader();
          $(".enter-otp").removeClass("linkDisabled");
          $('#myTab a[href="#step2"]').tab('show');
          window.scrollTo(0, 0);
        },
        (err) => {
          this.loadservice.removeloader();
          this.error = "Error while registering, mobile or email already exist.";
        }
      );
  }

  public confirmOTP() {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('signedUser'))
    if (this.otp != null) {
      this.loginService.confirmOTP(this.otp, this.phoneNo)
        .subscribe(
          (response: Response) => {
            if (response.status == 200) {
              this.loadservice.removeloader();
              if (response.json().confirmed) {
                this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                  (response: any) => {
                    localStorage.setItem('token', response.token);
                    this.loadservice.removeloader();
                    $(".enter-info").removeClass("linkDisabled");
                    $('#myTab a[href="#step4"]').tab('show');
                    $(".enter-otp").addClass("linkDisabled");
                    window.scrollTo(0, 0);
                  }
                );
              } else {
                this.loadservice.removeloader();
                this.OTPerror = "Error, the OTP number provided is invalid.";
              }

            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Error, please enter valid OTP.";
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error while registering, mobile or email already exist.";
          }
        );

    } else {
      this.loadservice.removeloader();
      alert("Error please enter the otp");
    }

  }

  public companyInfo() {

    var token = localStorage.getItem('token');
    this.loadservice.addloader();
    var comppanyInfo = {
      "companyName": this.companyName,
      "companyReg": this.companyReg,
      "make": this.make,
      "model": this.model,
      "registration": this.registration,
      "vinNo": this.vinNo,
      "Vcolor": this.Vcolor,
      "type": this.type
    };

    this.loginService.companyInfo(comppanyInfo, token, null)
      .subscribe(
        (response: Response) => {
          if (response.status == 200) {
            this.loadservice.removeloader();
            $(".docs").removeClass("linkDisabled");
            $('#myTab a[href="#step3"]').tab('show');
            $(".enter-info").addClass("linkDisabled");
            window.scrollTo(0, 0);
          } else {
            this.loadservice.removeloader();
            this.OTPerror = "Error while adding company please contact Droppa.";
            alert(this.OTPerror);
          }
        },
        (err) => {
          this.loadservice.removeloader();
          this.OTPerror = "Error while adding company";
        }
      );

  }

  public backHome() {
    this.loadservice.removeloader();
    $(".docs").addClass("linkDisabled");
    $(".enter-otp").addClass("linkDisabled");
    $(".enter-info").addClass("linkDisabled");

    $('#myTab a[href="#step1"]').tab('show');
    window.scrollTo(0, 0);
  }

  handleFileSelect(evt, fileType: string) {
    var files = evt.target.files;
    var file = files[0];
    if (fileType == 'ID') {
      if (files && file) {
        this.idName = file.name;
        var reader = new FileReader();
        reader.onload = this._handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'DISC') {

      if (files && file) {
        this.diskName = file.name;
        var reader = new FileReader();
        reader.onload = this._discFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } else if (fileType == 'CPIC') {
      if (files && file) {
        this.cpicName = file.name;
        var reader = new FileReader();
        reader.onload = this._cpicFileLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    }

  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64ID = btoa(binaryString);
  }

  _discFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64DISC = btoa(binaryString);
  }

  _cpicFileLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64CIPC = btoa(binaryString);
  }

  public onDocument() {
    var object = JSON.parse(localStorage.getItem('userData'));
    var IDData = {
      "personOId": object.owner.oid,
      "type": "ID_COPY",
      "document": this.base64ID
    };

    var CIPCData = {
      "personOId": object.owner.oid,
      "type": "CIPC_COPY",
      "document": this.base64CIPC
    };

    var DISCData = {
      "personOId": object.owner.oid,
      "type": "DISC_COPY",
      "document": this.base64DISC
    };

    if (this.base64ID != null && this.base64DISC != null) {
      this.loadservice.addloader();
      var token = localStorage.getItem('token');
      this.loginService.uploadDocs(IDData, CIPCData, DISCData, token)
        .subscribe(
          (response: Response) => {
            if (response.status == 200) {
              this.loadservice.removeloader();
              this.documentMsg = 'You have successfully completed all steps to become Droppa, goodluck!.';
              $(".completed").removeClass("linkDisabled");
              $('#myTab a[href="#complete"]').tab('show');
              $(".docs").addClass("linkDisabled");
              window.scrollTo(0, 0);
            } else {
              alert(this.OTPerror);
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error while adding company";
          }
        );
    } else {
      alert("Please attach all document");
    }
  }

  handleCorrectCaptcha(event) {
  }

  skip() {
    $(".docs").addClass("linkDisabled");
    $('#myTab a[href="#complete"]').tab('show');
  }
}
