declare var $: any;
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-retail-rental-success-payment',
  templateUrl: './retail-rental-success-payment.component.html',
  styleUrls: ['./retail-rental-success-payment.component.css']
})
export class RetailRentalSuccessPaymentComponent implements OnInit {

  loader: boolean = false;
  rentalOid: any;
  trackNo: any;
  userObj: any;
  addrressData: any;
  addressData = {
    from: {
      address: ''
    },
    branch: ''
  }

  constructor(private bookService: BookingService, private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => {
      this.rentalOid = params['rentalOid'];
    });
   }

  ngOnInit() {
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.getRentalByOid(this.userObj.token, this.rentalOid).subscribe(
      response => {
        this.addrressData = response;
        console.log(this.addressData);
        this.addressData.from.address = this.addrressData.from.address;
        
        
        this.addressData.branch = this.addrressData.branchDTO.name
      }, error => {
        console.log(error);
        console.log('Error block');
      }, () => {
      }
    )
  }

  public onHome() {
    this.router.navigateByUrl('/retail-profile');
  }

  public bringNavigationPopUp() {
    $('#optionalInvoice').modal('show');
  }

}
