import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-retail-home',
  templateUrl: './retail-home.component.html',
  styleUrls: ['./retail-home.component.css']
})
export class RetailHomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  onDeactivate() {
    document.body.scrollTop = 0;
    window.scrollTo(0, 0);
 }
  
}
