import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
declare var $: any;

@Component({
  selector: 'app-retail-successful-load',
  templateUrl: './retail-successful-load.component.html',
  styleUrls: ['./retail-successful-load.component.css']
})
export class RetailSuccessfulLoadComponent implements OnInit {

  userToken: any;
  sub: any;
  bucketBookingOid: any;
  comments: any;
  invoiceOption: boolean;
  invoiceControl: boolean = false;
  pickUpAddress: any;
  dropOffAddress: any;
  vehicleType: any;
  type: any;
  time: string;
  date: any;
  price: number
  loader: boolean = false;
  addrressData: any;

  constructor(private bookingService: BookingService, private route: ActivatedRoute, private routing: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.bucketBookingOid = params['bucketBookingOid'];
      this.successfulPayment(this.bucketBookingOid);
    });
    this.userToken = JSON.parse(localStorage.getItem('userInfo'));
  }

  public successfulPayment(bookingOid) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingService.getBucketBook(object.token, bookingOid).subscribe(
      response => {
        this.addrressData = response;
        this.pickUpAddress = this.addrressData.bookings[0].pickUpAddress;
        this.dropOffAddress = this.addrressData.bookings[0].dropOffAddress;
      }, error => {
        console.log(error);
        console.log('Error block');
      }, () => {
      }
    )
  }

  public attachInvoiceOrNot() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.invoiceControl = true;
    this.bookingService.invoiceRetailBucketCustomer(object.token, this.addrressData.trackNo).subscribe(
      (response) => {
        this.routing.navigateByUrl("/retail-profile");
      }, (error) => {
        console.log('Error inside the attach invoice block');
        console.log(error);
      }
    );
  }

  public finish() {
    this.attachInvoiceOrNot();
    // $('#finishModal').modal('show');
    this.routing.navigateByUrl("/retail-profile");
  }

}
