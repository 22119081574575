import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { UserLoggInService } from '../../services/loggedInUser.service';
import {UserService} from "../../services/user.service";
import {BookingService} from "../../services/booking.service";

declare var $: any;

@Component({
  selector: 'user-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css', './v-nav-menu.css',
    './theme-responsive.css']
})
export class UserNavbarComponent implements OnInit {

  // @Input('name') userName : string;
  subscription: Subscription;
  name: string;
  userObject: any;
  title = '';

  constructor(private nameService: SharedService, private route: Router, private userService: UserLoggInService) {

    this.subscription = this.nameService.message.subscribe(
      (message) => {
        this.userObject = JSON.parse(localStorage.getItem('userInfo'));
        this.name = this.userObject.owner.firstName;
      }
    );
    // this.subscription.unsubscribe();

  }

  ngOnInit() {
    this.userObject = JSON.parse(localStorage.getItem('userInfo'));

    if (this.userObject != null) {
      this.name = this.userObject.owner.firstName;
      this.title = this.userObject.owner.firstName;
    } else {
      this.title = 'Login';
    }

  }

  gobackHome() {
    console.log(this.userObject.owner);

    // if (this.userObject.owner.retailId != null) {
    //   this.route.navigateByUrl('/retail-profile');
    //   console.log("retail")
    // } else if(this.userObject.owner.retailId != null){
    //   this.route.navigateByUrl('/driver-profile');
    //   console.log("driver-profile")
    // }

  }

  public logout() {
    this.userService.clear();
    localStorage.removeItem('userInfoBooking');
    this.route.navigateByUrl('/');
  }

  public home() {
    // console.log(this.userObject);
    localStorage.getItem('individual') === null ?
      this.route.navigateByUrl('/driver-profile') :
      this.route.navigateByUrl('/user');

    /*let control = 0;
    this.userObject.roles.forEach(role => {
      if (role.code === 'partner') {
        this.route.navigateByUrl('/driver-profile');
        control = 1;
      }
    });
    if (control === 0) {
      this.route.navigateByUrl('/user');
    }*/
  }

  openNav() {
    document.getElementById('mySidenav').style.width = '252px';
  }

  closeNav() {
    document.getElementById('mySidenav').style.width = '0';
  }

  collapse() {
    $(document).ready(function () {
      $('nav').find('li').on('click', 'a', function () {
        $('.navbar-collapse.in').collapse('hide');
      });
    });
  }
}
