import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-web-hook-details',
  templateUrl: './web-hook-details.component.html',
  styleUrls: ['./web-hook-details.component.css', '../admin-portal/admin-portal.component.css']
})
export class WebHookDetailsComponent implements OnInit {

  webhook: any = {};
  userObj: any = {};
  webhookResults: any = {};
  loader: boolean = false;
  waybillevents: [];
  dateVar: string;

  year: string;
  month: string;
  day: string;
  dateConc: string;

  constructor(private adminService: AdminService, private route: Router) { }

  ngOnInit() {
    this.webhook = JSON.parse(localStorage.getItem("webhookEvents"));
    this.userObj = JSON.parse(localStorage.getItem('userInfo'));
    this.viewWaybillEvents();
  }

  public goBack() {
    return this.route.navigateByUrl('/web-hooks');
  }

  public viewWaybillEvents() {
    this.adminService.getShortFallWaybillEvents(this.userObj.token, this.webhook.waybill).subscribe(response => {
      this.webhookResults = response;
      this.waybillevents = response.WaybillParcelDetail;
      this.dateVar = this.webhookResults.EventDate;
      this.year = this.dateVar.substr(0,4);
      this.month = this.dateVar.substr(4,2);
      this.day = this.dateVar.substr(6,2);
      this.dateConc = this.year.concat('/' + this.month).concat('/' + this.day);
    }, error => {
      console.log(error);
    });
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
