import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Company } from '../models/company';
import { SharedService } from './../services/shared.service';

import { User } from '../models/user';

@Injectable()
export class LoginService {

  constructor(private http: Http, private httpClient: HttpClient, private sharedService: SharedService) { }

  SITE_KEY=this.sharedService.SITE_KEY.toString();



  register(user: User): Observable<any> {
    debugger;
    let deviceId = new Date().valueOf();
    var pass = user.login.pwd.split('');
    let userData = {
      "owner": {
        "deviceId": deviceId,
        "mobile": user.mobile,
        "firstName": user.firstName,
        "surname": user.surname,
        "rsaId": user.mobile + "123",
        "email": user.email
      },
      "username": user.email,
      "pwd": pass
    }

    return this.httpClient.post(this.sharedService.getUrl() + "sessions/register/mobile", userData,
    { headers: new HttpHeaders()
      .set("Accept", 'application/json')
      .set('Content-Type', 'application/json')
    });
  }

  registerCompany(user: User): Observable<any> {

    let deviceId = new Date().valueOf();
    var pass = user.login.pwd.split('');
    let userData = {
      "owner": {
        "deviceId": deviceId,
        "mobile": user.mobile,
        "firstName": user.firstName,
        "surname": user.surname,
        "rsaId": user.mobile + "123",
        "email": user.email
      },
      "username": user.email,
      "pwd": pass

    }

    return this.httpClient.post(this.sharedService.getUrl() + "sessions/register/company", userData,
      { headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json') });
  }

  registerRetailUser(user: User): Observable<any> {
    let deviceId = new Date().valueOf();
    var pass = user.login.pwd.split('');
    let userData = {
      "owner": {
        "deviceId": deviceId,
        "mobile": user.mobile,
        "firstName": user.firstName,
        "surname": user.surname,
        "rsaId": user.mobile + "123",
        "email": user.email
      },
      "username": user.email,
      "pwd": pass
    }
    return this.httpClient.post(this.sharedService.getUrl() + "sessions/register/retail", userData,
      { headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json') });
  }

  sendOTPagain(mobile):Observable<any>{
    return this.httpClient.post(this.sharedService.getUrl() + "sessions/resend/otp/"+mobile,'',
      { headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json') });
  }
  sendOTPemail(mobile):Observable<any>{
    return this.httpClient.post(this.sharedService.getUrl() + "sessions/send/otp/via/email/"+mobile,'',
      { headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json') });
  }
  registerUser(user: User): Observable<any> {

    let deviceId = new Date().valueOf();
    var pass = user.login.pwd.split('');
    let userData = {
      "owner": {
        "deviceId": deviceId,
        "mobile": user.mobile,
        "firstName": user.firstName,
        "surname": user.surname,
        "rsaId": user.mobile + "123",
        "email": user.email
      },
      "username": user.email,
      "pwd": pass

    }
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/register", userData, options);
  }


  public developerRegistration(user: User) {

    let deviceId = new Date().valueOf();
    var pass = user.login.pwd.split('');
    let userData = {
      "owner": {
        "deviceId": deviceId,
        "mobile": user.mobile,
        "firstName": user.firstName,
        "surname": user.surname,
        "rsaId": user.mobile + "123",
        "email": user.email
      },
      "username": user.email,
      "pwd": pass
    }
  //  console.log(userData);

    return this.httpClient.post(this.sharedService.getUrl()  + "sessions/register/api", userData ,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }

  confirmOTP(otp: number, phoneNo: string): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/mobile/confirmations/" + phoneNo + "/" + otp, "", options);

  }

  companyInfo(company: any, token: string, object: any): Observable<any> {

    //var object = user;//JSON.parse(localStorage.getItem('userData2'));

    var companyData = {
      "registrationNumber": company.companyReg,
      "name": company.companyName,
      "province" : company.province,
      "vatNo": company.vatNo,
      "contact": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      },
      "owner": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      }
    };

    var vehicleData = {
      "registrationNumber": company.registration,
      "vinNumber": company.vinNo,
      "make": company.make,
      "model": company.model,
      "color": company.Vcolor,
      "type": company.type,
      "companyId": object.owner.deviceId,
      "hasCanopy" : company.hasCanopy,
      "diskDate": company.diskDate
    };

    ///company/info
    var data = {
      "vehicle": vehicleData,
      "company": companyData
    }

    console.log({vehicleData});
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/company/info", data, options);

  }

  company(company: any, token: string, object: any): Observable<any> {

    //var object = user;//JSON.parse(localStorage.getItem('userData2'));

    var companyData = {
      "registrationNumber": company.companyReg,
      "name": company.companyName,
      "contact": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      },
      "owner": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      },
      "address":company.addressBody,
    };

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/retails", companyData, options);

  }
  company2(company: any,  object: any): Observable<any> {

    //var object = user;//JSON.parse(localStorage.getItem('userData2'));

    var companyData = {
      "registrationNumber": company.companyReg,
      "name": company.companyName,
      "contact": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      },
      "owner": {
        "oid": object.owner.oid,
        "deviceId": object.owner.deviceId,
        "mobile": object.owner.mobile,
        "firstName": object.owner.firstName,
        "surname": object.owner.surname,
        "rsaId": object.owner.rsaId,
        "email": object.owner.email
      },
      "address":company.addressBody,
      // "address":{
      //   "addressLine1":"123",
      //   "locality":"centruion",
      //   "city":"polokwane",
      //   "postalCode":"1234",
      // }
    };


    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/retails", companyData, options);

  }
  public captureAddressOnRetail2( token,retailOid, addressBody): Observable<any> {
    var userToken = 'Bearer ' + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + 'parties/retails/' + retailOid + '/address', addressBody, options);
}
  login(password, email): Observable<any> {
    debugger;
    var pass = password.split('');
    var login = {
      "username": email,
      "pwd": password
    }
    // console.log(login);
    return this.httpClient.post(this.sharedService.getUrl() + "sessions/login", login,
      { headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json') });
  }

  uploadDocs(idObject, cpicObject, diskObject, token): Observable<any> {

    var data = [];
    var documents = {};
    if (cpicObject.document == undefined) {
      data = [
        idObject,
        diskObject
      ];
      documents = {
        "documents": data
      }
    } else {
      data = [
        idObject,
        diskObject,
        cpicObject
      ];
      documents = {
        "documents": data
      }
    }


    //console.log(data);
    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/documents", documents, options);

  }

  uploadCPIC(cpicObject, token): Observable<any> {

    var data = [];
    var documents = {};

    data = [
      cpicObject
    ];

    documents = {
      "documents": data
    }

    var userToken = "Bearer " + token;
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append("Authorization", userToken);
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "parties/documents", documents, options);

  }

  activate(code, verificationCode, email): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/account/confirmations/" +
      email + "/" + verificationCode + "/" + code, '', options)
  }

  resetPwd(object: any): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/reset/pwd", object, options)
  }

  resetSkynetPwd(object: any): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/reset/skynet/pwd/", object, options)
  }


  requestPwdReset(email: string): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/reset/pwd/" + email, '', options)
  }

  requestSkynetPwdReset(email: string): Observable<any> {
    var headers = new Headers();
    headers.append("Accept", 'application/json');
    headers.append('Content-Type', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return this.http.post(this.sharedService.getUrl() + "sessions/reset/skynet/pwd/" + email, '', options)
  }


  public bookingrequestPwdReset(email) {

    return this.httpClient.post(this.sharedService.getUrl()  + "sessions/reset/pwd/mobile/" + email, '',
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }


  public confirmBookingOTP(data) {

    return this.httpClient.post(this.sharedService.getUrl()  + "sessions/reset/pwd/mobile", data ,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }


  public vehicleTypeRates(): Observable<any> {
    return this.httpClient.get(this.sharedService.getUrl() + "vehicles/vehicleTypeRate/all" ,
      {
        headers: new HttpHeaders().set("Accept", 'application/json').set('Content-Type', 'application/json')
      });
  }

  public applyReferralCode(referralCodeData): Observable<any> {
    // var userToken = 'Bearer ' + token;
    return this.httpClient.post(this.sharedService.getUrl() + 'bookings/use/referral/code', referralCodeData,
      {
        headers: new HttpHeaders().set('Accept', 'application/json').set('Content-Type', 'application/json')
      }
    );
  }

}
