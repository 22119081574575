import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../services/loader.service';
import { UserService } from '../services/user.service';
declare var $: any;

@Component({
  selector: 'app-developers-portal',
  templateUrl: './developers-portal.component.html',
  styleUrls: ['./developers-portal.component.css']
})
export class DevelopersPortalComponent implements OnInit {
  user: any;
  loginData: any;
  alertMessage
  constructor(private userService:UserService,private loadservice:LoaderService) { }





  whatIsDroppa = false;
  whatIsDroppaRetail = false;
  howDroppaWorks = false;
  activityDiagram = false;
  apiDiagram = false;
  apiRequest = false;
  overview = false;
  sameDayQuote = false;
  sameDayServices = false;
  sameDayQuoteEndpoint = false;
  sameDayBooking = false;
  sameDayBookingInfo = false;
  sameDayBookingEndpoint = false;
  budgetExpressQuote = false;
  budgetExpressQuoteInfo = false;
  budgetExpressQuoteEndpoint = false;
  budgetExpressBooking = false;
  budgetExpressBookingInfo = false;
  budgetExpressBookingEndpoint = false;
  budgetExpressBookingReadyEndpoint = false;
  budgetExpressBookingViewEndpoint = false;
  budgetExpressBookingCancelEndpoint = false;
  fleetQuote = false;
  fleetQuoteInfo = false;
  fleetQuoteEndpoint = false;
  glossary = false;
  glossaryProvince = false;
  glossaryType = false;
  glossaryVehicleType = false;
  glossaryTransportMode = false;

  apiRequestHeader = `
    var headers = new headers();
    headers.append(“accept”, ‘application/json’);
    headers.append(“content-type”, ‘application/json’);
    headers.append(“Authorization”, “Bearer API_KEY:SERVICE_ID”)`;

  sameDayQuoteHeader = `
    URL POST:https://www.droppergroup.co.za/droppa/services/v1/sameday/quotes
    Content-Type : application/Json
    Authorization: Bearer API:ServiceID`;

  sameDayQuoteBody = `
    {
      "mass":1,
      "pickUpProvince": "GAUTENG",
      "dropOffProvince": "WESTERN CAPE",
      "pickUpAddress": "unit 26, Oxford office park, 3 Bauhinia St, Highveld Techno Park, Centurion, 0157, South Africa",
      "dropOffAddress": "Foretrust Building, Martin Hammerschlag Way, Foreshore, Cape Town City Centre, Cape Town, 8001, South Africa",
      "pickUpPCode": "0157",
      "dropOffPCode": "8001",
      "parcelDimensions": [
        {
          "parcel_length": 5,
          "parcel_breadth": 2,
          "parcel_height": 5,
          "parcel_mass": 2
        }
      ]
    }`;

  sameDayQuoteResponse = `
    "oid": null,
    "businessKey": null,
    "quotes": [
        {
          "price": 0.0,
          "serviceName": "SWIFT",
          "description": "Pick up within 60 minutes.motorbikes for parcels that have a maximum weight of 7kg and maximum delivery distance of 15km.",
          "type": "DASH",
          "transportMode": "ROAD",
          "vehicleType": "MOTO_BIKE"
        },
        {
          "price": 13470.0,
          "serviceName": "FLASHROAD",
          "description": "Same day delivery is available nationwide. Collection and delivery within 24 hours. Available on weekends too. Maximum 70kg.",
          "type": "FLASH",
          "transportMode": "ROAD",
          "vehicleType": "DROPPA_EXPRESS"
        },
        {
          "price": 1233.375,
          "serviceName": "FLASHFLIGHT",
          "description": "Same day flight delivery between Gauteng, Durban and Cape town. Flight bookings should be made by 12pm everyday (including weekends). ",
          "type": "FLASH",
          "transportMode": "AIR",
          "vehicleType": "DROPPA_EXPRESS"
        },
        {
          "price": 0.0,
          "serviceName": "DASH",
          "description": "same-day delivery service available in Johannesburg, Pretoria, Durban, and Port Elizabeth, for items up to 15kgs with collection and delivery between 08:00 and 18:00.",
          "type": "DASH",
          "transportMode": "ROAD",
          "vehicleType": "MINI_VAN"
        }
    ],
    "mainCityId": null,
    "distance": 1440.0
}`;

  sameDayBookingHeader = `
    URL POST: https://www.droppergroup.co.za/droppa/services/v1/book
    Content-Type : application/Json
    Authorization: Bearer API:ServiceID`;

  sameDayBookingBody = `
    {
      "dropOffs": [
        {
          "dropOffAddress": "string",
          "dropOff": {
            "oid": "string",
            "businessKey": "string",
            "firstName": "string",
            "lastName": "string",
            "phone": "string",
            "email": "string",
            "companyName": "string",
            "complex": "string",
            "unitNo": "string"
          },
          "parcelDimensions": [
            {
              "parcel_length": 0,
              "parcel_breadth": 0,
              "parcel_height": 0,
              "parcel_mass": 0
            }
          ],
          "fromSuburb": "string",
          "toSuburb": "string",
          "pickUpPCode": "string",
          "dropOffPCode": "string",
          "comment": "string",
          "destinationProvince": "string",
          "itemMass": 0
        }
      ],
      "pickUp": {
        "firstName": "string",
        "lastName": "string",
        "phone": "string",
        "email": "string",
        "companyName": "string",
        "complex": "string",
        "unitNo": "string"
      },
      "vehicleType": "string",
      "pickUpAddress": "string",
      "date": "2022-11-23T10:53:55.164Z",
      "labour": 0,
      "comments": "string",
      "province": "string",
      "serviceId": "string",
      "transportMode": "string",
      "type": "string",
      "isExpress": false
    }`;

  sameDayBookingExample = `
  {
    "serviceId": "630db8502114cc006c1d81815",
    "dropOff": {
      "firstName": "Terrence",
      "lastName": "Manaka",
      "phone": "075515599",
      "email": "terrence@gmail.com"
    },
    "pickUp": {
      "firstName": "Player",
      "lastName": "Maker",
      "phone": "0788966522",
      "email": "player@gmail.com"
    },
    "vehicleType": "MOTO_BIKE",
    "pickUpAddress": "Eco Park Blvd, Eco-Park Estate, Centurion, 0144, South Africa",
    "date": "2023-11-23T10:53:55.163Z",
    "dropOffAddress": "6 Teak Cl, Eco-Park Estate, Centurion, 0144, South Africa",
    "price": 0,
    "province": "GAUTENG",
    "itemMass": 0,
    "transportMode": "ROAD",
    "type": "DASH",
    "pickUpDate": "2023-11-23 05:53",
    "destinationProvince": "GAUTENG",
    "comment": "test developer api",
    "isExpress": false,
    "fromSuburb": "highveld",
    "toSuburb": "highveld",
    "pickUpPCode": "0169",
    "dropOffPCode": "0169",
    "parcelDimensions": [
      {
        "parcel_length": 5,
        "parcel_breadth": 2,
        "parcel_height": 5,
        "parcel_mass": 2
      }
    ],
    "expectedDeliveryDate": "2023-11-23T10:53:55.163Z",
  }`;

  importantForMotorbike = `
  {
    "type": "DASH",
    "transportMode": "ROAD",
    "vehicleType": "MOTO_BIKE",
  }`;

  importantForDash = `
  {
    "type": "DASH",
    "transportMode": "ROAD",
    "vehicleType": "MINI_VAN"
  }`;

  importantForFlashRoad = `
  {
    "type": "FLASH",
    "transportMode": "ROAD",
    "vehicleType": "DROPPA_EXPRESS"
  }`;

  importantForFlashFlight = `
  {
    "type": "FLASH",
    "transportMode": "AIR",
    "vehicleType": "DROPPA_EXPRESS"
  }`;

  budgetExpressQuoteHeader = `
    URL POST: https://www.droppergroup.co.za/droppa/services/v1/courier/quotes
    Content-Type: application/json
    Authorization: Bearer API:serviceID`;

  budgetExpressQuoteBody = `
    {
      "parcelDimensions": [
        {
          "parcel_length": 5,
          "parcel_breadth": 2,
          "parcel_height": 5,
          "parcel_mass": 2
        }
      ],
      "pickUpPCode": "0082",
      "dropOffPCodes": ["2194"]
    }`;

  budgetExpressQuoteResponse = `
  {
    "pickUpPCode": "0082",
    "quotes": [
      {
        "dropOffPCode": "2194",
        "express": {
          "price": 98.08,
          "serviceName": "EXPRESS",
          "description": "Delivery between 1-3 working days"
        },
        "budget": {
          "price": 82.49,
          "serviceName": "BUDGET",
          "description": "Delivery between 2-5 working days"
        }
      },
    ]
  }`;

  budgetExpressBookingHeader = `
    URL POST: https://www.droppergroup.co.za/droppa/services/v1/book
    Content-Type: application/json
    Authorization: Bearer API: serviceID`;

  budgetExpressBookingBody = `
    {
      "serviceId": "630db8502114cc006cd81815",
      "dropOff": {
        "firstName": "Terrence",
        "lastName": "Manaka",
        "phone": "075515599",
        "email": "terrence@gmail.com"
      },
      "pickUp": {
        "firstName": "Player",
        "lastName": "Maker",
        "phone": "0788966522",
        "email": "player@gmail.com"
      },
      "vehicleType": "DROPPA_EXPRESS",
      "pickUpAddress": "Eco Park Blvd, Eco-Park Estate, Centurion, 0144, South Africa",
      "date": "2023-11-23T10:53:55.163Z",
      "dropOffAddress": "6 Teak Cl, Eco-Park Estate, Centurion, 0144, South Africa",
      "price": 0,
      "province": "GAUTENG",
      "itemMass": 0,
      "transportMode": "ROAD",
      "type": "EXPRESS_COURIER", or “BUDGET_COURIER”
      "pickUpDate": "2023-11-23 05:53",
      "destinationProvince": "GAUTENG",
      "comment": "test developer api",
      "isExpress": false,
      "fromSuburb": "highveld",
      "toSuburb": "highveld",
      "pickUpPCode": "0169",
      "dropOffPCode": "0169",
      "parcelDimensions": [
        {
          "parcel_length": 5,
          "parcel_breadth": 2,
          "parcel_height": 5,
          "parcel_mass": 2
        }
      ],
      "expectedDeliveryDate": "2023-11-23T10:53:55.163Z",
    }`;

  budgetExpressBookingResponse = ``;

  budgetExpressBookingReadyHeader = `
    URL POST: https://www.droppergroup.co.za/droppa/services/v1/ship/bookings/{trackNo}
    Content-Type: application/json
    Authorization: Bearer API:ServiceID`;

  sameDayBookingViewHeader = `
    URL GET: https://www.droppergroup.co.za/droppa/services/v1/sameday/booking/{serviceID}
    Content-Type: application/json
    Authorization: Bearer API:ServiceID`;
  budgetExpressBookingViewHeader = `
    URL GET: https://www.droppergroup.co.za/droppa/services/v1/express/booking/{serviceID}
    Content-Type: application/json
    Authorization: Bearer API:ServiceID`;

  budgetExpressBookingCancelHeader = `
    URL PUT: https://www.droppergroup.co.za/droppa/services/v1/booking/cancel/{trackNo}
    Content-Type: application/json
    Authorization: Bearer API:ServiceID`;

  fleetQuoteHeader = `
    URL POST: https://www.droppergroup.co.za/droppa/services/v1/bucket/quote
    Content-Type: application/json
    Authorization: Bearer API:ServiceID`;

  fleetQuoteBody = `
    {
      "pickUpAddress": "Eco Park Blvd, Eco-Park Estate, Centurion, 0144, South Afric",
      "dropOffAddresses": [
        "Southward Dr, Olifantsfontein 410-JR, Tembisa, South Africa","Cape Rd, Newton Park, Gqeberha, South Africa",
        "Centurion Cres, Ebotse Golf & Country Estate, Benoni, 1501, South Africa"
      ],
      "vehicleType": "MINI_VAN",
      "serviceId": "630db8502114c006cd81815", Droppa will provide serviceID
      "load": 0,
      "canopy": false,
      "labour": 2
    }`;

  fleetQuoteResponse = `
  {
    "totalAmount": 17377.55,
    "dropOffQuotes": [
      {
        "price": 195.5,
        "dropOffAddress": "Southward Dr, Olifantsfontein 410-JR, Tembisa, South Africa",
        "distance": 17.0
      },
      {
        "price": 16280.55,
        "dropOffAddress": "Cape Rd, Newton Park, Gqeberha, South Africa",
        "distance": 1089.0
      },
      {
        "price": 701.5,
        "dropOffAddress": "Centurion Cres, Ebotse Golf & Country Estate, Benoni, 1501, South Africa",
        "distance": 61.0
      }
    ]
  }`;

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.loginData = JSON.parse(localStorage.getItem('userInfoBookingLogin'));
    window.scrollTo(0, 0);
    if(this.user){
      this.getAlertMessage()
    }

  }
  public getAlertMessage(){
    this.loadservice.addloader()
    this.userService.getAlertMessage(this.user.token,this.user.oid)
    .subscribe((res)=>{

      this.alertMessage=res.json()
      console.log(res.json());

      if(this.alertMessage&&this.alertMessage.message&&!this.alertMessage.seen){
        $("#alertmessage").modal("show")
      }
      this.loadservice.removeloader()
    },(error)=>{
      console.log(error);
      this.loadservice.removeloader()
    },()=>{
      this.loadservice.removeloader()
    })
  }

  colorSelectedButton(buttonName: string) {
    this.overview = false;
    this.whatIsDroppa = false;
    this.whatIsDroppaRetail = false;
    this.howDroppaWorks = false;
    this.activityDiagram = false;
    this.apiDiagram = false;
    this.apiRequest = false;
    this.sameDayQuote = false;
    this.sameDayServices = false;
    this.sameDayQuoteEndpoint = false;
    this.sameDayBooking = false;
    this.sameDayBookingInfo = false;
    this.sameDayBookingEndpoint = false;
    this.budgetExpressQuote = false;
    this.budgetExpressQuoteInfo = false;
    this.budgetExpressQuoteEndpoint = false;
    this.budgetExpressBooking = false;
    this.budgetExpressBookingInfo = false;
    this.budgetExpressBookingEndpoint = false;
    this.budgetExpressBookingReadyEndpoint = false;
    this.budgetExpressBookingViewEndpoint = false;
    this.budgetExpressBookingCancelEndpoint = false;
    this.fleetQuote = false;
    this.fleetQuoteInfo = false;
    this.fleetQuoteEndpoint = false;
    this.glossary = false;
    this.glossaryProvince = false;
    this.glossaryType = false;
    this.glossaryVehicleType = false;
    this.glossaryTransportMode = false;

    switch (buttonName) {
      case 'overview' : this.overview = true; break;
      case 'whatIsDroppa' : this.whatIsDroppa = true; break;
      case 'whatIsDroppaRetail': this.whatIsDroppaRetail = true; break;
      case 'howDroppaWorks': this.howDroppaWorks = true; break;
      case 'activityDiagram': this.activityDiagram = true; break;
      case 'apiDiagram': this.apiDiagram = true; break;
      case 'apiRequest': this.apiRequest = true; break;
      case 'sameDayQuote': this.sameDayQuote = true; break;
      case 'sameDayServices': this.sameDayServices = true; break;
      case 'sameDayQuoteEndpoint': this.sameDayQuoteEndpoint = true; break;
      case 'sameDayBooking': this.sameDayBooking = true; break;
      case 'sameDayBookingInfo': this.sameDayBookingInfo = true; break;
      case 'sameDayBookingEndpoint': this.sameDayBookingEndpoint = true; break;
      case 'budgetExpressQuote': this.budgetExpressQuote = true; break;
      case 'budgetExpressQuoteInfo': this.budgetExpressQuoteInfo = true; break;
      case 'budgetExpressQuoteEndpoint': this.budgetExpressQuoteEndpoint = true; break;
      case 'budgetExpressBooking': this.budgetExpressBooking = true; break;
      case 'budgetExpressBookingInfo': this.budgetExpressBookingInfo = true; break;
      case 'budgetExpressBookingEndpoint': this.budgetExpressBookingEndpoint = true; break;
      case 'budgetExpressBookingReadyEndpoint': this.budgetExpressBookingReadyEndpoint = true; break;
      case 'budgetExpressBookingViewEndpoint': this.budgetExpressBookingViewEndpoint = true; break;
      case 'budgetExpressBookingCancelEndpoint': this.budgetExpressBookingCancelEndpoint = true; break;
      case 'fleetQuote': this.fleetQuote = true; break;
      case 'fleetQuoteInfo': this.fleetQuoteInfo = true; break;
      case 'fleetQuoteEndpoint': this.fleetQuoteEndpoint = true; break;
      case 'glossary': this.glossary = true; break;
      case 'glossaryProvince': this.glossaryProvince = true; break;
      case 'glossaryType': this.glossaryType = true; break;
      case 'glossaryVehicleType': this.glossaryVehicleType = true; break;
      case 'glossaryTransportMode': this.glossaryTransportMode = true; break;
    }
  }

}
