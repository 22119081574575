import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';
import { SharedService } from '../../services/shared.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-driver-profile',
  templateUrl: './driver-profile.component.html',
  styleUrls: ['./driver-profile.component.css']
})
export class DriverProfileComponent implements OnInit {

  @ViewChild('vehicleForm', { static: true }) vehicleForm: NgForm;
  @ViewChild('assignForm', { static: true }) assignForm: NgForm;
  @ViewChild('bankForm', { static: true }) addVForm: NgForm;

  userName: string = "Nicolas Rasekgala";
  user: any;
  company: any = {};
  vehicles: any = [];
  drivers: any = [];
  deliveries: any = [];
  driverMore: any = [];

  deliverys: any = [];
  payment: any = [];
  foundDriver: any = {};

  valid: boolean = false;
  loader: boolean = false;

  make: string = '';
  model: any;
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  type: string = '';
  vehicleFeedBack: string = '';
  idNo: string;
  err: string = '';
  assignStatus: string = '';
  vehicleOid: string;

  bankFeedBack: string = "";
  bankName: string;
  accountName: string;
  branchCode: string;
  accountNo: string;
  bankID: string;

  isBank: boolean = true;
  bank: any;
  lat: number = -25.990886;
  lng: number = 28.129811999999998;
  index: any;
  totalItems: number;
  itemsPage: number = 1;
  totalItemsDriver: number;
  itemsPage_driver: number = 1;
  totalItemsVehicle: number;
  itemsPage_vehicle: number = 1;

  vihiclePerPage: number;
  vehiclePage: number = 1;
  totalVehicles: number;

  driverDetails: any;
  driverOid: any;
  today: number = Date.now();
  totalPrice: number;
  userMobile: string;
  total: number = 0.0;

  diskExpiryDate: any;
  vehicleFeed: any;
  driverObj = {
    color: "",
    diskDate: "",
    driverOid: "",
    hasCanopy: '',
    make: "",
    model: "",
    oid: "",
    ownerName: "",
    ownerOid: "",
    registrationNumber: "",
    type: "",
    vinNumber: ""
  }

  bankDetails = {
    accountName: "",
    accountNo: "",
    bankName: "",
    branchCode: ""
  }

  totalPaid: number = 0;
  unpaidAmount: number = 0;
  numberOfUnpaid: number = 0;

  editFirstName=false
  editLastName=false
  editEmail=false
  editOwnerName=false
  editRegistrationNumber=false

  ownerFirstName
  ownerLastName
  ownerEmail
  ownerRegistraNumber
  token
  btnText="Update"
  startDate
  endDate
  skynetPartner=false
  penalties
  driver

  deliveriesTable = true;
  vehiclesTable = false;
  skyNetTable = false;
  driversTable = false;
  alertMessage

  constructor(private userService: UserService, private nameService: SharedService,
              private route: Router, private adminService: AdminService, private loadservice: LoaderService) { }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('userInfo'));
    let fullName = this.user.owner.firstName + ' ' + this.user.owner.surname;
    this.nameService.setName(fullName);


    this.recieveCompany();

    this.button();
    this.getAlertMessage();
  }

  public getAlertMessage() {
    this.loadservice.addloader();
    this.userService.getAlertMessage(this.user.token, this.user.oid).subscribe(
      (res) => {

      this.alertMessage = res.json();
      // console.log(res.json());

      if (this.alertMessage && this.alertMessage.message && !this.alertMessage.seen) {
        $('#alertmessage').modal('show');
      }
      this.loadservice.removeloader();
    }, (error) => {
      console.log(error);
      this.loadservice.removeloader();
    }, () => {
      this.loadservice.removeloader();
    });
  }

  public selectedDriver(driver){
    this.driver=driver
    const filter={
      "driverOid":this.driver.oid,
      "startMonth":1,
      "startYear":2020,
      "endMonth":12,
      "endYear":2023,
      "numRecords":0,
      "startRecord":0,
      "getNewRecords":false
  }
  this.loadservice.addloader();
  this.userService.getPenaltiesList(this.user.token, filter)
    .subscribe(
      (res: Response) => {
        console.log(res.json());
        this.penalties=res.json()
        this.loadservice.removeloader();
        $("#veiwPenalties").modal('show')
      },
      (err) => {
        console.log(err);
        this.loadservice.removeloader();
      }
    );
  }

  public recieveCompany(){
    this.loadservice.addloader();
    this.userService.retrieveCompany(this.user.token, this.user.owner.oid)
      .subscribe(
        (response: Response) => {
          this.loadservice.removeloader();
          // console.log(response.json());
          if (response.status === 200) {
            if (response.json()[0] != null) {
              this.company = response.json()[0];
              // console.log(this.company);
              /*this.userService.getPartnerBundleBookingPayment(this.user.token, this.company.oid).subscribe(
                (stats) => {
                  console.log(stats);
                }
              );*/
              if(this.company.contractOwnerKey){
                this.skynetPartner=true
                // localStorage.setItem("company",JSON.stringify(this.company))
                if(this.user.owner.firstName==='NO FIRST NAME'||this.user.owner.firstName==='--')this.editFirstName=true
                if(this.user.owner.surname==='NO LAST NAME'||this.user.owner.surname==='--')this.editLastName=true
                if(this.company.ownerName==='NO FIRST NAME NO LAST NAME'||this.company.ownerName==='--')this.editOwnerName=true
                if(this.user.owner.email.indexOf('fakeemail')>=0)this.editEmail=true
                if(this.company.registrationNumber.indexOf('fake')>=0)this.editRegistrationNumber=true
                this.getFreightTransactionDetailByOwner()
              }


              if(this.editFirstName||this.editLastName||this.editEmail||this.editOwnerName||this.editRegistrationNumber){
                $('#updateDetails').modal('show');
              }
              this.getDeliveries();
              this.getBankAccount();
              this.getDrivers();
              // this.loadservice.addloader();
            } else {
              // this.loadservice.addloader();
              this.route.navigateByUrl('/add-company');
            }
          }
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText == "Unknown Error" || err.statusText == "Internal Server Error") {
            this.route.navigate(['/partner-signin']);
          }
        }, () => {
          this.loadservice.removeloader();
        }
      );
  }

  public normalCompanyCall(){
    this.userService.retrieveCompany(this.user.token, this.user.owner.oid)
      .subscribe(
        (response: Response) => {
          this.loadservice.addloader();
          // console.log(response.json());
          if (response.status === 200) {
            if (response.json()[0] != null) {
              this.company = response.json()[0];
              localStorage.setItem("company",JSON.stringify(this.company))
              this.loadservice.removeloader();
            }
          }
        },
        (err) => {
          if (err.statusText == "Unknown Error" || err.statusText == "Internal Server Error") {
            this.route.navigate(['/partner-signin']);
          }
        }, () => {
          this.loadservice.removeloader();
        }
      );
  }

  public getVehicles() {
    this.userService.retrieveVehicles(this.user.token, this.company.oid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            // Called method
            this.vehicles = res.json();
            // console.log(this.vehicles);
          }
        }
      );
  }

  updateNewInfo=""

  public updateDetails(){
    this.updateNewInfo=""
   this.btnText="Loading..."
    const ownerData={
      personOid:this.user.owner.oid,
      contractOwnerKey:this.company.contractOwnerKey,
      firstName:this.ownerFirstName,
      lastName:this.ownerLastName,
      email:this.ownerEmail,
      registrationNumber:this.ownerRegistraNumber,
    }
    this.userService.updateDetails(this.user.token, ownerData)
    .subscribe(
      (res: Response) => {
       localStorage.setItem('userInfo',JSON.stringify(res.json()));
       this.user = res.json();
        this.normalCompanyCall()
       $('#updateDetails').modal('hide');
       this.btnText="Done"
      },
      (err) => {
        console.log(err.json())
        this.updateNewInfo=err.json().message
        this.btnText="Update"
      },
      ()=>{

      }
    );
  }

  vehicle

  public selectVehicle(vehicle){
    this.vehicle=vehicle
    localStorage.setItem('vehicle',JSON.stringify(vehicle))



  }

  chosenYearHandler(event){
    console.log(event);

  }

  startMonth:any
  startYear:any
  endMonth:any
  endYear:any
  rangeMsg=""

  public viewTransactionSummary(){
    this.rangeMsg=""
    var currentDate = new Date(this.startDate);
    var currentDate2 = new Date(this.endDate);

    this.startMonth=(currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    this.startYear=currentDate.getFullYear()

    this.endMonth= (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    this.endYear=currentDate2.getFullYear()

    if(this.startYear>this.endYear){
      this.rangeMsg="Error with selected years"
      return
    }else if(this.startYear==this.endYear && this.startMonth>this.endMonth){
      this.rangeMsg="Error with selected months"
      return
    }
    const filter={
      "companyOid":this.company.oid,
      "vehicleRegistration":this.vehicle.registrationNumber,
      "route":"",
      "startMonth":this.startMonth,
      "startYear":this.startYear,
      "endMonth":this.endMonth,
      "endYear":this.endYear
  }
    this.loadservice.addloader();
    this.userService.getFreightTransactionSummary(this.user.token, filter)
    .subscribe(
      (res: Response) => {
        console.log(res.json());
        localStorage.setItem("filter",JSON.stringify(filter));
        localStorage.setItem("transactionsummary",JSON.stringify(res.json()));
        this.loadservice.removeloader();
        $('#transactionmodal').modal('hide');
        this.route.navigateByUrl('/owner-financial');
      },
      (err) => {
        console.log(err);
        this.loadservice.removeloader();
      }
    );
  }

  transactions
  transaction

  public getFreightTransactionDetailByOwner(){

    const filter={
      "oId":this.company.oid,
      "startMonth":1,
      "startYear":2020,
      "endMonth":12,
      "endYear":2023,
      "numRecords":0,
      "startRecord":0,
      "getNewRecords":false
  }

    this.userService.getFreightTransactionDetailByOwner(this.user.token, filter)
    .subscribe(
      (res: Response) => {
        this.transactions=res.json()
        this.loadservice.removeloader();
      },
      (err) => {
        this.loadservice.removeloader();
        console.log(err)
      }
    );
  }

  public viewAllData(transaction){
    this.transaction=transaction
    $('#transactionmodal').modal('show');
  }

  // Called method
  public getDrivers() {
    if(this.company.contractOwnerKey){
      this.userService.getContractOwnerDrivers(this.user.token, this.company.contractOwnerKey)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.loadservice.removeloader();
            this.drivers=res.json();
          }
        },
        (err) => {
          console.log(err)
          this.loadservice.removeloader();
        }
      );
    }else{
    this.userService.retrieveVehicles(this.user.token, this.company.oid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.vehicles = res.json();
            // console.log(this.vehicles);
            for (let truck of this.vehicles) {
              if (truck.driverOid != null) {
                this.userService.retrieveDrivers(this.user.token, truck.driverOid)
                  .subscribe(
                    (res: Response) => {
                      if (res.status == 200) {
                        this.loadservice.removeloader();
                        this.drivers.push(res.json());
                        // this.drivers(res.json());
                      }
                    },
                    (err) => {
                      this.loadservice.removeloader();
                    }
                  );
              }
            }
          }
        }
      );
    }
  }

  // Called method
  public getDeliveries() {

      this.userService.retrieveDeliveris(this.user.token, this.company.oid)
        .subscribe(
          (res: Response) => {
            if (res.status === 200) {
              this.deliverys = res.json();
              console.log(this.deliverys);
              this.loadservice.removeloader();
              this.getStats();
              this.getTotalPayment();
              this.getTotalWeight();
              for (var m = 0; m < this.deliverys.length; m++) {
                this.deliveries.push(this.deliverys[m]);
              }
              this.deliveries = this.deliveries.reverse();
              // console.log(this.deliveries);
            }
          },
          (err) => {
            this.loadservice.removeloader();
          }
        );

  }

  public getTotalWeight() {
    this.deliverys.forEach(x => {
      this.total += (x.cost / 100);
    });
  }

  public getStats() {
    this.deliverys.forEach(x => {
      // console.log(x.isPayed);
      if (x.isPayed) {
        // The formula below removes 15% VAT and the Droppa fee (20%) in this order
        this.totalPaid += (x.cost / 100) * (100 / 115) * (100 / 120);
      } else {
        this.unpaidAmount += (x.cost / 100) * (100 / 115) * (100 / 120);
        this.numberOfUnpaid += 1;
      }
    });

  }

  public getTotalPayment() {
    this.deliverys.forEach(x => {
      this.total += (x.cost / 100);
    });
  }

  button() {
    var header = document.getElementById("myTab");
    var btns = header.getElementsByClassName("tabs");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activee");
        current[0].className = current[0].className.replace(" activee", "");
        this.className += " activee";
      });
    }
  }

  public getPayment(token, companyOid) {
    this.userService.retrievePayment(token, companyOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.payment = res.json();
            this.loadservice.removeloader();
          }
        },
        (err) => {
          this.loadservice.removeloader();
        }
      );
  }

  public onAddVehicle() {
    var currentDate = new Date();
    var dateValue = this.diskExpiryDate;
    var realTime = new Date(dateValue);
    var currentDate = new Date();
    var dateFormat = currentDate.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var diskDate = realTime.toLocaleString('en-us', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
    var dateSame = diskDate === dateFormat;
    var dateDiffer = diskDate > dateFormat;
    var dateStart = new Date(currentDate);
    var dateEnd = new Date(diskDate);
    var Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    var vehicleData = {
      "registrationNumber": this.registration,
      "vinNumber": this.vinNo,
      "make": this.make,
      "model": this.model,
      "color": this.Vcolor,
      "type": this.type,
      "companyId": this.company.oid,
      "diskDate": diskDate
    };

    if (dateSame === true || dateDiffer === true) {
      if (Difference_In_Days > 7) {
        if (vehicleData.type == "ONE_HALF_TRUCK" && vehicleData.model <= 2010 || vehicleData.type == "ONE_TON_TRUCK" && vehicleData.model <= 2010) {
          $('#vehicleModel').modal('show');
          this.vehicleFeed = "We don't accept any vehicle made before 2011.";
        } else {
          if (this.company.oid != null) {
            this.userService.addVehicle(this.user.token, vehicleData)
              .subscribe(
                (res: Response) => {
                  this.loadservice.removeloader();
                  if (res.status == 200) {
                    this.getVehicles();
                    this.vehicleForm.reset();
                    this.vehicleFeedBack = "Vehicle was successfully added.";
                    this.loadservice.removeloader();
                  }
                },
                (err) => {
                  this.loadservice.removeloader();
                  this.vehicleFeedBack = "Error while adding a vehicle, please verify your entries.";
                }
              );
          }
        }
      }
      else {
        this.vehicleFeed = 'License disk is expiring in less than 7 days, Please renew it first to create an account';
        $('#vehicleModel').modal('show');
        this.loadservice.removeloader();
      }
    }
    else {
      this.vehicleFeed = 'Licence disk expiry date has  already passed.';
      $('#vehicleModel').modal('show');
      this.loadservice.removeloader();
    }
  }

  public findDriver() {
    this.valid = false;
    this.err = "";
    this.foundDriver = {};
    if (this.idNo != null && this.idNo.length == 10) {
      this.userService.checkDriver(this.user.token, this.idNo)
        .subscribe(
          (res: Response) => {
            this.loadservice.removeloader();
            if (res.status == 200) {
              this.valid = true;
              this.foundDriver = res.json();
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.err = err.json().message;
          }
        );
    }
  }

  public getVehicleOid(vehicleOid: string) {
    this.vehicleOid = vehicleOid;
  }

  public assignNow() {
    this.userService.assignDriver(this.user.token, this.vehicleOid, this.foundDriver.oid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.assignStatus = "Driver successfully assigned a vehicle.";
          }
          this.loadservice.removeloader();
        },
        (err) => {
          this.loadservice.removeloader();
          this.assignStatus = err.json().message;//"Error occured, Please contact Droppa.";
        }
      );
  }

  private getBankAccount() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getBankAccount(object.token, this.company.oid).subscribe(
      (res) => {
        this.loadservice.removeloader();
        this.bank = res;
        this.bankDetails = {
          accountName: this.bank.accountName,
          accountNo: this.bank.accountNo,
          bankName: this.bank.bankName,
          branchCode: this.bank.branchCode
        }
        this.isBank = false;
      },
      (err) => {
        this.loadservice.removeloader();
        this.isBank = true;
      }
    )
  }

  public updateBank() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var account = {
      "accountNo": this.accountNo,
      "bankName": this.bankName,
      "branchCode": this.branchCode,
      "accountName": this.accountName,
      "companyOid": this.company.oid
    }
    if (this.bank == null) {
      this.adminService.bankAccount(account, object.token).subscribe(
        (res) => {
          this.bankFeedBack = "Bank account added";
          this.addVForm.reset();
          this.getBankAccount();
        },
        (error) => {
          this.bankFeedBack = "Error while add bank account added";
        }
      );
    }
    else {
      var accountUpdate = {
        "oid": this.bank.oid,
        "accountNo": this.accountNo,
        "bankName": this.bankName,
        "branchCode": this.branchCode,
        "accountName": this.accountName,
        "companyOid": this.company.oid
      }
      this.adminService.updateBankAccount(accountUpdate, this.bank.oid, object.token).subscribe(
        (res) => {
          this.bankFeedBack = "Bank account updated";
          $('#feedbackDialog').modal('show');
          this.addVForm.reset();
          this.ngOnInit();
        },
        (error) => {
          this.bankFeedBack = "Error while add bank account added";
          $('#feedbackDialog').modal('show');
        }
      );
    }
  }

  public changeTable(table) {
    this.deliveriesTable = false;
    this.vehiclesTable = false;
    this.skyNetTable = false;
    this.driversTable = false;

    switch (table) {
      case 'deliveriesTable' : this.deliveriesTable = true; break;
      case 'skyNetTable' : this.skyNetTable = true; break;
      case 'vehiclesTable' : this.vehiclesTable = true; break;
      case 'driversTable' : this.driversTable = true; break;
    }
}

}


