import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-payment-gateway-page',
  templateUrl: './payment-gateway-page.component.html',
  styleUrls: ['./payment-gateway-page.component.css', '../admin-portal/admin-portal.component.css']
})
export class PaymentGatewayPageComponent implements OnInit {

  adminUser: any;
  paymentOptions = [];
  paymentType: string = '';
  isActive: boolean;
  responseMsg: string = '';
  @ViewChild('promoForm', { static: true }) mainForm: NgForm;

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getPaymentOptions();
  }

  public change(event) {
    this.isActive = event;
  }

  public addPaymentType() {
    let paymentObj = {
      "optionType": this.paymentType,
      "isActive": this.isActive
    }

    console.log(paymentObj);

    this.adminService.addPaymentType(this.adminUser.token, paymentObj).subscribe(response => {
      console.log(response);
      this.responseMsg = 'Payment type successfully added';
      this.getPaymentOptions();
      this.mainForm.reset();
    }, error => {
      console.log(error);
    }, () => {
      console.log('Finally');
    })

  }

  public getPaymentOptions() {
    this.adminService.listAvailPaymentOptions(this.adminUser.token).subscribe(
      response => { this.paymentOptions = response, console.log(this.paymentOptions) },
      error => console.log(error)
    );
  }

  public updatePaymentOption(data, status) {
    let paymentObj = {
      "optionOid": data.oid,
      "isActive": status
    }

    if (status == "Active") {
      paymentObj.isActive = true;
    }
    else {
      paymentObj.isActive = false;
    }

    this.adminService.updatePaymentType(this.adminUser.token, paymentObj).subscribe(
      response => console.log(response),
      error => console.log(error),
      () => this.getPaymentOptions()
    );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
