import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-view-api-user',
  templateUrl: './view-api-user.component.html',
  styleUrls: ['./view-api-user.component.css']
})
export class ViewApiUserComponent implements OnInit {


  user: any = {};
  selectedRole: any;
  responseMsg: string = "";
  msg: string = "";
  loader: boolean = false;
  role;
  status: string;

  testApiKey: any;
  testServiceID: any;

  storeName: string = '';
  eventsWebhook: string = '';
  storeMsg: any
  eventsWebhookMsg: any

  constructor(private adminService: AdminService) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('selectedUser'));
    console.log(this.user);
  }

  public changeStatus() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.changeUserStatus(object.token, this.user.serviceId, this.status).subscribe(
      (res) => {
        // console.log({res})
        this.responseMsg = "Status changed.";
      },
      (error) => {
        // console.log({error})
        this.responseMsg = "status not changed."
      }
    );
  }

  public addTestData() {

    var data = {
      "apiKey": this.testApiKey,
      "serviceId": this.testServiceID
    }
    // console.log(data)


    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.testData(object.token, this.user.serviceId, data).subscribe(
      (res) => {
      // console.log({res})
      this.msg = "Test Data Succesfully added!"
      },
      (error) => {
        this.msg = "Test Data Not added!"
        // console.log({error})
      }
    );


  }

  public blockUser() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.blockUser(object.token, this.user.person.email).subscribe(
      (res) => {
        alert("User is blocked");
      },
      (error) => {
        alert("Error while blocking user..");
      }
    );
  }

  public activateUser() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.activateUser(object.token, this.user.person.email).subscribe(
      (res) => {
        alert("User is activated");
      },
      (error) => {
        alert("Error while activating user..");
      }
    );
  }

  public storePlugInName() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    // console.log(this.storeName);
    this.adminService.assingStoreName(object.token, this.user.oid, this.storeName).subscribe(
      response => {
        // console.log(this.storeName);
        console.log(response.json());
        localStorage.setItem('selectedUser', JSON.stringify(response.json()));
        this.user.storeName = response.json().storeName;
        this.storeMsg = "Store name saved.";
      }, error => {
        console.log(error);
        this.storeMsg = error.statusText;
      }
    )
  }
  public addEventWebHook(){
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.eventsWebhookMsg=""
    if(!this.eventsWebhook)
    {
      this.eventsWebhookMsg="please enter api webhook";
      return
    }
    if(this.eventsWebhook.trim()==""){
      this.eventsWebhookMsg="please enter api webhook";
      return
    }
    const webhook={
      "eventsWebhook":this.eventsWebhook,
      "serviceId":this.user.person.oid
    }
    this.adminService.addEventWebHook(object.token, webhook).subscribe(
      response => {
        // console.log(this.storeName);
        console.log(response.json());

      }, error => {
        console.log(error);
        this.eventsWebhookMsg = error.statusText;
      }
    )
  }


}
