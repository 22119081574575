import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
declare var google: any;
@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.css', '../admin-portal/admin-portal.component.css']
})
export class WarehousesComponent implements OnInit {

  @ViewChild('warehouseForm', { static: true }) warehouseForm: NgForm;
  geometry: any = {};
  location: any = {};
  coordinates: any = [];
  newCoordinates: any = [];
  retrievedLocations: any = [];
  retrievedSheshaLocations: any = [];
  address: any;
  postal: any;
  name: any;
  province: any;
  locationObj: any;
  feedbackMsg: any;
  zone: any;
  postalCode: any;
  postalError: any;
  zoneError: any;
  pickUpCoordinate = [];
  noPostalcode:boolean=false;
  menualPostalcode:boolean=false;
  addPOI:boolean=false;
  btnTitle:any="Add New POI";
  selectedPOIweather:any={};
  weatherStatus:any;

  warehouseBody = {
    location: {
      coordinates: [
        "string"
      ],
      address: "string"
    },
    name: "string",
    province: "string",
    type: "string",
    zone:"string",
    postalCode:'string'
  }

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter point of interest.'
  };

  adminUser: any;
  locationType: string;

  badWeather = false;
  lightWeather = false;

  constructor(private service: AdminService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.retrieveWareHouseLocations();
  }
  addNewPOI(){
    if(this.addPOI){
      this.btnTitle="Add New POI";
      this.addPOI=false;
    }else{
      this.btnTitle="Cancel";
      this.addPOI=true;
    }
  }

  autoCompleteCallback($event) {
    this.locationObj = $event;
    this.pickUpCoordinate = [
      this.locationObj.data.geometry.location.lat,
      this.locationObj.data.geometry.location.lng
    ];

    this.getPostalCode(this.locationObj)
  }

  getPostalCode(selectedData){
    this.noPostalcode=false;

    for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.postalCode = selectedData.data.address_components[x].long_name;
              this.noPostalcode=false;
            }else{

              this.noPostalcode=true;
            }
            // if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1'){
            //   console.log(selectedData.data.address_components[x].long_name)
            //   console.log(selectedData)
            // }
          }
        }
      }

  }

  weather = {
    pointOfInterestOid: '',
    weatherStatus: ''
  };

  public changeWeather(code) {

    for (const weatherLocation of this.retrievedSheshaLocations) {
      this.weather.pointOfInterestOid = weatherLocation.oid;

      switch (code) {
        case 1:
          this.weather.weatherStatus = 'NORMAL_WEATHER';
          break;
        case 2:
          this.weather.weatherStatus = 'LIGHT_WEATHER';
          break;
        case 3:
          this.weather.weatherStatus = 'BAD_WEATHER';
          break;
      }

      // console.log(this.weather);

      this.service.changeWeatherStatus(this.adminUser.token, this.weather).subscribe(
        (res) => {
          // console.log(res);
        }, (err) => {
          // console.log(err);
        });
    }

    this.retrieveWareHouseLocations();

    /*this.service.changeWeatherStatus(this.adminUser.token, weather).subscribe(
      (res) => {
      console.log(res);
      this.retrieveWareHouseLocations();
    }, (err) => {
      console.log(err);
    });*/
  }

  public storeWarehouse() {
    this.postalError = '';
    this.zoneError = '';
    // if(this.noPostalcode&&!this.menualPostalcode){
    //   this.postalError="postal address does't exist";
    //   return
    // }
    // if(!this.postalCode){
    //   this.postalError="Please enter postal code";
    //   return
    // }
    if(this.locationType=="SHESHA"&&!this.zone){
      this.zoneError="Select a zone";
      return
    }
    if(this.locationType!="SHESHA"){
      this.postalCode=null;
    }
    this.warehouseBody = {
      location: {
        coordinates: [this.locationObj.data.geometry.location.lat.toString(), this.locationObj.data.geometry.location.lng.toString()],
        address: this.locationObj.data.formatted_address
      },
      name: this.name,
      province: this.province,
      type: this.locationType,
      zone:this.zone,
      postalCode:this.postalCode
    }
    //
    this.service.addPOI(this.adminUser.token, this.warehouseBody).subscribe(
      response => {
        this.feedbackMsg = 'Successfully added.';
        this.retrieveWareHouseLocations();
        this.afterSubmittion()
        this.warehouseForm.reset();
      }, error => {
        console.log(error);
        this.feedbackMsg = 'Error message.';
      }, () => {}
    );
  }

  public afterSubmittion(){
    this.locationType=null
    this.postalCode=null
  }

  public onWeatherChange(newWeather){
    // console.log(newWeather)//
    this.selectedPOIweather=newWeather
  }

  public retrieveWareHouseLocations() {
    this.service.getPOI(this.adminUser.token).subscribe(
      response => {
        this.retrievedLocations = response.filter(poi=>poi.type!="SHESHA");
        this.retrievedSheshaLocations = response.filter(poi=>poi.type=="SHESHA");
        this.POIweatherStatusBadWeather('bad');
        this.POIweatherStatusLightWeather('light');
        // console.log(this.retrievedLocations);
      }, error => {
        console.log(error);
      }, () => {}
    )
  }

  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

  public postalCodeCheck() {
    this.menualPostalcode=false;
    this.postalError="";
    this.service.postalCodeCheck(this.postalCode).subscribe(
      response => {
        if(response.suburb){
          this.menualPostalcode=true;
        }else{
          this.postalError="postal address does't exist";
        }

      }, error => {
        console.log(error);
        this.postalError="postal address does't exist";
      }, () => {
      }
    )
  }

  public POIweatherStatusBadWeather(status) {
    this.service.POIweatherStatus(this.adminUser.token, status).subscribe(
      (res) => {
        // console.log(res)
        this.badWeather = res.length > 0;

      },
      (err) => {
        console.log(err);
      });
    // return length;
  }

  public POIweatherStatusLightWeather(status) {
    this.service.POIweatherStatus(this.adminUser.token, status).subscribe(
      (res) => {
        // console.log(res)
        this.lightWeather = res.length > 0;
      },
      (err) => {
        console.log(err);
      });
    // return length;
  }
}
