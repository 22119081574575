import { Component, OnInit } from '@angular/core';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {BookingService} from "../../services/booking.service";

@Component({
  selector: 'app-pudo-service',
  templateUrl: './pudo-service.component.html',
  styleUrls: ['./pudo-service.component.css']
})
export class PudoServiceComponent implements OnInit {

  showPickUpLockerOption = false;
  showPickUpDoorOption = false;
  showDropOffLockerOption = false;
  showDropOffDoorOption = false;

  pickUpLockerTab = false;
  boxSizeTab = false;
  pickUpContactTab = false;
  dropOffLockerTab = false;
  dropOffContactTab = false;
  summaryTab  = false;
  paymentTab = false;

  pickUpLocker: Address;
  invalidPickUpLocker = false;

  boxSize = '';
  invalidBoxSize = false;

  pickUpContact = {
    name: '',
    phone: '',
    email: ''
  };
  fillPickUpContact = false;
  invalidPickUpContact = false;

  dropOffLocker: Address;
  invalidDropOffLocker = false;

  dropOffContact = {
    name: '',
    phone: '',
    email: ''
  };
  fillDropOffContact = false;
  invalidDropOffContact = false;

  summaryHasBeenViewed = false;
  invalidSummary = false;

  terms = false;

  paymentOptions = [];
  paymentOptionsRetrieved = false;
  payFastAvailable = false;
  ozowAvailable = false;
  walletAvailable = false;
  paygateAvialable = false;

  user: any;
  isUserLoggedIn = false;

  constructor(private bookService: BookingService) {
    this.pickUpLockerTab = true;
  }

  ngOnInit() {
  }

  public showPudoTab(selectedTab) {

    switch (selectedTab) {
      case 'pickUpLockerTab': this.showPickUpLockerTab(); break;
      case 'boxSizeTab': this.checkPickupLocker(); break;
      case 'pickUpContactTab': this.checkBoxSize(); break;
      case 'dropOffLockerTab': this.checkPickUpContact(); break;
      case 'dropOffContactTab': this.checkDropOffLocker(); break;
      case 'summaryTab': this.checkDropOffContact(); break;
      case 'paymentTab': this.checkSummary(); break;
    }
  }

  public goToL2L() {
    this.showPickUpLockerOption = true;
    this.showDropOffLockerOption = true;

    this.showPickUpDoorOption = false;
    this.showDropOffDoorOption = false;
  }

  public goToD2L() {
    this.showPickUpLockerOption = true;
    this.showDropOffLockerOption = true;

    this.showPickUpDoorOption = true;
    this.showDropOffDoorOption = false;
  }

  public goToL2D() {
    this.showPickUpLockerOption = true;
    this.showDropOffLockerOption = true;

    this.showPickUpDoorOption = false;
    this.showDropOffDoorOption = false;
  }

  private showPickUpLockerTab() {
    this.pickUpContactTab = false;
    this.dropOffLockerTab = false;
    this.dropOffContactTab = false;
    this.summaryTab  = false;
    this.paymentTab = false;
    this.boxSizeTab = false;
    this.pickUpLockerTab = true;
  }

  private checkPickupLocker() {
    if (this.pickUpLocker !== undefined) {
      this.invalidPickUpLocker = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.dropOffContactTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = true;
    } else {
      this.invalidPickUpLocker = true;
    }
  }

  private checkBoxSize() {
    if (this.boxSize !== '') {
      this.invalidBoxSize = false;
      this.dropOffLockerTab = false;
      this.dropOffContactTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = false;
      this.pickUpContactTab = true;
      this.checkLogin();
    } else {
      this.invalidBoxSize = true;
    }
  }

  private checkPickUpContact() {
    if (this.pickUpContact.name !== '' && this.pickUpContact.phone !== '' && this.pickUpContact.email !== '') {
      this.invalidPickUpContact = false;
      this.dropOffContactTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = true;
    } else {
      this.invalidPickUpContact = true;
    }
  }

  private checkDropOffLocker() {
    if (this.dropOffLocker !== undefined) {
      this.invalidDropOffLocker = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.summaryTab  = false;
      this.paymentTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = false;
      this.dropOffContactTab = true;
    } else {
      this.invalidDropOffLocker = true;
    }
  }

  private checkDropOffContact() {
    if (this.dropOffContact.name !== '' && this.dropOffContact.phone !== '' && this.dropOffContact.email !== '') {
      this.invalidDropOffContact = false;
      this.dropOffContactTab = false;
      this.paymentTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.summaryTab = true;
      this.summaryHasBeenViewed = true;
      this.getPaymentOptions();
    } else {
      this.invalidDropOffContact = true;
    }
  }

  private checkSummary() {
    if (this.summaryHasBeenViewed) {
      this.invalidSummary = false;
      this.dropOffContactTab = false;
      this.pickUpLockerTab = false;
      this.boxSizeTab = false;
      this.pickUpContactTab = false;
      this.dropOffLockerTab = false;
      this.summaryTab = false;
      this.paymentTab = true;
    } else {
      this.invalidSummary = true;
    }
  }

  public selectPickUpLocker() {
    this.pickUpLocker = new Address();
    this.pickUpLocker.adr_address = 'PTA';
  }

  public selectDropOffLocker() {
    this.dropOffLocker = new Address();
    this.dropOffLocker.adr_address = 'JHB';
  }

  public fillInUserDetails(option, pickOrDrop) {
    if (option && pickOrDrop === 'pickUp') {
      this.pickUpContact.name = 'Name';
      this.pickUpContact.phone = '0123456789';
      this.pickUpContact.email = 'mail@mail.com';
    }

    if (option && pickOrDrop === 'dropOff') {
      this.dropOffContact.name = 'Name';
      this.dropOffContact.phone = '0123456789';
      this.dropOffContact.email = 'mail@mail.com';
    }
  }

  public clearFill(pickOrDrop) {
    if (pickOrDrop === 'pickUp') {
      this.fillPickUpContact = false;
    }

    if (pickOrDrop === 'dropOff') {
      this.fillDropOffContact = false;
    }
  }

  private getPaymentOptions() {
    if (this.paymentOptionsRetrieved) {
      return;
    }
    this.bookService.listAvailPaymentOptions(this.user.token).subscribe(
      response => {
        this.paymentOptionsRetrieved = true;
        this.paymentOptions = response;
        this.paymentOptions.forEach(element => {
          if (element.optionType === 'PAYFAST') {
            this.payFastAvailable = element.isActive;
          } else if (element.optionType === 'OZOW') {
            this.ozowAvailable = element.isActive;
          } else if (element.optionType === 'WALLET') {
            this.walletAvailable = element.isActive;
          } else if (element.optionType === 'PAYGATE') {
            this.paygateAvialable = element.isActive;
          }
        });
      },
      error => {
        this.paymentOptionsRetrieved = false;
        console.log(error);
      }
    );
  }

  private checkLogin() {
    this.user = JSON.parse(localStorage.getItem('userInfoBooking'));
    if (this.user === null) {
      this.isUserLoggedIn = false;
      // Go to log in
    } else {
      this.isUserLoggedIn = true;
    }
  }

  public payWithOzow() {

  }

  public payWithPayfast() {

  }

  public payWithWallet() {

  }
}
