import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { BookingService } from '../../services/booking.service';
import { AdminService } from 'src/app/services/admin.service';
declare var google;

import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-internal-tracking',
  templateUrl: './internal-tracking.component.html',
  styleUrls: ['./internal-tracking.component.css']
})
export class InternalTrackingComponent implements OnInit {

  lat: number = parseFloat('-25.98953');
  lng: number = parseFloat('28.12843');
  geocoder: any;
  status = 'Please wait patiently, while your request is being processed.';
  sub: any;
  bookingOid: string;
  userLat: any; //= parseFloat('-25.98953');
  userLong: any; // = parseFloat('28.12843');
  travelTime: any;
  onDistanceuserLat: any;
  onDistanceuserLong: any;
  showMap = false;
  showMapBucket = false;
  bookingDropOffLoc: any;
  bookingPickUpLoc: any;
  dir_result: any;
  MAKER: any;
  link = '/assets/mapmarker.png';
  origin = {
    lat: 0,
    lng: 0
  };
  pickupLocation = {};
  waypoints = [];
  destination = {};
  markerOptions1 = {
    origin: {
      icon: '/assets/img/home/pickUp.png',
      label: 'Pick-up'},
    destination: {
      icon: '/assets/img/home/dropOff.png',
      label: 'Drop-off'}
  };

  markerOptions = {
    origin: {
      icon: this.link},
  };
  trackingValue = true;
  bucketListDropOff: any = [];
  bucketDropOffLatLng: any = [];
  bookings: any = [];
  driver: any;
  user: any;
  driverLocation: any;
  time: any;
  dropOffLat: any;
  dropOffLng: any;
  mapPage: boolean = false;
  flightBookingStatus: any = [];
  flightStatus: any;
  pickUpBooking: any;
  dropOffBooking: any;
  isDropOff: boolean = true;

  person = {
    name: '',
    surname: '',
    registrationNum: '',
    contactNum: ''
  };

  arrival = {
    arrivalTime: '',
    distanceToDestination: ''
  };

  tripStarted: any;
  statusControl: any = null;
  pickupAddress: any;
  dropOff: any;
  date: any;
  price: any;

  // Pudo
  pudoBooking = {};
  viewPudoTracking = -1;
  pudoBookingBeingTracked: any;

  pudoMessage = '';
  // Pudo

  constructor(private onGetBookingStatus: UserService, private bookService: BookingService, public db: AngularFireDatabase) {
  }

  ngOnInit() {
    this.bookings = JSON.parse(localStorage.getItem('retrievedTrackBooking'));
    this.pudoBookingBeingTracked = JSON.parse(localStorage.getItem('retrievedPudoBooking'));

    if (this.bookings.length >= 1) {
      this.pickupAddress = this.bookings[0].pickUpAddress;
      this.dropOff = this.bookings[0].dropOffAddress;
      this.date = this.bookings[0].pickUpDate;
      this.price = this.bookings[0].price;

      this.getBookingStatus(this.bookings);
      this.viewPudoTracking = 2;

      if (this.bookings[0].driverOid != null) {
        this.onGetBookingStatus.getTrackBookingDriver(this.bookings[0].driverOid).subscribe(
          (res) => {
            this.driver = res;
            this.person = {
              name: this.driver.person.firstName,
              surname: this.driver.person.surname,
              registrationNum: this.driver.vehicle.registrationNumber,
              contactNum: this.driver.person.mobile
            };
          }
        );
      }
    } else if (this.pudoBookingBeingTracked !== null) {
      this.viewPudoTracking = 1;
    }

    localStorage.removeItem('retrievedTrackBooking');
    localStorage.removeItem('retrievedPudoBooking');
  }

  public viewBooking() {
    this.mapPage = true;
    this.showMap = true;
  }

  public cardTracking() {
    this.mapPage = false;
  }

  public getBookingStatus(bookingObj: any) {
    let bookingData;
    let bookingStatus;
    let bookingRefNo;
    let driverContactOid;

    bookingData = bookingObj;
    this.status = bookingData[0].status;
    this.statusControl = this.status;
    this.viewPudoTracking = 2;
    bookingRefNo = bookingData[0].timestamp;
    driverContactOid = bookingData[0].driverOid;
    if (this.status === 'Awaiting driver to be assigned') {
    } else if (this.status === 'Driver in transit') {
      let trackingData;
      this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
        // console.log(res.json());
        trackingData = res.json();
      }, (err) => {
      }, () => {
        this.destination = { lat: parseFloat(bookingData[0].dropOffCoordinate.coordinates[0]), lng: parseFloat(bookingData[0].dropOffCoordinate.coordinates[1]) };
        this.pickupLocation = { lat: parseFloat(bookingData[0].pickUpCoordinate.coordinates[0]), lng: parseFloat(bookingData[0].pickUpCoordinate.coordinates[1]) };
        this.waypoints = [{
          location: {lat: parseFloat(bookingData[0].pickUpCoordinate.coordinates[0]), lng: parseFloat(bookingData[0].pickUpCoordinate.coordinates[1])},
          stopover: true,
        }];
        // tslint:disable-next-line: max-line-length[0].dropOffCoordinate.coordinates[0]
        this.bookingDropOffLoc = new google.maps.LatLng(bookingData[0].dropOffCoordinate.coordinates[0], bookingData[0].dropOffCoordinate.coordinates[1]);
        this.trackDriverLocation(trackingData.mobile);
      });
    } else if (this.status === 'IN_TRANSACT') {
      let trackingData;
      this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
        trackingData = res.json();
      }, (err) => {
      }, () => {
        this.destination = { lat: parseFloat(bookingData[0].dropOffCoordinate.coordinates[0]), lng: parseFloat(bookingData[0].dropOffCoordinate.coordinates[1]) };
        this.dropOffLat = parseFloat(bookingData[0].dropOffCoordinate.coordinates[0]);
        this.dropOffLng = parseFloat(bookingData[0].dropOffCoordinate.coordinates[1]);
        // tslint:disable-next-line: max-line-length
        // this.bookingDropOffLoc = new google.maps.LatLng(bookingData[0].dropOffCoordinate.coordinates[0], bookingData[0].dropOffCoordinate.coordinates[1]);
        this.trackDriverLocation(trackingData.mobile);
      });
    } else if (this.status === 'Booking successfully complete') {
    } else if (this.status === 'Booking taken already') {
    } else if (this.status == null) {
    }
  }

  public getBookingStatusBucket(trackNo) {
    let bookingData;
    let bookingStatus;
    let bookingRefNo;
    let driverContactOid;
    const bookingCoord = [];
    let bookings: any;
    this.bookService.getBucketByTrackNum(trackNo).subscribe(
      (response: any) => {
        //bookingData = response.json();
        bookingData = response
        bookings = bookingData.bookings;
        bookingStatus = bookingData.status;
        bookingRefNo = bookingData.timestamp;
        driverContactOid = bookingData.driverOid;
        this.origin = { lat: parseFloat(bookings[0].pickUpCoordinate.coordinates[0]), lng: parseFloat(bookings[0].pickUpCoordinate.coordinates[1]) };
        bookings.forEach(element => {
          bookingCoord.push(element.dropOffCoordinate.coordinates);
        });
        this.bucketListDropOff = bookingCoord;
      },
      (err) => {
      }, () => {
        if (bookingStatus === 'BUCKET_AWAITING') {
          this.status = 'Please wait patently, a Droppa driver is being assigned.';
        } else if (bookingStatus === 'IN_TRANSACT') {
          this.status = 'Please wait patiently, a Droppa driver is in transit.';
          let trackingData;
          this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
            trackingData = res.json();
          }, (err) => {
          }, () => {
            for (let i = 0, len = bookingCoord.length; i < len; i++) {
              const markerPosition = new google.maps.LatLng(bookingCoord[i][0], bookingCoord[i][1]);
              this.bucketDropOffLatLng.push({ lat: parseFloat(bookingCoord[i][0]), lng: parseFloat(bookingCoord[i][1]) });
            }
          });
        } else if (bookingStatus === 'COMPLETE') {
          this.status = 'This booking was successully completed';
        } else if (bookingStatus === 'RESERVED') {
          this.status = 'A Droppa driver has accepted your booking.';
        } else if (bookingStatus == null) {
          this.status = 'This booking was successully completed';
        }
      }
    );

  }

  trackDriverLocation(cellNumber: string) {
    this.db.object('driverTracking/'+ cellNumber).snapshotChanges().map(res => res.payload.val())
      .subscribe(
        (response) => {
          if (response != null) {
            this.driverLocation = response;
            const degrees=Math.floor((this.driverLocation.bearing)/30)*30
            const icon_name=`mapmarker_${degrees}_degrees.png`
            const icon_url=`assets/vehicles/${icon_name}`
            this.link=icon_url
            this.tripStarted = this.driverLocation.tripStarted
            if (this.tripStarted) {
              // this.statusControl = 'Driver in transit';
              this.dropOffLat = parseFloat(this.bookings[0].dropOffCoordinate.coordinates[0]);
              this.dropOffLng = parseFloat(this.bookings[0].dropOffCoordinate.coordinates[1]);
              let distance = this.distance(this.driverLocation.lat, this.driverLocation.lng, this.dropOffLat, this.dropOffLng, "K");
              if (distance <= 1) {
                // this.statusControl = 'Driver at drop off';
              } else {
                // this.statusControl = 'Driver on the way';
              }
            }
            else {
              // this.statusControl = 'Driver in transit';
            }

            this.arrival = {
              arrivalTime: this.driverLocation.time,
              distanceToDestination: this.driverLocation.distance
            };
            this.origin = {lat: this.driverLocation.lat, lng: this.driverLocation.lng};
            const driverLoc = new google.maps.LatLng(this.driverLocation.lat, this.driverLocation.lng);
            this.bookingPickUpLoc = driverLoc;
            this.showMap = true;
          }
        }, error => {
          console.log(error);
          this.statusControl = 'Booking successfully complete';
        }
      )
  }

  // private refreshBooking(trackID) {
  //   this.bookService.getBookingByTrackNum(trackID.toUpperCase()).subscribe(
  //     (response) => {
  //       this.bookings = response.json();
  //       this.getBookingStatus(this.bookings);
  //     }, (error) => {
  //     }
  //   );
  // }

  private distance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist
  }

  public refreshStatus() {
    this.bookService.getBookingByTrackNum(this.bookings[0].trackNo.toUpperCase()).subscribe(
        (response) => {
          // console.log(response.json());
          this.bookings = response.json();

          this.pickupAddress = this.bookings[0].pickUpAddress;
          this.dropOff = this.bookings[0].dropOffAddress;
          this.date = this.bookings[0].pickUpDate;
          this.price = this.bookings[0].price;

          this.getBookingStatus(this.bookings);

          if (this.bookings[0].driverOid != null) {
            this.onGetBookingStatus.getTrackBookingDriver(this.bookings[0].driverOid).subscribe(
              (res) => {
                this.driver = res;
                this.person = {
                  name: this.driver.person.firstName,
                  surname: this.driver.person.surname,
                  registrationNum: this.driver.vehicle.registrationNumber,
                  contactNum: this.driver.person.mobile
                };
              }
            );
          }
        }, (error) => {
        console.log(error);
        }
      );
  }
}
