import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from 'src/app/services/user.service';
import {BookingService} from "../../services/booking.service";

@Component({
  selector: 'app-skynet-tracking',
  templateUrl: './skynet-tracking.component.html',
  styleUrls: ['./skynet-tracking.component.css']
})
export class SkynetTrackingComponent implements OnInit {
  selectedBooking: any;
  bookings: any;
  wayBillNumber: any;
  status: any = [];
  waybill: any;
  downloadResponse = '';
  downloadResponseError = '';
  loader = false;

  constructor(private user: UserService, private router: Router, private bookingService: BookingService,
              private loadservice: LoaderService) { }

  ngOnInit() {
    this.bookings = JSON.parse(localStorage.getItem('retrievedTrackBooking'));
    this.status = JSON.parse(localStorage.getItem('retrievedWaybillNumber'));
    this.waybill = localStorage.getItem('waybillNumber');
    // this.getBooking(this.waybill);
    if (this.bookings !== null) {
      this.selectedBooking = this.bookings[0];
      this.wayBillNumber = this.bookings[0].waybillResponse.waybillNumber;
      this.wayBillStatus(this.wayBillNumber);
    } else {
      this.status.reverse();
      this.getBooking(this.waybill);
    }
  }

  private getBooking(wayBillNum) {
    this.bookingService.getBookingByWaybillNumber(wayBillNum).subscribe(
      response => {
        this.selectedBooking = response.json();
      }, error => {
        console.log(error);
      }
    );
  }

  private wayBillStatus(wayBillNum) {
    this.user.retrieveWayBillStatus(wayBillNum).subscribe(
      response => {
        this.status = response.reverse();
      }, error => {
        console.log(error);
      }
    );
  }

  public goBack() {
    localStorage.removeItem('retrievedTrackBooking');
    localStorage.removeItem('retrievedWaybillNumber');
    localStorage.removeItem('waybillNumber');
    this.router.navigateByUrl('/track-my-booking');
  }

  public downloadPOD() {
    this.loadservice.addloader();
    this.downloadResponse = '';
    this.downloadResponseError = '';
    this.user.getDocument(this.waybill).subscribe(
      response => {
        if (response.errorCode === '0') {
          this.downloadResponse = response.errorDescription;
          window.open(response.imageURL);
        } else {
          this.downloadResponseError = response.errorDescription;
        }
        this.loadservice.removeloader();
      }, error => {
        this.loadservice.removeloader();
        this.downloadResponseError = '';
        console.log(error);
      }
    );
  }
}
