import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Http, Response } from '@angular/http';
import { from } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-all-drivers',
  templateUrl: './all-drivers.component.html',
  styleUrls: ['./all-drivers.component.css',   '../admin-portal/admin-portal.component.css']
})
export class AllDriversComponent implements OnInit {

  loader: boolean;
  adminUser: any;
  drivers: any = [];
  driverData: any;
  findDriver: any;
  searchDriverQuery = 'mobile';
  status: string = 'status';
  msg: any;

  constructor(private adminService: AdminService, private router: Router, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getDrivers();
    // this.drivers = JSON.parse(localStorage.getItem('allDrivers'));
  }

  public getDrivers() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.loadservice.addloader();
    this.adminService.getAllDrivers(this.adminUser.token)
      .subscribe(
        (res) => {
          this.drivers = res; // .reverse();
          localStorage.setItem('allDrivers', JSON.stringify(this.drivers));
          this.loadservice.removeloader();
        },
        (err) => {
          this.loadservice.removeloader();
          if (err.statusText == 'Unknown Error' || err.statusText == 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  public searchDriver() {
    this.loadservice.addloader();
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    if (this.findDriver != null && this.searchDriverQuery !== 'undefined') {
      if (this.searchDriverQuery === 'mobile') {
        this.adminService.getDriverByNumber(this.findDriver, this.adminUser.token)
          .subscribe(
            (res) => {
              this.driverData = res;
              this.loadservice.removeloader();
            }, (error) => {
              $('#searchUser').modal('show');
              this.msg = 'Mobile number Does not exist!';
              this.loadservice.removeloader();
            });
      } else {
        $('#searchUser').modal('show');
        this.msg = 'Please select phone on the select input!';
        this.loadservice.removeloader();
      }
    } else {
      $('#searchUser').modal('show');
      this.msg = 'Please enter user mobile number and select  number on the select input!';
      this.loadservice.removeloader();
    }
  }

  public onDriver(driver) {
    localStorage.setItem('selectedDriver', JSON.stringify(driver));
    this.router.navigate(['/view-driver']);
  }

  public suspendInactiveDrivers() {
    this.adminService.suspendDriverAccounts(this.adminUser.token).subscribe(
      (response) => {
        $('#driverSuspended').modal('show');
        this.msg = 'Inactive drivers suspended.';
        this.loadservice.removeloader();
      }, (error) => {
        $('#driverSuspended').modal('show');
        this.msg = 'Could not suspend drivers.';
        this.loadservice.removeloader();
      }
    );
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
