import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http'
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Parcel } from 'src/app/models/parcel';
import { ParcelDimensions } from 'src/app/models/parcelDimensions';
import { BookingService } from 'src/app/services/booking.service';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedService } from 'src/app/services/shared.service';
declare var google, $: any;

@Component({
  selector: 'app-create-retail-booking',
  templateUrl: './create-retail-booking.component.html',
  styleUrls: ['./create-retail-booking.component.css']
})

export class CreateRetailBookingComponent implements OnInit {

  public userSettings2: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: true,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop off address.',
    geoTypes: ['address', 'establishment']
  };

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };
  retail: any;
  vehicleTypeRate: any;

  disablePickUp: boolean;
  disableAddresses: boolean = true;
  disableDropOffAddress: boolean = true;
  pickUpNotMsg: string;
  pickUpNotFound: boolean;
  fromSuburb: any;
  newPickUpSuburb: any;
  pickUpPCode: any;
  pickUpProvince: any;
  pickUpAddress: any;
  pickUpCoordinate: any[];
  pickUpCoordinateLocation: { coordinates: any; address: any; };
  departureCoordinate: { lat: any; lng: any; };
  companyPickUpName: any;
  complexPickUp: any;
  unitPickUp: any;

  validateProvince: any;
  province: any;
  addressComponent: any = [];

  disableDropOff: boolean;
  dropOffNotMsg: string;
  dropOffNotFound: boolean;
  toSuburb: any;
  newDropOffSuburb: any;
  dropOffPCode: any;
  dropOffProvince: any;
  dropOffAddress: any;
  dropOffCoordinate: any[];
  destinationCoordinate: { lat: any; lng: any; };
  dropOffCoordinateLocation: { coordinates: any; address: any; };
  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;
  comment: string;


  isExpress_: boolean = false;
  isFleetPicked: boolean = false;
  isCourierPicked: boolean = false;
  isRoadPicked: boolean = false;
  isExpress: boolean = false;
  selectedOption: string = 'FLEET_OPTION';


  pracelDims: ParcelDimensions[] = [];
  pracels: Parcel[] = [];
  pracel: Parcel;
  numOfBoxes: any;
  customer_reference: any;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;
  totalMass: any;

  hourMsg: string;
  provinceMessage: any;

  quoteResponse: any;
  flashFlight: any;
  airlineAmt: any;
  pickUpAmt: any;
  dropOffAmt: any;
  flashRoad: any;
  sheshaMode: any;
  expressCourier: any;
  budgetCourier: any;
  tenderDocument: number = 0;

  flightAvailable: boolean = false;
  flashRoadAvailable: boolean = false;
  sheshaAvailable: boolean = false;
  dashAvailable: boolean = false;

  isBudget: boolean = false;
  isCourier: boolean = false;
  isRoad: boolean = false;
  isFlight: boolean = false;
  isDash: boolean = false;
  isTender: boolean = false;

  vehicleType: any;
  loader: boolean;

  bringMultipleDrop: boolean = true;
  bringFlashPrices: boolean = true;
  bringFleetPrices: boolean = true;

  distance: any;
  mass: any;

  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;

  load: number = 1;
  dropOffFloors: number = 0;
  floorsPickUp: number = 0;
  canopy: boolean = false;
  promoCodeAmnt: number = 0.0;
  hour: number;
  bookingDates: any;

  timeRange: string;
  msgExpress: any;
  pickUpChecked: any;
  dropOffChecked: any;
  customerRef: any;
  costCentreNumber: any;

  vehicle: string;
  bookingTime: any;
  bookingDate: any;
  bucketBooking: any
  bookingText: string;

  miniVan: string = 'assets\\img\\home\\Group_1014.png';
  oneTon: string = 'assets\\img\\home\\Group_1015.png';
  oneHalfTon: string = 'assets\\img\\home\\Group_1016.png';
  fourTon: string = 'assets\\img\\home\\Group_1017.png';
  express: string = 'assets\\img\\home\\Group_1046.png';

  missingTextMsg: string = '';
  massMsg: string = '';
  breadthMsg: string = '';
  heightMsg: string = '';
  lengthMsg: string = '';
  checkingMsg: string = '';

  today = new Date();
  yesterday = new Date(this.today);
  public min = new Date(this.today.setDate(this.today.getDate() + 1));
  public max = new Date(2025, 3, 21, 20, 30);

  public minTender = new Date(this.today.setDate(this.today.getDate() - 1));
  public maxTender = new Date(2025, 3, 21, 20, 30);
  pickUpExtraDetails: any = [];
  dropOffExtraDetails: any = [];
  promptPostalCode: string = '';
  promptedPickUpPCode: any;
  promptedDropOffPCode: any;

  enteredPickUpAddress: any;
  enteredPickUpSuburb: any;
  enteredPickUpPostalCode: any;
  enteredPickUpProvince: any;

  enteredPickUpAddressMsg: string = '';
  enteredPickUpSuburbMsg: string = '';
  enteredPickUpPostalCodeMsg: string = '';
  enteredPickUpProvinceMsg: string = '';
  enteredPickUpSuccessMsg: string = '';

  enteredDropOffAddress: any;
  enteredDropOffSuburb: any;
  enteredDropOffPostalCode: any;
  enteredDropOffProvince: any;

  enteredDropOffAddressMsg: string = '';
  enteredDropOffSuburbMsg: string = '';
  enteredDropOffPostalCodeMsg: string = '';
  enteredDropOffProvinceMsg: string = '';
  enteredDropOffSuccessMsg: string = '';

  user: any = {};
  savedAddresses: any = [];

  tenderDocumentSelected: boolean = false;
  tenderDate: any;
  tenderTime: any;

  terms: boolean;
  termsControl: boolean = false;

  bookingPrice: any;
  price: any;
  dir: {
    origin: { lat: any; lng: any; };
    destination: { lat: any; lng: any; };
  };

  //Delivery date
  dateObj: any;
  displayNewDate: any;
  workingDays: any;
  dateMessage: string;

  type: string;
  transportMode: string;
  tempAmount: any;
  fleetBookingDate: any;
  fleetBookingTime: any;
  validationMsg: string = '';
  workingHours: number;
  additionalHour: any;
  retailBranch: any

  constructor(private router: Router, private sharedService: SharedService, private bookService: BookingService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.retail = JSON.parse(localStorage.getItem('retail'));
    localStorage.setItem('retail', JSON.stringify(this.retail));
    this.vehicleTypeRate = this.retail.typeRates;
    this.min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
    this.getSavedAddress(this.user.token, this.user.oid);
  }

  public selectRetailBranch(selectedBranch) {
    this.retailBranch = selectedBranch;
    console.log(this.retailBranch);
  }

  public checkedPickup(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (pickUp == 'YES') {
      this.disablePickUp = true;
      this.contact.name = object.owner.firstName;
      this.contact.email = object.owner.email;
      this.contact.mobile = object.owner.mobile;
      this.contact.surname = object.owner.surname;
    } else {
      this.disablePickUp = false;
      this.contact.name = '';
      this.contact.email = '';
      this.contact.mobile = '';
      this.contact.surname = '';
    }
  }

  // Saved Addresses
  public selectPickUpSavedAddress() {
    $('#showPickUpSavedAddressModal').modal('show');
  }

  public selectDropOffSavedAddress() {
    $('#showDropOffSavedAddressModal').modal('show');
  }

  public getSavedAddress(token, personId) {
    this.bookService.retrieveAddresses(token, personId).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }, () => {

      }
    )
  }

  public selectPickUpAddress(selectedAddress) {
    this.disableAddresses = false;
    // Address used on the logic side
    this.pickUpAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.fromSuburb = selectedAddress.suburb;
    this.pickUpPCode = selectedAddress.postalCode;
    // Address used for display
    if ((selectedAddress.latitude == 0 || selectedAddress.latitude == null) && (selectedAddress.longitude == 0 || selectedAddress.longitude == null)) {
      this.pickUpCoordinate = ['-25.97521', '28.13954'];
    }
    this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
  }

  public selectDropOffAddress(selectedAddress) {
    this.disableDropOffAddress = false;
    // Address used on the logic side
    this.dropOffAddress = selectedAddress.address;
    this.province = selectedAddress.province;
    this.toSuburb = selectedAddress.suburb;
    this.dropOffPCode = selectedAddress.postalCode;
    // Address used for display
    this.dropOffAddress = selectedAddress.address;
    if ((selectedAddress.latitude == 0 || selectedAddress.latitude == null) && (selectedAddress.longitude == 0 || selectedAddress.longitude == null)) {
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
    }
    this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };
  }
  // Saved Addresses


  //Pick up address details
  autoCompleteCallback1(selectedData: any) {
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
      this.pickUpNotFound = false;
    } else {
      if (selectedData.response == false) {
        this.pickUpPCode = null;
      }
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {

            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newPickUpSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.pickUpPCode = selectedData.data.address_components[x].long_name;
              this.selectPickUpDropDownSuburb(this.pickUpPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.pickUpPCode == null) {
        this.pickUpPostalCodeService(selectedData);
      }
      this.pickUpAddress = selectedData.data.description;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { 'coordinates': this.pickUpCoordinate, 'address': this.pickUpAddress };
      var geocoder = new google.maps.Geocoder;
      this.departureCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.geocodeLatLng(geocoder, selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng);
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  public pickUpPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.pickUpPCode = foundAddress[x].long_name;
                  this.getPickUpSuburb(this.pickUpPCode);
                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = "Please enter your area postal code below";
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  clickedPickUppCode() {
    this.pickUpPCode = this.promptedPickUpPCode;
    this.getPickUpSuburb(this.pickUpPCode);
    this.selectPickUpDropDownSuburb(this.pickUpPCode);
  }

  public getPickUpSuburb(code) {
    this.bookService.getPickUpSuburb(code).subscribe(
      response => {
        this.fromSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  public selectedPickUpSuburb(fromSuburb) {
    this.fromSuburb = fromSuburb;
  }

  public selectPickUpDropDownSuburb(postalCode) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    if (this.pickUpPCode != null && this.pickUpPCode != undefined) {
      this.bookService.postalDetails(myToken, postalCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.pickUpExtraDetails = [];
    }
  }

  public getPickUpExtraDetails(postalCode) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.pickUpPCode = postalCode;
    if (this.pickUpPCode != null || this.pickUpPCode != undefined) {
      this.bookService.postalDetails(myToken, this.pickUpPCode).subscribe(
        response => {
          this.pickUpExtraDetails = response;
          if (this.pickUpExtraDetails.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.pickUpExtraDetails = [];
    }
  }

  public caturedPickpAddress() {

    if (this.enteredPickUpAddress == null || this.enteredPickUpAddress == '') {
      this.enteredPickUpAddressMsg = 'Please enter the address';
    }
    else if (this.enteredPickUpPostalCode == null || this.enteredPickUpPostalCode == '') {
      this.enteredPickUpPostalCodeMsg = 'Please enter the postal code.';
    }
    else if (this.enteredPickUpSuburb == null || this.enteredPickUpSuburb == '') {
      this.enteredPickUpSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredPickUpProvince == null || this.enteredPickUpProvince == '') {
      this.enteredPickUpProvinceMsg = 'Please enter the province.';
    }
    else {
      this.disableAddresses = false;
      this.enteredPickUpSuccessMsg = 'Address successfully captured.';
      this.fromSuburb = this.enteredPickUpSuburb;
      this.pickUpPCode = this.enteredPickUpPostalCode;
      this.pickUpProvince = this.enteredPickUpProvince;
      this.province = this.enteredPickUpProvince;
      this.pickUpCoordinate = ['-25.98953', '28.12843'];
      this.pickUpAddress =
        this.enteredPickUpAddress
          .concat(', ' + this.fromSuburb)
          .concat(', ' + this.pickUpPCode)
          .concat(', ' + this.province);
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };

      // Clearing error messages
      this.enteredPickUpAddressMsg = '';
      this.enteredPickUpSuburbMsg = '';
      this.enteredPickUpPostalCodeMsg = '';
      this.enteredPickUpProvinceMsg = '';

    }

  }

  public closeCaturedPickpAddress() {
    this.enteredPickUpAddressMsg = '';
    this.enteredPickUpSuburbMsg = '';
    this.enteredPickUpPostalCodeMsg = '';
    this.enteredPickUpProvinceMsg = '';
    this.enteredPickUpSuccessMsg = '';
  }
  //Pick up address details


  // Drop off Addresses
  autoCompleteCallback2(selectedData: any) {
    if (!selectedData) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      if (selectedData.response == false) {
        this.dropOffPCode = null;
      }
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {

            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              this.selectDropOffDropDownSuburb(this.dropOffPCode);
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      }
      this.dropOffAddress = selectedData.data.description;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.dropOffCoordinateLocation = { 'coordinates': this.dropOffCoordinate, 'address': this.dropOffAddress };
    }
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                  this.getDropOffSuburb(this.dropOffPCode);
                }
              }
            }
          }

          if (this.dropOffPCode == null) {
            this.promptPostalCode = "Please enter your area postal code below";
            $('#dropOffPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public getDropOffSuburb(code) {
    this.bookService.getDropOffSuburb(code).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  public clickedDropOffpCode() {
    this.dropOffPCode = this.promptedDropOffPCode;
    this.getDropOffSuburb(this.dropOffPCode);
    this.selectDropOffDropDownSuburb(this.dropOffPCode);
  }

  public selectedDropOffSuburb(toSuburb) {
    this.toSuburb = toSuburb;
  }

  public selectDropOffDropDownSuburb(postalCode) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    if (this.dropOffPCode != null && this.dropOffPCode != undefined) {
      this.bookService.postalDetails(myToken, postalCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public getDropOffExtraDetails(quote) {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    this.dropOffPCode = quote;
    if (this.dropOffPCode != null || this.dropOffPCode != undefined) {
      this.bookService.postalDetails(myToken, this.dropOffPCode).subscribe(
        response => {
          this.dropOffExtraDetails = response;
          if (this.dropOffExtraDetails.length == 0) {
            this.enteredDropOffPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          }
          else {
            this.enteredDropOffPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    }
    else {
      this.dropOffExtraDetails = [];
    }
  }

  public capturedDropOffAddress() {

    if (this.enteredDropOffAddress == null || this.enteredDropOffAddress == '') {
      this.enteredDropOffAddressMsg = 'Please enter the address';
    }
    else if (this.enteredDropOffPostalCode == null || this.enteredDropOffPostalCode == '') {
      this.enteredDropOffPostalCodeMsg = 'Please enter the postal code';
    }
    else if (this.enteredDropOffSuburb == null || this.enteredDropOffSuburb == '') {
      this.enteredDropOffSuburbMsg = 'Please enter the suburb';
    }
    else if (this.enteredDropOffProvince == null || this.enteredDropOffProvince == '') {
      this.enteredDropOffProvinceMsg = 'Please enter the province';
    }
    else {
      this.disableDropOffAddress = false;
      this.enteredDropOffSuccessMsg = 'Address successfully captured.'
      this.toSuburb = this.enteredDropOffSuburb;
      this.dropOffPCode = this.enteredDropOffPostalCode;
      this.dropOffProvince = this.enteredDropOffProvince;
      this.dropOffAddress = this.enteredDropOffAddress
        .concat(', ' + this.toSuburb)
        .concat(', ' + this.dropOffPCode)
        .concat(', ' + this.dropOffProvince);
      this.dropOffCoordinate = ['-25.97521', '28.13954'];
      this.dropOffCoordinateLocation = { coordinates: this.dropOffCoordinate, address: this.dropOffAddress };

      //Clear error messages
      this.enteredDropOffAddressMsg = '';
      this.enteredDropOffSuburbMsg = '';
      this.enteredDropOffPostalCodeMsg = '';
      this.enteredDropOffProvinceMsg = '';
    }

  }

  public closeCapturedDropOffAddress() {
    this.enteredDropOffAddressMsg = '';
    this.enteredDropOffSuburbMsg = '';
    this.enteredDropOffPostalCodeMsg = '';
    this.enteredDropOffProvinceMsg = '';
  }
  // Drop off Addresses


  public geocodeLatLng(geocoder, userLat, userLong) {
    var latlng = { lat: userLat, lng: userLong };
    var self = this;
    geocoder.geocode({ 'location': latlng }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name === 'South Africa') {
              self.geocodeLatLngProvince(geocoder, results[1].address_components[1].long_name);
            }
            break;
          }
        }
        self.province = data.long_name;
        self.validateProvince = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    var self = this;
    geocoder.geocode({ 'address': city }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length === 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince;
      } else {
        self.province = 'UNKNOWN';
        self.province = self.validateProvince;
      }
    });
  }

  private getAdminArea(address_component: []) {
    this.addressComponent = address_component;
    let len = address_component.length;
    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'LIMPOPO';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'MPUMALANGA';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'NORTHERN CAPE';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'NORTH WEST';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'FREE STATE';
          this.validateProvince = 'Free State';
          break;
        }
        default: {

          break;
        }

      }
    }
  }

  public checkedDropOff(pickUp: any) {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (pickUp == 'YES') {
      this.disableDropOff = true;
      this.dropContact.name = object.owner.firstName;
      this.dropContact.email = object.owner.email;
      this.dropContact.mobile = object.owner.mobile;
      this.dropContact.surname = object.owner.surname;
    } else {
      this.disableDropOff = false;
      this.dropContact.name = '';
      this.dropContact.email = '';
      this.dropContact.mobile = '';
      this.dropContact.surname = '';
    }
  }

  // manual address
  public enterPickUpAddress() {
    $('#addressPopUp').modal('show');
  }

  enterDropOffAddress() {
    $('#addressPopUp2').modal('show');
  }

  // service displayed
  public flashSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isRoadPicked = true;
    this.isExpress = true;
    this.selectedOption = 'SAME_DAY';
    this.pracelDims = [];
    this.pracels = [];
    this.disableDropOffAddress = true;
    this.disableAddresses = true;
    $(".flashHighlight").addClass("expressChoices_");
    $(".flashHighlight").removeClass("expressChoices");
  }

  public multipleDrops() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = true;
    this.isExpress = true;
    this.isCourierPicked = true;
    this.selectedOption = 'MULITPLE_DROPS';
    this.pracelDims = [];
    this.pracels = [];
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    $(".multipleDropsHighlight").addClass("expressChoices_");
    $(".multipleDropsHighlight").removeClass("expressChoices");
  }

  public fleetSelectedOption() {
    this.resetPickedImages();
    this.controlPickedSelection();
    this.isExpress_ = false;
    this.isExpress = false;
    this.isFleetPicked = true;
    this.selectedOption = 'FLEET_OPTION';
    this.pracelDims = [];
    this.pracels = [];
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    $(".fleetHighlight").addClass("expressChoices_");
    $(".fleetHighlight").removeClass("expressChoices");
  }
  // service displayed


  public controlPickedSelection() {
    $(".flashHighlight").addClass("expressChoices").removeClass("expressChoices_");
    $(".multipleDropsHighlight").addClass("expressChoices").removeClass("expressChoices_");
    $(".fleetHighlight").addClass("expressChoices").removeClass("expressChoices_");
  }

  public resetPickedImages() {
    this.isRoadPicked = false;
    this.isCourierPicked = false;
    this.isFleetPicked = false;
  }


  // Dims
  public enterItems() {
    $('#enterItems').modal('show');
  }

  public addItems() {

    if (this.numOfBoxes == null || this.numOfBoxes == '') {
      this.missingTextMsg = 'Please enter the number of boxes.';
    }
    else if (this.parcel_mass == null || this.parcel_mass == '') {
      this.massMsg = 'Please enter the mass.';
    }
    else if (this.parcel_breadth == null || this.parcel_breadth == '') {
      this.breadthMsg = 'Please enter the breadth.';
    }
    else if (this.parcel_height == null || this.parcel_height == '') {
      this.heightMsg = 'Please enter the height.';
    }
    else if (this.parcel_length == null || this.parcel_length == '') {
      this.lengthMsg = 'Please enter the length.';
    }
    else if (this.parcel_mass > 70 && (this.selectedOption == 'MULITPLE_DROPS' || this.selectedOption == 'SAME_DAY')) {
      this.massMsg = 'The max for mass is 70KG with courier service.';
    }
    else if (this.parcel_breadth > 150 && (this.selectedOption == 'MULITPLE_DROPS' || this.selectedOption == 'SAME_DAY')) {
      this.breadthMsg = 'The max for breadth is 150cm with courier service.';
    }
    else if (this.parcel_height > 150 && (this.selectedOption == 'MULITPLE_DROPS' || this.selectedOption == 'SAME_DAY')) {
      this.heightMsg = 'The max for height is 150cm with courier service.';
    }
    else if (this.parcel_length > 150 && (this.selectedOption == 'MULITPLE_DROPS' || this.selectedOption == 'SAME_DAY')) {
      this.lengthMsg = 'The max for length is 150cm with courier service.';
    }
    else if (this.parcel_mass == 0) {
      this.massMsg = 'The mass can not be 0.';
    }
    else if (this.parcel_breadth == 0) {
      this.breadthMsg = 'The breadth can not be 0.';
    }
    else if (this.parcel_height == 0) {
      this.heightMsg = 'The mass height not be 0.';
    }
    else if (this.parcel_length == 0) {
      this.lengthMsg = 'The mass length not be 0.';
    }
    else if (this.parcel_mass < 0) {
      this.massMsg = 'The mass can not be a negative number.';
    }
    else if (this.parcel_breadth < 0) {
      this.breadthMsg = 'The breadth can not be a negative number.';
    }
    else if (this.parcel_height < 0) {
      this.heightMsg = 'The mass height can not be a negative number.';
    }
    else if (this.parcel_length < 0) {
      this.lengthMsg = 'The mass length can not be a negative number.';
    }
    else if (this.pracelDims.length >= 8 && (this.selectedOption == 'MULITPLE_DROPS' || this.selectedOption == 'SAME_DAY')) {
      this.checkingMsg = 'You can only add 8 parcels.';
    }
    else {

      // adding dims for skynet and to get quote
      let courierObj = {
        parcel_length: this.parcel_length,
        parcel_breadth: this.parcel_breadth,
        parcel_height: this.parcel_height,
        parcel_mass: this.parcel_mass
      };
      this.pracelDims.push(courierObj);
      // adding dims for telkom
      this.pracels.push(
        this.pracel = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass,
          customer_reference: this.customer_reference,
          costCenter: this.cost_center_num,
          parcel_number: this.pracels.length + 1,
          noBox: this.numOfBoxes
        }
      );
      this.missingTextMsg = '';
      this.massMsg = '';
      this.breadthMsg = '';
      this.heightMsg = '';
      this.lengthMsg = '';

      this.cost_center_num = '';
      this.customer_reference = '';
      this.parcel_length = '';
      this.parcel_height = '';
      this.parcel_mass = '';
      this.parcel_breadth = '';
      this.numOfBoxes = '';
      this.addTotalMassOfDims();

    }
  }

  public removeDimensions(item) {
    this.pracels.splice(item, 1);
  }

  public addTotalMassOfDims() {
    var totalParcelMass = 0;
    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });
    this.totalMass = totalParcelMass;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      }
      else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      }
      else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      }
      else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      }
      else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      }
      else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      }
      else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      }
      else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      }
      else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }

  }

  public modelHeightChanged(parcel_height) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      }
      else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      }
      else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      }
      else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      }
      else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }

  }

  public modelLengthChanged(parcel_length) {

    if (this.selectedOption != 'FLEET_OPTION') {
      if (parcel_length > 150) {
        this.lengthMsg = "Length for parcel cannot exceed 150";
      }
      else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      }
      else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      }
      else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      }
      else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }

  }
  //Dims


  //Selected service
  public sameDaySelected() {
    if (this.pickUpAddress != null && this.dropOffAddress != null) {
      this.hourMsg = '';
      var d = new Date();
      var hours = d.getHours();
      // if ((this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn') ||this.validateProvince === 'Western Cape' || this.validateProvince === 'Gauteng') {
        if (this.termsControl) {
          if (hours >= 7 && hours <= 17) {
            if (this.pracels.length != 0) {
              this.disableAddresses = false;
              this.bringFlashPrices = false;
              this.disableDropOffAddress = false;
              var distanceResponse;
              var data = {
                "destination": this.destinationCoordinate,
                "departure": this.departureCoordinate
              };
              this.bookService.getDistance(data).subscribe(
                (response) => {
                  distanceResponse = response.json();
                  this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
                  this.distance = Math.round(this.distance / 1000);
                  this.getSameDayRates();
                }, error => {
                  console.log(error);
                }, () => {
                });
            }
            else {
              alert('Please add dimensions before getting a quote.');
            }
          }
          else {
            this.hourMsg = "Service only available from 06:00 AM to 18:00 PM.";
            $('#hourAheadValidation').modal('show');
          }
        }
        else {
          alert('Please click the checkbox next to the terms & conditions to proceed with the booking.');
        }
      // }
      // else {
      //   alert('Please note that SAME DAY service is only available in GAUTENG, WESTERN PROVINCE & KWAZULU-NATAL.');
      // }
    }
    else {
      this.hourMsg = 'Please enter the pickup and drop off address.';
    }
  }

  public courierSelected() {
    if (this.pickUpAddress != null && this.dropOffAddress != null) {
      this.hourMsg = '';
      if ((this.fromSuburb != null && this.toSuburb != null)) {
        if (this.termsControl) {
          if (this.pracels.length != 0) {
            this.disableAddresses = false;
            this.bringMultipleDrop = false;
            this.disableDropOffAddress = false;
            this.getCourierRates();
            this.getBookingDates();
          } else {
            alert('Please add items before getting a quote.');
          }
        }
        else {
          alert('Please click the checkbox next to the terms & conditions to proceed with the booking.');
        }
      }
      else {
        alert('Please select the suburbs under the addresses before proceeding.');
      }
    } else {
      this.hourMsg = 'Please enter the pickup and drop off address.';
      $('#hourAheadValidation').modal('show');
    }
  }

  public fleetSelected() {
    if (this.pickUpAddress != null && this.dropOffAddress != null) {

      if (this.pracels.length != 0) {
        this.bringFleetPrices = false;
        this.disableAddresses = false;
        this.disableDropOffAddress = false;
        this.tempAmount = 0;
        this.price = 0;
        this.hourMsg = '';
      }
      else {
        alert('Please add items before getting a quote.');
      }

    } else {
      this.hourMsg = 'Please enter the pickup and drop off address.';
    }
  }
  //Selected service


  public backToBookingDetails() {
    this.bringFlashPrices = true;
    this.bringMultipleDrop = true;
    this.bringFleetPrices = true;
    this.disableAddresses = true;
    this.disableDropOffAddress = true;
    this.vehicleType = null;
    this.resetPickedImages();
  }

  public calculateDistance() {
    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };
    this.loadservice.addloader();

    this.bookService.getDistance(data).subscribe(
      (response) => {
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);
      }, error => {
        console.log(error);
      }, () => {
      });
    return this.distance;
  }

  //Prices
  public getSameDayRates() {

    var quoteBody = {
      "mass": this.totalMass,
      "distance": this.distance,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.dropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.pracelDims,
      "platform": "web",
    }

    if (this.distance > 75) {
      this.sheshaMode = 0;
    }
    else if (this.validateProvince != 'Gauteng' && this.dropOffProvince != "Gauteng") {
      this.sheshaMode = 0;
      this.onFlashRoadhSelected();
    }

    this.bookService.getQuote(quoteBody).subscribe(
      response => {
        this.quoteResponse = response.json();
        this.flashFlight = Math.round(response.json().totalFlight);
        this.airlineAmt = response.json().totalFlight;
        this.pickUpAmt = response.json().flashFlightFromPickUp;
        this.dropOffAmt = response.json().flashFlightToDropff;
        this.flashRoad = Math.round(response.json().flashRoad);
        this.sheshaMode = Math.round(response.json().sheshaAmount);

        if (this.validateProvince === 'Western Cape' || (this.validateProvince === 'KwaZulu-Natal' || this.validateProvince === 'kzn')) {
          this.sheshaMode = 0;
          this.onFlashRoadhSelected();
        }

        if (this.flashFlight == 0) {
          this.flightAvailable = false;
          this.flashRoadAvailable = true;
        }
        else {
          var d = new Date();
          var hours = d.getHours();
          if (hours < 12) {
            this.flightAvailable = true;
            this.flashRoadAvailable = true;
          } else {
            this.flashFlight = 0;
            this.flightAvailable = false;
            this.flashRoadAvailable = true;
          }
        }

        if (this.distance > 30) {
          this.sheshaMode = 150;
        }

        if (this.distance > 75) {
          this.onFlashRoadhSelected();
          this.sheshaMode = 0;
        }
        else {
          this.onDashSelected();
        }

        if (this.mass > 15) {
          this.sheshaAvailable = false;
          this.onFlashRoadhSelected();
          this.sheshaMode = 0;
        }
        else {
          this.onDashSelected();
        }

        if (this.sheshaMode == 0) {
          if (this.flightAvailable) {
            this.onFlashFlightSelected();
          }
          else {
            this.onFlashRoadhSelected();
          }
        }
        else {
          this.onDashSelected();
          this.dashAvailable = true;
        }
        this.loadservice.removeloader();
      }, error => {
        console.log(error);
        this.loadservice.removeloader();
      }
    );

  }

  public getCourierRates() {
    var quoteBody = {
      "mass": this.totalMass,
      "distance": 1,
      "fromSuburb": this.fromSuburb,
      "toSuburb": this.toSuburb,
      "pickUpAddress": this.pickUpAddress,
      "dropOffAddress": this.dropOffAddress,
      "pickUpProvince": this.pickUpProvince,
      "dropOffProvince": this.dropOffProvince,
      "pickUpPCode": this.pickUpPCode,
      "dropOffPCode": this.dropOffPCode,
      "parcelDimensions": this.pracelDims,
      "platform": "web",
    }
    this.bookService.getNewSkynetPrices(quoteBody).subscribe(
      response => {
        this.quoteResponse = response.json();
        if (response.json().expressCourier > 0) {
          this.loadservice.removeloader();
          this.isCourierExpress = false;
          this.expressCourier = Math.round(response.json().expressCourier);
          this.tempAmount = this.expressCourier;
          this.onExpressCourierSelected();
        }

        if (response.json().expressBudgetAmt > 0) {
          this.loadservice.removeloader();
          this.isExpressBudget = false;
          this.budgetCourier = Math.round(response.json().expressBudgetAmt);
        }

        if (response.json().tenderDocAmount > 0) {
          this.loadservice.removeloader();
          this.tenderDocument = Math.round(response.json().tenderDocAmount);
          //this.tempAmount = this.tenderDocument;
        }

      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => { }
    );
  }

  public getNormalDroppaRates(vehicleType) {
    var object = JSON.parse(localStorage.getItem('userInfo'));

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };
    this.loadservice.addloader();
    this.bookService.getDistance(data).subscribe(
      (response) => {
        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);

        this.bookService.getRetailVehicleRate(this.retail.oid, vehicleType)
          .subscribe(
            (res: Response) => {

              var bookingData = {
                "distance": this.distance,
                "retailOid": this.retail.oid,
                "totalPrice": 0,
                "vehicleType": vehicleType
              };
              this.loadservice.addloader();
              this.bookService.getRetailbookingPrice(bookingData, object.token)
                .subscribe(
                  (response: Response) => {
                    this.bookingPrice = response.json();
                    this.sharedService.setTotalAmount(this.bookingPrice.totalPrice);
                    this.tempAmount = this.bookingPrice.totalPrice;
                    this.tempAmount = Math.round(this.tempAmount);
                    this.price = Math.round(this.bookingPrice.totalPrice);
                    this.dir = {
                      origin: { lat: this.pickUpCoordinate[0], lng: this.pickUpCoordinate[1] },
                      destination: { lat: this.dropOffCoordinate[0], lng: this.dropOffCoordinate[1] }
                    };
                    this.loadservice.removeloader();
                    window.scrollTo(0, 0)
                  },
                  (err) => {
                    console.log(err);
                    this.loadservice.removeloader()
                  }
                );
            },
            (err) => {
              console.log(err);
              this.bookingText = err.json().message;
              $('#results3').modal('show');
              this.loadservice.removeloader()
            }
          )
      }), error => {
        console.log(error);
        this.loadservice.removeloader();
      };


  }

  private validateInputs() {
    return (this.pickUpAddress && this.contact.name !== null && this.contact.mobile !== null &&
      this.dropOffAddress && this.dropContact.name && this.dropContact.mobile);
  }

  public onSelectChange(vehicleType) {

    if (!this.validateInputs()) {
      return;
    }

    if (this.isExpress) {
      this.isExpress = true;
      this.bookingDate = "";
      this.bookingTime = "";
    }

    this.vehicleType = vehicleType.vehicleType;

    this.getNormalDroppaRates(vehicleType.vehicleType);

    for (let myChild of this.vehicleTypeRate) {
      myChild.BackgroundColour = "#CCCCCC";
    }
    if (vehicleType.vehicleType == 'MINI_VAN') {
      this.vehicle = this.miniVan
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'ONE_TON_TRUCK') {
      this.vehicle = this.oneTon
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'ONE_HALF_TRUCK') {
      this.vehicle = this.oneHalfTon
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'FOUR_TON_TRUCK') {
      this.vehicle = this.fourTon
      vehicleType.BackgroundColour = "black";
    }
    else if (vehicleType.vehicleType == 'DROPPA_EXPRESS') {
      this.isExpress = true;
      this.vehicle = this.express
      vehicleType.BackgroundColour = "black";
    }
  }
  //Prices

  public getBookingDates() {
    this.bookService.getBookingDate(this.pickUpPCode, this.dropOffPCode).subscribe(
      response => {
        if (response.pickUpDate != null) {
          this.dateObj = response;
          this.displayNewDate = this.dateObj.pickUpDate;
          this.workingDays = this.dateObj.workingDays;
        } else {
          var tate = new Date();
          var datePipe = new DatePipe('en-US');
          tate.setDate(tate.getDate());
          var dateObject = {
            "workingDays": [],
            "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
            "dropOffSuburb": this.toSuburb,
            "pickUpSuburb": this.fromSuburb
          }
          this.dateObj = dateObject;
        }
      }, error => {
        var tate = new Date();
        var datePipe = new DatePipe('en-US');
        tate.setDate(tate.getDate());
        var dateObject = {
          "workingDays": [],
          "pickUpDate": datePipe.transform(tate, 'yyyy-MM-dd'),
          "dropOffSuburb": this.toSuburb,
          "pickUpSuburb": this.fromSuburb
        }
        this.dateObj = dateObject;
        console.log(error);
      }, () => {
      }
    )
  }

  public checkingDeliveryDates(theDateFound: Date): boolean {
    var datePipe = new DatePipe('en-US');
    let valid = false;
    const pickUpDate = moment(theDateFound).toDate();
    let day = moment(pickUpDate).format('dddd');
    if (pickUpDate.getDay() == 6 || pickUpDate.getDay() == 0) {

      const foundDisplayDate = new Date(this.displayNewDate);

      if (foundDisplayDate > pickUpDate) {
        this.bookingDate = datePipe.transform(foundDisplayDate, 'yyy-MM-dd');
        valid = true;
      }

      this.dateMessage = "Please note that Courier and Budget is not available on weekend.";
      valid = false;
    }
    else if (!this.workingDays.includes(day)) {
      var message = "";
      for (let element of this.workingDays) {
        message = message + "" + element + ", ";
      }
      this.dateMessage = "There is no pick up on " + day + " at " + this.fromSuburb + ", available " + message;
      valid = false;
    }
    else {
      this.bookingDate = datePipe.transform(pickUpDate, 'yyyy-MM-dd');
      valid = true;
    }

    return valid;
  }

  public getBookingDate(pickUpDate: string): string {
    var currentDate = new Date(pickUpDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    return currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;
  }
  //Delivery date

  // create booking
  public createSameDayBooking() {
    // console.log(this.tempAmount);
    if (this.tempAmount < 0 || this.tempAmount === undefined) {
      return;
    }
    if (this.distance > 15 && this.type == "DASH") {
      alert('DASH service is only available within a 15KM radius.');
    }
    else {

      var object = JSON.parse(localStorage.getItem('userInfo'));
      var datePipe = new DatePipe("en-US");
      var timestamp = new Date().valueOf();
      var currentDate = new Date();
      let value = null;
      var timestamp = new Date().valueOf();
      var datePipe = new DatePipe('en-US');
      let valueTime = null;

      value = datePipe.transform(currentDate, 'yyyy-MM-dd');
      valueTime = datePipe.transform(currentDate, 'HH:mm a');

      var bookingData = {
        'dropOff': {
          'firstName': this.dropContact.name,
          'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
          'phone': this.dropContact.mobile,
          'email': object.owner.email,
          'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : null,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : null
        },
        'pickUp': {
          'firstName': this.contact.name,
          'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
          'phone': this.contact.mobile,
          'email': object.owner.email,
          'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
          'complex': this.complexPickUp != null ? this.complexPickUp : null,
          'unitNo': this.unitPickUp != null ? this.unitPickUp : null
        },
        "customer": object.owner,
        "price": this.tempAmount,
        "vehicleType": this.vehicleType,
        "pickUpAddress": this.pickUpAddress,
        "pickUpTime": valueTime,
        "phoneNo": object.owner.mobile,
        "pickUpDate": value,
        "dropOffAddress": this.dropOffAddress,
        "comment": this.comment,
        "labour": this.contact.labours,
        "timestamp": timestamp,
        "status": "BUCKET",
        "dropFloors": this.dropOffFloors,
        "pickUpFloors": this.floorsPickUp,
        "pickUpCoordinate": this.pickUpCoordinateLocation,
        "dropOffCoordinate": this.dropOffCoordinateLocation,
        "load": this.load == 0 ? 1 : this.load,
        "canopy": this.canopy,
        "dropOid": null,
        "distance": this.distance,
        "province": this.validateProvince,
        'transportMode': this.transportMode,
        'type': this.type,
        "itemMass": this.totalMass != null ? this.totalMass : null,
        'isItemPicked': false,
        'mainCityOid': null,
        'fromSuburb': this.fromSuburb,
        'toSuburb': this.toSuburb,
        'pickUpPCode': this.pickUpPCode,
        'dropOffPCode': this.dropOffPCode,
        'pickUpAmt': this.pickUpAmt,
        'airlineAmt': this.airlineAmt,
        'dropOffAmt': this.dropOffAmt,
        'destinationProvince': this.dropOffProvince,
        'isPayed': false,
        'paymentType': "ONLINE_PAY",
        'isExpress': true,
        "customerReference": this.customer_reference,
        "costCenter": this.cost_center_num,
        "parcelDimensions": this.pracelDims,
        "parcels": null,
        'platform': 'web'
      };

      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }

      localStorage.setItem('bookingObj', JSON.stringify(bookingData));
      this.router.navigateByUrl('/confirm-single-delivery');

    }
  }

  public createMultipleDrop() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    var datePipe = new DatePipe("en-US");
    let value = null;
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe('en-US');
    let valueTime = null;

    if (this.bookingDate != null) {
      value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');
      var dateHalf2 = new Date(this.bookingDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
      valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
    }
    else {
      value = datePipe.transform(this.displayNewDate, 'yyyy-MM-dd');
      var dateHalf2 = new Date(currentDate.getFullYear(), Number(monthNo), Number(date), 14, 30);
      valueTime = datePipe.transform(dateHalf2, 'HH:mm a');
    }

    var bookingData = {
      'dropOff': {
        'firstName': this.dropContact.name,
        'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
        'phone': this.dropContact.mobile,
        'email': object.owner.email,
        'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
        'complex': this.complexDropOff != null ? this.complexDropOff : null,
        'unitNo': this.unitDropOff != null ? this.unitDropOff : null
      },
      'pickUp': {
        'firstName': this.contact.name,
        'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
        'phone': this.contact.mobile,
        'email': object.owner.email,
        'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
        'complex': this.complexPickUp != null ? this.complexPickUp : null,
        'unitNo': this.unitPickUp != null ? this.unitPickUp : null
      },
      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": valueTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comment,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "BUCKET",
      "dropFloors": this.dropOffFloors,
      "pickUpFloors": this.floorsPickUp,
      "pickUpCoordinate": this.pickUpCoordinateLocation,
      "dropOffCoordinate": this.dropOffCoordinateLocation,
      "load": 1,
      "canopy": this.canopy,
      "dropOid": null,
      "distance": this.distance,
      "province": this.validateProvince != null ? this.validateProvince : this.province,
      'transportMode': this.transportMode,
      'type': this.type,
      "itemMass": this.mass != null ? this.mass : null,
      'isItemPicked': false,
      'mainCityOid': null,
      'fromSuburb': this.fromSuburb,
      'toSuburb': this.toSuburb,
      'pickUpPCode': this.pickUpPCode,
      'dropOffPCode': this.dropOffPCode,
      'destinationProvince': this.dropOffProvince,
      'isPayed': false,
      'paymentType': "ONLINE_PAY",
      'isExpress': true,
      "customerReference": this.customer_reference,
      "costCenter": this.cost_center_num,
      "parcelDimensions": this.pracelDims,
      "parcels": null,
      'platform': 'web',
      'expectedDeliveryDate': null
    };

    if ((this.tenderDocumentSelected) && this.tenderDate !== undefined && this.tenderTime !== undefined) {
      let valueTime = datePipe.transform(this.tenderTime, 'HH:mm a');
      var currentDate = new Date(this.tenderDate);
      let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
      let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
      var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + valueTime.split(" ")[0] + ':' + second + '.957Z';
      var tenderSelectedDate = dropTime;
      var valudeTender = datePipe.transform(this.tenderDate, 'yyyy-MM-dd');
      let calDiffDays = moment(value).diff(moment(valudeTender), 'days');
      if (calDiffDays < 0) {
        bookingData.expectedDeliveryDate = tenderSelectedDate;
        if (this.pracels.length > 0) {
          bookingData.parcels = this.pracels
        }
        var drop = {
            "name": "Drop",
            "notes": "be careful",
            "cost": (this.tempAmount * 100) - this.promoCodeAmnt,
            "clientOid": object.owner.oid,
            "vehicleOid": '1521105441081',
            "driverOid": "575ae6bd30bc5d5017477143",
            "from": {
              "coordinates": [
                "-26.002483",
                "28.099503"
              ],
              'address': this.pickUpAddress,
            },
            'to': {
              'coordinates': [
                '-26.002483',
                '28.099503'
              ],
              'address': this.dropOffAddress,
            },
            'when': dropTime,
            'atp': dropTime,
            'eta': dropTime,
            'ata': dropTime,
            'droplets': [
              {
                'itemOid': '575ae6bd30bc5d5017477143',
                'notes': 'new'
              }
            ],
            'booking': bookingData,
        };
        let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
        // if (this.checkingDeliveryDates(value)) {
          this.loadservice.removeloader();
          this.pushDrop(drop, myToken, bookingData);
        // } else {
        //   alert(this.dateMessage);
        // }
      } else {
        alert('Please note that the Tender delivery date should be after the collection date.');
      }
    }
    else if (!this.tenderDocumentSelected) {
      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }
      var drop = {
        "name": "Drop",
        "notes": "be careful",
        "cost": (this.tempAmount * 100) - this.promoCodeAmnt,
        "clientOid": object.owner.oid,
        "vehicleOid": '1521105441081',
        "driverOid": "575ae6bd30bc5d5017477143",
        "from": {
          "coordinates": [
            "-26.002483",
            "28.099503"
          ],
          'address': this.pickUpAddress,
        },
        'to': {
          'coordinates': [
            '-26.002483',
            '28.099503'
          ],
          'address': this.dropOffAddress,
        },
        'when': dropTime,
        'atp': dropTime,
        'eta': dropTime,
        'ata': dropTime,
        'droplets': [
          {
            'itemOid': '575ae6bd30bc5d5017477143',
            'notes': 'new'
          }
        ],
        'booking': bookingData,
      };
      let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
      if (this.checkingDeliveryDates(value)) {
        this.loadservice.removeloader();
        this.pushDrop(drop, myToken, bookingData);
      } else {
        alert(this.dateMessage);
      }
    }
    else {
      alert('Please select a delivery date & delivery time for your tender documents.');
      return false;
    }
  }

  private pushDrop(drop: any, myToken: string, bookingData: any) {
    this.bookService.plainDrop(drop, myToken)
      .subscribe(
        (res: Response) => {
          bookingData.dropOid = res.json().oid;
          var bucket = {
            "bookings": bookingData,
            "retailOid": this.retail.oid,
            "price": bookingData.price,
            "status": "BUCKET_AWAITING",
            "vehicleType": bookingData.vehicleType,
            "dropOid": res.json().oid,
            "date": this.getBookingDate(bookingData.pickUpDate),
            "time": bookingData.pickUpTime,
            "province": this.validateProvince != null ? this.validateProvince : this.province,
            "type": this.type,
            "isExpress": true,
            "branch": {
              "name": this.retailBranch != null ? this.retailBranch.name : null,
              "phone": this.retailBranch != null ? this.retailBranch.phone : null,
              "email": this.retailBranch != null ? this.retailBranch.email : null
            }
          }
          localStorage.setItem('BookingData', JSON.stringify(bookingData));
          this.bookService.bucketsBook(bucket, myToken)
            .subscribe(
              (response: Response) => {
                this.bucketBooking = response;
                localStorage.setItem('bucketBooking', JSON.stringify(this.bucketBooking));
                this.loadservice.removeloader();
                this.onHome();
              },
              (err) => {
                console.log(err);
                this.loadservice.removeloader();
                this.bookingText = 'Error occured while making a booking, please try again.';
                $('#results2').modal('show');
              }
            );
        },
        (error) => {
          console.log(error);
          this.loadservice.removeloader();
          this.bookingText = 'Unable to make a booking.';
          $('#results3').modal('show');
        }
      );
  }

  public createFleetDrop() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var currentDate = new Date();
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    let hour = currentDate.getHours() <= 9 ? '0' + currentDate.getHours() : currentDate.getHours();
    let minutes = currentDate.getMinutes() <= 9 ? '0' + currentDate.getMinutes() : currentDate.getMinutes();
    var second = currentDate.getSeconds() <= 9 ? '0' + currentDate.getSeconds() : currentDate.getSeconds();
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + hour + ':' + minutes + ':' + second;

    var dateValue = this.fleetBookingDate;
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(dateValue, 'yyyy-MM-dd');
    var timestamp = new Date().valueOf();
    var datePipe = new DatePipe('en-US');
    var timeDate = new Date();
    var hours = this.fleetBookingTime.split(':');
    timeDate.setHours(hours[0], hours[1]);
    let valueTime = datePipe.transform(timeDate, 'HH:mm a');

    var bookingData = {
      'dropOff': {
        'firstName': this.dropContact.name,
        'lastName': this.dropContact.surname != null ? this.dropContact.surname : object.owner.surname,
        'phone': this.dropContact.mobile,
        'email': object.owner.email,
        'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
        'complex': this.complexDropOff != null ? this.complexDropOff : null,
        'unitNo': this.unitDropOff != null ? this.unitDropOff : null
      },
      'pickUp': {
        'firstName': this.contact.name,
        'lastName': this.contact.surname != null ? this.contact.surname : object.owner.surname,
        'phone': this.contact.mobile,
        'email': object.owner.email,
        'companyName': this.companyPickUpName != null ? this.companyPickUpName : object.owner.firstName,
        'complex': this.complexPickUp != null ? this.complexPickUp : null,
        'unitNo': this.unitPickUp != null ? this.unitPickUp : null
      },
      "customer": object.owner,
      "price": this.tempAmount,
      "vehicleType": this.vehicleType,
      "pickUpAddress": this.pickUpAddress,
      "pickUpTime": valueTime,
      "phoneNo": object.owner.mobile,
      "pickUpDate": value,
      "dropOffAddress": this.dropOffAddress,
      "comment": this.comment,
      "labour": this.contact.labours,
      "timestamp": timestamp,
      "status": "BUCKET",
      "dropFloors": this.dropOffFloors,
      "pickUpFloors": this.floorsPickUp,
      "pickUpCoordinate": this.pickUpCoordinateLocation,
      "dropOffCoordinate": this.dropOffCoordinateLocation,
      "load": this.load == 0 ? 1 : this.load,
      "canopy": this.canopy,
      "dropOid": null,
      "distance": this.distance,
      "province": this.validateProvince,
      'transportMode': 'ROAD',
      'type': 'DEDICATED',
      "itemMass": this.totalMass != null ? this.totalMass : null,
      'isItemPicked': false,
      'mainCityOid': null,
      'fromSuburb': this.fromSuburb,
      'toSuburb': this.toSuburb,
      'pickUpPCode': this.pickUpPCode,
      'dropOffPCode': this.dropOffPCode,
      'pickUpAmt': this.pickUpAmt,
      'airlineAmt': this.airlineAmt,
      'dropOffAmt': this.dropOffAmt,
      'destinationProvince': this.dropOffProvince,
      'isPayed': false,
      'paymentType': "ONLINE_PAY",
      "customerReference": this.customer_reference,
      "costCenter": this.cost_center_num,
      "parcelDimensions": this.pracelDims,
      "parcels": null,
      'platform': 'web'
    };

    if (this.pracels.length > 0) {
      bookingData.parcels = this.pracels
    }

    var drop = {
      "name": "Drop",
      "notes": "be careful",
      "cost": Math.round((this.tempAmount * 100) - this.promoCodeAmnt),
      "clientOid": object.owner.oid,
      "vehicleOid": '1521105441081',
      "driverOid": "575ae6bd30bc5d5017477143",
      "from": {
        "coordinates": [
          "-26.002483",
          "28.099503"
        ],
        'address': this.pickUpAddress,
      },
      'to': {
        'coordinates': [
          '-26.002483',
          '28.099503'
        ],
        'address': this.dropOffAddress,
      },
      'when': dropTime,
      'atp': dropTime,
      'eta': dropTime,
      'ata': dropTime,
      'droplets': [
        {
          'itemOid': '575ae6bd30bc5d5017477143',
          'notes': 'new'
        }
      ],
      'booking': bookingData,
    };

    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;

    this.loadservice.addloader();
    let todaysDate = datePipe.transform(currentDate, 'yyyy-MM-dd');

    let TimeSeparatorIndex = valueTime.indexOf(':');
    this.hour = parseInt(valueTime.substring(0, TimeSeparatorIndex));
    var timeArray = valueTime.split(' ');
    var bookingTimeArray = timeArray[0].split(':');
    this.bookingDates = new Date(datePipe.transform(dateValue, 'yyyy-MM-dd'));
    this.bookingDates.setHours(this.hour);
    this.bookingDates.setMinutes(parseInt(bookingTimeArray[1]));
    var validHour = moment(this.bookingDates).isAfter(moment().add(1, 'hours'));

    if (value >= todaysDate) {
      this.validationMsg = '';
      if (this.hour > 7 && this.hour < 18) {
        this.hourMsg = '';
        if (validHour) {
          this.additionalHour = '';
          if (this.tempAmount != 0) {
            this.bookService.plainDrop(drop, myToken).subscribe(
                (res: Response) => {
                  bookingData.dropOid = res.json().oid;
                  var bucket = {
                    "bookings": bookingData,
                    "retailOid": this.retail.oid,
                    "price": bookingData.price,
                    "status": "BUCKET_AWAITING",
                    "vehicleType": bookingData.vehicleType,
                    "dropOid": res.json().oid,
                    "date": this.getBookingDate(value),
                    "time": bookingData.pickUpTime,
                    "province": this.validateProvince,
                    "branch": {
                      "name": this.retailBranch != null ? this.retailBranch.name : null,
                      "phone": this.retailBranch != null ? this.retailBranch.phone : null,
                      "email": this.retailBranch != null ? this.retailBranch.email : null
                    }
                  }
                  localStorage.setItem('BookingData', JSON.stringify(bookingData));
                  this.bookService.bucketsBook(bucket, myToken).subscribe(
                      (response: Response) => {
                        this.bucketBooking = response;

                        $('#dateAndTime').modal('hide');
                        localStorage.setItem('bucketBooking', JSON.stringify(this.bucketBooking));
                        this.loadservice.removeloader();
                        this.onHome();
                      },
                      (err) => {
                        console.log(err);
                        this.loadservice.removeloader();
                        this.bookingText = 'Error occurred while making a booking, please try again.';
                        $('#results2').modal('show');
                      }
                    );
                },
                (error) => {
                  console.log(error);
                  this.loadservice.removeloader();
                  this.bookingText = 'Unable to make a booking.';
                  $('#results3').modal('show');
                }
              );
          }
          else {
            this.loadservice.removeloader();
            alert('Please select a vehicle type to proceed with the booking.');
          }
        } else {
          this.loadservice.removeloader();
          this.additionalHour = 'Please increase the time by an hour!';
        }
      } else {
        this.loadservice.removeloader();
        this.hourMsg = 'Working hours are from 07:00 AM to 18:00 PM.';

      }
    } else {
      this.loadservice.removeloader();
      this.validationMsg = 'Error, invalid pick up date. The date already passed.';
    }
  }
  // create booking

  // Fleet options
  public DateAndTimePopUp() {
    if (this.tempAmount != 0) {
      $('#dateAndTime').modal('show');
    } else {
      alert('Please select a vehicle to proceed.');
    }
  }
  // Fleet options

  labourFactor(labour) {
    if (labour > 0) {
      this.onLabour(labour);
    }
    else {
      this.onLabour(labour);
    }
  }

  public onLabour(labour: number) {
    if (labour != null) {
      if (labour >= 0) {
        this.tempAmount = (((this.price + this.contact.labours * 100)));
      }
      else {
        this.tempAmount = ((this.price + (this.contact.labours * 100)));
      }
    }
  }


  public onHome() {
    this.router.navigateByUrl('/multiple-drops');
  }

  public onFlashRoadhSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'FLASH';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.flashRoad);
    $(".road").addClass("expressChoices_");
    $(".road").removeClass("expressChoices");
    this.isRoad = true;
    this.tenderDocumentSelected = false;
  }

  public onDashSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'DASH';
    this.vehicleType = 'DASH';
    this.tempAmount = Math.round(this.sheshaMode);
    $(".dash").addClass("expressChoices_");
    $(".dash").removeClass("expressChoices");
    this.isDash = true;
    this.tenderDocumentSelected = false;
  }

  public onFlashFlightSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'AIR';
    this.type = 'FLASH';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.flashFlight);
    this.airlineAmt = this.airlineAmt;
    this.pickUpAmt = this.pickUpAmt;
    this.dropOffAmt = this.dropOffAmt;
    $(".flight").addClass("expressChoices_");
    $(".flight").removeClass("expressChoices");
    this.isFlight = true;
    this.tenderDocumentSelected = false;
  }

  public onExpressCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.expressCourier);
    $(".courierExp").addClass("expressChoices_");
    $(".courierExp").removeClass("expressChoices");
    this.isCourier = true;
    this.tenderDocumentSelected = false;
  }

  public onBudgetCourierSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'BUDGET_COURIER';
    this.vehicleType = 'DROPPA_EXPRESS';
    this.tempAmount = Math.round(this.budgetCourier);
    $(".budget").addClass("expressChoices_");
    $(".budget").removeClass("expressChoices");
    this.isBudget = true;
    this.tenderDocumentSelected = false;
  }

  public onTenderDocumentSelected() {
    this.controlSelection();
    this.resetImages();
    this.transportMode = 'ROAD';
    this.type = 'EXPRESS_COURIER';
    this.vehicleType = "DROPPA_EXPRESS";
    this.tempAmount = this.tenderDocument;
    $(".tender").addClass("expressChoices_");
    $(".tender").removeClass("expressChoices");
    this.isTender = true;
    this.tenderDocumentSelected = true;
  }

  private controlSelection() {
    $(".road").addClass("expressChoices").removeClass("expressChoices_");
    $(".flight").addClass("expressChoices").removeClass("expressChoices_");
    $(".courierExp").addClass("expressChoices").removeClass("expressChoices_");
    $(".budget").addClass("expressChoices").removeClass("expressChoices_");
    $(".dash").addClass("expressChoices").removeClass("expressChoices_");
    $(".tender").addClass("expressChoices").removeClass("expressChoices_");
  }

  private resetImages() {
    this.isBudget = false;
    this.isCourier = false;
    this.isRoad = false;
    this.isFlight = false;
    this.isDash = false;
    this.isTender = false;
  }

  public checkedTerms(terms) {
    if (terms) {
      this.termsControl = true;
    } else {
      this.termsControl = false;
    }
  }

  public openPopup() {
    var options = 'location=no,clearcache=no,toolbar=no';
    var paymentWindow = window.open('https://www.droppa.co.za/droppa/courier-terms', '_blank', options);
  }


}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}

