import { UserService } from '../../services/user.service';
import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
declare var google;
import { ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';

declare var $: any;

@Component({
  selector: 'app-track-driver',
  templateUrl: './track-driver.component.html',
  styleUrls: ['./track-driver.component.css']
})
export class TrackDriverComponent implements OnInit {

  lat: number = parseFloat('-25.873245');
  lng: number = parseFloat('28.184625');
  geocoder: any;
  status = 'Please wait patiently, while your request is being processed.';
  sub: any;
  bookingOid: string;
  userLat: any;
  userLong: any;
  travelTime: any;
  onDistanceuserLat: any;
  onDistanceuserLong: any;
  showMap = true;
  showMapBucket = false;
  bookingDropOffLoc: any;
  bookingPickUpLoc: any;
  dir_result: any;
  MAKER: any;
  link = 'assets\\img\\delivery-truck.png';
  origin = {
    lat: 0,
    lng: 0
  };
  destination = {};
  trackingValue = true;
  bucketListDropOff: any = [];
  bucketDropOffLatLng: any = [];
  driverLocation: any;
  time: any;

  bookingObj = {
    deliveryDate: '',
    pickuptime: '',
    amount: '',
    firstAddress: '',
    secondAddress: ''
  }

  driverObj = {
    driverName: '',
    driverRegNum: '',
    driverContactNum: ''
  }

  arrival = {
    arrivalTime: '',
    distanceToDestination: ''
  };

  driver: any;
  noDriver: string;
  inTransit: boolean = false;

  tripStarted: any;
  statusControl: any = null;
  dropOffLat: number;
  currentBooking: any;
  bookings: any;
  dropOffLng: number;
  mapPage: boolean = false;

  isItemPicked = false;

  constructor(private onGetBookingStatus: UserService, private route: ActivatedRoute, public db: AngularFireDatabase, private user: UserService) {
    this.sub = this.route.params.subscribe(params => {
      this.bookingOid = params['trackNo'];
    });
  }

  ngOnInit() {
    this.getBookingStatus(this.bookingOid);
  }

  public viewBooking() {
    this.mapPage = true;
  }

  public cardTracking() {
    this.mapPage = false;
  }

  public getBookingStatus(trackNo) {
    let bookingData;
    let bookingStatus;
    let bookingRefNo;
    let driverContactOid;

    this.onGetBookingStatus.getBookingTrackNO(trackNo).subscribe(
      (response: any) => {
        bookingData = response.json();
        this.currentBooking = bookingData;
        this.status = bookingData.status;
        this.statusControl = this.status;
        this.isItemPicked = bookingData.isItemPicked;
        bookingRefNo = bookingData.timestamp;
        driverContactOid = bookingData.driverOid;

        if (driverContactOid != null) {
          this.user.getTrackBookingDriver(driverContactOid).subscribe(
            res => {
              this.driverObj = {
                driverName: res.person.firstName + ' ' + res.person.surname,
                driverRegNum: res.vehicle.registrationNumber,
                driverContactNum: res.person.mobile
              };
            }, err => {
              console.log(err);
            }
          );
        }

        this.bookingObj = {
          deliveryDate: bookingData.pickUpDate,
          pickuptime: bookingData.pickUpTime,
          amount: bookingData.price,
          firstAddress: bookingData.pickUpAddress,
          secondAddress: bookingData.dropOffAddress,
        };
      },
      (err) => {
        console.log(err);
      }, () => {
        if (this.status === 'Awaiting driver to be assigned') {
        } else if (this.status === 'Driver in transit') {
          let trackingData;
          this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
            trackingData = res.json();
          }, (err) => {
          }, () => {
            this.destination = { lat: parseFloat(bookingData.dropOffCoordinate.coordinates[0]), lng: parseFloat(bookingData.dropOffCoordinate.coordinates[1]) };
            this.bookingDropOffLoc = new google.maps.LatLng(bookingData.dropOffCoordinate.coordinates[0], bookingData.dropOffCoordinate.coordinates[1]);
            this.trackDriverLocation(trackingData.mobile);
          });
        } else if (this.status === 'IN_TRANSACT') {
          let trackingData;
          this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
            trackingData = res.json();
          }, (err) => {
          }, () => {
            this.lat = parseFloat(bookingData.dropOffCoordinate.coordinates[0]);
            this.lng = parseFloat(bookingData.dropOffCoordinate.coordinates[1]);
            this.destination = { lat: parseFloat(bookingData.dropOffCoordinate.coordinates[0]), lng: parseFloat(bookingData.dropOffCoordinate.coordinates[1]) };
            this.bookingDropOffLoc = new google.maps.LatLng(bookingData.dropOffCoordinate.coordinates[0], bookingData.dropOffCoordinate.coordinates[1]);
            this.trackDriverLocation(trackingData.mobile);
          });
        } else if (this.status === 'Booking successfully complete') {
        } else if (this.status === 'Booking taken already') {
        } else if (this.status == null) {
        }
      }
    );
  }

  public getBookingStatusBucket(trackNo) {
    let bookingData;
    let bookingStatus;
    let bookingRefNo;
    let driverContactOid;
    const bookingCoord = [];
    let bookings: any;
    this.onGetBookingStatus.onGetBucketBookingTrackNO(trackNo).subscribe(
      (response: any) => {
        bookingData = response.json();
        bookings = bookingData.bookings;
        bookingStatus = bookingData.status;
        bookingRefNo = bookingData.timestamp;
        driverContactOid = bookingData.driverOid;
        this.origin = { lat: parseFloat(bookings[0].pickUpCoordinate.coordinates[0]), lng: parseFloat(bookings[0].pickUpCoordinate.coordinates[1]) };
        bookings.forEach(element => {
          bookingCoord.push(element.dropOffCoordinate.coordinates);
        });
        this.bucketListDropOff = bookingCoord;
      },
      (err) => {
      }, () => {
        if (bookingStatus === 'BUCKET_AWAITING') {
          this.status = 'Please wait patently, a Droppa driver is being assigned.';
        } else if (bookingStatus === 'IN_TRANSACT') {
          this.status = 'Please wait patiently, a Droppa driver is in transit.';
          let trackingData;
          this.onGetBookingStatus.onGetDriverNo(driverContactOid).subscribe((res) => {
            trackingData = res.json();
          }, (err) => {
          }, () => {
            for (let i = 0, len = bookingCoord.length; i < len; i++) {
              const markerPosition = new google.maps.LatLng(bookingCoord[i][0], bookingCoord[i][1]);
              this.bucketDropOffLatLng.push({ lat: parseFloat(bookingCoord[i][0]), lng: parseFloat(bookingCoord[i][1]) });
            }
            this.trackDriverLocationBucket(trackingData.mobile);
          });
        } else if (bookingStatus === 'COMPLETE') {
          this.status = 'This booking was successully completed';
        } else if (bookingStatus === 'RESERVED') {
          this.status = 'A Droppa driver has accepted your booking.';
        } else if (bookingStatus == null) {
          this.status = 'This booking was successully completed';
        }
      }
    );

  }

  trackDriverLocation(cellNumber: string) {
    this.db.object('driverTracking/' + cellNumber).snapshotChanges().map(res => res.payload.val())
      .subscribe(
        (response) => {
          if (response != null) {
            this.driverLocation = response;
            this.tripStarted = this.driverLocation.tripStarted
            if (this.tripStarted) {
              this.statusControl = 'Driver on the way';
              this.dropOffLat = parseFloat(this.bookings.dropOffCoordinate.coordinates[0]);
              this.dropOffLng = parseFloat(this.bookings.dropOffCoordinate.coordinates[1]);
              let distance = this.distance(this.driverLocation.lat, this.driverLocation.lng, this.dropOffLat, this.dropOffLng, "K");
              if (distance <= 1) {
                this.statusControl = 'Driver at drop off';
              } else {
                this.statusControl = 'Driver on the way';
              }
            }
            else {
              this.statusControl = 'Driver in transit';
            }
            this.arrival = {
              arrivalTime: this.driverLocation.time,
              distanceToDestination: this.driverLocation.distance
            };
            this.origin = { lat: this.driverLocation.lat, lng: this.driverLocation.lng };
            const driverLoc = new google.maps.LatLng(this.driverLocation.lat, this.driverLocation.lng);
            this.bookingPickUpLoc = driverLoc;
            this.showMap = true;
            this.travelTime = this.driverLocation.time;
          }
        }
      ), error => {
        console.log(error);
        this.statusControl = 'Booking successfully complete';
      }
  }

  trackDriverLocationBucket(cellNumber: string) {
    const trackDriver = firebase.database().ref('trackDriver/' + cellNumber);
    trackDriver.set({ track: true });
    trackDriver.on('child_added', (childSnapshot) => {
      const driverLocationRef = firebase.database().ref('unavalibleDrivers/' + cellNumber);
      driverLocationRef.on('value', (DataSnapshot) => {
        if (this.trackingValue) {
          this.trackingValue = false;
          this.userLat = DataSnapshot.val().l[0];
          this.userLong = DataSnapshot.val().l[1];
          this.lat = DataSnapshot.val().l[0];
          this.lng = DataSnapshot.val().l[1];
          const driverLoc = new google.maps.LatLng(this.lat, this.lng);
          this.bookingPickUpLoc = driverLoc;
          this.onDirection(driverLoc, this.bookingDropOffLoc);
          this.showMapBucket = true;
          this.travelTime = this.onGetBookingStatus.onGetTravelTime(this.userLat, this.userLong, this.onDistanceuserLat, this.onDistanceuserLong);
        } else {
        }
      });
    });

  }

  onDirection(driverLoc, userLoc) {
    const ds = new google.maps.DirectionsService;
    ds.route({
      origin: driverLoc,
      destination: userLoc,
      travelMode: 'DRIVING'
    }, (directions, status) => {
      this.dir_result = directions.routes[0].overview_path;
      const routes = [];
      const e = 0;
      this.dir_result.forEach((value, key) => {
        routes.push({
          lat: value.lat(),
          lng: value.lng()
        });
      });

      this.MAKER = routes;
    });
  }

  private distance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist
  }

}
