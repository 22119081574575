import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.css', '../admin-portal/admin-portal.component.css']
})
export class BankAccountsComponent implements OnInit {

  bank : any = [];
  admin : any = {};

  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getBankAccounts(this.admin.token).subscribe(
      (res) => {
        this.bank = res;

      }
    )
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }

}
