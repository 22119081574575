import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-customer-waybill',
  templateUrl: './view-customer-waybill.component.html',
  styleUrls: ['./view-customer-waybill.component.css']
})
export class ViewCustomerWaybillComponent implements OnInit {

  status: any;
  statusEvents: any;

  constructor() { }

  ngOnInit() {
    this.status = JSON.parse(localStorage.getItem('wayBillStatuses'));
  }

}
