import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router';

import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';
import { SharedService } from '../../services/shared.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {

  @ViewChild('vehicleForm', { static: true }) vehicleForm: NgForm;
  @ViewChild('assignForm', { static: true }) assignForm: NgForm;
  userName: string = "Nicolas Rasekgala";

  user: any;
  company: any = {};
  vehicles: any = [];
  drivers: any = [];
  deliveries: any = [];
  deliverys: any = [];
  payment: any = [];
  foundDriver: any = {};

  valid: boolean = false;
  loader: boolean = false;

  make: string = '';
  model: string = '';
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  type: string = '';
  vehicleFeedBack: string = '';
  idNo: string;
  err: string = '';
  assignStatus: string = '';
  vehicleOid: string;

  bankFeedBack: string = "";
  bankName: string;
  accountName: string;
  branchCode: string;
  accountNo: string;
  @ViewChild('bankForm', { static: true }) addVForm: NgForm;

  isBank: boolean = true;
  bank: any;

  constructor(private userService: UserService, private nameService: SharedService,
    private route: Router, private adminService: AdminService,private loadservice:LoaderService) { }

  ngOnInit() {

    this.user = JSON.parse(localStorage.getItem('userInfo'));
    let fullName = this.user.owner.firstName + ' ' + this.user.owner.surname;
    this.nameService.setName(fullName);
    this.loadservice.addloader();

    this.userService.retrieveCompany(this.user.token, this.user.owner.oid)
      .subscribe(
        (response: Response) => {

          this.loadservice.removeloader();

          if (response.status == 200) {
            if (response.json()[0] != null) {

              this.company = response.json()[0];
              // console.log(this.company);
              this.getDeliveries(this.user.token, this.company.oid);

              this.userService.retrieveVehicles(this.user.token, this.company.oid)
                .subscribe(
                  (res: Response) => {
                    if (res.status == 200) {
                      this.vehicles = res.json();

                      for (let truck of this.vehicles) {

                        if (truck.driverOid != null) {
                          this.getDrivers(this.user.token, truck.driverOid);
                        }
                      }
                      //console.log(this.vehicles);
                    }
                  }
                );
                this.getBankAccount();
            } else {
              //window.alert("Your registration was not complete...");
              this.loadservice.removeloader();
              this.route.navigateByUrl('/add-company');
            }
          }

        },
        (err) => {
          // window.alert("Your registration was not complete...");
          console.log(err);
          this.loader = false;
          if (err.statusText == "Unknown Error" || err.statusText == "Internal Server Error") {
            this.route.navigate(['/partner-signin']);
          }
        }
      );

  }

  public getDrivers(token, driverOid) {
    this.userService.retrieveDrivers(token, driverOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.drivers.push(res.json());
            //console.log(this.drivers);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public getDeliveries(token, companyOid) {

    this.userService.retrieveDeliveris(token, companyOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.deliverys = res.json();
            for (var m = 0; m < this.deliverys.length; m++) {
              this.deliveries.push(this.deliverys[m]);
              this.deliveries.reverse();

            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public getPayment(token, companyOid) {

    this.userService.retrievePayment(token, companyOid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            // this.deliveries = res.json();
            this.payment = res.json();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public onAddVehicle() {
    var vehicleData = {
      "registrationNumber": this.registration,
      "vinNumber": this.vinNo,
      "make": this.make,
      "model": this.model,
      "color": this.Vcolor,
      "type": this.type,
      "companyId": this.company.oid
    };

    if (this.company.oid != null) {
      //this.user
      this.userService.addVehicle(this.user.token, vehicleData)
        .subscribe(
          (res: Response) => {
            if (res.status == 200) {
              // this.deliveries = res.json();
              console.log(res.json());
              this.vehicleForm.reset();
              this.vehicleFeedBack = "Vehicle was successfully added.";
            }
          },
          (err) => {
            console.log(err);
            this.vehicleFeedBack = "Error while adding a vehicle, please verify your entries.";
          }
        );
    }
  }

  public findDriver() {
    this.valid = false;
    this.err = "";
    this.foundDriver = {};
    if (this.idNo != null && this.idNo.length == 10) {
      this.userService.checkDriver(this.user.token, this.idNo)
        .subscribe(
          (res: Response) => {
            if (res.status == 200) {
              // this.deliveries = res.json();
              console.log(res);
              //if(res.body != null) {
              this.valid = true;
              this.foundDriver = res.json();
              //}else {
              /// this.err = "Error, driver not found (driver must be registered on the mobile app).";
              //  }
              //
              //console.log(this.foundDriver);
            }
          },
          (err) => {
            console.log(err.json());
            this.err = err.json().message;
          }
        );
    }
  }

  public getVehicleOid(vehicleOid: string) {
    this.vehicleOid = vehicleOid;
    console.log(vehicleOid);
  }

  public assignNow() {
    console.log(this.vehicleOid);
    console.log(this.foundDriver.oid);

    this.userService.assignDriver(this.user.token, this.vehicleOid, this.foundDriver.oid)
      .subscribe(
        (res: Response) => {
          if (res.status == 200) {
            this.assignStatus = "Driver successfully assigned a vehicle.";
          }
        },
        (err) => {
          //console.log(err.json().message);
          this.assignStatus = err.json().message;//"Error occured, Please contact Droppa.";
        }
      );
  }

  private getBankAccount() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    //console.log(this.company);
    this.adminService.getBankAccount(object.token, this.company.oid).subscribe(
      (res) => {
        this.bank = res;
        this.isBank = false;
        console.log(this.bank);
      },
      (err) => {
        this.isBank = true;
        //console.log(this.isBank);
      }
    )
  }

  public updateBank() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    var account = {
      "accountNo": this.accountNo,
      "bankName": this.bankName,
      "branchCode": this.branchCode,
      "accountName": this.accountName,
      "companyOid": this.company.oid
    }
    if (this.bank == null) {
      this.adminService.bankAccount(account, object.token).subscribe(
        (res) => {
          this.bankFeedBack = "Bank account added";
          // $('#feedbackDialog').modal('show');
          this.addVForm.reset();
          this.getBankAccount();
        },
        (error) => {
          console.log(error);
          this.bankFeedBack = "Error while add bank account added";
          // $('#feedbackDialog').modal('show');
        }
      );
    }
  }
}
