import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { HaversineService } from 'ng2-haversine';
import { ReCaptchaModule } from 'angular2-recaptcha';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { DataTableModule } from 'angular2-datatable';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './modules/angular-material.module';
import { MatInputModule, MatOptionModule, MatSelectModule, MatIconModule } from '@angular/material';
import { AdminPortalModule } from './modules/admin.module';
import { LoginService } from './services/login.service';
import { UserService } from './services/user.service';
import { BookingService } from './services/booking.service';
import { SharedService } from './services/shared.service';
import { AppComponent } from './app.component';
import { NavbarComponent } from './home/navbar/navbar.component';
import { FooterComponent } from './home/footer/footer.component';
import { TermsComponent } from './home/terms/terms.component';
import { routing } from './routing/app.route';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './home/faq/faq.component';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { CustomerLoginComponent } from './home/customer-login/customer-login.component';
import { RegistrationComponent } from './home/registration/registration.component';
import { PartnerLoginComponent } from './home/partner-login/partner-login.component';
import { PasswordResetComponent } from './home/password-reset/password-reset.component';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { BookingComponent } from './home/booking/booking.component';
import { SignUpChoiceComponent } from './home/sign-up-choice/sign-up-choice.component';
import { CustomerSignupComponent } from './home/customer-signup/customer-signup.component';
import { ConfirmingOtpComponent } from './home/confirming-otp/confirming-otp.component';
import { UserPortalComponent } from './user-portal/user-portal/user-portal.component';
import { PartnerComponent } from './user-portal/partner/partner.component';
import { UserHomeComponent } from './user-portal/user-home/user-home.component';
import { UserNavbarComponent } from './user-portal/navbar/navbar.component';
import { HomeNavigateComponent } from './home/home-navigate/home-navigate.component';
import { OnlyLoggedInUsersGuard } from './guards/onlyLoggedIn.guard';
import { AlwaysAuthGuard } from './guards/alwaysAuth.guard';
import { UserLoggInService } from './services/loggedInUser.service';
import { EditBookingComponent } from './user-portal/edit-booking/edit-booking.component';
import { NotFoundComponent } from './home/404/404.component';
import { AdminNavbarComponent } from './admin/admin-navbar/admin-navbar.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { AddCompanyComponent } from './user-portal/add-company/add-company.component';
import { PromotionComponent } from './home/promotion/promotion.component';
import { PrivacyPolicyComponent } from './home/privacy-policy/privacy-policy.component';
import { AboutDroppaComponent } from './home/about-droppa/about-droppa.component';
import { BookingSignUpComponent } from './home/booking-sign-up/booking-sign-up.component';
import { BecomeDroppaComponent } from './home/become-droppa/become-droppa.component';
import { SelectDateComponent } from './home/select-date/select-date.component';
import { ErrorMessageComponent } from './home/error-message/error-message.component';
import { ConfirmOrderComponent } from './home/confirm-order/confirm-order.component';
import { TrackDriverComponent } from './home/track-driver/track-driver.component';
import { BecomeRetailComponent } from './home/become-retail/become-retail.component';
import { RetailTypeRateComponent } from './user-portal/retail-type-rate/retail-type-rate.component';
import { RetailBookComponent } from './user-portal/retail-book/retail-book.component';
import { RetailPromocodeComponent } from './user-portal/retail-promocode/retail-promocode.component';
import { ConfirmRetailBookingComponent } from './user-portal/confirm-retail-booking/confirm-retail-booking.component';
import { AddRetailComponent } from './user-portal/add-retail/add-retail.component';
import { BucketBookingReviewComponent } from './user-portal/bucket-booking-review/bucket-booking-review.component';
import { AddBucketBookingComponent } from './user-portal/add-bucket-booking/add-bucket-booking.component';
import { RetailProfileComponent } from './user-portal/retail-profile/retail-profile.component';
import { DriverProfileComponent } from './user-portal/driver-profile/driver-profile.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RetailSigninComponent } from './home/retail-signin/retail-signin.component';
import { ViewBookingComponent } from './user-portal/view-booking/view-booking.component';
import { RetailHomeComponent } from './user-portal/retail-home/retail-home.component';
import { RetailNavbarComponent } from './user-portal/retail-navbar/retail-navbar.component';
import { DroppaBlogComponent } from './home/droppa-blog/droppa-blog.component';
import { ConfirmMobileComponent } from './home/confirm-mobile/confirm-mobile.component';
import { ViewSearchedUserComponent } from './admin/view-searched-user/view-searched-user.component';
import { TrackDriverRetailComponent } from './home/track-driver-retail/track-driver-retail.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { FilterbyusernamePipe } from './pipes/filterbyusername.pipe';
import { ViewVouchersComponent } from './admin/view-vouchers/view-vouchers.component';
import { ConfirmVoucherPaymentComponent } from './user-portal/confirm-voucher-payment/confirm-voucher-payment.component';
import { ViewBucketBookingsComponent } from './admin/view-bucket-bookings/view-bucket-bookings.component';
import { RetailBookReviewComponent } from './user-portal/retail-book-review/retail-book-review.component';
import { UpdatedriverappComponent } from './admin/updatedriverapp/updatedriverapp.component';
import { RatingPageComponent } from './user-portal/rating-page/rating-page.component';
import { RatingModule } from 'ng-starrating';
import { CustomMessagesComponent } from './admin/custom-messages/custom-messages.component';
import { UpdateCustomMessagesComponent } from './admin/update-custom-messages/update-custom-messages.component';
import { BookingResertPasswordComponent } from './home/booking-resert-password/booking-resert-password.component';
import { BookingOtpComponent } from './home/booking-otp/booking-otp.component';
import { DevelopersPageComponent } from './home/developers-page/developers-page.component';
import { RetailInvoicesComponent } from './admin/retail-invoices/retail-invoices.component';
import { RetailInvoiceReviewComponent } from './admin/retail-invoice-review/retail-invoice-review.component';
import { DevelopersPortalComponent } from './developers-portal/developers-portal.component';
import { DevelopersPortalNavigateComponent } from './developers-portal/developers-portal-navigate/developers-portal-navigate.component';
import { DevelopersPortalNavbarComponent } from './developers-portal/developers-portal-navbar/developers-portal-navbar.component';
import { SupportComponent } from './developers-portal/support/support.component';
import { APIDevelopersPageComponent } from './admin/api-developers-page/api-developers-page.component';
import { DriverRatingsComponent } from './admin/driver-ratings/driver-ratings.component';
import { InternalTrackingComponent } from './home/internal-tracking/internal-tracking.component';
import { SignUpComponent } from './developers-portal/sign-up/sign-up.component';
import { LogInComponent } from './developers-portal/log-in/log-in.component';
import { ConfirmOtpComponent } from './developers-portal/confirm-otp/confirm-otp.component';
import { DashboardComponent } from './developers-portal/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './developers-portal/forgot-password/forgot-password.component';
import { DevelopersConfirmOtpComponent } from './developers-portal/developers-confirm-otp/developers-confirm-otp.component';
import { ViewApiUserComponent } from './admin/view-api-user/view-api-user.component';
import { AccountNotActiveComponent } from './developers-portal/account-not-active/account-not-active.component';
import { RetailNotActiveComponent } from './home/retail-not-active/retail-not-active.component';
import { AddRetailCompanyComponent } from './admin/add-retail-company/add-retail-company.component';
import { DroppaExpressComponent } from './home/droppa-express/droppa-express.component';
import { SkynethomeComponent } from './home/skynethome/skynethome.component';
import { WarehousesComponent } from './admin/warehouses/warehouses.component';
import { SkynetTrackingComponent } from './home/skynet-tracking/skynet-tracking.component';
import { FlightTrackingComponent } from './home/flight-tracking/flight-tracking.component';
import { WalletSuccessfulLoadComponent } from './user-portal/wallet-successful-load/wallet-successful-load.component';
import { ViewWalletTransactionsComponent } from './user-portal/view-wallet-transactions/view-wallet-transactions.component';
import { UserWalletTransactionsComponent } from './user-portal/user-wallet-transactions/user-wallet-transactions.component';
import { ConfirmSingleCourierComponent } from './user-portal/confirm-single-courier/confirm-single-courier.component';
import { WalletsComponent } from './admin/wallets/wallets.component';
import { ViewWaybillStatusesComponent } from './admin/view-waybill-statuses/view-waybill-statuses.component';
import { ResetSkynetPasswordComponent } from './home/reset-skynet-password/reset-skynet-password.component';
import { ResetSkynetUsersPasswordsComponent } from './home/reset-skynet-users-passwords/reset-skynet-users-passwords.component';
import { RetailSuccessfulLoadComponent } from './user-portal/retail-successful-load/retail-successful-load.component';
import { ParcelDetailsComponent } from './home/parcel-details/parcel-details.component';
import { CheckoutPageComponent } from './home/checkout-page/checkout-page.component';
import { RetailCheckoutComponentComponent } from './user-portal/RetailCheckoutComponent/RetailCheckoutComponent.component';
import { DumpsComponent } from './admin/dumps/dumps.component';
import { CreateRetailBookingComponent } from './user-portal/create-retail-booking/create-retail-booking.component';
import { CreateRetailBookingWithTagComponent } from './user-portal/create-retail-booking-with-tag/create-retail-booking-with-tag.component';
import { RetailMultipleDropComponent } from './user-portal/retail-multiple-drop/retail-multiple-drop.component';
import { RetailMultipleDropNoTagComponent } from './user-portal/retail-multiple-drop-no-tag/retail-multiple-drop-no-tag.component';
import { PluginTermsAndConditionsComponent } from './home/plugin-terms-and-conditions/plugin-terms-and-conditions.component';
import { SkynetTrackingViaEmailComponent } from './home/skynet-tracking-via-email/skynet-tracking-via-email.component';
import { FlightTrackingViaEmailComponent } from './home/flight-tracking-via-email/flight-tracking-via-email.component';
import { OutstandingRetailInvoicesComponent } from './admin/outstanding-retail-invoices/outstanding-retail-invoices.component';
import { ViewOutstandingRetailBookingInvoicesComponent } from './admin/view-outstanding-retail-booking-invoices/view-outstanding-retail-booking-invoices.component';
import { ConfirmWalletOrderComponent } from './home/confirm-wallet-order/confirm-wallet-order.component';
import { RetailConfirmWalletBookingComponent } from './home/retail-confirm-wallet-booking/retail-confirm-wallet-booking.component';
import { RetailSingleBookingSuccessfulLoadComponent } from './user-portal/retail-single-booking-successful-load/retail-single-booking-successful-load.component';
import { ElitePricesComponent } from './home/elite-prices/elite-prices.component';
import { EliteSignUpComponent } from './home/elite-sign-up/elite-sign-up.component';
import { EliteConfirmDetailsComponent } from './home/elite-confirm-details/elite-confirm-details.component';
import { FleetSerivceComponent } from './home/fleet-serivce/fleet-serivce.component';
import { CourierServiceComponent } from './home/courier-service/courier-service.component';
import { EliteThankYouPageComponent } from './home/elite-thank-you-page/elite-thank-you-page.component';
import { AllRentalsComponent } from './admin/all-rentals/all-rentals.component';
import { ViewRentalsComponent } from './admin/view-rentals/view-rentals.component';
import { RetailRentalSuccessPaymentComponent } from './user-portal/retail-rental-success-payment/retail-rental-success-payment.component';
import { ViewRentalDetailsComponent } from './user-portal/view-rental-details/view-rental-details.component';
import { RetailRentalViewDetailsComponent } from './user-portal/retail-rental-view-details/retail-rental-view-details.component';
import { VodacomPromoCodeComponent } from './admin/vodacom-promo-code/vodacom-promo-code.component';
import { TermsCourierComponent } from './home/terms-courier/terms-courier.component';
import { TermsEliteComponent } from './home/terms-elite/terms-elite.component';
import { WebHooksComponent } from './admin/web-hooks/web-hooks.component';
import { WebHookDetailsComponent } from './admin/web-hook-details/web-hook-details.component';
import { UserOtpComponent } from './home/user-otp/user-otp.component';
import { UploadCsvComponent } from './admin/upload-csv/upload-csv.component';
import { BecomeHmbRetailComponent } from './home/become-hmb-retail/become-hmb-retail.component';
import { ViewCustomerWaybillComponent } from './user-portal/view-customer-waybill/view-customer-waybill.component';
import { PaymentGatewayPageComponent } from './admin/payment-gateway-page/payment-gateway-page.component';
import { SelectPaymentComponent } from './home/select-payment/select-payment.component';
import { SelectPaymentFleetComponent } from './home/select-payment-fleet/select-payment-fleet.component';
import { RetailSelectPaymentTagComponent } from './user-portal/retail-select-payment-tag/retail-select-payment-tag.component';
import { RetailSelectPaymentNoTagComponent } from './user-portal/retail-select-payment-no-tag/retail-select-payment-no-tag.component';
import { AdminActivitiesComponent } from './admin/admin-activities/admin-activities.component';
import { OperationsComponent } from './admin/operations/operations.component';
import { StatusFilterPipe } from './pipes/status-filter.pipe';
import { FilterTableResultsPipe } from './pipes/filter-table-results.pipe';
import { OldSpreadsheetComponent } from './admin/old-spreadsheet/old-spreadsheet.component';
import { ViewSpreadsheetComponent } from './admin/view-spreadsheet/view-spreadsheet.component';
import {BusinessReportsComponent} from './admin/business-reports/business-reports.component'
import { SkynetTrackingWaybillComponent } from './home/skynet-tracking-waybill/skynet-tracking-waybill.component';
import { TrackMyBookingComponent } from './home/track-my-booking/track-my-booking.component';
import { MarketingComponent } from './admin/marketing/marketing.component';
import { CreateAdvertComponent } from './admin/create-advert/create-advert.component';
import { ViewAdvertDetailsComponent } from './admin/view-advert-details/view-advert-details.component';
import { EditAdvertComponent } from './admin/edit-advert/edit-advert.component';
import { AdminSearchResutsComponent } from './admin/admin-search-resuts/admin-search-resuts.component';
import { AllPartnersComponent } from './admin/all-partners/all-partners.component';
import { ViewPartnerComponent } from './admin/view-partner/view-partner.component';
import { ConfigureSkynetRateComponent } from './admin/configure-skynet-rate/configure-skynet-rate.component';
import {environment} from '../environments/environment';
import {SkynetPortalComponent} from './admin/skynet-portal/skynet-portal.component';
import { FlightBookingDetailsComponent } from './user-portal/flight-booking-details/flight-booking-details.component';
import { UberBookingsComponent } from './admin/uber-bookings/uber-bookings.component';
import { UberBookingDetailsComponent } from './admin/uber-booking-details/uber-booking-details.component';
import { DriverOwnerFinancialsComponent } from './user-portal/driver-owner-financials/driver-owner-financials.component';
import { AdminOwnerFinancialComponent } from './admin/admin-owner-financial/admin-owner-financial.component';
import { MobileFaqComponent } from './mobile-faq/mobile-faq.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { ClientLoginComponent } from './home/client-login/client-login.component';
import { ClientProfileComponent } from './user-portal/client-profile/client-profile.component';
import { ClientProfileNavigationComponent } from './user-portal/client-profile-navigation/client-profile-navigation.component';
import { ClientNavComponent } from './user-portal/client-nav/client-nav.component';
import { PudoServiceComponent } from './home/pudo-service/pudo-service.component';
import { AdminPudoIndividualComponent } from './admin/admin-pudo-individual/admin-pudo-individual.component';
import { DroppaContractOwnersComponent } from './admin/droppa-contract-owners/droppa-contract-owners.component';
import { DetailedContractOwnerComponent } from './admin/detailed-contract-owner/detailed-contract-owner.component';
import { PudoBookingsComponent } from './admin/pudo-bookings/pudo-bookings.component';
import { PudoRetailTermsComponent } from './user-portal/pudo-retail-terms/pudo-retail-terms.component';
import { MobileTermsComponent } from './mobile-terms/mobile-terms.component';
import { InsuredBookingsComponent } from './admin/insured-bookings/insured-bookings.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import { AdminIntegratedCompanyComponent } from './admin/admin-integrated-company/admin-integrated-company.component';
import { WhitelistingComponent } from './admin/whitelisting/whitelisting.component';
import {WhitelistingServiceService} from "./services/whitelisting-service.service";
import {
  DroppaContractOwnersComponentApi
} from "./admin/droppa-contract-owners-api/droppa-contract-owners.component-api";
import {
  DroppaContractOwnersDetailsApiComponent
} from "./admin/droppa-contract-owners-details-api/droppa-contract-owners-details-api.component";
import { DeliveryOptionsComponent } from './admin/delivery-options/delivery-options.component';


// test
/*export const firebaseConfig = {
  apiKey: 'AIzaSyA_BpvhQXtu3zxvoEy_VqCdRdUrL8nV8Q4',
  authDomain: 'droppa-922ea.firebaseapp.com',
  databaseURL: 'https://droppa-922ea.firebaseio.com',
  storageBucket: 'droppa-922ea.appspot.com',
  messagingSenderId: '300318099113'
};*/

// production
/*export const firebaseConfig = {
  apiKey: 'AIzaSyCz7rJ5FIYLT5UAiFVxEHeuQCsP44kDCeI',
  authDomain: 'droppa-1258.firebaseapp.com',
  databaseURL: 'https://droppa-1258.firebaseio.com',
  storageBucket: 'droppa-1258.appspot.com',
  messagingSenderId: '560005876586'
};*/



// firebase.initializeApp(firebaseConfig);

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TermsComponent,
    HomeComponent,
    FaqComponent,
    SignInComponent,
    CustomerLoginComponent,
    RegistrationComponent,
    PartnerLoginComponent,
    PasswordResetComponent,
    ResetPasswordComponent,
    BookingComponent,
    UserPortalComponent,
    PartnerComponent,
    UserHomeComponent,
    UserNavbarComponent,
    HomeNavigateComponent,
    SignUpChoiceComponent,
    CustomerSignupComponent,
    ConfirmingOtpComponent,
    EditBookingComponent,
    NotFoundComponent,
    AdminNavbarComponent,
    AdminHomeComponent,
    AddCompanyComponent,
    PromotionComponent,
    PrivacyPolicyComponent,
    AboutDroppaComponent,
    BookingSignUpComponent,
    BecomeDroppaComponent,
    SelectDateComponent,
    ErrorMessageComponent,
    ConfirmOrderComponent,
    TrackDriverComponent,
    BecomeRetailComponent,
    RetailTypeRateComponent,
    RetailBookComponent,
    RetailPromocodeComponent,
    ConfirmRetailBookingComponent,
    AddRetailComponent,
    BucketBookingReviewComponent,
    AddBucketBookingComponent,
    RetailProfileComponent,
    DriverProfileComponent,
    RetailSigninComponent,
    ViewBookingComponent,
    RetailHomeComponent,
    RetailNavbarComponent,
    DroppaBlogComponent,
    ConfirmMobileComponent,
    ViewSearchedUserComponent,
    TrackDriverRetailComponent,
    ViewVouchersComponent,
    ConfirmVoucherPaymentComponent,
    ViewBucketBookingsComponent,
    RetailBookReviewComponent,
    UpdatedriverappComponent,
    RatingPageComponent,
    CustomMessagesComponent,
    UpdateCustomMessagesComponent,
    TrackDriverComponent,
    BookingResertPasswordComponent,
    BookingOtpComponent,
    DevelopersPageComponent,
    RetailInvoicesComponent,
    RetailInvoiceReviewComponent,
    DevelopersPortalComponent,
    DevelopersPortalNavigateComponent,
    DevelopersPortalNavbarComponent,
    SupportComponent,
    APIDevelopersPageComponent,
    DriverRatingsComponent,
    InternalTrackingComponent,
    SignUpComponent,
    LogInComponent,
    ConfirmOtpComponent,
    DashboardComponent,
    ForgotPasswordComponent,
    DevelopersConfirmOtpComponent,
    ViewApiUserComponent,
    AccountNotActiveComponent,
    RetailNotActiveComponent,
    AddRetailCompanyComponent,
    DroppaExpressComponent,
    SkynethomeComponent,
    WarehousesComponent,
    SkynetTrackingComponent,
    FlightTrackingComponent,
    WalletSuccessfulLoadComponent,
    ViewWalletTransactionsComponent,
    UserWalletTransactionsComponent,
    ConfirmSingleCourierComponent,
    WalletsComponent,
    ViewWaybillStatusesComponent,
    ResetSkynetPasswordComponent,
    ResetSkynetUsersPasswordsComponent,
    RetailSuccessfulLoadComponent,
    ParcelDetailsComponent,
    CheckoutPageComponent,
    RetailCheckoutComponentComponent,
    FilterbyusernamePipe,
    DumpsComponent,
    CreateRetailBookingComponent,
    CreateRetailBookingWithTagComponent,
    RetailMultipleDropComponent,
    RetailMultipleDropNoTagComponent,
    PluginTermsAndConditionsComponent,
    SkynetTrackingViaEmailComponent,
    FlightTrackingViaEmailComponent,
    OutstandingRetailInvoicesComponent,
    ViewOutstandingRetailBookingInvoicesComponent,
    ConfirmWalletOrderComponent,
    RetailConfirmWalletBookingComponent,
    RetailSingleBookingSuccessfulLoadComponent,
    ElitePricesComponent,
    EliteSignUpComponent,
    EliteConfirmDetailsComponent,
    FleetSerivceComponent,
    CourierServiceComponent,
    EliteThankYouPageComponent,
    AllRentalsComponent,
    ViewRentalsComponent,
    RetailRentalSuccessPaymentComponent,
    ViewRentalDetailsComponent,
    RetailRentalViewDetailsComponent,
    VodacomPromoCodeComponent,
    TermsCourierComponent,
    TermsEliteComponent,
    WebHooksComponent,
    WebHookDetailsComponent,
    UserOtpComponent,
    UploadCsvComponent,
    BecomeHmbRetailComponent,
    ViewCustomerWaybillComponent,
    PaymentGatewayPageComponent,
    SelectPaymentComponent,
    SelectPaymentFleetComponent,
    RetailSelectPaymentTagComponent,
    RetailSelectPaymentNoTagComponent,
    AdminActivitiesComponent,
    OperationsComponent,
    StatusFilterPipe,
    FilterTableResultsPipe,
    OldSpreadsheetComponent,
    ViewSpreadsheetComponent,
    BusinessReportsComponent,
    SkynetTrackingWaybillComponent,
    TrackMyBookingComponent,
    MarketingComponent,
    CreateAdvertComponent,
    ViewAdvertDetailsComponent,
    EditAdvertComponent,
    AdminSearchResutsComponent,
    AllPartnersComponent,
    ViewPartnerComponent,
    ConfigureSkynetRateComponent,
    SkynetPortalComponent,
    FlightBookingDetailsComponent,
    UberBookingsComponent,
    UberBookingDetailsComponent,
    DriverOwnerFinancialsComponent,
    AdminOwnerFinancialComponent,
    MobileFaqComponent,
    ClientLoginComponent,
    ClientProfileComponent,
    ClientProfileNavigationComponent,
    ClientNavComponent,
    PudoServiceComponent,
    MobileFaqComponent,
    DroppaContractOwnersComponent,
    DetailedContractOwnerComponent,
    PudoBookingsComponent,
    PudoRetailTermsComponent,
    MobileTermsComponent,
    InsuredBookingsComponent,
    AdminPudoIndividualComponent,
    AdminIntegratedCompanyComponent,
    WhitelistingComponent,
    DroppaContractOwnersComponentApi,
    DroppaContractOwnersDetailsApiComponent,
    DeliveryOptionsComponent
  ],
    imports: [
        BrowserModule,
        MatInputModule, MatOptionModule, MatSelectModule, MatIconModule,
        NgxPaginationModule,
        routing,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        DataTableModule,
        ReCaptchaModule,
        AdminPortalModule,
        Ng2SearchPipeModule,
        CommonModule,
        Ng4GeoautocompleteModule.forRoot(),
        BrowserAnimationsModule,
        AngularMaterialModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyB3UqCtg7neJzmVlc2FE4aEjFF-e0PvtuE',
            libraries: ['places']
        }),
        AgmDirectionModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule, ScrollToModule.forRoot(),
        RatingModule,
        OverlayModule, MatPaginatorModule
    ],
  providers: [
    LoginService,
    UserService,
    HaversineService,
    BookingService,
    SharedService,
    OnlyLoggedInUsersGuard,
    AlwaysAuthGuard,
    UserLoggInService,
    WhitelistingServiceService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
