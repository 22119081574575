import { Component, OnInit } from '@angular/core';
import { BookingService } from 'src/app/services/booking.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.css']
})

export class CheckoutPageComponent implements OnInit {

  payFastResponse: any;
  paymentData: any;
  userInfo: any;
  paymentMsg: any;
  loader: boolean = false;
  drop: any;
  vehicleExpressType: any;
  pickUpSuburb: any;
  pickUpDate: any;
  pickUpAddress: any;
  pickUpName: any;
  pickUpNumber: any;

  dropOffAddress: any;
  dropOffSuburb: any;
  dropOffName: any;
  dropOffNumber: any;

  comments: any;
  parcels: any;

  url: string = "https://sandbox.payfast.co.za/eng/process";
  merchant_id: any;
  merchant_key: any;
  return_url: any;
  cancel_url: any;
  notify_url: any;
  name_first: any;
  name_last: any;
  email_address: any;
  cell_number: any;
  m_payment_id: any;
  amount: any;
  item_name: any;
  item_description: any;
  confirmation_address: any;
  signature: any;
  custom_int1 : any;

  constructor(private bookingService: BookingService) { }

  ngOnInit() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.drop = JSON.parse(localStorage.getItem('dropData'));
    // console.log(this.drop)
    this.paymentData = JSON.parse(localStorage.getItem('paymentData'));
    this.payFastResponse = JSON.parse(localStorage.getItem('payFast'));
    console.log(this.payFastResponse);
    // this.makePayment(this.payFastResponse);

    this.dropOffAddress = this.drop.booking.dropOffAddress;
    this.dropOffSuburb = this.drop.booking.toSuburb;
    this.dropOffName = this.drop.booking.dropOff.firstName;
    this.dropOffNumber = this.drop.booking.dropOff.phone;
    this.comments = this.drop.booking.comment;

    this.vehicleExpressType = this.drop.booking.type;
    this.pickUpSuburb = this.drop.booking.fromSuburb;
    this.pickUpDate = this.drop.booking.pickUpDate;
    this.pickUpAddress = this.drop.booking.pickUpAddress;
    this.pickUpName = this.drop.booking.pickUp.firstName;
    this.pickUpNumber = this.drop.booking.pickUp.phone;

    this.parcels = this.drop.booking.load;
  }

  // public makePayment(response) {
  //   console.log(response);
    
  //   this.merchant_id = response.merchant_id.trim();
  //   this.merchant_key = response.merchant_key.trim();
  //   this.return_url = response.return_url.trim();
  //   this.cancel_url = response.cancel_url.trim();
  //   this.notify_url = response.notify_url.trim();
  //   this.name_first = response.name_first.trim();
  //   this.name_last = response.name_last.trim();
  //   this.email_address = response.email_address.trim();
  //   this.cell_number = response.cell_number.trim();
  //   this.m_payment_id = response.m_payment_id.trim();

  //   this.getRequestPayment(this.m_payment_id);

  //   this.amount = response.amount.trim();
  //   this.item_name = response.item_name.trim();
  //   this.item_description = response.item_description.trim();
  //   this.custom_int1 = response.custom_int1.trim();
  //   this.confirmation_address = response.confirmation_address.trim();
  // }

  // private getRequestPayment(referenceID) {
  //   this.bookingService.getRequestPayment(referenceID).subscribe(
  //     (res) => {
  //       console.log(res);
  //       this.merchant_id = res.merchant_id;
  //       this.merchant_key = res.merchant_key;
  //       this.return_url = res.return_url;
  //       this.cancel_url = res.cancel_url;
  //       this.notify_url = res.notify_url;

  //       this.name_first = res.name_first;
  //       this.name_last = res.name_last;
  //       this.email_address = res.email_address;
  //       this.cell_number = res.cell_number;

  //       this.m_payment_id = res.oid;
  //       this.amount = res.amount;
  //       this.item_name = res.item_name;

  //       this.item_description = res.item_description;
  //       this.confirmation_address = res.confirmation_address;
  //       //this.signature = res.signature;
  //       this.custom_int1 = res.custom_int1;
  //     },
  //     (err) => {
     
  //     }
  //   );
  // }

}
