import { Component, OnInit, ViewChild,isDevMode } from '@angular/core';
import { Response } from '@angular/http';
import { NgForm } from '@angular/forms';
import 'rxjs/Rx';
import { Router } from '@angular/router';
import { UserLoggInService } from '../../services/loggedInUser.service';
import { SharedService } from '../../services/shared.service';
import { LoginService } from '../../services/login.service';
import { User } from '../../models/user';
import { Login } from '../../models/login';
import { from } from 'rxjs/observable/from';
import { ViewEncapsulation } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-booking-sign-up',
  templateUrl: './booking-sign-up.component.html',
  styleUrls: ['./booking-sign-up.component.css', '../sign-in/sign-in.component.css', '../customer-login/customer-login.component.css']//,
  //encapsulation: ViewEncapsulation.Native
})
export class BookingSignUpComponent implements OnInit {

  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';

  @ViewChild('userForms', { static: false }) signupForm: NgForm;
  @ViewChild('otpForms', { static: false }) otpForm: NgForm;

  username: string;
  pwd: string;
  loader: boolean;
  errorMsg: string = '';

  //sign-up form
  firstName: string;
  name: string = '';
  surname: string;
  lastname: string;
  email: string;
  pwd1: string = '';
  mail: string = '';
  mobile: string;
  phone: string = '';
  password: string;
  re_password: string;
  otp: number;
  isRegisteringForm: boolean = false;
  error: string;
  user: User;
  loginObj: Login;
  numberOTP: string = '';
  confirming: boolean;
  OTPerror: string = '';
  addrressData: any;
  captcha: any;
  messege: string;
  dateInfo: any;
  typeofLogin: boolean = false;

  isRobot=true
  capture: any;
  UAT_SITE_KEY:String

  isDevMode=isDevMode()

  constructor(private loginService: LoginService, private router: Router, private userLogged: UserLoggInService, private service: SharedService,private loadservice:LoaderService) { }

  ngOnInit() {
    this.UAT_SITE_KEY=this.loginService.SITE_KEY;
    this.addrressData = JSON.parse(localStorage.getItem('addressData'));
    this.dateInfo = JSON.parse(localStorage.getItem('newDateObj'));
    this.messege = localStorage.getItem('otpMessege');
  }

  public login() {

    const url = 'https://www.droppa.co.za/home/client-login/nav';
    const link = document.createElement('a');
    link.href = url;
    // document.body.appendChild(link);
    link.click();
    // link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
    return;

    /*if (this.username === '' || this.username === null || this.username === undefined) {
      this.errorMsg = 'Please enter in your login credentials.';
    } else if (this.pwd === '' || this.pwd === null || this.pwd === undefined) {
      this.errorMsg = 'Please enter in your password.';
    } else if (this.isRobot&&!this.isDevMode) {
      this.errorMsg = 'Please click the checkbox to verify you are a human.';
    } else {
      this.loadservice.addloader();
      this.loginService.login(this.pwd, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {

          let notValid = false;

          response.roles.forEach(role => {
            if (role.code === 'retail' || role.code === 'business_User') {
              notValid = true;
            }
          });

          if (!notValid) {
            localStorage.removeItem('userData');
            localStorage.setItem('userData', JSON.stringify(response));
            localStorage.setItem('token', response.token);
            localStorage.setItem('userInfo', JSON.stringify(response));

            const loginsData = {username: this.username.toLowerCase().trim(), pwd: this.pwd};
            localStorage.setItem('userInfoBooking', JSON.stringify(response));
            localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));

            this.userLogged.setUser(response);
            this.service.setMessage('Sign In');

            this.loadservice.removeloader();
            //console.log(this.addrressData);

            localStorage.setItem('individual', 'individual');

            if (this.addrressData.vehicleType.vehicleType === 'DROPPA_EXPRESS' || this.addrressData.vehicleType.vehicleType === 'DASH') {
              this.router.navigate(['/droppa-express']);
            } else {
              this.router.navigate(['/Select-Date']);
            }
          } else {
            // window.alert('You do not have access to this profile type.');
            $('#accessNotAllowed').modal('show');
            this.loadservice.removeloader();
          }
          window.scrollTo(0, 0);
        },
        (err) => {
          console.log('error message: ', err)
          this.loadservice.removeloader();
          this.errorMsg = "Invalid username or password";

        }
      );
    }*/
  }

  public signin() {
    this.isRegisteringForm = false;
    this.error = '';
    this.loadservice.addloader();
    this.user = new User(this.firstName, this.surname,
      this.email.toLowerCase().trim(), this.mobile);
    this.loginObj = new Login(this.password, this.email.toLowerCase().trim());
    this.user.login = this.loginObj;
    this.numberOTP = this.mobile;
    // console.log(this.user);

    this.loginService.register(this.user)
      .subscribe(
        (response: any) => {
          debugger;
          console.log(response);
          this.isRegisteringForm = true;
          this.confirming = true;
          this.loadservice.removeloader();
          localStorage.removeItem('userData');
          localStorage.removeItem('signedUser');
          localStorage.setItem('userData', JSON.stringify(response));
          localStorage.setItem("signedUser", JSON.stringify({
            email: this.email,
            password: this.password
          }));

        },
        (err) => {
          console.log("Registration error: ", err);
          this.loadservice.removeloader();
          this.isRegisteringForm = false;
          this.error = "Error while registering, mobile or email already exist.";
        }
      );

  }

  cancel() {
    this.isRegisteringForm = false;
  }

  public confirmAcc() {
    this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('signedUser'));
    if (this.otp != null) {
      this.loginService.confirmOTP(this.otp, this.numberOTP)
        .subscribe(
          (response: Response) => {
            if (response.status == 200) {
              if (response.json().confirmed) {
                this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                  (response: any) => {
                    localStorage.setItem('userData', JSON.stringify(response));
                    localStorage.setItem('token', response.token);
                    localStorage.setItem('userInfo', JSON.stringify(response));

                    var loginsData = { "username": this.username, "pwd": this.pwd };
                    localStorage.setItem('userInfoBooking', JSON.stringify(response));
                    localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));

                    this.userLogged.setUser(response);
                    this.service.setMessage("Sign In");
                    this.OTPerror = "Your account is activated successful.";
                    this.loadservice.removeloader();

                    if (this.addrressData.vehicleType.vehicleType == 'DROPPA_EXPRESS' || this.addrressData.vehicleType.vehicleType == 'DASH') {
                      this.router.navigate(['/droppa-express']);
                      // console.log(this.addrressData.vehicleType)
                    }
                    else {
                      this.router.navigate(['/Select-Date']);
                      // console.log(this.addrressData.vehicleType)
                    }



                    window.scrollTo(0, 0);
                  }
                );
              } else {
                this.loadservice.removeloader();
                this.OTPerror = "Error, the OTP number provided is invalid.";

              }

            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Error, please enter valid OTP.";
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error while registering, mobile or email already exist.";
          }
        );

    } else {
      this.loadservice.removeloader();
      alert("Error please enter the otp");
    }
  }

  showPassword() {
    this.toggleEye = !this.toggleEye;
    if (this.toggleEye) {
      this.passwordType = 'text';
      this.passwordEye = 'glyphicon glyphicon-eye-close';
    } else {
      this.passwordType = 'password';
      this.passwordEye = 'glyphicon glyphicon-eye-open';
    }

  }

  handleCorrectCaptcha(event) {

    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }
  }

  showSignUpPage() {
    this.typeofLogin = true;
  }

  showSignInPage() {
    this.typeofLogin = false;
  }

  public omit_special_char(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

}
