import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';
import { LoaderService } from 'src/app/services/loader.service';

import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-view-searched-user',
  templateUrl: './view-searched-user.component.html',
  styleUrls: ['./view-searched-user.component.css']
})
export class ViewSearchedUserComponent implements OnInit {

  user: any = {};
  roles: any = [];
  selectedRole: any;
  responseMsg: string = "";
  loader: boolean = false;
  role;
  image : string;
  isBank: any;
  searchedUser: any;
  constructor(private adminService: AdminService,private loadservice:LoaderService) {
    this.searchedUser = localStorage.getItem('searchedUser');
    console.log(this.searchedUser)
  }

  ngOnInit() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getRoles(object.token).subscribe(
      (res) => {
        this.roles = res;
      }
    )
  }

  select(selectedRole) {
    this.selectedRole = selectedRole;
  }

  onRoleUpdated() {
    if (this.selectedRole != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.loadservice.addloader();
      this.adminService.getUserByEmail(object.token, this.user.owner.email).subscribe(
        (res) => {

          this.adminService.addRole(object.token, res.oid, this.selectedRole.oid).subscribe(
            (data) => {
              this.loadservice.removeloader();
              this.responseMsg = "Role Successfully updated.";
            },
            (error) => {
              this.responseMsg = "Role not updated.";
            }
          );

        },
        (error) => {
          this.loadservice.removeloader();
          this.responseMsg = "Error while retrieving the user, contact IT support..";
        }
      );
    } else {
      alert("Please select the role.");
    }

  }
  onSelectChange(role) {
    console.log(role);
  }

  public blockUser() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.blockUser(object.token, this.user.owner.email).subscribe(
      (res) => {
        alert("User is blocked");
      },
      (error) => {
        alert("Error while blocking user..");
      }
    );
  }

  public activateUser() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.activateUser(object.token, this.user.owner.email).subscribe(
      (res) => {
        alert("User is activated");
      },
      (error) => {
        alert("Error while activating user..");
      }
    );
  }

  public avator() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.user);
    this.adminService.getUserByAvator(object.token, this.user.owner.oid).subscribe(
      (res) => {
        console.log(res);
        this.image = res.base64Image;
      }
    )
  }

}
