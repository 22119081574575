import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-admin-owner-financial',
  templateUrl: './admin-owner-financial.component.html',
  styleUrls: ['./admin-owner-financial.component.css']
})
export class AdminOwnerFinancialComponent implements OnInit {

  constructor(private userService: UserService,private loaderservice:LoaderService) { }
  itemsPage: number = 1;
  totalItems: number;
  loader
  user:any
  vehicle:any
  rates
  partner:any
  viewheading="Vehicle Freight Transaction Summary";
  transactionsummary:any;
  selecterate
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.vehicle=JSON.parse(localStorage.getItem("selectedVehicle"));
    this.partner=JSON.parse(localStorage.getItem("partner"));
    this.button()
    this.getRates()
    this.gettransactionsummary()
  }
  button() {
    var header = document.getElementById("myTab");
    var btns = header.getElementsByClassName("tabs");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("activee");
        current[0].className = current[0].className.replace(" activee", "");
        this.className += " activee";
      });
    }
  }
  public getRates(){
    this.loaderservice.addloader()
    this.userService.getRatesVehicle(this.user.token, this.vehicle.oid)
    .subscribe(
      (res: Response) => {
        this.rates=res.json()
        this.loaderservice.removeloader()
      },
      (err) => {
        console.log(err)
        this.loaderservice.removeloader()
      }
    );
  }

  public changeTitle(title){
    this.viewheading=title
  }

  public gettransactionsummary(){
    const today=new Date()
    const currentmonth=today.getMonth() +1;
    const monthBack=5;
    let startMonth=(currentmonth -monthBack)<0?(12+(currentmonth -monthBack)):(currentmonth -monthBack);
    let endMonth=currentmonth;
    let startYear=(currentmonth -monthBack)<0?today.getFullYear()-1 :today.getFullYear()
    let endYear=today.getFullYear()

  const filter={
    "companyOid":this.partner.oid,
    "vehicleRegistration":this.vehicle.registrationNumber,
    "route":"",
    "startMonth":startMonth,
    "startYear":startYear,
    "endMonth":endMonth,
    "endYear":endYear
}


    this.loaderservice.addloader();
    this.userService.getFreightTransactionSummary(this.user.token, filter)
    .subscribe(
      (res: Response) => {
        this.transactionsummary=res.json()
        this.loaderservice.removeloader();
      },
      (err) => {
        console.log(err);
        this.loaderservice.removeloader();
      }
    );
  }
  selectRate(rate){
    this.selecterate=rate.tierRates
  }

}
