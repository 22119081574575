import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-droppa-blog',
  templateUrl: './droppa-blog.component.html',
  styleUrls: ['./droppa-blog.component.css']
})
export class DroppaBlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
