import { Component, OnInit, ViewChild,isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css',
    '../css/style.css', '../css/v-bg-stylish.css', '../css/custom.css',
    '../css/v-shortcodes.css']
})

export class HomeComponent implements OnInit {

  @ViewChild('contactFormss', { static: true }) contactFormss: NgForm;
  eliteStreetName: any;
  eliteSuburb: any;
  elitePostalCode: any;
  eliteProvince: any;
  eliteComplexName: any;
  eliteUnitNo: any;
  eliteStartDate: any;
  eliteEndDate: any;
  eliteType: any;
  eliteAddress: any;

  eliteStreetNameMsg: string = '';
  eliteSuburbMsg: string = '';
  elitePostalCodeMsg: string = '';
  eliteProvinceMsg: string = '';
  eliteStartDateMsg: string = '';
  eliteEndDateMsg: string = '';
  eliteTypeMsg: string = '';
  kilometersToTravelMsg = '';
  eliteCalDiffDays: string = '';
  kilometersAbove: boolean = false;
  kilometersToTravel = 0;

  msgStatus = '';
  homeImg;
  isLand: boolean = true;

  constructor(private router: Router, private http: HttpClient, private _sanitizer: DomSanitizer) { }


  ngOnInit() {
    window.scrollTo(0, 0);
    // this.loadHomePageImage();
    !isDevMode() ? console.log('Hello Welcome to droppa 😃') : console.log('Hello droppa developer 😂');
   }

  public truckType(eliteType) {
    this.eliteType = eliteType;
  }

  public captureDate() {

    this.eliteStartDateMsg = '';
    this.eliteStreetNameMsg = '';
    this.eliteSuburbMsg = '';
    this.elitePostalCodeMsg = '';
    this.eliteProvinceMsg = '';
    this.eliteEndDateMsg = '';
    this.eliteTypeMsg = '';
    this.kilometersToTravelMsg = '';

    var pickUpDate = new Date(this.eliteStartDate);
    var returnDate = new Date(this.eliteEndDate);
    var currentDate = new Date();
    var datePipe = new DatePipe('en-US');
    let startDays = moment(pickUpDate).diff(moment(currentDate), 'days');
    let validateEndDate = moment(returnDate).diff(moment(currentDate), 'days');
    let calDiffDays = moment(returnDate).diff(moment(pickUpDate), 'days');
    if (pickUpDate.toString() != 'Invalid Date') {
      var valuePickUp = datePipe.transform(pickUpDate, 'yyyy-MM-dd');
      var today = datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
    else {
      this.eliteStartDateMsg = 'Please select the start date.';
    }
    if (this.eliteStreetName == null) {
      this.eliteStreetNameMsg = 'Please enter the street name.';
    }
    else if (this.eliteSuburb == null) {
      this.eliteSuburbMsg = 'Please enter the suburb.';
    }
    else if (this.elitePostalCode == null) {
      this.elitePostalCodeMsg = 'Please enter a postal code.';
    }
    else if (this.eliteProvince == null) {
      this.eliteProvinceMsg = 'Please enter the province.';
    }
    else if (this.eliteStartDate == null) {
      this.eliteStartDateMsg = 'Please select the start date.';
    }
    else if (startDays <= -1) {
      this.eliteStartDateMsg = 'Invalid pick up date. The date already passed.';
    }
    /*else if (startDays < 1) {
      this.eliteStartDateMsg = 'Please book for 48 hours in advance. For emergency booking, kindly contact 0128802159.';
    }*/
    /*else if (calDiffDays > 21) {
      this.eliteEndDateMsg = 'Maximum number of rental days are 21.';
    }*/
    else if (calDiffDays < 0) {
      this.eliteEndDateMsg = 'The return date cannot be before the pick up date. Please select a date 2 days after the pick up date.';
    }
    /*else if (validateEndDate < 1) {
      this.eliteEndDateMsg = 'Please book for 48 hours in advance. For emergency booking, kindly contact 0128802159.';
    }*/
    else if (this.eliteType == null) {
      this.eliteTypeMsg = 'Please select the type of truck.';
    }
    else if (this.kilometersToTravel === 0) {
      this.kilometersToTravelMsg = 'Please enter kilometers to be travelled daily.';
    }
    else {

      this.eliteAddress = this.eliteStreetName.concat(', ' + this.eliteSuburb).concat(', ' + this.elitePostalCode).concat(', ' + this.eliteProvince.charAt(0).toUpperCase() + this.eliteProvince.slice(1));

      let eliteObj = {
        'eliteStreetName': this.eliteStreetName,
        'eliteSuburb': this.eliteSuburb,
        'elitePostalCode': this.elitePostalCode,
        'eliteProvince': this.eliteProvince,
        'eliteComplexName': this.eliteComplexName,
        'eliteUnitNo': this.eliteUnitNo,
        'eliteStartDate': pickUpDate,
        'eliteReturnDate': returnDate,
        'eliteType': this.eliteType,
        'eliteAddress': this.eliteAddress,
        'eliteKilometers': this.kilometersAbove,
        kilometersToTravel: this.kilometersToTravel
      };

      // console.log(eliteObj);

      $('#elite').modal('hide');
      localStorage.setItem('eliteData', JSON.stringify(eliteObj));
      this.router.navigate(['/Elite-prices']);
    }
  }

  async loadHomePageImage(){
   await this.http.get('/assets/Homepage_banner.png', { responseType: 'blob' })
    .subscribe(res => {
      const reader = new FileReader();
      reader.onloadend = () => {
        var base64data = reader.result;
        this.homeImg = this._sanitizer.bypassSecurityTrustResourceUrl(''+base64data);
        // this.isLand = false;
      }
      reader.readAsDataURL(res);
    });
  }

  public proceedWithElite() {
    $('#elite').modal('show');
  }

  public proceedWithFleet() {
    if (this.isRetailUser()) {
      this.router.navigateByUrl('/retail-profile');
    } else {
      this.router.navigateByUrl('/Droppa-Fleet-Service');

    }
  }

  public proceedWithCourier() {

    if (this.isRetailUser()) {
      this.router.navigateByUrl('/retail-profile');

    } else {
      this.router.navigateByUrl('/Courier-Service');
    }
  }

  public proceedWithPudo() {

    this.router.navigateByUrl('/pudo-service');
  }

  toPartner() {
    $('#partnerRegistration').modal('show');
  }

  private isRetailUser() {
    //check token if expired
    const loggedInUser = JSON.parse(localStorage.getItem('userInfo'));
    let valid = false;

    if (loggedInUser !== null) {
      loggedInUser.roles.forEach(role => {
        if (role.code === 'retail' || role.code === 'business_User') {
          valid = true;
        }
      });
    }

    return valid;
  }

  goToPartnerRegistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'yes2') {
            result = "fail"
          }
        }
      }
    }
    if (count >= 6) {
      if (result == 'success') {
        this.router.navigate(['/Become-Droppa']);
      }
      else if (result == 'fail') {
        $('#criminalResModal').modal('show');
        this.msgStatus = "You cannot proceed with registration due to criminal record.";
      }
    }
    else {
      $('#partnerResModal2').modal('show');
      this.msgStatus = "Please answer all questions to proceed with the registration";
    }
  }

  toRetail() {
    $('#retailRegistration').modal('show');
  }

  goToRetailRagistration() {
    var ele = document.getElementsByTagName('input');
    var result = "success"
    var count = 0
    for (var i = 0; i < ele.length; i++) {
      if (ele[i].type = "radio") {
        if (ele[i].checked) {
          count = count + 1;
          if (ele[i].value == 'no') {
            result = "fail"
          }
        }
      }
    }
    if (count == 3) {
      // if (result == 'success') {
      //   this.router.navigate(['/Become-Retail']);
      // }
      // else if (result == 'fail') {
      //   $('#retailResModal').modal('show');
      //   this.msgStatus = "All answers must be YES to proceed with the registration";
      // }
      // this.router.navigate(['/Become-Retail']);
    }
    else {
      $('#retailResModal2').modal('show');
      this.msgStatus = "Please answer all the questions to proceed with the registration.";
    }
  }
}
