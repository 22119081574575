import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Response } from '@angular/http'
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { HaversineService, GeoCoord } from 'ng2-haversine';
import { BookingService } from '../../services/booking.service';
import { UserService } from 'src/app/services/user.service';
import { Parcel } from 'src/app/models/parcel';
import { ParcelDimensions } from 'src/app/models/parcelDimensions';
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;
declare var google: any;

@Component({
  selector: 'app-retail-book-review',
  templateUrl: './retail-book-review.component.html',
  styleUrls: ['./retail-book-review.component.css']
})
export class RetailBookReviewComponent implements OnInit {

  @ViewChild('submitButton', { static: false }) submitButton: ElementRef;
  @ViewChild('submitButton2', { static: false }) submitButton2: ElementRef;
  @ViewChild('submitButton3', { static: false }) submitButton3: ElementRef;
  @ViewChild('submitButton4', { static: false }) submitButton4: ElementRef;
  @ViewChild('submitButton5', { static: false }) submitButton5: ElementRef;

  @ViewChild('merchant_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('merchant_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) amount: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('signature', { static: false }) signature: ElementRef;
  @ViewChild('payFast', { static: false }) payFastForm: ElementRef;
  @ViewChild('addressFormVailidater', { static: false }) addressFormVailidater: ElementRef;

  bucketBookingObjDropOffAddress: string;
  bucketBookingObjDropOffCoordinateLocation: any;
  bucketBookingObjDropOfflocation: string;
  bucketBookingObjDropOffFirstname: string;
  bucketBookingObjDropOffMobile: string;
  bucketBookingObjDropOffChecked: boolean = false
  bucketBookingObjDropPrice: number;
  bucketBookingObjDrop2: boolean;
  bucketBookingObjBookOID: string;
  bucketBookingObjCommentDrop: string;
  bucketBookingObjNumb: number;

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter pick-up address.',
    geoTypes: ['address', 'establishment']
  };

  public userSettings: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter drop-Off address.',
    geoTypes: ['address', 'establishment']
  };

  //location input
  pickUpNotMsg: string;
  pickUpAddress: string;
  dropOffAddress: string;
  dropOffNotMsg: string = '';
  dropOffNotFound: boolean;
  dropOfflocation: string;

  pickUpNotFound: boolean;
  pickUpLat: any;
  pickUpLong: any;

  pickUpCoordinate = [];
  dropOffCoordinate = [];

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};
  vehicleTypeRate: any = [];

  dropOffLat: number;
  dropOffLong: number;

  vehicleType: any;

  userLat: number;
  userLong: number;

  location: any = {};
  dir = undefined;

  distance: number;
  loader: boolean = false;

  totalExpressPrice: number;
  distanceOfTrip: number;
  tempAmount: number;

  vehicleTypeData: any;
  vehicleOid: string;
  errorMessage: string;
  message: string;
  addrressData: any;
  pickUpChecked: any;
  numberOfLabours: number = 0;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  disablePickUp: boolean = false;
  dropOffChecked: boolean = false;
  dropOffChecked2: boolean = false;
  datePipe: DatePipe;

  bookingTime: any;
  bookingDate: any;
  comment: string;
  comment2: string;
  dropOffFloors: number = 0;
  floorsPickUp: number = 0;
  labour: number = 0;
  hour: number;

  price: number = 0.0;
  availableQuotes: boolean;

  promoStatus: string;
  terms: boolean;
  termsControl: boolean = false;
  today = new Date();
  yesterday = new Date(this.today);
  promoCode: string;
  promoCodeAmnt: number = 0.0;
  msg: string;
  retail: any;
  bookingsData: any;
  load: number = 1;
  canopy: boolean = false;
  validateProvince: any;
  province: string = "UNKNOWN";
  validatePickuProvince: string;
  bookingDates: any;
  pickupNumber: any = [];
  dropoffNumber: any = [];
  bookingPrice: any;
  bucketBooking: any = {};
  booking: any = {};
  bucketBookingOID: any
  bookingText: any;
  bookData: any;
  err: any;
  bookingData: any = [];
  vehicle: string;
  userToken: any;
  dropContactFirstname: any;
  dropContactPhone: any;
  surname: any;
  mobile: any;
  email: any;
  noOfLabour: number = 1;
  vehicleName: any;

  dropOff1: boolean = false;
  dropOff2: boolean = false;
  dropOff3: boolean = false;
  dropOff4: boolean = false;
  dropOff5: boolean = false;
  dropOff6: boolean = false;
  dropOff7: boolean = false;
  dropOff8: boolean = false;
  dropOff9: boolean = false;
  dropOff10: boolean = false;
  dropOff11: boolean = false;
  dropOff12: boolean = false;
  dropOff13: boolean = false;
  dropOff14: boolean = false;
  dropOff15: boolean = false;
  dropOff16: boolean = false;
  dropOff17: boolean = false;
  dropOff18: boolean = false;
  dropOff19: boolean = false;
  dropOff20: boolean = false;

  firstDropPrice: number;
  bucketStatus: string;
  //drop off 2
  secondDropOffAddress: string;
  secondDropOffCoordinateLocation: any;
  secondDropOfflocation: string;
  secondDropOffFirstname: string;
  secondDropOffMobile: string;
  secondDropOffChecked: boolean = false;
  secondDropPrice: number;
  secondDrop2: boolean;
  secondBookOID: string;
  commentSecondDrop: string;

  //drop off 3
  thirdDropOffAddress: string;
  thirdDropOffCoordinateLocation: any;
  thirdDropOfflocation: string;
  thirtDropOffFirstname: string;
  thirdDropOffMobile: string;
  thirdDropOffChecked: boolean = false
  thirdDropPrice: number;
  thirdDrop2: boolean;
  thirdBookOID: string;
  commentThirdDrop: string;

  miniVan: string = 'assets\\img\\retail\\miniVan2.png';
  oneTon: string = 'assets\\img\\retail\\oneTon2.png';
  oneHalfTon: string = 'assets\\img\\retail\\oneHaltTon2.png';
  fourTon: string = 'assets\\img\\retail\\fourTon2.png';

  departureCoordinate: any = {};
  destinationCoordinate: any = {};

  noBookings: number = 1;

  public min = new Date(this.yesterday.setDate(this.today.getDate() - 1));
  public max = new Date(2020, 3, 21, 20, 30);

  reloading: boolean = false;

  firstName: string;
  phone: string;
  bucketBookingPrice: number;
  standardRateApplied: string;
  recurringBookings: any = "ONCE";
  recurringStatus: any = [];
  recurringBooking: any;
  endDate: any;

  public listOfBucketBookingObjs: any = [];

  dropOffAutoComplete: any;

  dayOfTheWeek: any;
  dayOfTheMonth: any;
  bookingBucketList: number;
  mass: any;
  mainCenter: any;
  fromSuburb: any;
  toSuburb: any;
  pickUpPCode: any;
  dropOffPCode: any;
  pickUpAmt: any;
  airlineAmt: any;
  dropOffAmt: any;
  destinationProvince: any;
  dropOffProvince: any;
  newDropOffSuburb: any;
  isBalancePositive: boolean = false;
  newBookingPrice: any;

  massBookingObjElement: any;
  quoteResponse: any;
  isExpressBooking: any;
  priceMessage: string;
  type: any;
  transportMode: any;
  canPayWithEFT: boolean = true;
  pickUpProvince: any;
  parcelPrice: number = 0.0;
  paymentMsg: any;
  isExpressBudget: boolean = true;
  isCourierExpress: boolean = true;
  expressCourier: number = 0;
  budgetCourier: number = 0;
  parcels: any = 1;
  msgStatus: string;
  usedTotal: number;
  currentAmt: any;
  courierQuotedTotal: number = 0.0
  allowLoaderToShow: boolean = false

  url: string;

  companyDropOffName: any;
  complexDropOff: any;
  unitDropOff: any;

  customerRef: any;
  costCentreNumber: any;

  numOfParcels: any;
  numberOfPracels = [];
  pracels: Parcel[] = [];
  pracel: Parcel;

  massMsg: string;
  breadthMsg: string;
  heightMsg: string;
  lengthMsg: string;

  overload: boolean = false;
  telkomTag: boolean = false;

  numOfBoxes: any;
  parcelName: string = '';

  @Input() selectedPracel = {}

  constructor(private router: Router, private sharedService: SharedService, private _haversineService: HaversineService, private bookService: BookingService, private userService: UserService,private loadservice:LoaderService) {
    this.url = this.sharedService.getPayFastUrl();
  }

  ngOnInit() {
    this.url = this.sharedService.getPayFastUrl();
    this.userToken = JSON.parse(localStorage.getItem('userInfo'));
    this.retail = JSON.parse(localStorage.getItem('retail'));
    this.telkomTag = this.retail.canAddParcels;
    // console.log(this.telkomTag);
    if (this.telkomTag) {
      this.parcelName = 'Add Parcel';
    } else {
      this.parcelName = 'Add Dimensions';
    }
    this.bookingData = JSON.parse(localStorage.getItem('BookingData'));

    this.canPayWithEFT = this.retail.canInvoice;
    this.bucketBooking = JSON.parse(localStorage.getItem('bucketBooking'));
    this.vehicle = localStorage.getItem('vehicleType');
    this.vehicleTypeRate = this.retail.typeRates;

    this.getBucketbook(this.userToken.token, this.bucketBooking.oid);
    this.getPointOfInterest();
    this.availableBalance();
  }

  totalMass: any = 0;

  public switchPages() {
    if (this.telkomTag == true) {
      // if user has a tag
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.router.navigate(['/multiple-drops']);
    } else {
      // if user has no tag
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.router.navigate(['/multiple-drop-booking']);
    }
  }

  public calculateParcelDimensions() {
    var totalParcelMass = 0;

    this.pracels.forEach(element => {
      totalParcelMass += element.parcel_mass;
    });

    this.totalMass = totalParcelMass;
  }

  public checkingParcelOverLimit() {
    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_mass > 70) {
        this.overload = true;
        break;
      }
      else {
        this.overload = false;
      }
    }
  }

  getBucketbook(token: string, bucketOid: string) {
    this.bookService.getBucketBook(token, bucketOid)
      .subscribe(
        (res: Response) => {
          localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
          localStorage.setItem('bucketBooking', JSON.stringify(res));
          this.booking = res;
          this.isExpressBooking = this.booking.vehicleType;
          this.bookingTime = this.booking.time;
          this.noBookings = this.booking.bookings.length;
          var datePipe = new DatePipe('en-US');
          let value = datePipe.transform(this.booking.date, 'yyyy-MM-dd');
          this.bookingDate = value;
        },
        (error) => {
          console.log(error);
        }, () => {

          var lastIndexOfBucket: [] = this.booking.bookings
          if (lastIndexOfBucket.length > 1) {
            this.setBucketBookingData(lastIndexOfBucket.length - 1)
          } else {
            this.setBucketBookingData(0)
          }

        }
      );
  }

  pickupCompanyName: any;
  pickupComplex: any;
  pickUpUnitNo: any;

  public enterDimensions() {
    $('#enterDimensions').modal('show');
  }

  customer_reference: any;
  cost_center_num: any;
  parcel_breadth: any;
  parcel_height: any;
  parcel_length: any;
  parcel_mass: any;
  courierArray = [];

  public addDimensions() {

    if (this.bucketBooking.type != 'DEDICATED') {

      if (this.parcel_breadth == 0 || this.parcel_height == 0 || this.parcel_length == 0 || this.parcel_mass == 0) {
        this.checkingMsg = 'Please make sure the entered dimensions is not 0.';
        $('#validationModal').modal('show');
      }
      else if (this.parcel_breadth < 0 || this.parcel_height < 0 || this.parcel_length < 0 || this.parcel_mass < 0) {
        this.checkingMsg = 'Please make sure the entered dimensions is not a negative number.';
        $('#validationModal').modal('show');
      }
      else {

        let courierObj = {
          parcel_length: this.parcel_length,
          parcel_breadth: this.parcel_breadth,
          parcel_height: this.parcel_height,
          parcel_mass: this.parcel_mass
        };

        this.courierArray.push(courierObj);

        if (this.retail.canAddParcels) {

          this.pracels.push(
            this.pracel = {
              parcel_length: this.parcel_length,
              parcel_breadth: this.parcel_breadth,
              parcel_height: this.parcel_height,
              parcel_mass: this.parcel_mass,
              customer_reference: this.customer_reference,
              costCenter: this.cost_center_num,
              parcel_number: this.pracels.length,
              noBox: this.numOfBoxes
            }
          );

        }

        this.calculateParcelDimensions();

        this.cost_center_num = '';
        this.customer_reference = '';
        this.parcel_length = '';
        this.parcel_height = '';
        this.parcel_mass = '';
        this.parcel_breadth = '';
        this.numOfBoxes = '';
      }

    } else {

      let courierObj = {
        parcel_length: this.parcel_length,
        parcel_breadth: this.parcel_breadth,
        parcel_height: this.parcel_height,
        parcel_mass: this.parcel_mass
      };

      this.courierArray.push(courierObj);

      if (this.retail.canAddParcels) {

        this.pracels.push(
          this.pracel = {
            parcel_length: this.parcel_length,
            parcel_breadth: this.parcel_breadth,
            parcel_height: this.parcel_height,
            parcel_mass: this.parcel_mass,
            customer_reference: this.customer_reference,
            costCenter: this.cost_center_num,
            parcel_number: this.pracels.length + 1,
            noBox: this.numOfBoxes
          }
        );

      }

      this.calculateParcelDimensions();

      this.cost_center_num = '';
      this.customer_reference = '';
      this.parcel_length = '';
      this.parcel_height = '';
      this.parcel_mass = '';
      this.parcel_breadth = '';
      this.numOfBoxes = '';
    }

  }

  onSelect(pracel): void {
    this.selectedPracel = pracel;
    this.pracel = pracel;
  }

  checkingMsg: string = '';
  goThroughMsg: string = '';

  public checkDimensionValidation() {

    // let check = false;

    for (let a = 0; a < this.pracels.length; a++) {
      if (this.pracels[a].parcel_breadth == null || this.pracels[a].parcel_height == null || this.pracels[a].parcel_length == null || this.pracels[a].parcel_mass == null) {
        this.checkingMsg = 'Please make sure that all the parcel details are filled in before proceeding.';
        //.log(this.checkingMsg);
        $('#validationModal').modal('show');
        // check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth == 0 || this.pracels[a].parcel_height == 0 || this.pracels[a].parcel_length == 0 || this.pracels[a].parcel_mass == 0) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are 0.';
        $('#validationModal').modal('show');
        // check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth > 150 || this.pracels[a].parcel_height > 150 || this.pracels[a].parcel_length > 150 || this.pracels[a].parcel_mass > 150) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are greater than 150.';
        $('#validationModal').modal('show');
        // check = false;
        break;
      }
      else if (this.pracels[a].parcel_breadth < 0 || this.pracels[a].parcel_height < 0 || this.pracels[a].parcel_length < 0 || this.pracels[a].parcel_mass < 0) {
        this.checkingMsg = 'Please make sure that none of the dimensions entered are a negative number.';
        $('#validationModal').modal('show');
        // check = false;
        break;
      }
      //   else {
      //     check = true;
      //   }
    }

    // return check;
  }

  public modelBreadthChanged(parcel_breadth) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_breadth > 150) {
        this.breadthMsg = "Width for parcel cannot exceed 150";
      }
      else if (parcel_breadth == 0) {
        this.breadthMsg = "Width cannot be 0";
      }
      else if (parcel_breadth < 0) {
        this.breadthMsg = "Width cannot be a negative number";
      }
      else if (parcel_breadth == null) {
        this.breadthMsg = "Width cannot be empty";
      }
      else if (parcel_breadth < 150) {
        this.breadthMsg = " ";
      }
    }
  }

  public modelMassChanged(parcel_mass) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_mass > 70) {
        this.massMsg = "Mass cannot exceed 70";
      }
      else if (parcel_mass == 0) {
        this.massMsg = "Mass cannot be 0";
      }
      else if (parcel_mass < 0) {
        this.massMsg = "Mass cannot be a negative number";
      }
      else if (parcel_mass == null) {
        this.massMsg = "Mass cannot be empty";
      }
      else if (parcel_mass < 70) {
        this.massMsg = " ";
      }
    }
  }

  public modelHeightChanged(parcel_height) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_height > 150) {
        this.heightMsg = "Height for parcel cannot exceed 150";
      }
      else if (parcel_height == 0) {
        this.heightMsg = "Height cannot be 0";
      }
      else if (parcel_height < 0) {
        this.heightMsg = "Height cannot be a negative number";
      }
      else if (parcel_height == null) {
        this.heightMsg = "Height cannot be empty";
      }
      else if (parcel_height < 150) {
        this.heightMsg = " ";
      }
    }
  }

  public modelLengthChanged(parcel_length) {
    if (this.bucketBooking.type != 'DEDICATED') {
      if (parcel_length > 150) {
        this.lengthMsg = "Length for parcel cannot exceed 150";
      }
      else if (parcel_length == 0) {
        this.lengthMsg = "Length cannot be 0";
      }
      else if (parcel_length < 0) {
        this.lengthMsg = "Length cannot be a negative number";
      }
      else if (parcel_length == null) {
        this.lengthMsg = "Length cannot be empty";
      }
      else if (parcel_length < 150) {
        this.lengthMsg = " ";
      }
    }
  }

  public setBucketBookingData(lastIndexOfBucket: number) {
    this.pickupCompanyName = this.booking.bookings[lastIndexOfBucket].pickUp.companyName;
    this.pickupComplex = this.booking.bookings[lastIndexOfBucket].pickUp.complex;
    this.pickUpUnitNo = this.booking.bookings[lastIndexOfBucket].pickUp.unitNo;
    this.firstName = this.booking.bookings[lastIndexOfBucket].pickUp.firstName;
    this.phone = this.booking.bookings[lastIndexOfBucket].pickUp.phone;
    this.mobile = this.booking.bookings[lastIndexOfBucket].contact
    this.surname = this.booking.bookings[lastIndexOfBucket].pickUp.lastName;
    this.dropContactFirstname = this.booking.bookings[lastIndexOfBucket].dropOff.firstName;
    this.dropContactPhone = this.booking.bookings[lastIndexOfBucket].dropOff.phone;
    this.pickUpAddress = this.booking.bookings[lastIndexOfBucket].pickUpAddress;
    this.dropOfflocation = this.booking.bookings[lastIndexOfBucket].dropOffAddress;
    this.vehicleType = this.booking.bookings[lastIndexOfBucket].vehicleType;
    this.tempAmount = this.booking.price;
    this.comment = this.booking.bookings[lastIndexOfBucket].comment;
    this.pickUpCoordinateLocation = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate;
    this.bucketStatus = this.booking.status
    this.numberOfLabours = this.bookingData != null ? this.bookingData.labour : 0
    this.pickUpLong = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[1];
    this.pickUpLat = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[0];
    this.departureCoordinate = { "lat": this.pickUpLat, "lng": this.pickUpLong };
    this.mass = this.booking.bookings[lastIndexOfBucket].itemMass;
    this.pickUpPCode = this.booking.bookings[lastIndexOfBucket].pickUpPCode;
    this.pickUpAmt = this.booking.bookings[lastIndexOfBucket].pickUpAmt;
    this.airlineAmt = this.booking.bookings[lastIndexOfBucket].airlineAmt;
    this.dropOffAmt = this.booking.bookings[lastIndexOfBucket].dropOffAmt;
    this.fromSuburb = this.booking.bookings[lastIndexOfBucket].fromSuburb;
    this.listOfBucketBookingObjs = this.booking.bookings;
    this.bookingBucketList = this.listOfBucketBookingObjs.length;
    this.type = this.booking.bookings[lastIndexOfBucket].type;
    this.transportMode = this.booking.bookings[lastIndexOfBucket].transportMode;
    this.pickUpProvince = this.booking.bookings[lastIndexOfBucket].province;
  }

  public sendInvoiceTax() {
    this.bookService.invoiceBucketCustomer(this.userToken.token, this.bucketBooking.trackNo).subscribe(
      (response) => {
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }, () => {

      }
    )
  }

  public sendNewRetailInvoices() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookService.invoiceRetailBucketCustomer(object.token, this.bucketBooking.trackNo).subscribe(
      (response) => {
      }, (error) => {
        console.log('Error inside the attach invoice block');
        console.log(error);
      }
    );
  }

  public onUpdateMass(mass: number) {
    if (this.courierQuotedTotal > 0 && this.addressFormVailidater.nativeElement.checkValidity()) {
      this.allowLoaderToShow = false
      this.price = 0.0
      this.getBothQuote();
    }
  }

  public dropOffPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;
          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.dropOffPCode = foundAddress[x].long_name;
                  this.getDropOffSuburb();
                }
              }
            }
          }
        }
      }
    );
  }

  public getDropOffSuburb() {

    this.bookService.getDropOffSuburb(this.dropOffPCode).subscribe(
      response => {
        this.toSuburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  autoCompleteBucketBookingObj(selectedData: any) {
    this.dropOffAutoComplete = selectedData;
    if (!selectedData.response) {
      this.dropOffNotMsg = 'Location not found, Please enter nearest location.';
      this.dropOffNotFound = false;
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'locality') {
              this.toSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'sublocality') {
              this.newDropOffSuburb = selectedData.data.address_components[x].long_name;
            }
            else if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.dropOffPCode = selectedData.data.address_components[x].long_name;
              this.getDropOffSuburb();
            }
            else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.dropOffProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }

      if (this.dropOffPCode == null) {
        this.dropOffPostalCodeService(selectedData);
      }

      this.bucketBookingObjDropOffAddress = selectedData.data.description;
      this.dropOffLat = selectedData.data.geometry.location.lat;
      this.dropOffLong = selectedData.data.geometry.location.lng;
      this.dropOffCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.destinationCoordinate = { "lat": selectedData.data.geometry.location.lat, "lng": selectedData.data.geometry.location.lng };
      this.bucketBookingObjDropOffCoordinateLocation = {
        "coordinates": this.dropOffCoordinate,
        "address": this.dropOffAddress
      };

      if (this.courierQuotedTotal > 0 && this.addressFormVailidater.nativeElement.checkValidity()) {
        this.price = 0.0
        this.getBothQuote()
      }

    }
  }

  public addingBucketBookingObj() {

    if (this.parcelPrice || this.price != 0) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      var token = object.token;
      var timestamp = new Date().valueOf();
      var bookingData = {
        "dropOff": {
          "firstName": this.bucketBookingObjDropOffFirstname,
          "lastName": this.bucketBookingObjDropOffFirstname,
          "phone": this.bucketBookingObjDropOffMobile,
          "email": object.owner.email,
          'companyName': this.companyDropOffName != null ? this.companyDropOffName : object.owner.firstName,
          'complex': this.complexDropOff != null ? this.complexDropOff : null,
          'unitNo': this.unitDropOff != null ? this.unitDropOff : null
        },
        "pickUp": {
          "firstName": this.firstName,
          "lastName": this.surname != null ? this.surname : object.owner.surname,
          "phone": this.phone,
          "email": object.owner.email,
          'companyName': this.pickupCompanyName != null ? this.pickupCompanyName : object.owner.firstName,
          'complex': this.pickupComplex != null ? this.pickupComplex : null,
          'unitNo': this.pickUpUnitNo != null ? this.pickUpUnitNo : null
        },
        "customer": object.owner,
        "price": this.price != 0 ? this.price : this.parcelPrice,
        "vehicleType": this.vehicleType,
        "pickUpAddress": this.pickUpAddress,
        "pickUpTime": this.bookingTime,
        "phoneNo": object.owner.mobile,
        "pickUpDate": this.booking.bookings[0].pickUpDate,
        "dropOffAddress": this.bucketBookingObjDropOffAddress,
        "comment": this.bucketBookingObjCommentDrop,
        "labour": 0,
        "timestamp": timestamp,
        "status": "BUCKET",
        "dropFloors": 0,
        "pickUpFloors": 0,
        "pickUpCoordinate": this.pickUpCoordinateLocation,
        "dropOffCoordinate": this.bucketBookingObjDropOffCoordinateLocation,
        "load": this.parcels,
        "canopy": false,
        "dropOid": null,
        "distance": 0,
        "province": this.dropOffProvince,
        'transportMode': this.transportMode,
        'type': this.type,
        "itemMass": this.totalMass,
        'isItemPicked': false,
        'mainCityOid': this.mainCenter != null ? this.mainCenter.oid : null,
        'fromSuburb': this.fromSuburb,
        'toSuburb': this.toSuburb,
        'pickUpPCode': this.pickUpPCode,
        'dropOffPCode': this.dropOffPCode,
        'pickUpAmt': this.pickUpAmt,
        'airlineAmt': this.airlineAmt,
        'dropOffAmt': this.dropOffAmt,
        'destinationProvince': this.dropOffProvince,
        'isPayed': false,
        'paymentType': "ONLINE_PAY",
        "customerReference": this.customerRef,
        "costCenter": this.costCentreNumber,
        "parcels": null,
        "parcelDimensions": this.courierArray
      };

      if (this.pracels.length > 0) {
        bookingData.parcels = this.pracels
      }

      var addbookingObj = {
        "bucketBookingOid": this.booking.oid,
        "booking": bookingData
      };

      this.loadservice.addloader();
      this.bookService.addToBucketsBook(addbookingObj, token)
        .subscribe(
          (res: Response) => {
            this.loadservice.removeloader();
            this.msg = 'Booking successfully added to the bucket!';
            $('#Bookingresults').modal('show');
            this.getBucketbook(token, this.booking.oid);
            this.bucketBookingObjDropOffChecked = true;
          },
          (error: Response) => {
            console.log(error);
            this.loadservice.removeloader();
            this.err = error;
            this.msg = this.err.error.message != null ? this.err.error.message : 'Error occured while making a booking, Please try again.';
            $('#Bookingresults').modal('show');
          }
        );
    } else {
      this.priceMessage = "Please click the get quote button & get a price to add an element.";
      $('#priceModal').modal('show');
    }

  }

  public clearBucketBookingObj() {
    this.dropOffAutoComplete.data.active = false;
    this.bucketBookingObjDropOffAddress = '';
    this.bucketBookingObjDropOfflocation = '';
    this.bucketBookingObjDropOffFirstname = ''
    this.bucketBookingObjDropOffMobile = '';
    this.bucketBookingObjDropOffChecked = false;
    this.bucketBookingObjDropPrice = 0.00;
    this.bucketBookingObjDrop2 = false;
    this.bucketBookingObjCommentDrop = '';
    this.massBookingObjElement = '';
    // this.parcels = 1;
    this.courierQuotedTotal = 0.0
    this.customerRef = '';
    this.costCentreNumber = '';
    this.companyDropOffName = '';
    this.complexDropOff = '';
    this.unitDropOff = '';
    this.pracels = [];
    this.courierArray = [];

    this.userSettings = {
      showRecentSearch: false,
      geoCountryRestriction: ['ZA'],
      inputPlaceholderText: 'Enter drop-Off address.',
      geoTypes: ['address', 'establishment'],
      inputString: ''
    };
  }

  public removeBucketBookingObj(i) {
    this.loadservice.addloader();
    this.bookService.removeFromBucketsBook(this.booking.oid, this.booking.bookings[i].oid, this.userToken.token).subscribe(
      (res) => {
        this.msg = "Booking deleted from the Bucket!";
        $('#removeElement').modal('show');
        this.loadservice.removeloader();
        this.clearFields();
        this.getBucketbook(this.userToken.token, this.booking.oid);
      }, (error) => {
        console.log(error);
        $('#removeElement').modal('show');
        this.msg = "Booking  not deleted!";
        this.loadservice.removeloader();
      }
    )
  }

  public onHome() {
    this.router.navigateByUrl('/retail-profile');
  }

  public onAddDrop() {
    if (this.bucketBooking.status == "BUCKET_AWAITING") {
      this.dropOff1 = true;
    }
  }

  public onAddSecondDrop() {
    if (this.bucketBooking.status == "BUCKET_AWAITING") {
      this.clearBucketBookingObj();
    }
    this.parcelPrice = 0.00;
    this.price = 0.00;
  }

  public controlSecondDrop(booking: any) {
    this.dropOff1 = true;
    this.dropOff2 = true;
    this.secondDrop2 = true;
    this.secondDropOfflocation = booking.bookings[1].dropOffAddress;
    this.secondDropOffFirstname = booking.bookings[1].dropOff.firstName;
    this.secondDropOffMobile = booking.bookings[1].dropOff.phone;
    this.secondDropOffChecked = true;
    this.secondDropPrice = booking.bookings[1].price;
    this.commentSecondDrop = booking.bookings[1].comments;
  }

  private clearFields() {
    this.dropOff3 = false;
    this.thirdDropOfflocation = '';
    this.thirtDropOffFirstname = '';
    this.thirdDropOffMobile = '';
    this.thirdDropOffChecked = false;
    this.thirdDropPrice = 0.00;
    this.commentThirdDrop = '';

    this.dropOff2 = false;
    this.secondDropOfflocation = '';
    this.secondDropOffFirstname = '';
    this.secondDropOffMobile = '';
    this.secondDropOffChecked = false;
    this.secondDropPrice = 0.00;
    this.commentSecondDrop = '';

    this.parcelPrice = 0.00;
    this.price = 0.00;
    this.courierQuotedTotal = 0.0
  }

  public cancelBacketBook() {
    this.loadservice.addloader();
    this.bookService.cancelBucket(this.userToken.token, this.booking.oid).subscribe(
      (res) => {
        this.msg = "Booking cancelled.";
        $('#bookingCancelled').modal('show');
        this.getBucketbook(this.userToken.token, this.booking.oid);
        this.router.navigateByUrl('/retail-profile');
      }, (error) => {
        console.log(error);
        this.msg = "Could not cancel booking.";
        $('#bookingCancelled').modal('show');
      }
    )
  }

  public showToggle() {
    if (this.bucketBooking.status == "BUCKET_AWAITING") {
      $('#myBankModals').modal('show');
    } else {
      this.msg = "Bucket has been Successfully Added!";
      $('#pushBooking').modal('show');
    }
  }

  getDistance(dropOffLat: number, dropOffLong: number): number {
    let pickCordinate: GeoCoord = {
      latitude: this.pickUpLat,
      longitude: this.pickUpLong
    };
    let dropCordinate: GeoCoord = {
      latitude: dropOffLat,
      longitude: dropOffLong
    };

    let kilometers = this._haversineService.getDistanceInKilometers(pickCordinate, dropCordinate);
    return kilometers;
  }

  public getDroppOffQuote(address: string, dropOffLat: number, dropOffLong: number) {
    if (address != null && this.vehicleType != null) {
      var data = {
        "destination": this.destinationCoordinate,
        "departure": this.departureCoordinate
      };
      this.bookService.getDistance(data).subscribe(
        (response) => {
          var distanceResponse = response.json();
          this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
          this.distance = Math.round(this.distance / 1000);
          localStorage.setItem('distance', this.distance + '');
          var object = JSON.parse(localStorage.getItem('userInfo'));
          this.bookService.getRetailVehicleRate(this.retail.oid, this.vehicleType)
            .subscribe(
              (res: Response) => {
                var bookingData = {
                  "distance": this.distance,
                  "retailOid": this.retail.oid,
                  "totalPrice": 0.0,
                  "vehicleType": this.vehicleType
                };
                this.loadservice.addloader()
                this.bookService.getRetailbookingPrice(bookingData, object.token)
                  .subscribe(
                    (response: Response) => {
                      this.bookingPrice = response.json();
                      this.price = this.bookingPrice.totalPrice;
                      this.loadservice.removeloader
                    },
                    (err) => {
                      console.log(err);
                      this.loadservice.removeloader()
                    }
                  );
              },
              (err) => {
                console.log(err);
                this.loadservice.removeloader();
                this.msg = err.json().message;
                $('#results').modal('show');
              }
            )
        });
    }
  }

  public getDroppaPricings() {

    var data = {
      "destination": this.destinationCoordinate,
      "departure": this.departureCoordinate
    };

    this.bookService.getDistance(data).subscribe(
      response => {

        var distanceResponse = response.json();
        this.distance = distanceResponse.rows[0].elements[0].distance.inMeters;
        this.distance = Math.round(this.distance / 1000);

        var quoteBody = {
          "mass": this.totalMass,
          "distance": this.distance,
          "fromSuburb": this.fromSuburb,
          "toSuburb": this.toSuburb,
          "pickUpAddress": this.pickUpAddress,
          "dropOffAddress": this.bucketBookingObjDropOffAddress,
          "pickUpProvince": this.pickUpProvince,
          "dropOffProvince": this.dropOffProvince,
          "pickUpPCode": this.pickUpPCode,
          "dropOffPCode": this.dropOffPCode,
          "parcelDimensions": this.courierArray,
          "platform": "web",
        }

        this.loadservice.addloader()
        this.bookService.getNewSkynetPrices(quoteBody).subscribe(
          response => {
            this.quoteResponse = response.json();

            if (response.json().expressCourier > 0) {
              this.loadservice.removeloader()
              this.isCourierExpress = false;
              this.expressCourier = response.json().expressCourier;
              this.courierQuotedTotal = Math.round(this.expressCourier)
              this.parcelPrice = Math.round(this.expressCourier);
            }

            if (response.json().expressBudgetAmt > 0) {

              this.loadservice.removeloader()
              this.isExpressBudget = this.allowLoaderToShow;
              this.budgetCourier = response.json().expressBudgetAmt;
              this.courierQuotedTotal = Math.round(this.budgetCourier)
              this.parcelPrice = (Math.round(this.budgetCourier) * this.parcels);
            }
          }, error => {
            this.loadservice.addloader()
            console.log(error);
          }, () => { }
        );

      }, error => {
        console.log(error);
      }
    )

  }

  msgAddress: string;
  msgComment: string;
  msgDropOffName: string;
  msgDropOffMobile: string;
  msgItems: string;

  public getBothQuote() {

    if (this.retail.canAddParcels) {

      if (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER') {
        if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
          this.msgAddress = "Please enter your address";
        }
        else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
          this.msgDropOffName = "Please fill in the name";
        }
        else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
          this.msgDropOffMobile = "Please enter in your numbers.";
        }
        else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
          this.msgComment = "Please fill in the comments (Instructions...)";
        }
        else if (this.courierArray.length <= 0 && this.retail.canAddParcels) {
          this.msgItems = "Please fill in the items before getting a quote";
        }
        else {
          this.getDroppaPricings();
          this.msgStatus = "";
          this.msgAddress = "";
          this.msgComment = "";
          this.msgDropOffName = "";
          this.msgDropOffMobile = "";
          this.goThroughMsg = "";
          this.checkingMsg = "";
          this.msgItems = "";
        }
      }
      else {
        if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
          this.msgAddress = "Please enter your address";
        }
        else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
          this.msgDropOffName = "Please fill in the name";
        }
        else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
          this.msgDropOffMobile = "Please enter in your numbers.";
        }
        else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
          this.msgComment = "Please fill in the comments (Instructions...)";
        }
        else if (this.courierArray.length <= 0 && this.retail.canAddParcels) {
          this.msgItems = "Please fill in the items before getting a quote";
        }
        else {
          this.getDroppOffQuote(this.bucketBookingObjDropOffAddress, this.dropOffLat, this.dropOffLong);
          this.msgStatus = "";
          this.msgAddress = "";
          this.msgComment = "";
          this.msgDropOffName = "";
          this.msgDropOffMobile = "";
          this.msgItems = "";
        }
      }

    } else {

      if (this.type == 'EXPRESS_COURIER' || this.type == 'BUDGET_COURIER') {

        if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
          this.msgAddress = "Please enter your address";
        }
        else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
          this.msgDropOffName = "Please fill in the name";
        }
        else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
          this.msgDropOffMobile = "Please enter in your numbers.";
        }
        else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
          this.msgComment = "Please fill in the comments (Instructions...)";
        }
        else if (this.courierArray.length <= 0 && !this.retail.canAddParcels) {
          this.msgItems = "Please fill in the items before getting a quote";
        }
        else {
          this.getDroppaPricings();
          this.msgStatus = "";
          this.msgAddress = "";
          this.msgComment = "";
          this.msgDropOffName = "";
          this.msgDropOffMobile = "";
          this.goThroughMsg = "";
          this.checkingMsg = "";
          this.msgItems = "";
        }

      }
      else {

        if (this.bucketBookingObjDropOffAddress == '' || this.bucketBookingObjDropOffAddress == null) {
          this.msgAddress = "Please enter your address";
        }
        else if (this.bucketBookingObjDropOffFirstname == '' || this.bucketBookingObjDropOffFirstname == null) {
          this.msgDropOffName = "Please fill in the name";
        }
        else if (this.bucketBookingObjDropOffMobile == '' || this.bucketBookingObjDropOffMobile == null) {
          this.msgDropOffMobile = "Please enter in your numbers.";
        }
        else if (this.bucketBookingObjCommentDrop == '' || this.bucketBookingObjCommentDrop == null) {
          this.msgComment = "Please fill in the comments (Instructions...)";
        }
        else {
          this.getDroppOffQuote(this.bucketBookingObjDropOffAddress, this.dropOffLat, this.dropOffLong);
          this.msgStatus = "";
          this.msgAddress = "";
          this.msgComment = "";
          this.msgDropOffName = "";
          this.msgDropOffMobile = "";
          this.msgItems = "";
        }

      }


    }
  }

  public reloadCss() {
    var links = document.getElementsByTagName("link");
    for (var cl in links) {
      var link = links[cl];
      if (link.rel === "stylesheet")
        link.href += "";
    }
  }

  public geocodeLatLng(geocoder, userLat, userLong) {
    var latlng = { lat: userLat, lng: userLong };
    var self = this;
    geocoder.geocode({ 'location': latlng }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length == 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            if (data.long_name == 'South Africa') {
              self.geocodeLatLngProvince(geocoder, results[1].address_components[1].long_name);
            }
            break;
          }
        }
        self.province = data.long_name;
        self.validateProvince = data.long_name;
        self.province = self.validateProvince
      } else {
        self.province = "UNKNOWN";
        self.province = self.validateProvince
      }
    });
  }

  public geocodeLatLngProvince(geocoder, city) {
    var self = this;
    geocoder.geocode({ 'address': city }, function (results, status) {
      if (status === 'OK') {
        var len = results[0].address_components.length;
        var data;
        for (var i = 0; i < len; i++) {
          if (results[0].address_components[i].short_name.length == 2 && results[0].address_components[i].long_name.length >= 7) {
            data = results[0].address_components[i];
            self.validateProvince = data.long_name;
            break;
          }
        }
        self.province = data.long_name;
        self.province = self.validateProvince
      } else {
        self.province = "UNKNOWN";
        self.province = self.validateProvince
      }
    });
  }

  public getPointOfInterest() {
    this.bookService.getPOI().subscribe(
      response => {
        for (let i = 0; i < response.length; i++) {
          let distance = this.coordinateDistance(this.pickUpLat, this.pickUpLong,
            Number.parseFloat(response[i].location.coordinates[0]),
            Number.parseFloat(response[i].location.coordinates[1]), 'K');
          if (distance <= 30) {
            this.mainCenter = response[i];
          }
        }
      }, error => {
      }
    )
  }

  private coordinateDistance(lat1, lon1, lat2, lon2, unit): number {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }

  public availableBalance() {
    this.bookService.checkAvailBalance(this.userToken.token, this.userToken.owner.oid, this.bucketBooking.price).subscribe(
      response => {
        this.isBalancePositive = response.isBalancePositive;
      }, error => {
        console.log(error);
      }
    )
  }

  public confirmBooking() {
    let myToken = JSON.parse(localStorage.getItem('userInfo')).token;
    var data = {
      "comments": this.comment,
      "labour": this.numberOfLabours,
      "bookingOid": this.bucketBooking.oid,
      "recurrence": null
    };
    this.loadservice.addloader();
    this.bucketBookingPrice = this.tempAmount
    this.bookService.pushBucketsBook(data, myToken).subscribe(
      (res) => {
        this.bucketBooking = res;
        if (this.bucketBooking.price > this.bucketBookingPrice) {
          this.standardRateApplied = "Please note standard rate  of " + " " + "R" + this.bucketBooking.price + " " + "and 15% VAT will be applied."
        }
        this.loadservice.removeloader();
        this.msg = "Bucket Booking successfully created.";
        $('#pushBooking').modal('show');
        //this.sendNewRetailInvoices();
        this.sendInvoiceTax();
        localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
      },
      (error) => {
        console.log(error);
        this.msg = error.error.message;
        this.loadservice.removeloader()
        $('#pushBooking').modal('show');
      }
    );
  }

  public payWithWallet() {

    var walletBody = {
      "comments": this.comment,
      "labour": this.numberOfLabours,
      "bookingOid": this.bucketBooking.oid,
      "recurrence": {
        "period": null,
        "fromDate": null,
        "toDate": null
      }
    }
    this.loadservice.addloader();

    this.bookService.checkAvailBalance(this.userToken.token, this.userToken.oid, this.bucketBooking.price).subscribe(
      response => {
        this.isBalancePositive = response.isBalancePositive;
        if (this.isBalancePositive) {
          this.bookService.authWallet(this.userToken.token, walletBody).subscribe(
            response => {
              this.loadservice.removeloader();
              this.msg = "Bucket Booking successfully created.";
              $('#walletPayment').modal('show');
              //this.sendNewRetailInvoices();
              this.sendInvoiceTax();
            }, error => {
              this.loadservice.removeloader();
              console.log(error);
            }, () => {
            }
          );
        }
        else {
          this.loadservice.removeloader();
          this.msg = "Your wallet has insufficient funds. Please credit your wallet to proceed with the transaction.";
          $('#walletPayment').modal('show');
        }
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }
    )

  }

  public onPostBucketBookingPaymentCard() {

    var userPaymentData = JSON.parse(localStorage.getItem('userInfo'));
    var gotoCheckOut: boolean = false
    var cardPaymentData = {
      "name_first": userPaymentData.owner.firstName,
      "name_last": userPaymentData.owner.surname,
      "email_address": userPaymentData.owner.email,
      "cell_number": userPaymentData.owner.mobile,
      "m_payment_id": this.bucketBooking.oid,
      "amount": this.tempAmount,
      "customerReference": userPaymentData.owner.oid,
      "item_name": "BUCKET BOOKING",
      "item_description": "Transporting multiple Items from ",
      "email_confirmation": userPaymentData.owner.email,
      "confirmation_address": userPaymentData.owner.email,
      "reference": this.bucketBooking.oid,
      "paymentType": "BUCKET_BOOKING_PAYMENT"
    }

    this.bookService.requestBucketPaymentPayFast(cardPaymentData, this.userToken.token).subscribe((res: Response) => {

      let successfulPaymentData = res.json()
      this.merchant_id.nativeElement.value = successfulPaymentData.merchant_id;
      this.merchant_key.nativeElement.value = successfulPaymentData.merchant_key;

      this.return_url.nativeElement.value = successfulPaymentData.return_url;
      this.cancel_url.nativeElement.value = successfulPaymentData.cancel_url;
      this.notify_url.nativeElement.value = successfulPaymentData.notify_url;
      this.name_first.nativeElement.value = successfulPaymentData.name_first;
      this.name_last.nativeElement.value = successfulPaymentData.name_last;
      this.email_address.nativeElement.value = successfulPaymentData.email_address;
      this.m_payment_id.nativeElement.value = successfulPaymentData.m_payment_id;
      this.amount.nativeElement.value = successfulPaymentData.amount;
      this.item_name.nativeElement.value = successfulPaymentData.item_name;
      this.item_description.nativeElement.value = successfulPaymentData.item_description;
      this.confirmation_address.nativeElement.value = successfulPaymentData.confirmation_address;
      this.custom_int1.nativeElement.value = successfulPaymentData.custom_int1;

      localStorage.setItem('paymentReference', successfulPaymentData.m_payment_id);
      gotoCheckOut = true

    }, (error) => {
      console.log(error);
      this.loadservice.removeloader();
      gotoCheckOut = false
    }, () => {

      if (gotoCheckOut) {
        this.payFastForm.nativeElement.submit()
      } else {

      }
    });

  }



}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
