import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';
import { SharedService } from '../../services/shared.service';
import { BookingService } from 'src/app/services/booking.service';
import { Component, ElementRef, OnInit, ViewChild, isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms';
import { User } from '../../models/user';
import { Login } from '../../models/login';
import { AngularFireDatabase } from '@angular/fire/database';
import { LoaderService } from 'src/app/services/loader.service';
import { async } from '@angular/core/testing';

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-retail-profile',
  templateUrl: './retail-profile.component.html',
  styleUrls: ['./retail-profile.component.css']
})

export class RetailProfileComponent implements OnInit {

  @ViewChild('m_id', { static: false }) merchant_id: ElementRef;
  @ViewChild('m_key', { static: false }) merchant_key: ElementRef;
  @ViewChild('return_url', { static: false }) return_url: ElementRef;
  @ViewChild('cancel_url', { static: false }) cancel_url: ElementRef;
  @ViewChild('notify_url', { static: false }) notify_url: ElementRef;
  @ViewChild('name_first', { static: false }) name_first: ElementRef;
  @ViewChild('name_last', { static: false }) name_last: ElementRef;
  @ViewChild('email_address', { static: false }) email_address: ElementRef;
  @ViewChild('cell_number', { static: false }) cell_number: ElementRef;
  @ViewChild('m_payment_id', { static: false }) m_payment_id: ElementRef;
  @ViewChild('amount', { static: false }) price: ElementRef;
  @ViewChild('item_name', { static: false }) item_name: ElementRef;
  @ViewChild('item_description', { static: false }) item_description: ElementRef;
  @ViewChild('custom_int1', { static: false }) custom_int1: ElementRef;
  @ViewChild('confirmation_address', { static: false }) confirmation_address: ElementRef;

  // Ozow
  @ViewChild('SiteCode', { static: false }) SiteCode: ElementRef;
  @ViewChild('CountryCode', { static: false }) CountryCode: ElementRef;
  @ViewChild('CurrencyCode', { static: false }) CurrencyCode: ElementRef;
  @ViewChild('Amount', { static: false }) Amount: ElementRef;
  @ViewChild('TransactionReference', { static: false }) TransactionReference: ElementRef;
  @ViewChild('BankReference', { static: false }) BankReference: ElementRef;
  @ViewChild('Customer', { static: false }) Customer: ElementRef;
  @ViewChild('Optional1', { static: false }) Optional1: ElementRef;
  @ViewChild('CancelUrl', { static: false }) CancelUrl: ElementRef;
  @ViewChild('ErrorUrl', { static: false }) ErrorUrl: ElementRef;
  @ViewChild('SuccessUrl', { static: false }) SuccessUrl: ElementRef;
  @ViewChild('NotifyUrl', { static: false }) NotifyUrl: ElementRef;
  @ViewChild('IsTest', { static: false }) IsTest: ElementRef;
  @ViewChild('HashCheck', { static: false }) HashCheck: ElementRef;
  @ViewChild('paymentForm', { static: true }) paymentForm: NgForm;

  user: any;
  bookings: any = [];
  bookingList: any = [];
  awaitBooking: any = [];
  bucketBooking: any = [];
  retail: any;
  assignStatus: string = "";
  userMobile: string;
  retailOwner: boolean;
  retailAvailable: boolean = false;
  company: any = {};
  vehicles: any = [];
  drivers: any = [];
  deliveries: any = [];
  deliverys: any = [];
  payment: any = [];
  foundDriver: any = {};

  valid: boolean = false;
  loader: boolean = false;

  make: string = '';
  model: string = '';
  registration: string = '';
  vinNo: string = '';
  Vcolor: string = '';
  type: string = '';
  vehicleFeedBack: string = '';
  idNo: string;
  err: string = '';

  vehicleOid: string;

  bankFeedBack: string = "";
  bankName: string;
  accountName: string;
  branchCode: string;
  accountNo: string;

  isBank: boolean = true;
  bank: any;
  lat: number = -25.990886;
  lng: number = 28.129811999999998;
  index: any;

  totalItem: number;
  expressPage: number = 1;

  totalItems: number;
  itemsPage: number = 1;

  total: number;
  page: number = 1;

  totalUser: number;
  pageUser: 1;

  driverDetails: any;
  driverOid: any;
  today: number = Date.now();
  vehicleTypes: any = [];
  invoices: any;
  retailId: string;
  returnAmount: number;
  retailUser: any;
  retailFlag: boolean;
  expressBookings: any = [];

  pudoService = true

  miniVan = 'assets\\img\\retail\\miniVan2.png';
  oneTon = 'assets\\img\\retail\\oneTon2.png';
  oneHalfTon = 'assets\\img\\retail\\oneHaltTon2.png';
  fourTon = 'assets\\img\\retail\\fourTon2.png';

  amountType: any = [
    { amount: 500 },
    { amount: 1000 },
    { amount: 2000 },
    { amount: 5000 },
    { amount: 10000 }
  ];

  amountChosen: number = 0;
  currentAmt: any;
  message: string;
  successmsg: string;
  walletOID: any;
  payFastResponse: any;
  url: string;
  startDate: any;
  endDate: any;
  canAddParcels: any;

  savedAddresses: any = [];
  displayManualAddress: boolean = false;

  pickUpLat: any;
  pickUpLong: any;
  capturePickUpAddressLabel: any;
  pickUpAddress: string;
  checkingPickUpMsg: string = '';

  public userSettings2: any = {
    showRecentSearch: false,
    geoCountryRestriction: ['ZA'],
    inputPlaceholderText: 'Enter your street address.',
    geoTypes: ['address', 'establishment']
  };

  validateProvince: any;
  validatePickuProvince: string;
  addressComponent: any = [];
  pickUpProvince: any;
  postalCode: any;
  pickUpNotMsg: string;
  pickUpCoordinate: any = [];
  departureCoordinate: any = {};
  suburb: any;
  pickUpCoordinateLocation: any = {};
  province: string = "UNKNOWN";
  suburbList: any = [];
  enteredPickUpPostalCodeMsg: string = '';
  addressPage: number = 1;
  addressTotal: number;

  rentalBookings: any = [];

  rentalPage: number = 1;
  rentalTotal: number;

  recreateMsg: string = '';

  showOwnAmountInput: boolean = false;
  emptyWallet: boolean = false
  reporterror: boolean = false

  @ViewChild('m_id_rental', { static: false }) merchant_id_rental: ElementRef;
  @ViewChild('m_key_rental', { static: false }) merchant_key_rental: ElementRef;
  @ViewChild('return_url_rental', { static: false }) return_url_rental: ElementRef;
  @ViewChild('cancel_url_rental', { static: false }) cancel_url_rental: ElementRef;
  @ViewChild('notify_url_rental', { static: false }) notify_url_rental: ElementRef;
  @ViewChild('name_first_rental', { static: false }) name_first_rental: ElementRef;
  @ViewChild('name_last_rental', { static: false }) name_last_rental: ElementRef;
  @ViewChild('email_address_rental', { static: false }) email_address_rental: ElementRef;
  @ViewChild('cell_number_rental', { static: false }) cell_number_rental: ElementRef;
  @ViewChild('m_payment_id_rental', { static: false }) m_payment_id_rental: ElementRef;
  @ViewChild('amount_rental', { static: false }) price_rental: ElementRef;
  @ViewChild('item_name_rental', { static: false }) item_name_rental: ElementRef;
  @ViewChild('item_description_rental', { static: false }) item_description_rental: ElementRef;
  @ViewChild('custom_int1_rental', { static: false }) custom_int1_rental: ElementRef;
  @ViewChild('confirmation_address_rental', { static: false }) confirmation_address_rental: ElementRef;
  url_rental: any;

  retailBranches = [];
  @ViewChild('branchForm', { static: false }) branchForm: NgForm;
  @ViewChild('assignForm', { static: false }) assignForm: NgForm;
  branchPage = 1;
  branchName: string = '';
  branchPhoneNum: string = '';
  branchEmail: string = '';
  branchMsg: string = '';
  removeBranchMsg: string = '';
  userFound: boolean = false;
  userName: string = '';
  userNum: string = '';

  itemsPerPage = 10;

  viewUserTransactions = false;
  history: any = [];
  transactionsPage: number = 1;

  listofBookingOids = [];

  newUser = false;
  addUserTitle = 'Add new user';

  removeUserMessage = '';

  newUserFirstName = '';
  newUserSurname = '';
  newUserEmail = '';
  newUserMobile = '';
  newUserPassword = '';
  newUserResponseMsg = '';

  isSkyNetRetail = false;
  canInvoice = false;

  allocatedBookings = [];
  unAllocatedBookings = [];
  collectedBookings = [];
  cancelledBookings = [];
  failedCollectionBookings = [];
  deliveredBookings = [];
  failedDeliveryBookings = [];
  returnedBookings = [];

  allCount = 0;
  awaitingDriverCount = 0;
  allocatedCount = 0;
  collectedCount = 0;
  cancelledCount = 0;
  failedCollectionCount = 0;
  completedCount = 0;
  failedDeliveryCount = 0;
  returnsCount = 0;

  typeOfView = 'Bookings';

  protected readonly isDevMode = isDevMode;

  // This property controls whether a SkyNet or Normal retails view is loaded on a retail profile
  loadView = -1;

  bookingTab = true;
  sameDayTab = false;
  pudoTab = false;
  rentalTab = false;
  vehiclesTab = false;
  addressesTab = false;
  branchesTab = false;

  skyNetAllTab = true;
  skyNetUnallocatedTab = false;
  skyNetAllocatedTab = false;
  skyNetCollectedTab = false;
  skyNetCancelledTab = false;
  skyNetFailedCollectionTab = false;
  skyNetCompletedTab = false;
  skyNetFailedDeliveryTab = false;
  skyNetReturnsTab = false;
  alertMessage

  taxInvoiceButtonText = 'Email tax invoice';
  // This parallel array is used to determine which Email tax invoice button to change after a mail is sent
  taxInvoiceButtonTracking = [];

  bucketStatuses = [
    ['BUCKET_AWAITING', 'Booking incomplete'],
    ['COMPLETE', 'Booking successfully complete'],
    ['AWAITING_DRIVER', 'Awaiting driver to be assigned'],
    ['IN_TRANSACT', 'Driver in transit'],
    ['INVALID', 'Booking cancelled'],
    ['AWAITING_PAYMENT', 'Awaiting payments'],
    ['CANCELLED', 'Cancelled'],
    ['CREATED_WAYBILL', 'Waybill created'],
    ['AWAITING_AUTHORIZATION', 'Awaiting authorization'],
    ['ELEMENT_TO_ANOTHER_BRANCH', 'Moved to another branch'],
    ['RESERVED', 'Accepted']];

  icon = 'assets/mapmarker.png';
  icon2 = 'assets/vehicles/mapmarker_210_degrees.png';
  package = 'assets/package.png';
  uberDriver = 'assets/uberDriver.png';

  selectedVehicleType = 'All';
  allocatedDriver: any;
  selectedSkyNetTab = '';

  uberqoute
  uberError = ""

  showOverview = true;

  // Pudo
  pudoBookings = [];
  pudoTablePage = 1;
  viewPudoTracking = false;
  viewPudoBookingDetails = false;
  pudoBookingBeingTracked: any;
  pudoBookingBeingViewed: any;
  pudoBookingBeingPayed: any;

  pudoMessage = '';

  pudoFeedbackMsg = '';
  // Pudo

  atSelectAmount = false;
  showSelectWalletAmountError = false;
  ozowUrl: string;

  //Tags
  tagList = [];
  tagName = '';
  canAddTags : boolean = false;

  emailInvoiceFeedbackMsg = '';
  taxInvoiceBooking: any;

  invoiceCompanyName = '';
  invoiceVatNo = '';
  invoiceCompanyAddress = '';

  constructor(private bookingService: BookingService, private userService: UserService, private route: Router,
    private nameService: SharedService, private adminService: AdminService, public db: AngularFireDatabase,
    private loadservice: LoaderService) {
    this.url = this.nameService.getPayFastUrl();
    this.url_rental = this.nameService.getPayFastUrl();
    this.ozowUrl = this.nameService.getOzowUrl();
  }

  ngOnInit() {
    window.scroll(0, 0);
    // Use to show public announcements on login
    /*if (!(localStorage.getItem('alertShown') === 'true')) {
      this.showPublicAnnouncement();
    }*/
    this.loadservice.addloader();
    this.checkPudoService();
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.selectedSkyNetTab = JSON.parse(localStorage.getItem('selectedSkyNetTab'));
    // Selected previously selected tab
    this.styleSelectedTab(this.selectedSkyNetTab);
    this.typeOfView = JSON.parse(localStorage.getItem('skyNetSameDay')) !== null ? 'Sameday' : 'Bookings';
    localStorage.removeItem('skyNetSameDay');
    // console.log(this.user);
    this.canAddTags = this.user.roles.some((item) => item.code.includes('api'));
    this.getUserRetail(this.user.owner.oid);
    this.getExpressRetailBookings();
    this.retailOwner = this.user.owner.oid === this.user.owner.retailId;
    if (this.user.owner.retailId != null) {
      this.getRetails(this.user.owner.retailId);
    } else {
      this.getRetails(this.user.owner.oid);
      // this.getUserRetail(this.user.owner.oid);
    }
    this.getAlertMessage();
  }

  public deletePushNotification(response) {
    const trackNumbers = Object.keys(response)

    trackNumbers.forEach(trackNumber => {
      // console.log(response[trackNumber]);

      this.db.database.ref("driver_declined").child(trackNumber).remove().then(val => {
        // console.log(val);
      }).catch((e) => {
        console.log(e);

      })
    })
  }

  public getDeclinedBooking() {

    this.db.object('driver_declined/').snapshotChanges().map((res) => res.payload.val()).subscribe(
      (res: any) => {
        this.deletePushNotification(res)
      }, (error) => {
        console.log(error);
      });
  }

  public getAlertMessage() {
    this.loadservice.addloader()
    this.userService.getAlertMessage(this.user.token, this.user.oid)
      .subscribe((res) => {

        this.alertMessage = res.json()
        // console.log(res.json());

        if (this.alertMessage && this.alertMessage.message && !this.alertMessage.seen) {
          $("#alertmessage").modal("show")
        }
        this.loadservice.removeloader()
      }, (error) => {
        console.log(error);
        this.loadservice.removeloader()
      }, () => {
        this.loadservice.removeloader()
      })
  }

  public trackBooking(bucket) {
    let link: string;
    // console.log(bucket);
    if (bucket.bookings[0].uberResponse) {
      link = bucket.bookings[0].uberResponse.tracking_url;
    } else {
      const site = isDevMode() ? 'https://droppergroup.co.za/droppa/Track-Driver/' : 'https://droppa.co.za/droppa/Track-Driver/';
      link = site + bucket.bookings[0].trackNo;
    }
    window.open(link, '_blank');
  }

  public createUberBooking() {
    this.createuberdelivery();
  }

  public createuberdelivery() {
    //....
    this.loadservice.addloader()
    this.selectedBucket.bookings.forEach((bookingData) => {
      let mani = []
      this.bookingService.getBookingContactPerson(this.user.token, bookingData.oid).subscribe(
        (response) => {
          const contacts = response.json()
          const dropOffContact = contacts.dropOffContact
          const pickUpContact = contacts.pickUpContact

          const uberdeliveryObj = {
            "dropoff_address": bookingData.dropOffAddress,
            "dropoff_name": dropOffContact.firstName + " " + dropOffContact.lastName,
            "dropoff_phone_number": "+27" + dropOffContact.phone.substring(1),
            "manifest_items": mani,
            "pickup_address": bookingData.pickUpAddress,
            "pickup_name": pickUpContact.firstName + " " + pickUpContact.lastName,
            "pickup_phone_number": "+27" + pickUpContact.phone.substring(1),
            "dropoff_business_name": "Droppa",
            "dropoff_latitude": parseFloat(bookingData.dropOffCoordinate.coordinates[0]),
            "dropoff_longitude": parseFloat(bookingData.dropOffCoordinate.coordinates[1]),
            "dropoff_notes": bookingData.comment,
            "manifest_reference": bookingData.trackNo,
            // "manifest_total_value":100000,
            "pickup_business_name": "Vodacom",
            "pickup_latitude": parseFloat(bookingData.pickUpCoordinate.coordinates[0]),
            "pickup_longitude": parseFloat(bookingData.pickUpCoordinate.coordinates[1]),
            "pickup_notes": bookingData.comment,
            "pickup_verification": bookingData.trackNo,
            "requires_dropoff_signature": false,
            "tip": 0,
          }

          this.bookingService.getUberdelivery(this.user.token, uberdeliveryObj, bookingData.oid).subscribe(
            (response) => {
              this.getExpressRetailBookings();
              $('#successmodal').modal('show')
              this.loadservice.removeloader()
            },
            (error) => {
              console.log(error.json());
              const resposeError = error.json()
              this.errorModal("Error creating uber delivery", resposeError.message)
              this.loadservice.removeloader()
            }
          )
        },
        (error) => {
          console.log(error.json());
          const resposeError = error.json()
          this.errorModal("Error getting contact person", resposeError.message)
          this.loadservice.removeloader()
        }
      );
    });
  }

  modalTitle = "Error"
  modalError = "Error"

  public errorModal(title, error) {
    this.modalTitle = title
    this.modalError = error
    $('#errorModal').modal('show')//
  }

  public getUberQuote() {
    this.uberError = ""
    this.loadservice.addloader()

    this.uberqoute = null
    const quoterequestbody = {
      "dropoff_address": this.bookingData.dropOffAddress,
      "pickup_address": this.bookingData.pickUpAddress,
      "dropoff_latitude": parseFloat(this.bookingData.dropOffCoordinate.coordinates[0]),
      "dropoff_longitude": parseFloat(this.bookingData.dropOffCoordinate.coordinates[1]),
      "dropoff_phone_number": "+27" + this.bookingData.phoneNo.substring(1),
      "pickup_latitude": parseFloat(this.bookingData.pickUpCoordinate.coordinates[0]),
      "pickup_longitude": parseFloat(this.bookingData.pickUpCoordinate.coordinates[1])

    }
    this.bookingService.getuberquote(this.user.token, quoterequestbody).subscribe(
      response => {
        const resJson = response.json()
        this.uberqoute = response.json()
        // console.log(resJson);

        // if(resJson.currency){
        //   this.uberqoute=response.json()
        // }else{
        //   this.uberError="please varify the addresses to get accurate uber quotes. (NB: use google) "
        // }
        $('#listOfDrivers').modal('show');
        this.loadservice.removeloader()
      }, error => {
        const err = error
        this.uberError = "Uber Error: " + err.message
        console.log(this.uberError);
        $('#listOfDrivers').modal('show');
        this.loadservice.removeloader()
      }, () => {
        this.loadservice.removeloader()
      }
    );
  }

  public getAllRentals() {
    this.bookingService.getEliteBookingByCustomer(this.user.token, this.user.owner.oid).subscribe(
      response => {
        this.rentalBookings = response.reverse();
      }, error => {
        console.log(error);
        if (error.statusText === 'Unknown Error' || error.statusText === 'Internal Server Error') {
          this.loadView = -1;
          this.route.navigate(['/customer-signin']);
        }
      }, () => {
      }
    );
  }

  public getSavedAddress() {
    this.bookingService.retrieveAddresses(this.user.token, this.user.oid).subscribe(
      response => {
        this.savedAddresses = response;
      }, error => {
        console.log(error);
      }, () => {
      }
    );
  }

  public capturePickUpAddress() {
    $('#capturePickUpAddress').modal('show');
  }

  // Displays the manual address column
  public enterManualAddress() {
    this.displayManualAddress = true;
  }

  // Brings back the google address component
  public backToGoogleAddress() {
    this.displayManualAddress = false;
  }

  autoCompleteCallback1(selectedData: any) {
    this.postalCode = null;
    if (!selectedData) {
      this.pickUpNotMsg = 'Location not found, Please enter nearest location.';
    } else {
      for (let x = 0; x < selectedData.data.address_components.length; x++) {
        for (let y = 0; y < selectedData.data.address_components[x].types.length; y++) {
          for (let a = 0; a < selectedData.data.address_components[x].types.length; a++) {
            if (selectedData.data.address_components[x].types[a] == 'postal_code') {
              this.postalCode = selectedData.data.address_components[x].long_name;
              this.getPickUpSuburb(this.postalCode);
            } else if (selectedData.data.address_components[x].types[a] == 'administrative_area_level_1') {
              this.pickUpProvince = selectedData.data.address_components[x].long_name;
            }
          }
        }
      }
      if (this.postalCode == null) {
        this.pickUpPostalCodeService(selectedData);
      }
      this.pickUpAddress = selectedData.data.description;
      this.pickUpLat = selectedData.data.geometry.location.lat;
      this.pickUpLong = selectedData.data.geometry.location.lng;
      this.pickUpCoordinate = [selectedData.data.geometry.location.lat, selectedData.data.geometry.location.lng];
      this.pickUpCoordinateLocation = { coordinates: this.pickUpCoordinate, address: this.pickUpAddress };
      this.departureCoordinate = {
        "lat": selectedData.data.geometry.location.lat,
        "lng": selectedData.data.geometry.location.lng
      };
      this.getAdminArea(selectedData.data.address_components);
    }
  }

  public pickUpPostalCodeService(selectedData) {
    let latTest = selectedData.data.geometry.location.lat;
    let lngTest = selectedData.data.geometry.location.lng;

    let geocoder = new google.maps.Geocoder;
    const latlng = {
      lat: latTest,
      lng: lngTest,
    };
    geocoder.geocode(
      { location: latlng }, (results) => {
        if (results[0]) {
          let foundAddress = results[0].address_components;

          for (let x = 0; x < foundAddress.length; x++) {
            for (let y = 0; y < foundAddress[x].types.length; y++) {
              for (let a = 0; a < foundAddress[x].types.length; a++) {
                if (foundAddress[x].types[a] == 'postal_code') {
                  this.postalCode = foundAddress[x].long_name;
                  this.getPickUpSuburb(this.postalCode);
                }
              }
            }
          }

          if (this.postalCode == null) {
            $('#pickUpPostcalCode').modal('show');
          }

        }
      }
    );
  }

  public getPickUpSuburb(postcalCode) {
    this.bookingService.getPickUpSuburb(postcalCode).subscribe(
      response => {
        this.suburb = response.suburb;
      }, error => {
        console.log(error);
      }, () => {
      }
    )
  }

  private getAdminArea(address_component: []) {

    this.addressComponent = address_component;
    let len = address_component.length;

    for (let i = 0; i < len; i++) {
      switch (this.addressComponent[i].long_name) {
        case 'Gauteng': {
          this.province = 'GAUTENG';
          this.validateProvince = 'Gauteng';
          break;
        }
        case 'KwaZulu-Natal': {
          this.province = 'KWAZULU-NATAL';
          this.validateProvince = 'KwaZulu-Natal';
          break;
        }
        case 'kzn': {
          this.province = 'kzn';
          this.validateProvince = 'KWA_ZULU_NATAL';
          break;
        }
        case 'Western Cape': {
          this.province = 'WESTERN CAPE';
          this.validateProvince = 'Western Cape';
          break;
        }
        case 'Eastern Cape': {
          this.province = 'EASTERN CAPE';
          this.validateProvince = 'Eastern Cape';
          break;
        }
        case 'Limpopo': {
          this.province = 'LIMPOPO';
          this.validateProvince = 'Limpopo';
          break;
        }
        case 'Mpumalanga': {
          this.province = 'MPUMALANGA';
          this.validateProvince = 'Mpumalanga';
          break;
        }
        case 'Northern Cape': {
          this.province = 'NORTHERN CAPE';
          this.validateProvince = 'Northern Cape';
          break;
        }
        case 'North West': {
          this.province = 'NORTH WEST';
          this.validateProvince = 'North West';
          break;
        }
        case 'Free State': {
          this.province = 'FREE STATE';
          this.validateProvince = 'Free State';
          break;
        }
        default: {
          break;
        }

      }
    }
  }

  public getListOfSuburbs(postalCode) {
    this.postalCode = postalCode;
    if (this.postalCode != null || this.postalCode != undefined) {
      this.bookingService.postalForHomeDetails(this.postalCode).subscribe(
        response => {
          this.suburbList = response;
          if (this.suburbList.length == 0) {
            this.enteredPickUpPostalCodeMsg = 'Invalid postal code, enter a valid postal code to select a suburb.';
          } else {
            this.enteredPickUpPostalCodeMsg = '';
          }
        }, error => {
          console.log(error);
        }
      );
    } else {
      this.suburbList = [];
    }
  }

  public viewRetailElite(rentals) {
    localStorage.setItem('selectedRental', JSON.stringify(rentals));
    this.loadView = -1;
    this.route.navigate(['/view-details']);
  }

  public savePickUpAdddress() {
    // If its displaying manual addresses
    if (this.displayManualAddress) {
      var addressData = {
        "label": this.capturePickUpAddressLabel,
        "latitude": '-25.98953',
        "longitude": '28.12843',
        "address": this.pickUpAddress,
        "personId": this.user.oid,
        "suburb": this.suburb,
        "province": this.province,
        "postalCode": this.postalCode
      }
      if (addressData.address == null || addressData.address == '') {
        this.checkingPickUpMsg = 'Please enter your address to proceed.';
      } else if (addressData.label == null || addressData.label == '') {
        this.checkingPickUpMsg = 'Please enter the label of your address.';
      } else if (addressData.suburb == null || addressData.suburb == '') {
        this.checkingPickUpMsg = 'Please enter the name of your suburb.';
      } else if (addressData.province == null || addressData.province == '') {
        this.checkingPickUpMsg = 'Please enter the province.';
      } else if (addressData.postalCode == null || addressData.postalCode == '') {
        this.checkingPickUpMsg = 'Please enter the postal code.';
      } else if (addressData.address != null && addressData.label != null && addressData.suburb != null && addressData.province != null && addressData.postalCode != null) {
        this.bookingService.storeAddresses(this.user.token, addressData).subscribe(
          response => {
            this.capturePickUpAddressLabel = '';
            this.pickUpAddress = '';
            this.postalCode = '';
            this.suburbList = [];
            this.pickUpProvince = '';
            this.checkingPickUpMsg = 'Pick Up address successfully saved.';
            this.getSavedAddress();
          }, error => {
            console.log(error);
          }
        );
      } else {
        this.checkingPickUpMsg = 'Please enter the label or the capture the address first before saving it.';
      }
    }
    // If its NOT displaying manual addresses
    else {
      var addressData = {
        "label": this.capturePickUpAddressLabel,
        "latitude": '15.000953',
        "longitude": '-25.98953',
        "address": this.pickUpAddress,
        "personId": this.user.oid,
        "suburb": this.suburb,
        "province": this.province,
        "postalCode": this.postalCode
      }
      if (addressData.address == null || addressData.address == '') {
        this.checkingPickUpMsg = 'Please enter your address to proceed.';
      } else if (addressData.label == null || addressData.label == '') {
        this.checkingPickUpMsg = 'Please enter the label of your address.';
      } else if (addressData.address != null && addressData.label != null && addressData.suburb != null && addressData.province != null && addressData.postalCode != null) {
        this.bookingService.storeAddresses(this.user.token, addressData).subscribe(
          response => {
            //Clear entered data
            this.capturePickUpAddressLabel = '';
            this.pickUpAddress = '';
            this.postalCode = '';
            this.suburbList = [];
            this.pickUpProvince = '';
            this.checkingPickUpMsg = 'Pick up address successfully saved.';
            this.getSavedAddress();
          }, error => {
            console.log(error);
          }
        );
      } else {
        this.checkingPickUpMsg = 'Please enter the label or the capture the address first before saving it.';
      }
    }
  }

  // button for styling selected table button (e.g. Bookings / Rental)
  /*public async normalRetailbutton() {
    this.loadView = 1;
    const header = await document.getElementById('myTab');
    const btns = await header.getElementsByClassName('tabs');
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function() {
        const current = document.getElementsByClassName('activee');
        current[0].className = current[0].className.replace(' activee', '');
        this.className += ' activee';
      });
    }
  }

  public async skynetRetailbutton() {
    this.loadView = 2;
    const header = await document.getElementById('mySkyNetTab');
    const btns = await header.getElementsByClassName('tabs');
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener('click', function() {
        const current = document.getElementsByClassName('activee');
        current[0].className = current[0].className.replace(' activee', '');
        this.className += ' activee';
      });
    }
  }*/

  public book() {
    this.loadView = -1;
    if (this.retail == null) {
      this.route.navigate(['/']);
    } else {
      localStorage.setItem('retail', JSON.stringify(this.retail));
      this.loadView = -1;
      if (this.canAddParcels && !this.isSkyNetRetail) {
        this.route.navigate(['/create-retail-book']);
      } else {
        this.route.navigate(['/create-retail-booking']);
      }
    }
  }

  public typeRate() {
    localStorage.setItem('retail', JSON.stringify(this.retail));
    this.loadView = -1;
    this.route.navigate(['/retail-typeRate']);
  }

  public getRetails(retailId: string) {
    if (this.user.owner.retailId != null) {
      this.userService.retriveRetail(this.user.token, retailId).subscribe(
        (res) => {
          this.retail = res.json();
          this.canAddParcels = this.retail.canAddParcels;
          this.vehicleTypes = this.retail.typeRates;
          this.isSkyNetRetail = this.retail.canAssignDriver === undefined ? false : this.retail.canAssignDriver;
          this.canInvoice = this.retail.canInvoice === undefined ? false : this.retail.canInvoice;

          if (this.isSkyNetRetail) {
            if (this.retail.name === 'Quro Medical') {
              // console.log(this.retail.name);
              this.loadView = 3;
            } else {
              this.loadView = 2;
            }
            // this.getDeclinedBooking()
          } else {
            this.loadView = 1;
          }

          this.retailId = this.retail.oid;
          if (this.retail.branches != null) {
            this.retailBranches = this.retail.branches;
          }

          if (this.retail.tags){
            this.tagList = this.retail.tags;
          }

          this.getBucketBookings(this.retail.oid);
          this.getPudoBookings(this.retail.oid);
          this.retrieveWalletBalance();
        }
      );
    } else {
      this.user.roles.forEach(role => {
        if (role.code === 'retail') {
          this.retailAvailable = true;
        }
      });
    }
  }

  driversNoLocation = [];
  closeRetailDriver = [];

  public getCloseDriver() {
    if (this.unAllocatedBookings.length > 0) {
      this.bookingService.getClosedDriver(this.user.token, this.unAllocatedBookings[0].oid).subscribe(
        (res) => {
          const excludeUber = res.filter(ress => ress.driver.vehicle.type != "UBER");
          const includeUber = res.filter(ress => ress.driver.vehicle.type == "UBER");
          const available = excludeUber.filter(ress => ress.status === "AVAILABLE");
          const notavailable = excludeUber.filter(ress => ress.status !== "AVAILABLE");

          const sortbyavailable = available.concat(notavailable)

          const drivers = includeUber.concat(sortbyavailable)

          this.retailDrivers = drivers;
          this.retailDriversToFilter = drivers;
          const trackedDriver = excludeUber.filter(ress => ress.tracking);
          const nottrackedDriver = excludeUber.filter(ress => !ress.tracking);
          this.driversNoLocation = nottrackedDriver;
          this.closeRetailDriver = trackedDriver;


          this.trackDriverLocation(trackedDriver);

        },
        (error) => {
          console.log('error', error);
        },
      );
    }
  }

  public trackDriverLocation(driversTocheck) {
    driversTocheck.forEach((driver) => {
      // console.log(driver);
      const cellNumber = driver.tracking.driverPhone;

      this.db.object('driverTracking/' + cellNumber).snapshotChanges().map((res) => res.payload.val()).subscribe(
        (res: any) => {
          this.saveSubcribeData(res, driver);
        }, (error) => {
          console.log(error);
        });
    });
  }

  closeRetailDriverMapper = [];
  loadClose = true;

  public saveSubcribeData(response, driver) {
    const bearing = response ? response.bearing : 0
    const degrees = Math.floor((bearing) / 30) * 30
    const icon_name = `mapmarker_${degrees}_degrees.png`
    const icon_url = `assets/vehicles/${icon_name}`


    const date = new Date(driver.tracking.lastSeen);
    const currentDate = new Date();

    const days = Math.floor((currentDate.getTime() - date.getTime()) / 1000 / 60 / 60 / 24);

    const trackingData = {
      lat: response ? response.lat : parseFloat(driver.tracking.location.coordinates[0]),
      lng: response ? response.lng : parseFloat(driver.tracking.location.coordinates[1]),
      driverName: driver.tracking.driverName,
      driverPhone: driver.tracking.driverPhone,
      address: driver.tracking.location.address.substring(0, 25),
      driver: driver.driver,
      isOnline: !!response,
      status: driver.status.toLowerCase(),
      lastseen: driver.tracking.lastSeen,
      pipe: days >= 1 ? 'longDate' : 'shortTime',
      bearing: bearing,
      icon_url: icon_url
    };
    const newList = this.closeRetailDriverMapper.filter(d => d.driverPhone !== trackingData.driverPhone);
    newList.push(trackingData)
    this.closeRetailDriverMapper = newList;

    // console.log(this.closeRetailDriverMapper," === VS ++")

    // this.closeRetailDriverMapper.push(trackingData);

    if (this.closeRetailDriver.length === this.closeRetailDriverMapper.length) {
      this.loadClose = false;
    }
  }

  styleSelectedTab(selectedTab) {
    switch (selectedTab) {
      case 'bookingTab':
        this.bookingTab = true;
        this.sameDayTab = false;
        this.pudoTab = false;
        this.rentalTab = false;
        this.vehiclesTab = false;
        this.addressesTab = false;
        this.branchesTab = false;
        break;
      case 'sameDayTab':
        this.bookingTab = false;
        this.sameDayTab = true;
        this.pudoTab = false;
        this.rentalTab = false;
        this.vehiclesTab = false;
        this.addressesTab = false;
        this.branchesTab = false;
        break;
      case 'pudoTab':
        this.bookingTab = false;
        this.sameDayTab = false;
        this.pudoTab = true;
        this.rentalTab = false;
        this.vehiclesTab = false;
        this.addressesTab = false;
        this.branchesTab = false;
        break;
      case 'rentalTab':
        this.bookingTab = false;
        this.sameDayTab = false;
        this.pudoTab = false;
        this.rentalTab = true;
        this.vehiclesTab = false;
        this.addressesTab = false;
        this.branchesTab = false;
        break;
      case 'vehiclesTab':
        this.bookingTab = false;
        this.sameDayTab = false;
        this.pudoTab = false;
        this.rentalTab = false;
        this.vehiclesTab = true;
        this.addressesTab = false;
        this.branchesTab = false;
        break;
      case 'addressesTab':
        this.bookingTab = false;
        this.sameDayTab = false;
        this.pudoTab = false;
        this.rentalTab = false;
        this.vehiclesTab = false;
        this.addressesTab = true;
        this.branchesTab = false;
        break;
      case 'branchesTab':
        this.bookingTab = false;
        this.sameDayTab = false;
        this.pudoTab = false;
        this.rentalTab = false;
        this.vehiclesTab = false;
        this.addressesTab = false;
        this.branchesTab = true;
        break;

      case 'skyNetAllTab':
        this.skyNetAllTab = true;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetUnallocatedTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = true;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetAllocatedTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = true;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetCollectedTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = true;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetCancelledTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = true;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetFailedCollectionTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = true;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetCompletedTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = true;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetFailedDeliveryTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = true;
        this.skyNetReturnsTab = false;
        break;
      case 'skyNetReturnsTab':
        this.skyNetAllTab = false;
        this.skyNetUnallocatedTab = false;
        this.skyNetAllocatedTab = false;
        this.skyNetCollectedTab = false;
        this.skyNetCancelledTab = false;
        this.skyNetFailedCollectionTab = false;
        this.skyNetCompletedTab = false;
        this.skyNetFailedDeliveryTab = false;
        this.skyNetReturnsTab = true;
        break;
    }
  }

  public getBucketBookings(retailOid: string) {
    this.userService.getBucketBooking(this.user.token, retailOid).subscribe(
      (res) => {
        this.bucketBooking = res.reverse();
        this.allCount = this.bucketBooking.length;
        // console.log(this.bucketBooking);
        if (this.isSkyNetRetail) {
          // Filter SkyNet Tables
          const failedBooking = this.bucketBooking.filter(booking => (!booking.failedCollectionType || (booking.failedCollectionType == "COLLECTION" && booking.status.includes('IN_TRANSACT') && booking.bookings[0].isItemPicked) || ((booking.failedCollectionType == "COLLECTION" || booking.failedCollectionType == "DELIVERY") && booking.status.includes('COMPLETE'))))
          this.failedCollectionBookings = this.bucketBooking.filter(booking => booking.failedCollectionType == "COLLECTION" && (booking.status.includes('IN_TRANSACT') && !booking.bookings[0].isItemPicked || booking.status.includes('RESERVED') && !booking.bookings[0].isItemPicked))
          this.failedDeliveryBookings = this.bucketBooking.filter(booking => booking.failedCollectionType == "DELIVERY" && !booking.status.includes('COMPLETE'))

          this.unAllocatedBookings = failedBooking.filter(booking => {
            return booking.status.includes('BUCKET_AWAITING') ||
              booking.status.includes('CREATED_WAYBILL') ||
              booking.status.includes('AWAITING_DRIVER');
          });
          this.allocatedBookings = failedBooking.filter(booking => (booking.status.includes('RESERVED') || (booking.status.includes('IN_TRANSACT') && !booking.bookings[0].isItemPicked)));
          this.deliveredBookings = failedBooking.filter(booking => booking.status.includes('COMPLETE'));
          this.cancelledBookings = failedBooking.filter(booking => booking.status.includes('CANCELLED'));
          this.collectedBookings = failedBooking.filter(booking => booking.bookings[0].isItemPicked && booking.status.includes('IN_TRANSACT'));
          // Determine the count (size) of each type of table
          this.allocatedCount = this.allocatedBookings.length;
          this.awaitingDriverCount = this.unAllocatedBookings.length;
          this.collectedCount = this.collectedBookings.length;
          this.cancelledCount = this.cancelledBookings.length;
          this.failedCollectionCount = this.failedCollectionBookings.length;
          this.completedCount = this.deliveredBookings.length;
          this.failedDeliveryCount = this.failedDeliveryBookings.length;
          this.returnsCount = this.returnedBookings.length;

          this.getCloseDriver();
        }
        this.loadservice.removeloader();
      }, (error) => {
        this.loadservice.removeloader();
      }
    );
  }

  public getUserRetail(retailOid: string) {
    this.userService.getRetailUser(this.user.token, retailOid).subscribe(
      (res) => {
        // console.log(res);
        this.retailUser = res;
      }, err => {
        console.log(err);
        localStorage.clear()
        this.route.navigate(['/logon'])
      }
    );
  }

  findDriver() {
    this.err = "";
    this.valid = false;
    if (this.userMobile != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getDriverByMobile(object.token, this.userMobile).subscribe(
        (res) => {
          this.valid = true;
        }, (error) => {
          this.err = error.error.message;
        }
      );
    } else {
      alert("Please enter mobile numbers.");
    }
  }

  public getUserDetails() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.getUserDetails(this.user.token, this.userMobile).subscribe(
      response => {
        this.userName = response.json().owner.firstName + ' ' + response.json().owner.surname;
        this.userNum = response.json().owner.mobile;
        this.userFound = true;
      }, error => {
        console.log(error);
        this.userFound = false;
        this.err = 'No person matching the mobile ' + this.userMobile + ' was found.';
      }, () => {
        console.log('Number found');
      });
  }

  public assignUser() {
    this.user = JSON.parse(localStorage.getItem('userInfo'));
    this.userService.assignNow(this.user.token, this.userMobile, this.user.owner.retailId).subscribe(
      (res) => {
        this.assignStatus = res.firstName + ' ' + res.surname + ' added to retail';
        res = this.retailUser;
        localStorage.setItem('retailUsers', JSON.stringify(this.retailUser));
        this.assignForm.reset();
        this.getUserRetail(this.user.owner.oid);
      }, (error) => {
        this.assignStatus = error.error.message;
        console.log(error);
      }
    );
  }

  public onBucketSelected(bucket) {

    localStorage.setItem('retail', JSON.stringify(this.retail));
    localStorage.setItem('bucketBooking', JSON.stringify(bucket));
    localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(bucket));
    this.loadView = -1;

    if (this.canAddParcels && !this.isSkyNetRetail) {
      this.route.navigate(['/multiple-drops']);
    } else {
      // Record which SkyNet tab the user was on before navigating away
      this.selectedSkyNetTab = this.skyNetAllTab ? 'skyNetAllTab' :
        this.skyNetUnallocatedTab ? 'skyNetUnallocatedTab' :
          this.skyNetAllocatedTab ? 'skyNetAllocatedTab' :
            this.skyNetCollectedTab ? 'skyNetCollectedTab' :
              this.skyNetCancelledTab ? 'skyNetCancelledTab' :
                this.skyNetFailedCollectionTab ? 'skyNetFailedCollectionTab' :
                  this.skyNetCompletedTab ? 'skyNetCompletedTab' :
                    this.skyNetFailedDeliveryTab ? 'skyNetFailedDeliveryTab' : 'skyNetReturnsTab';
      localStorage.setItem('selectedSkyNetTab', JSON.stringify(this.selectedSkyNetTab));

      if (!this.skyNetAllTab && !this.skyNetUnallocatedTab) {
        // Get allocated driver if clicked from SkyNet Allocated table
        this.userService.retrieveDrivers(this.user.token, bucket.driverOid).subscribe(
          response => {
            this.allocatedDriver = response.json();
            localStorage.setItem('allocatedDriver', JSON.stringify(this.allocatedDriver));
          },
          error => {
            console.log(error);
          }
        );
      }
      this.route.navigate(['/multiple-drop-booking']);
    }
  }

  public onFlightSelected(flightBooking) {
    if (this.canAddParcels) {
      localStorage.setItem('retail', JSON.stringify(this.retail));
      localStorage.setItem('selectedFlightBooking', JSON.stringify(flightBooking));
      this.loadView = -1;
      this.route.navigate(['/flight-booking-details']);
    } else {
      // Do nothing
    }
  }

  assignDriverStatus = false;
  bookingData: any;
  selectedBucket: any

  public assignOwnDriver(bucket) {
    window.scrollTo(0, 0)
    this.bookingData = bucket.bookings[0];
    this.selectedBucket = bucket;
    this.pickUpCoordinates = {
      lat: parseFloat(this.bookingData.pickUpCoordinate.coordinates[0]),
      lng: parseFloat(this.bookingData.pickUpCoordinate.coordinates[1])
    };
    $('#listOfDrivers').modal('show');
    // this.getUberQuote()

  }

  driverAssigned = false;
  driverAssignedToBooking: any;

  /*public assignSelectedDriver(driver) {
    this.driverAssigned = true;
    this.driverAssignedToBooking = driver;
    this.mapOpen=false
    //if map open close it
  }*/

  /*public removeAssignedDriver() {
    this.driverAssigned = false;
    this.driverAssignedToBooking = null;
  }*/

  driversListTitle = 'List of drivers';
  displayDriverMap = false;
  mapDriversButtonTxt = 'Show drivers map';
  mapOpen = false;
  pickUpCoordinates = {
    lat: 0,
    lng: 0
  };

  showDriversMap() {
    this.mapOpen = true;
    this.displayDriverMap = !this.displayDriverMap;
    if (this.displayDriverMap) {
      this.driversListTitle = 'Map of drivers';
      this.mapDriversButtonTxt = 'Show drivers list';
    } else {
      this.driversListTitle = 'List of drivers';
      this.mapDriversButtonTxt = 'Show drivers map';
    }
  }

  retailDrivers = [];
  retailDriversToFilter = [];

  public filterListOfDrivers(filterDropDown, driverDetails) {
    this.retailDriversToFilter = this.retailDrivers;

    if (filterDropDown !== 'All') {
      this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
        return (driver.driver.vehicle.type.toLowerCase().includes(filterDropDown.toLowerCase())
        );
      });
    }

    this.retailDriversToFilter = this.retailDriversToFilter.filter((driver) => {
      return (
        driver.driver.person.mobile.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.person.firstName.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.person.surname.toLowerCase().includes(driverDetails.toLowerCase()) ||
        driver.driver.location.toLowerCase().includes(driverDetails.toLowerCase())
      );
    });
  }

  failedToAssignMsg = '';

  public assignDriverOnAwaiting(driver) {
    this.loadservice.addloader();

    if (driver.vehicle.type === 'UBER') {
      this.createuberdelivery()
    } else {
      this.failedToAssignMsg = '';
      this.assignDriverStatus = false;
      if (driver != null) {
        const object = JSON.parse(localStorage.getItem('userInfo'));
        // console.log(this.selectedBucket);
        this.adminService.assignDriverToBucketBooking(object.token, this.selectedBucket.oid, driver.oid).subscribe(
          (res) => {
            this.assignDriverStatus = true;
            this.getBucketBookings(this.retail.oid);
          }, (error) => {
            this.loadservice.removeloader();
            this.assignDriverStatus = false;
            // console.log(error);
            if (this.triggeredFromRetailProfile) {
              this.failedToAssignMsg = error.error.message;
              $('#failedToAssignDriver').modal({ backdrop: 'static', keyboard: false });
            }
          }
        );
      } else {
        //  Do nothing
      }
    }
  }

  triggeredFromRetailProfile = false;
  // This method is used to determine whether the
  // assignDriverOnAwaiting method is triggered from the retail profile or not
  public fromRetailProfile() {
    this.triggeredFromRetailProfile = true;
  }

  showListOfDrivers() {
    this.pickUpCoordinates = {
      lat: parseFloat(this.bookingData.pickUpCoordinate.coordinates[0]),
      lng: parseFloat(this.bookingData.pickUpCoordinate.coordinates[1])
    };
    // console.log(this.pickUpCoordinates);
    $('#listOfDrivers').modal('show');
  }

  public getExpressRetailBookings() {
    this.userService.expressRetail(this.user.token, this.user.owner.oid).subscribe(
      response => {
        this.expressBookings = response.json().reverse();
        this.expressBookings = this.expressBookings.filter(booking => booking.pickUpPCode !== null);
        // Filter out bookings ending in 'M'. They are part of bucket bookings which are displayed on the Bookings tab
        /*let len = this.expressBookings.length;
        for (let i = 0; i < len; i++) {
          if (this.expressBookings[i].trackNo.toLowerCase().includes('m')) {
            console.log(this.expressBookings[i].trackNo.toLowerCase());
            this.expressBookings.splice(this.expressBookings.indexOf(this.expressBookings[i]), 1);
            i = 0;
            len = this.expressBookings.length;
          }
        }*/
        // this.expressBookings.forEach(item => this.taxInvoiceButtonTracking.push(0));
      }, error => {
        console.log(error);
      }
    );
  }

  public onItemChange(item) {
    this.showOwnAmountInput = false;
    this.amountChosen = item.amount;
  }

  public ownAmountTrigger() {
    this.showOwnAmountInput = true
  }

  public getAmount(amount: Number) {
    // console.log(amount,">>>>>>>>>>>>>>")
  }

  public redirectToPayFast(response) {
    localStorage.setItem('paidByRetail', 'true');
    this.merchant_key.nativeElement
    this.merchant_id.nativeElement.value = response.merchant_id;
    this.merchant_key.nativeElement.value = response.merchant_key;
    this.return_url.nativeElement.value = response.return_url;
    this.cancel_url.nativeElement.value = response.cancel_url;
    this.notify_url.nativeElement.value = response.notify_url;
    this.name_first.nativeElement.value = response.name_first;
    this.name_last.nativeElement.value = response.name_last;
    this.email_address.nativeElement.value = response.email_address;
    this.cell_number.nativeElement.value = response.cell_number;
    this.m_payment_id.nativeElement.value = response.m_payment_id;
    this.price.nativeElement.value = response.amount;
    this.item_name.nativeElement.value = response.item_name;
    this.item_description.nativeElement.value = response.item_description;
    this.custom_int1.nativeElement.value = response.custom_int1;
    this.confirmation_address.nativeElement.value = response.confirmation_address;
    $("#btnMakePayment1").trigger("click");
  }

  public ozowRedirect(ozowBody): void {
    localStorage.setItem('paidByRetail', 'true');
    this.SiteCode.nativeElement.value = ozowBody.SiteCode;
    this.CountryCode.nativeElement.value = ozowBody.CountryCode;
    this.CurrencyCode.nativeElement.value = ozowBody.CurrencyCode;
    this.Amount.nativeElement.value = ozowBody.Amount.toFixed(2);
    this.TransactionReference.nativeElement.value = ozowBody.TransactionReference;
    this.BankReference.nativeElement.value = ozowBody.BankReference;
    this.Customer.nativeElement.value = ozowBody.Customer;
    this.Optional1.nativeElement.value = ozowBody.Optional1;
    this.CancelUrl.nativeElement.value = ozowBody.CancelUrl;
    this.ErrorUrl.nativeElement.value = ozowBody.ErrorUrl;
    this.SuccessUrl.nativeElement.value = ozowBody.SuccessUrl;
    this.NotifyUrl.nativeElement.value = ozowBody.NotifyUrl;
    this.IsTest.nativeElement.value = ozowBody.IsTest;
    this.HashCheck.nativeElement.value = ozowBody.HashCheck;
    $("#ozowPayment").trigger("click");
  }

  public creditWallet(paymentType: string) {
    if (this.amountChosen < 0) {
      this.message = "Enter a valid amount"
      this.emptyWallet = true
      return
    }
    if (this.amountChosen != 0) {
      this.loadservice.addloader();
      this.emptyWallet = false;
      var loadWallet = {
        "userAccountID": this.retail.owner.userAccountOid,
        "rechargeAmt": this.amountChosen
      }
      this.userService.loadWalletService(this.user.token, loadWallet).subscribe(
        response => {
          if (paymentType === 'payfast') {
            var paymentData = {
              "name_first": this.retail.owner.firstName,
              "name_last": this.retail.owner.surname,
              "email_address": this.retail.owner.email,
              "cell_number": this.retail.owner.mobile,
              "m_payment_id": response.oid,
              "amount": this.amountChosen,
              "customerReference": this.retail.owner.oid,
              "item_name": 'Wallet Recharge',
              "item_description": 'Loading Wallet',
              "email_confirmation": 0,
              "confirmation_address": this.user.owner.email,
              "reference": response.oid,
              "paymentType": "WALLET_PAYMENT"
            };
            this.loadservice.addloader();
            this.bookingService.requestPayfastPayment(paymentData, this.user.token).subscribe(
              resp => {
                this.redirectToPayFast(resp.json());
              }, error => {
                console.log(error);
                this.loadservice.removeloader();
              }
            );
          } else {
            // ozow
            let ozowObj = {
              "CountryCode": "ZA",
              "CurrencyCode": "ZAR",
              "Amount": this.amountChosen,
              "TransactionReference": response.oid,
              "BankReference": "",
              "Customer": this.user.owner.email,
              "IsTest": true,
              "paymentType": "WALLET_PAYMENT"
            };
            this.bookingService.requestOzowPayment(ozowObj, this.user.token).subscribe(
              (res) => {
                // console.log(resp);
                this.loadservice.removeloader();
                this.ozowRedirect(res.json());
              }, (errr) => {
                console.log(errr);
                this.loadservice.removeloader();
              });
          }

        }, error => {
          console.log(error);
          this.loadservice.removeloader();
        }
      );
    } else {
      this.message = 'Please choose an amount to credit your wallet.';
      this.emptyWallet = true
      // $('#emptyWallet').modal('show');
    }
  }

  public viewTransactions() {
    // this.route.navigate(['/retail-transaction-history']);
    localStorage.setItem('walletID', this.walletOID);
    this.viewUserTransactions = true;
    this.retrieveTransaction();
  }

  public retrieveWalletBalance() {
    this.userService.retrieveCurrentBalance(this.user.token, this.retail.owner.userAccountOid).subscribe(
      response => {
        // console.log(response);
        this.walletOID = response.json().oid;
        this.currentAmt = response.json().amount;
      }, error => {
        console.log(error);
      }
    )
  }

  public downloadSpreadsheet() {
    var currentDate = new Date(this.startDate);
    let date = currentDate.getDate() <= 9 ? '0' + currentDate.getDate() : currentDate.getDate();
    let monthNo = (currentDate.getMonth() + 1) <= 9 ? '0' + (currentDate.getMonth() + 1) : (currentDate.getMonth() + 1);
    var dropTime = currentDate.getFullYear() + '-' + monthNo + '-' + date + 'T' + '00' + ':' + '00' + ':' + '00';
    this.startDate = dropTime;

    var currentDate2 = new Date(this.endDate);
    let date2 = currentDate2.getDate() <= 9 ? '0' + currentDate2.getDate() : currentDate2.getDate();
    let monthNo2 = (currentDate2.getMonth() + 1) <= 9 ? '0' + (currentDate2.getMonth() + 1) : (currentDate2.getMonth() + 1);
    var dropTime2 = currentDate2.getFullYear() + '-' + monthNo2 + '-' + date2 + 'T' + '23' + ':' + '59' + ':' + '59';
    this.endDate = dropTime2;

    var dateObj = {
      "startDate": this.startDate,
      "endDate": this.endDate
    };

    this.loadservice.addloader();

    this.reporterror = false;
    this.successmsg = '';
    this.message = '';

    if (dateObj.startDate.toString() == 'NaN-NaN-NaNT00:00:00' || dateObj.endDate.toString() == 'NaN-NaN-NaNT23:23:59' || this.endDate == undefined || this.startDate == undefined) {
      this.message = 'Please select start & end date to download the spreadsheet.';
      this.reporterror = true;
      // $('#emptyWallet').modal('show');
      this.loadservice.removeloader();
    } else {
      this.userService.downloadSpreadsheet(this.user.token, dateObj, this.retail.oid).subscribe(
        response => {
          this.loadservice.removeloader();
          this.downloadBookingFile(response.json().bookingsDump);
          if (this.canAddParcels) {
            // if user has a tag
            this.downloadDumpsFile(response.json().parcelDump);
          }
          this.reporterror = false;
          this.successmsg = 'Spreadsheet successfully downloaded.';
          // $('#spreadsheetDownload').modal('show');
        }, error => {
          this.loadservice.removeloader();
          this.reporterror = true;
          console.log(error);
          this.message = 'Could not download spreadsheet.';
          // $('#emptyWallet').modal('show');
        }, () => {

        }
      );
    }
  }

  downloadBookingFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);

    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    a.download = value + '-' + 'bookingData.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  downloadDumpsFile(data: any) {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')
    );
    csv.unshift(header.join(','));
    const csvArray = csv.join('\r\n');

    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    var currentDate = new Date();
    var datePipe = new DatePipe("en-US");
    let value = datePipe.transform(currentDate, 'yyyy-MM-dd');
    a.download = value + '-' + 'itemDetails.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  payfastMsg: string = '';

  public recreateRental(rentals) {
    this.payfastMsg = 'Please wait while we redirect you to payfast...';
    let payObj = {
      "from": {
        "coordinates": ["-25.8770951", "28.1787793"],
        "address": rentals.from.address
      },
      "branchOid": rentals.branchDTO.oid,
      "in": rentals.in,
      "out": rentals.out,
      "rateUnit": rentals.rateUnit,
      "category": rentals.category,
      "assistance": rentals.assistance,
      "includedDistance": rentals.includedDistance,
      "comments": rentals.comments,
      "customerOid": this.user.owner.oid,
      "total": rentals.total,
      "platform": 'web'
    }
    this.loadservice.addloader();
    this.bookingService.saveEliteVehicles(this.user.token, payObj).subscribe(response => {
      this.loadservice.removeloader();
      var paymentData = {
        "name_first": this.user.owner.firstName,
        "name_last": this.user.owner.surname,
        "email_address": this.user.owner.email,
        "cell_number": this.user.owner.mobile,
        "m_payment_id": response.oid,
        "amount": rentals.total,
        "customerReference": this.user.owner.oid,
        "item_name": 'Elite Rental',
        "item_description": 'Payment For Rental Truck',
        "email_confirmation": 0,
        "confirmation_address": this.user.owner.email,
        "reference": response.oid,
        "paymentType": "RETAIL_RENTAL_PAYMENT"
      };
      this.bookingService.requestPayfastPayment(paymentData, this.user.token).subscribe(response => {
        this.loadservice.removeloader();
        this.rentalPayFast(response.json());
      }, error => {
        this.loadservice.removeloader();
        console.log(error);
      }, () => {
        this.loadservice.removeloader();
      }
      )
    })
  }

  public rentalPayFast(response) {
    this.merchant_id_rental.nativeElement.value = response.merchant_id;
    this.merchant_key_rental.nativeElement.value = response.merchant_key;
    this.return_url_rental.nativeElement.value = response.return_url;
    this.cancel_url_rental.nativeElement.value = response.cancel_url;
    this.notify_url_rental.nativeElement.value = response.notify_url;
    this.name_first_rental.nativeElement.value = response.name_first;
    this.name_last_rental.nativeElement.value = response.name_last;
    this.email_address_rental.nativeElement.value = response.email_address;
    this.cell_number_rental.nativeElement.value = response.cell_number;
    this.m_payment_id_rental.nativeElement.value = response.m_payment_id;
    this.price_rental.nativeElement.value = response.amount;
    this.item_name_rental.nativeElement.value = response.item_name;
    this.item_description_rental.nativeElement.value = response.item_description;
    this.custom_int1_rental.nativeElement.value = response.custom_int1;
    this.confirmation_address_rental.nativeElement.value = response.confirmation_address;
    $("#btnRentalPay").trigger("click");
  }

  public recreateBooking(bookingData) {
    this.book = JSON.parse(localStorage.getItem('bucketBooking'));
    this.loadservice.addloader();
    this.adminService.book(bookingData, this.user.token).subscribe(
      (response) => {
        this.loadservice.removeloader();
        $('#recreateBookings').modal('show');
        this.recreateMsg = 'Booking re-created.';
        this.getExpressRetailBookings();
      }, (error) => {
        this.recreateMsg = error.error.message;
        $('#recreateBookings').modal('show');
        console.log(error);
        this.loadservice.removeloader();
      }, () => {
      }
    );
  }

  public readyForShipping(booking) {
    this.loadservice.addloader();
    this.bookingService.readyForShipping(booking.oid, this.user.token).subscribe(
      (response) => {
        this.loadservice.removeloader();
        $('#recreateBookings').modal('show');
        this.recreateMsg = 'Booking re-created.';
        this.getExpressRetailBookings();
      }, (error) => {
        this.recreateMsg = error.error.message;
        $('#recreateBookings').modal('show');
        console.log(error);
        this.loadservice.removeloader();
      }, () => {
      }
    );
  }

  public addBranchToRetail() {
    let retailBranchDTO = {
      "name": this.branchName,
      "phone": this.branchPhoneNum,
      "email": this.branchEmail
    };
    this.adminService.addRetailBranch(this.user.token, this.retail.oid, retailBranchDTO).subscribe(response => {
      this.branchMsg = 'Branch successfully added to retail.';
      this.getRetails(this.user.owner.retailId);
    }, error => {
      console.log(error);
      this.branchMsg = error.error.message + ' already exists.';
    }, () => {
      this.branchForm.reset();
    });
  }

  public deleteBranch(branch) {
    this.adminService.deleteBranch(this.user.token, this.retail.oid, branch.name).subscribe(response => {
      this.removeBranchMsg = 'Branch successfully removed';
      $('#branchModal').modal('show');
    }, error => {
      console.log(error);
    }, () => {
      this.getRetails(this.user.owner.retailId);
    });
  }

  public retrieveTransaction() {
    this.bookingService.getTransactions(this.user.token, this.walletOID).subscribe(
      response => {
        // console.log(response);
        this.history = response.reverse();
      }, error => {
        console.log(error);
      }
    );
  }

  public downloadWaybills(bucket) {
    // console.log(bucket);
    // let booking: any;
    for (const booking of bucket.bookings) {
      // console.log(booking);
      this.listofBookingOids.push(booking.oid);
    }

    const data = {
      bookingOids: this.listofBookingOids
    };

    // console.log(this.listofBookingOids);

    this.userService.downloadRetailWaybills(data).subscribe(
      (response) => {
        // console.log(response);
        const downloadWaybill = document.createElement('a');
        downloadWaybill.href = response.downloadPdfURL;
        downloadWaybill.download = 'Waybill';
        downloadWaybill.click();
        downloadWaybill.remove();
        this.listofBookingOids = [];
      },
      error => {
        console.log(error);
        this.listofBookingOids = [];
      }
    );
  }

  public newUserOrExistingUser() {
    this.newUser = !this.newUser;
    if (this.newUser) {
      this.addUserTitle = 'Add existing user';
    } else {
      this.addUserTitle = 'Add new user';
    }
  }

  public addNewUser() {
    if (this.newUserFirstName === '' || this.newUserSurname === '' || this.newUserEmail === '' ||
      this.newUserMobile === '' || this.newUserPassword === '') {
      this.newUserResponseMsg = 'All fields must be entered';
    } else {

      const user = new User(this.newUserFirstName, this.newUserSurname, this.newUserEmail.toLowerCase().trim(), this.newUserMobile);
      user.login = new Login(this.newUserPassword, this.newUserEmail.toLowerCase().trim());

      const deviceId = new Date().valueOf();
      const pass = user.login.pwd.split('');
      const userData = {
        owner: {
          deviceId,
          mobile: user.mobile,
          firstName: user.firstName,
          surname: user.surname,
          rsaId: user.mobile + '123',
          email: user.email,
          retailId: this.retail.owner.retailId
        },
        username: user.email,
        pwd: pass
      };

      this.userService.addNewUserToRetail(this.user.token, userData).subscribe(
        (response) => {
          // console.log(response);
          this.newUserResponseMsg = 'User added successfully';
          this.getUserRetail(this.user.owner.oid);
        },
        (error) => {
          // console.log(error);
          this.newUserResponseMsg = error.error.message;
        }
      );
    }
  }

  clearAddNewUserFields() {
    this.newUserFirstName = '';
    this.newUserSurname = '';
    this.newUserEmail = '';
    this.newUserMobile = '';
    this.newUserPassword = '';
    this.newUserResponseMsg = '';
    this.userFound = false;
    this.assignStatus = '';
    this.removeUserMessage = '';
  }

  public removeUserFromRetail(user) {
    this.removeUserMessage = '';
    // console.log(user);
    this.userService.removeUserToRetail(this.user.token, user.oid).subscribe(
      (response) => {
        this.getUserRetail(this.user.owner.oid);
        this.removeUserMessage = user.firstName + ' ' + user.surname + ' removed successfully';
      },
      (error) => {
        console.log(error);
        this.removeUserMessage = 'Failed to remove user';
      });
  }

  downloadTaxInvoice(booking) {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    console.log(booking);
    if (booking.isBucket === null) {
      this.loadservice.addloader();
      this.bookingService.downloadTaxInvoiceForRetail(object.token, booking.trackNo).subscribe(
        (response) => {
          this.loadservice.removeloader();
          this.emailInvoiceFeedbackMsg = 'Email sent';
          $('#emailTaxInvoice').modal('show');
        }, (error) => {
          this.loadservice.removeloader();
          console.log(error);
          this.emailInvoiceFeedbackMsg = 'Failed to send. Please try again later';
          $('#emailTaxInvoice').modal('show');
        }
      );
    } else {
      $('#addTaxNumber').modal('show');
      this.taxInvoiceBooking = booking;
    }
  }

  downloadTaxInvoiceForSingleBooking() {
    if (this.invoiceCompanyName !== '' && this.invoiceCompanyAddress !== '' && this.invoiceVatNo !== '') {
      $('#addTaxNumber').modal('hide');
      const invoiceObj = {
        companyName: this.invoiceCompanyName,
        address: this.invoiceCompanyAddress,
        vatNo: this.invoiceVatNo,
        toNames: this.user.owner.firstName + ' ' + this.user.owner.surname,
        toEmail: this.user.owner.email,
        toPhone: this.user.owner.mobile,
        trackNo: this.taxInvoiceBooking.trackNo
      };
      this.loadservice.addloader();
      this.bookingService.newInvoiceCustomer(this.user.token, invoiceObj).subscribe(
        response => {
          this.loadservice.removeloader();
          this.emailInvoiceFeedbackMsg = 'Email sent';
          $('#emailTaxInvoice').modal('show');
        }, (error) => {
          this.loadservice.removeloader();
          console.log(error);
          this.emailInvoiceFeedbackMsg = 'Failed to send. Please try again later';
          $('#emailTaxInvoice').modal('show');
        }, () => {
        }
      );
    }
  }

  public goToService(serviceType) {
    localStorage.setItem('retail', JSON.stringify(this.retail));
    if (this.canAddParcels && !this.isSkyNetRetail) {
      if (serviceType === 'pudo') {
        localStorage.setItem('selectedRetailService', 'pudo');
        this.route.navigate(['/create-retail-booking']);
      } else {
        this.route.navigate(['/create-retail-book']);
      }
    } else {
      switch (serviceType) {
        case 'courier':
          localStorage.setItem('selectedRetailService', 'courier');
          break;
        case 'pudo':
          localStorage.setItem('selectedRetailService', 'pudo');
          break;
        case 'fleet':
          localStorage.setItem('selectedRetailService', 'fleet');
          break;
        case 'rental':
          localStorage.setItem('selectedRetailService', 'rental');
          break;
      }
      this.route.navigate(['/create-retail-booking']);
    }
  }

  public showOverviewDetails() {
    this.showOverview = !this.showOverview;
  }

  public getPudoBookings(retailOid) {
    this.bookingService.getPudoBookings(this.user.token, retailOid).subscribe(
      (response1: any) => {
        // console.log(response1);
        this.pudoBookings = response1;
      },
      (error1) => {
        console.log(error1);
      });
  }

  public showPudoTracking(booking) {
    this.pudoBookingBeingTracked = booking;
    this.viewPudoTracking = true;
  }

  public showPudoDetails(booking) {
    this.pudoBookingBeingViewed = booking;
    this.viewPudoBookingDetails = true;
  }

  public showPudoTable() {
    this.pudoBookingBeingTracked = {};
    this.viewPudoTracking = false;
    this.viewPudoBookingDetails = false;
  }

  public showPudoPaymentOptions(pudoBooking) {
    this.pudoBookingBeingPayed = pudoBooking;
    $('#pudoPaymentOptions').modal('show');
  }

  public payPudoWithPayfast() {
    this.loadservice.addloader();
    const paymentData = {
      name_first: this.user.owner.firstName,
      name_last: this.user.owner.surname,
      email_address: this.user.owner.email,
      cell_number: this.user.owner.mobile,
      m_payment_id: this.pudoBookingBeingPayed.oid,
      amount: this.pudoBookingBeingPayed.price,
      customerReference: this.user.owner.oid,
      item_name: "Pudo Booking",
      item_description: "Payment For Pudo locker",
      email_confirmation: 1,
      confirmation_address: this.user.owner.email,
      reference: this.pudoBookingBeingPayed.oid,
      paymentType: "LOCKER_PAYMENT",
    };
    this.bookingService.requestPayfastPayment(paymentData, this.user.token).subscribe(
      (response1) => {
        this.loadservice.addloader();
        localStorage.setItem("Payment", "PUDO");
        localStorage.setItem('paidByRetail', 'true');
        this.redirectToPayFast(response1.json());

      }, (error1) => {
        console.log(error1);
        this.loadservice.removeloader();
      });
  }

  public payPudoWithWallet() {
    this.loadservice.addloader();
    this.bookingService.checkAvailBalance(this.user.token, this.retail.owner.userAccountOid, this.pudoBookingBeingPayed.price).subscribe(
      (response2) => {
        // Pay for booking with Wallet
        if (response2.isBalancePositive) {
          this.bookingService.walletPayPudoBooking(this.user.token, this.pudoBookingBeingPayed.transactionId).subscribe(
            (response3) => {
              this.pudoMessage = 'Booking is being processed. \n ' +
                'Once a Locker is confirmed, you will have 36 hours place you parcel in the Locker \n' +
                'Failure will result in a cancellation with NO REFUND.';
              $('#pudoPaymentOptions').modal('hide');
              this.loadservice.removeloader();
              $('#pudoMessage').modal('show');
              this.getPudoBookings(this.retail.oid);
            },
            (error3) => {
              console.log(error3);
              this.loadservice.removeloader();
            }
          );
        } else {
          $('#pudoPaymentOptions').modal('hide');
          this.pudoMessage = 'Your Wallet balance is insufficient. Please top-up your Wallet';
          this.loadservice.removeloader();
          $('#pudoMessage').modal('show');
        }
      },
      (error2) => {
        console.log(error2);
        this.loadservice.removeloader();
      }
    );
  }

  public payPudoWithMonthlyInvoice() {
    this.loadservice.addloader();
    this.bookingService.invoicePayPudoBooking(this.user.token, this.pudoBookingBeingPayed.transactionId).subscribe(
      (response2) => {
        this.sendNewRetailInvoices(this.pudoBookingBeingPayed);
        this.pudoMessage = 'Booking is being processed. \n ' +
          'Once a Locker is confirmed, you will have 36 hours place you parcel in the Locker \n' +
          'Failure will result in a cancellation with NO REFUND.';
        $('#pudoPaymentOptions').modal('hide');
        this.loadservice.removeloader();
        $('#pudoMessage').modal('show');
        this.getPudoBookings(this.retail.oid);
      },
      (error2) => {
        console.log(error2);
        this.loadservice.removeloader();
      }
    );
  }

  private sendNewRetailInvoices(booking) {
    const object = JSON.parse(localStorage.getItem('userInfo'));
    this.bookingService.invoiceRetailBucketCustomer(object.token, booking.trackNo).subscribe(
      (response) => {
        //   Do nothing
      }, (error) => {
        console.log(error);
      }
    );
  }

  public refreshPudoBooking(pudoBooking, index) {
    if (pudoBooking.status === 'COMPLETE') {
      return;
    }
    this.loadservice.addloader();
    if (pudoBooking.wayBillNumber) {
      this.bookingService.getPudoBookingEvents(this.user.token, pudoBooking.wayBillNumber).subscribe(
        (response1) => {
          // console.log(response1);
          this.pudoBookings.splice(index, 1, response1);
          this.loadservice.removeloader();
        },
        (error1) => {
          console.log(error1);
          this.pudoFeedbackMsg = error1.error.message;
          this.loadservice.removeloader();
          $('#pudoRefresh').modal('show');
        }
      );
    } else {
      this.bookingService.getPudoBookingWaybill(this.user.token, pudoBooking.transactionId).subscribe(
        (response1) => {
          // console.log(response1);
          this.pudoBookings.splice(index, 1, response1);
          this.loadservice.removeloader();
        },
        (error1) => {
          console.log(error1);
          this.pudoFeedbackMsg = error1.error.message;
          this.loadservice.removeloader();
          $('#pudoRefresh').modal('show');
        }
      );
    }
  }

  public downloadPudo(booking) {
    const downloadPudo = document.createElement('a');
    downloadPudo.href = this.nameService.getUrl() + 'pudo/download/waybill/' + booking.transactionId;
    downloadPudo.download = 'Pudo booking waybill';
    downloadPudo.click();
    downloadPudo.remove();
  }

  public checkPudoService() {
    this.loadservice.addloader();
    this.adminService.getTerminalCount()
      .subscribe(
        (res: any) => {
          if (res.count == 0) {
            this.pudoService = false
          }
        },
        (error) => {
          this.loadservice.removeloader();
          console.log(error);
        },
        () => {
          this.loadservice.removeloader();
        }
      )
  }

  initialLoadWalletView() {
    this.atSelectAmount = true;
    this.amountChosen = 0;
  }

  showLoadWalletViewOptions() {
    if (this.amountChosen > 0) {
      this.atSelectAmount = false;
      this.showSelectWalletAmountError = false;
    } else {
      this.showSelectWalletAmountError = true;
    }
  }

  /***
   * TAGS
   * Used for adding order tags to filter plugin orders
   */
  newTagList = [];
  public appendList() {
    if (this.tagName != "") {
      this.newTagList.push(this.tagName)
      this.addTags();
    }else {
      this.reporterror = true;
      this.message = "Tag value cannot be empty";
    }
  }

  public addTags() {
    this.loadservice.addloader();
    this.userService.addOrdersTag(this.user.token, this.newTagList, this.retailId).subscribe(
      (res) => {
        this.loadservice.removeloader();
        this.reporterror = false;
        this.successmsg = `Tag ${this.newTagList[0]} added succesfully`;
        this.getRetails(this.user.owner.oid);
        this.newTagList = [];
        this.tagName = "";
      },
      (err) => {
        this.loadservice.removeloader();
        if (err.error) {
          this.reporterror = true
          this.message = err.error.message;
          this.newTagList = [];
        }

      }
    );

  }

  public deleteTag (tag){
    const deleteTag = [tag]
    this.loadservice.addloader();
    this.userService.deleteOrdersTag(this.user.token, deleteTag, this.retailId).subscribe(
      (res) => {
        this.reporterror = false;
        this.successmsg = "Tag deleted succesfully";
        this.getRetails(this.user.owner.oid);
      },
      (err) => {
        this.loadservice.removeloader();
        if (err.error) {
          this.reporterror = true
          this.message = err.error.message;
          this.newTagList = [];
        }
      }
    );
  }

  clearErrorMsg(){
    if (this.reporterror) {
        this.message = "";
    }
  }

  clearTagsMessages(){
    this.message = "";
    this.successmsg = "";
    $('#tagsModal').modal('hide');
  }

}
