import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-reset-skynet-password',
  templateUrl: './reset-skynet-password.component.html',
  styleUrls: ['./reset-skynet-password.component.css']
})
export class ResetSkynetPasswordComponent implements OnInit {

  username : string;
  sub : any;
  error : string = '';
  success : string = '';
  submitted : boolean = true;

  constructor(private loginService : LoginService, private router: Router) { }

  ngOnInit() {

  }

  public requestPassword() {
    
    this.submitted = false;
    this.error = '';
    this.success = '';

    this.loginService.requestSkynetPwdReset(this.username.toLowerCase().trim())
    .subscribe(
      (res) => {
        console.log(res);
        if(res.status == 200) {
          this.success = "Email sent, Check your emails for verification.";
          this.router.navigateByUrl('/');
        }

      },
      (err) => {
        console.log(err);
        this.submitted = false;
        this.error = "Email address doesn't exist.";
      }
    )

  }

}
