import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {error} from "protractor";
import {Response} from "@angular/http";
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-view-partner',
  templateUrl: './view-partner.component.html',
  styleUrls: ['./view-partner.component.css']
})
export class ViewPartnerComponent implements OnInit {

  partner: any;
  admin: any;
  companyVehicles = [];
  companyDrivers = [];
  companyDeliveries = [];
  owner: any;
  vehicleToDelete: any;

  removalVehicleMsg = '';
  removalVehicleBoolean = false;

  loader = false;

  bankName: string = null;
  bankAccount: string = null;
  bankHolder: string = null;
  bankCode: string = null;

  editBank = false;
  showUpdateBank = false;
  showUpdateSuccess = false;
  showUpdateFailed = false;

  showDrivers = false;
  showVehicles = true;
  showDeliveries = false;

  backNav="all-partners"

  constructor(private adminService: AdminService, private partnerService: UserService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.loadservice.addloader();
    this.partner = JSON.parse(localStorage.getItem('partner'));
    // console.log(this.partner);
    this.admin = JSON.parse(localStorage.getItem('userInfo'));

    const prevUrl=localStorage.getItem('prevUrl');
    this.backNav=prevUrl?prevUrl:"all-partners"

    this.bankName = this.partner.bankAccount && this.partner.bankAccount.bankName ? this.partner.bankAccount.bankName : null;
    this.bankAccount = this.partner.bankAccount && this.partner.bankAccount.accountNo ? this.partner.bankAccount.accountNo : null;
    this.bankHolder = this.partner.bankAccount && this.partner.bankAccount.accountName ? this.partner.bankAccount.accountName : null;
    this.bankCode = this.partner.bankAccount && this.partner.bankAccount.branchCode ? this.partner.bankAccount.branchCode : null;

    this.partnerService.retrieveVehicles(this.admin.token, this.partner.oid).subscribe(
    (res) => {
            if (res.status === 200) {
              this.companyVehicles = res.json();
            }
            for (const vehicle of this.companyVehicles) {
              if (vehicle.driverOid != null) {
                this.getDrivers(this.admin.token, vehicle.driverOid);
              }
            }
          }
      );

    this.adminService.getOwner(this.admin.token, this.partner.ownerOid).subscribe(
      (result) => {
        // console.log(result);
        this.owner = result;
      }
    );

    this.getDeliveries();
    this.loadservice.removeloader();
  }

  private getDrivers(admin, driver) {
    this.partnerService.retrieveDrivers(admin, driver).subscribe(
      (res) => {
        if (res.status === 200) {
          this.companyDrivers.push(res.json());
        }
      },
      (err) => {
      }
    );

  }

  public getDeliveries() {
    this.partnerService.retrieveDeliveris(this.admin.token, this.partner.oid).subscribe(
    (res) => {
            if (res.status === 200) {
              this.companyDeliveries = res.json();
              // console.log(this.companyDeliveries);
              this.companyDeliveries = this.companyDeliveries.reverse();
              }
          },
        (err) => {
          console.log(err);
          });
  }

  public editBankingDetails() {
    this.showUpdateSuccess = false;
    this.showUpdateFailed = false;
    this.editBank = !this.editBank;
    if (!this.editBank) {
      this.showUpdateBank = false;
      /*this.bankName = this.partner.bankAccount && this.partner.bankAccount.bankName ? this.partner.bankAccount.bankName : null;
      this.bankAccount = this.partner.bankAccount && this.partner.bankAccount.accountNo ? this.partner.bankAccount.accountNo : null;
      this.bankHolder = this.partner.bankAccount && this.partner.bankAccount.accountName ? this.partner.bankAccount.accountName : null;
      this.bankCode = this.partner.bankAccount && this.partner.bankAccount.branchCode ? this.partner.bankAccount.branchCode : null;*/
    }
    /*else {
      this.showUpdateBank = false;
    }*/
  }

  checkBankDetails() {
    // Check the input boxes are not empty or contain the same data
    if (this.partner.bankAccount !== null) {
      this.showUpdateBank = (this.bankName !== '' && this.bankAccount !== '' && this.bankHolder !== '' && this.bankCode !== '') && (
        this.bankName.toLowerCase() !== this.partner.bankAccount.bankName.toLowerCase() ||
        this.bankAccount !== this.partner.bankAccount.accountNo ||
        this.bankHolder.toLowerCase() !== this.partner.bankAccount.accountName.toLowerCase() ||
        this.bankCode !== this.partner.bankAccount.branchCode);
    } else {
      this.showUpdateBank = ((this.bankName !== null) && (this.bankName !== '') && (this.bankAccount !== null) && (this.bankAccount !== '') &&
        (this.bankHolder !== null) && (this.bankHolder !== '') && (this.bankCode !== null) && (this.bankCode !== ''));
    }
  }

  public updateBankingDetails() {
    if (this.partner.bankAccount === null) {
      const bankData = {
        bankName : this.bankName,
        accountNo: this.bankAccount,
        accountName : this.bankHolder,
        branchCode : this.bankCode,
        companyOid : this.partner.oid
      };

      this.adminService.bankAccount(bankData, this.admin.token).subscribe(
        (response) => {
          this.showUpdateSuccess = true;
          this.editBank = !this.editBank;
          this.showUpdateBank = false;
          const bank = response;
          this.bankName = bank.bankName;
          this.bankAccount = bank.accountNo;
          this.bankHolder = bank.accountName;
          this.bankCode = bank.branchCode;
        },
        (error) => {
          console.log(error);
          this.showUpdateFailed = true;

        }
      );
    } else {
      const bankData = {
        oid : this.partner.bankAccount.oid,
        bankName : this.bankName,
        accountNo: this.bankAccount,
        accountName : this.bankHolder,
        branchCode : this.bankCode,
        companyOid : this.partner.oid
      };

      this.adminService.updateBankAccount(bankData, this.partner.bankAccount.oid, this.admin.token).subscribe(
        (response) => {
          this.showUpdateSuccess = true;
          this.editBank = !this.editBank;
          this.showUpdateBank = false;
          // console.log(response);
          const bank = response;
          this.bankName = bank.bankName;
          this.bankAccount = bank.accountNo;
          this.bankHolder = bank.accountName;
          this.bankCode = bank.branchCode;
        },
        (error) => {
          console.log(error);
          this.showUpdateFailed = true;
        }
      );
    }
  }

  public removeVehicleModal(vehicle) {
    $('#removeVehicle').modal('show');
    this.vehicleToDelete = vehicle;
    // console.log(vehicle);
    // console.log(this.vehicleToDelete.oid);
    // console.log(this.partner.oid);
  }

  public removeVehicle() {
    // var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.removeVehicleFromCompany(this.admin.token, this.vehicleToDelete.oid, this.partner.oid).subscribe(
      response => {
        this.removalVehicleBoolean = true;
        this.removalVehicleMsg = 'Vehicle Successfully Removed';
        alert('Vehicle Successfully Removed');
      }, error => {
        console.log(error);
      }
    );
  }

  public viewVehicle(vehicle) {
    localStorage.setItem('selectedVehicle', JSON.stringify(vehicle));
    this.router.navigate(['/update-vehicle'])
  }
  public transactionsSummary(summary){
    localStorage.setItem('selectedVehicle', JSON.stringify(summary));
    this.navigate("admin-owner-financial")
  }
  public showDriversTable() {
    this.showDrivers = true;
    this.showVehicles = false;
    this.showDeliveries = false;
  }

  public showVehiclesTable() {
    this.showDrivers = false;
    this.showVehicles = true;
    this.showDeliveries = false;
  }

  public showDeliveriesTable() {
    this.showDrivers = false;
    this.showVehicles = false;
    this.showDeliveries = true;
  }
  public backBtn(){
    const route=`/${this.backNav}`
    localStorage.removeItem('prevUrl')
    this.router.navigate([route]);
  }
  public navigate(path){
    const route=`/${path}`
    this.router.navigate([route]);
  }
}
