import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-retail-invoices',
  templateUrl: './retail-invoices.component.html',
  styleUrls: ['./retail-invoices.component.css', '../admin-portal/admin-portal.component.css']
})
export class RetailInvoicesComponent implements OnInit {

  admin: any;
  invoices: any = [];
  addValue : any;

  constructor(private adminService: AdminService, private service: UserService, private route: Router) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.getInvoices();
  }

  public getInvoices() {
    this.admin = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.getInvoices(this.admin.token).subscribe(
      (response) => {

        this.invoices = response;
        localStorage.setItem('selectedPartner', JSON.stringify(this.invoices));
      }, (error) => {
      }
    );
  }

  public onInvoiceSelected(invoice) {

    localStorage.setItem('selectedRetailInvoice', JSON.stringify(invoice));
    this.route.navigate(['/retail-invoices-review']);
  }
  public showTabByRole(role):boolean{
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let result=false;
    userInfo.roles.map(r=>{
      if(role===r.code){
        result=true;
      }
    })
    return result;
  }
}
