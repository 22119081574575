import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-search-resuts',
  templateUrl: './admin-search-resuts.component.html',
  styleUrls: ['./admin-search-resuts.component.css']
})
export class AdminSearchResutsComponent implements OnInit {

  adminSearchRetailers: any;
  adminSearchUsers: any;

  constructor() { }

  ngOnInit() {
    this.adminSearchRetailers = JSON.parse(localStorage.getItem('adminRetailSearchResults'));

    this.adminSearchUsers = JSON.parse(localStorage.getItem('adminUserSearchResults'));
  }
}
