import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';
import { DatePipe } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-view-express-booking',
  templateUrl: './view-express-booking.component.html',
  styleUrls: ['./view-express-booking.component.css']
})
export class ViewExpressBookingComponent implements OnInit {

  book: any;
  expressBooking: any;
  driver: any;
  driverMobile: string;
  valid = false;
  foundDriver: any;
  err = '';
  assignStatus = '';
  errorMessage: string;
  bookingTime: any;
  bookingDate: any;
  message: string;
  messege: string;
  provinceUpdate: string;
  vehicleType: string;
  page: number = 1;
  msg: string;
  messageProvince: string;
  bookingData: any;
  selectedTabID: any;
  doneMsg: string;

  constructor(private adminService: AdminService) {
    this.book = JSON.parse(localStorage.getItem('selectedBooking'));
    this.bookingDate = this.book.pickUpDate;
    let TimeSeparatorIndex = this.book.pickUpTime.indexOf(':');
    let hour = parseInt(this.book.pickUpTime.substring(0, TimeSeparatorIndex));
    let minutes = this.book.pickUpTime.substring(TimeSeparatorIndex + 1, 5);
    this.bookingTime = hour + ':' + minutes + ':00'; // this.book.pickUpTime;
    this.selectedTabID = localStorage.getItem('activeTab');
    localStorage.setItem('activeID', this.selectedTabID);
   }

  ngOnInit() {
    window.scrollTo(0, 0);
    var object = JSON.parse(localStorage.getItem('userInfo'));
    if (this.book.driverOid != null) {
      this.adminService.getDriver(object.token, this.book.driverOid).subscribe(
        (res) => {
          this.driver = res;
        }
      );
    }
  }

  public updateProvince() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.updateProvince(object.token, this.book.oid, this.provinceUpdate).subscribe(
      response => {
        //console.log(response);
        this.messageProvince = 'Province updated.';
        this.book = response;
        localStorage.setItem('selectedBooking', JSON.stringify(response));
      }, error => {
        this.messageProvince = 'Province not updated.';
        console.log(error);
      }
    );
  }

  public cancelBooking() {
    var cancelObj = {
      'status': 'INVALID',
      'bookingOid': this.book.oid
    };
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.cancelBooking(object.token, cancelObj).subscribe(
      (res) => {
        console.log(res);
        this.errorMessage = "Booking Cancelled.";
        this.book = res;
        localStorage.setItem('selectedBooking', JSON.stringify(this.book));
        $('#cancelBooking').modal('show');
      }, error => {
        console.log(error);
      }
    );
  }

  public findDriver() {
    this.err = '';
    this.valid = false;
    if (this.driverMobile != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.getDriverByMobile(object.token, this.driverMobile).subscribe(
        (res) => {
          this.valid = true;
          this.foundDriver = res;
          this.err = this.foundDriver.vehicle != null ? '' : 'Driver doesn\'t have a vehicle.';
        }, (error) => {
          this.err = error.error.message;
          console.log(this.err);
        }
      );
    } else {
      alert('Please enter mobile numbers.');
    }
  }

  public assignNow() {
    var bookingData = {
      'status': 'RESERVED',
      'bookingOid': this.book.oid,
      'driverOid': this.foundDriver.oid
    };

    if (this.foundDriver != null) {
      var object = JSON.parse(localStorage.getItem('userInfo'));
      this.adminService.assignDriverBooking(object.token, bookingData).subscribe(
        (res) => {
          this.assignStatus = 'Driver successfully assigned to booking.';
        }, (error) => {
          this.assignStatus = 'Driver was not assigned, please contact Droppa IT support.';
          console.log(error);
        }
      );
    } else {
      alert('Driver details missing..');
    }
  }

  public completeBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    this.adminService.completeBooking(object.token, this.book.oid).subscribe(
      (res) => {
        this.doneMsg = 'Booking completed by admin.';
        this.book = res;
        // console.log(this.book);
        localStorage.setItem('selectedBooking', JSON.stringify(res));
        $('#completeDialog').modal('show');
      }, error => {
        console.log(error)
        this.doneMsg = 'Could not complete the booking.';
        $('#completeDialog').modal('show');
      }
    );
  }

  public createBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    // if(this.book.status == "Awaiting payments") {

    this.adminService.book(this.book, object.token).subscribe(
      (res) => {
        // alert("Booking completed by admin.");
        this.errorMessage = 'Booking created by admin.';
        $('#errorDialog').modal('show');
      }, (err) => {
        console.log(err)
        this.errorMessage = 'Booking is not in awaiting payment state';
        $('#errorDialog').modal('show');
      }
    );
    // } else {
    //  this.errorMessage = "Booking is not in awaiting payment state";
    //  $('#errorDialog').modal('show');
    // }
  }

  public recreateBooking() {
    var object = JSON.parse(localStorage.getItem('userInfo'));
    // if(this.book.status == "Booking taken already") {

    this.adminService.rebook(this.book.oid, object.token).subscribe(
      (res) => {
        // alert("Booking completed by admin.");
        this.errorMessage = 'Booking created by admin.';
        $('#errorDialog').modal('show');
      }, error => {
        console.log(error)
      }
    );
    // } else {
    //  this.errorMessage = "Booking is not in reserve state";
    //  $('#errorDialog').modal('show');
    // }
  }

  public updateTimes() {
    this.book = JSON.parse(localStorage.getItem('selectedBooking'));

    var datePipe = new DatePipe('en-US');
    let value = datePipe.transform(this.bookingDate, 'yyyy-MM-dd');

    var datePipe = new DatePipe('en-US');
    let TimeSeparatorIndex = this.bookingTime.indexOf(':');
    let hour = parseInt(this.bookingTime.substring(0, TimeSeparatorIndex));
    let minutes = this.bookingTime.substring(TimeSeparatorIndex + 1, 5);
    let date = new Date();

    date.setHours(hour);
    date.setMinutes(minutes);

    let valueTime = datePipe.transform(date, 'HH:mm a');
    var object = JSON.parse(localStorage.getItem('userInfo'));

    if (hour >= 7 && hour <= 18) {
      const bookingUpdate = {
        'date': value,
        'time': valueTime,
        'bookingOid': this.book.oid
      };
      this.adminService.updateBooking(bookingUpdate, object.token).subscribe(
        (response) => {
          this.message = 'Booking is updated successfully.';
          this.book.pickUpTime = response.pickUpTime;
          this.book.pickUpDate = response.pickUpDate;
        }, (err) => {
          this.message = 'Unknown Error while updating booking.';
          console.log(err)
        }
      );
    } else {
      this.message = 'Working hours are from 07:00 AM to 18:59 PM.';
    }
  }

}
