import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router'
import { LoaderService } from 'src/app/services/loader.service';
declare var $: any;

@Component({
  selector: 'app-confirm-otp',
  templateUrl: './confirm-otp.component.html',
  styleUrls: ['./confirm-otp.component.css']
})
export class ConfirmOtpComponent implements OnInit {

  public passwordEye: string = 'glyphicon glyphicon-eye-open';
  public toggleEye: boolean = false;
  public passwordType: string = 'password';


  OTPerror: string = "";
  numberOTP: string;
  loader: boolean = false;
  error: string;
  color: string;
  otp: number;
  userObject: any;
  captcha: any;
  userData: any;

  constructor(private loginService : LoginService, private router: Router,private loadservice:LoaderService) { }

  ngOnInit() {
    this.userData =  JSON.parse(localStorage.getItem('userData'));
    // console.log( this.userData);
    // console.log( this.userData.owner.mobile);
    this.numberOTP = this.userData.owner.mobile;

  }


  public confirmingOTP() {
    //this.loadservice.addloader();
    var object = JSON.parse(localStorage.getItem('signedUser'))
    // console.log(this.otp);

    if (this.otp !== null) {
      this.loadservice.addloader();
      this.loginService.confirmOTP(this.otp, this.numberOTP)
        .subscribe(
          (response: any) => {
            console.log(response.json());
            if (response.status == 200) {
              if (response.json().confirmed) {
                // console.log(object.email +' '+object.password);
                this.loginService.login(object.password, object.email.toLowerCase().trim()).subscribe(
                  (response1: any) => {
                    localStorage.setItem('token', response1.token);
                    // console.log(response);
                    localStorage.removeItem('userData');
                    localStorage.setItem('userData2', response1);
                    localStorage.setItem('userData', response1);
                    this.userObject = response1;
                    this.loadservice.removeloader();
                    this.router.navigateByUrl('/developers-sign-in');

                  }, error1 => {
                    console.log(error1);
                    this.loadservice.removeloader();
                  }
                );
              } else {
                this.loadservice.removeloader();
                this.OTPerror = "The OTP number provided is invalid.";
                // $('#results').modal('show');
              }

            } else {
              this.loadservice.removeloader();
              this.OTPerror = "Please enter valid OTP.";
              // $('#results').modal('show');
            }
          },
          (err) => {
            this.loadservice.removeloader();
            this.OTPerror = "Error occurred. Please contact Droppa.";
            // $('#results').modal('show');
          }
        );

    } else {
      this.loadservice.removeloader();
      this.OTPerror = "Please enter the OTP.";
      // $('#results').modal('show');
    }

  }
}
