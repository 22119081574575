import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirm-mobile',
  templateUrl: './confirm-mobile.component.html',
  styleUrls: ['./confirm-mobile.component.css']
})
export class ConfirmMobileComponent implements OnInit {
  myVar: any;

  constructor() { }

  ngOnInit() {
    
  }

  closeWin() {
    // window.close();
    setTimeout(function(){ window.close() }, 3000);
  }
  

}
