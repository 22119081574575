import { Component, OnInit, isDevMode } from '@angular/core';
import {Router} from '@angular/router';
import 'rxjs/Rx';
import { UserLoggInService } from '../../services/loggedInUser.service';


import { LoginService } from '../../services/login.service';


declare var $: any;
@Component({
  selector: 'app-client-login',
  templateUrl: './client-login.component.html',
  styleUrls: ['./client-login.component.css', '../sign-in/sign-in.component.css']
})
export class ClientLoginComponent implements OnInit {

  password : string;
  username : string;
  errorMsg : string = '';
  isRobot=true
  capture: any;
  loader: boolean;
  UAT_SITE_KEY:String
  isDevMode=isDevMode()


  constructor(private loginService : LoginService, private route : Router, private userLogged : UserLoggInService) { }

  ngOnInit() {
    this.UAT_SITE_KEY=this.loginService.SITE_KEY
  }

  public login() {

    if (this.username === '' || this.username === null || this.username === undefined) {
      this.errorMsg = 'Please enter in your login credentials.';
    }
    else if (this.password === '' || this.password === null || this.password === undefined) {
      this.errorMsg = 'Please enter in your password.';
    }else if(this.isRobot&&!this.isDevMode){
      this.errorMsg = 'Please click the checkbox to verify you are a human.';
    }
    else {
      this.errorMsg = '';
      this.loginService.login(this.password, this.username.toLowerCase().trim()).subscribe(
        (response: any) => {

          let notValid = false;

          response.roles.forEach(role => {
            if (role.code !== 'client') {
              notValid = true;
            }
          });

          if (notValid) {
            // window.alert('You do not have access to this profile type.');
            $('#accessNotAllowed').modal('show');
          } else {
            const loginsData = {
              username: this.username.toLowerCase().trim(),
              pwd: this.password
            };
            localStorage.setItem('userInfo', JSON.stringify(response));
            localStorage.setItem('userInfoBooking', JSON.stringify(response));
            localStorage.setItem('userInfoBookingLogin', JSON.stringify(loginsData));
            localStorage.setItem('individual', 'individual');
            this.userLogged.setUser(response);
            this.route.navigateByUrl('/client');
          }
        },
        (err) => {
          // console.log(err);
          this.errorMsg = err.error.message;
        });
    }
  }
  handleCorrectCaptcha(event) {


    if(event!=null){
      this.isRobot=false
    }else{
      this.isRobot=true

    }

  }

}
