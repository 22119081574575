import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatRippleModule} from '@angular/material';

import {MatInputModule} from '@angular/material/input';

import {MatButtonModule, MatCheckboxModule,
  MatIconModule, MatDialogModule, MatProgressSpinnerModule,
   MatSelectModule, MatRadioModule, MatTableModule} from '@angular/material';


@NgModule({
  imports: [
    CommonModule, MatButtonModule, MatCheckboxModule, MatInputModule,
    MatIconModule, MatDialogModule, MatProgressSpinnerModule, MatSelectModule,
     MatDatepickerModule, MatNativeDateModule, MatRadioModule
  ],
  exports : [
    MatButtonModule, MatCheckboxModule, MatInputModule,
    MatIconModule, MatDialogModule, MatProgressSpinnerModule,
     MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule
  ],
  declarations: []
})
export class AngularMaterialModule { }
