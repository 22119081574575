import { Component, OnInit } from '@angular/core';
import { Response } from '@angular/http';
import { PlatformLocation, DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import * as moment from 'moment';
import { LoaderService } from 'src/app/services/loader.service';

declare var $: any;

@Component({
  selector: 'app-confirm-voucher-payment',
  templateUrl: './confirm-voucher-payment.component.html',
  styleUrls: ['./confirm-voucher-payment.component.css']
})
export class ConfirmVoucherPaymentComponent implements OnInit {

  addrressData: any;
  bookingData: any;
  dropData: any;
  retail: any = {};
  userLat: number;
  userLong: number;
  dir: any;
  location: any;

  termsControl = false;
  loader = false;
  bookingText: string;
  dropOid: string;
  lng: number;
  lat: number;
  // addrressData: any;
  pickUpChecked: any;

  contact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  dropContact: Contact = {
    name: '',
    surname: '',
    mobile: '',
    email: '',
    labours: 0
  };

  disablePickUp = false;
  dropOffChecked = false;
  datePipe: DatePipe;

  bookingTime: any;
  bookingDate: any;
  comment: string;
  // dropOffFloors: number = 0;
  // floorsPickUp: number = 0;
  labour = 0;
  tempAmount: number;
  // loader = false;
  hour: number;

  promoStatus: string;
  voucherStatus: string;
  terms: boolean;
  // termsControl = false;
  today = new Date();
  yesterday = new Date(this.today);
  promoCode: string;
  promoCodeAmnt = 0.0;
  voucherAmount = 0.0;
  msg: string;
  message: string;
  load = 1;
  canopy = false;
  province: string;
  hourDiff: any;
  bookingDates: any;
  theBookingDate: any;
  // dropOid: string;
  promocodeUsed = false;
  createdDrop: any;

  constructor(private bookService: BookingService, private router: Router, location: PlatformLocation,private loadservice:LoaderService) { }

  ngOnInit() {
    this.addrressData = JSON.parse(localStorage.getItem('addressData'));
    // console.log(this.addrressData);

    this.bookingData = JSON.parse(localStorage.getItem('bookingDataObject'));
    // console.log(this.bookingData);

    var object = JSON.parse(localStorage.getItem('userData'));
    // console.log(object);

    // this.dropData = JSON.parse(localStorage.getItem('dropObject'));
    // console.log(this.dropData);

    this.dropOid = localStorage.getItem('dropOid');
    // console.log(this.dropOid);

    this.dir = {
      origin: { lat: this.addrressData.pickUpAddress.coordinates[0], lng: this.addrressData.pickUpAddress.coordinates[1] },
      destination: { lat: this.addrressData.dropOffAddress.coordinates[0], lng: this.addrressData.dropOffAddress.coordinates[1] }
    };
  }

  setPosition(position) {
    this.location = position.coords;
    this.userLat = parseFloat(this.location.latitude);
    this.userLong = parseFloat(this.location.longitude);
  }

  public confirmBooking() {
    // var object = JSON.parse(localStorage.getItem('userData'));
    // console.log(object);

    // var token = localStorage.getItem('token');
    // console.log(token);

    var timestamp = new Date().valueOf();
    var object = JSON.parse(localStorage.getItem('userData'));
    var token = localStorage.getItem('token');
    this.loadservice.removeloader();
    this.bookingData.dropOid = this.dropOid;
    // if (this.bookingData != null) {
    //   this.bookService.voucherPayment(this.bookingData, token)
    //     .subscribe(
    //     (data: any) => {
    //       this.loadservice.a();
    //       this.bookingText = "Booking successful.";
    //       localStorage.removeItem('payUurl');
    //       localStorage.removeItem('bookingDataObject');
    //       localStorage.removeItem('distance');
    //       localStorage.removeItem('payURef');
    //       $('#results').modal('show');
    //       window.scrollTo(0, 0);
    //     },
    //     (err: any) => {
    //       this.loadservice.a();
    //       this.bookingText = "Booking error, please contact Droppa Team.";
    //       $('#results').modal('show');
    //     }
    //     );
    // } else {
    //   // this.router.navigateByUrl('/');
    // }
    // $('#results').modal('show');
    this.router.navigateByUrl('/user');
  }
}

interface Contact {
  name: string;
  surname: string;
  mobile: string;
  email: string;
  labours: number;
}
