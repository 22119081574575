import { Component, OnInit } from '@angular/core';
import {AdminService} from '../../services/admin.service';

@Component({
  selector: 'app-delivery-options',
  templateUrl: './delivery-options.component.html',
  styleUrls: ['./delivery-options.component.css']
})
export class DeliveryOptionsComponent implements OnInit {

  constructor(private adminService: AdminService) { }

  public deliveryOptions: any = [];
  selectedFile: File | null = null;
  uploadProgress = 0;
  message = '';


  ngOnInit() {
    this.adminService.getDeliveryOptions().subscribe(
      response => {
        console.log(response);
        this.deliveryOptions = response;
      }, error => {
        console.log(error);
      }
    );
  }

  public updateDeliveryOptions(id, data) {
    this.adminService.updateDeliveryOptions(id, data).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      }
    );
  }

  public deleteDeliveryOptions(id) {
    this.adminService.deleteDeliveryOptions(id).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      }
    );
  }

  public getDeliveryOptionsById(id){
    this.adminService.getDeliveryOptionsId(id).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      }
    );
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  public addDeliveryOptions() {

    const data = {
      "name": "International",
      "description": "Send non-prohibited goods weighing up to 30kg to over 150 countries.",
      "availability": true,
      "deliveryOptionImageUrl": "",
      "supportedPlatforms": [
        "WEB"
      ]
    };

    this.adminService.addDeliveryOptions(data).subscribe(
      response => {
        console.log(response);
      }, error => {
        console.log(error);
      }
    );
  }

  onFileSelect(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  onUpload(): void {
    if (this.selectedFile) {
      this.adminService.uploadDeliveryOptionsImage(this.selectedFile)
        .subscribe({
          next: (response) => {
            console.log('Image uploaded successfully', response);
            this.message = 'Image uploaded successfully';
          },
          error: (err) => {
            console.error('Upload error', err);
            this.message = 'Failed to upload image';
          }
        });
    } else {
      this.message = 'No file selected!';
    }
  }

}
