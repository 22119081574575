import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-view-bucket-bookings',
  templateUrl: './view-bucket-bookings.component.html',
  styleUrls: ['./view-bucket-bookings.component.css']
})
export class ViewBucketBookingsComponent implements OnInit {

  bookings: any;
  today: number = Date.now();
  total: number;
  page: number = 1;
  msg: string;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.bookings = JSON.parse(localStorage.getItem('bookings'));
  }

}
