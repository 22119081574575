import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-retail-select-payment-no-tag',
  templateUrl: './retail-select-payment-no-tag.component.html',
  styleUrls: ['./retail-select-payment-no-tag.component.css']
})
export class RetailSelectPaymentNoTagComponent implements OnInit {

  url: string = '';
  vehicle: string;
  retail: any;
  userToken: any;
  bookingData: any;
  bucketBooking: any;
  booking: any;

  isExpressBooking: any;
  bookingTime: any;
  bookingDate: any;
  noBookings: number = 1;

  pickUpCoordinateLocation: any = {};
  dropOffCoordinateLocation: any = {};
  departureCoordinate: any = {};
  pickUpLat: any;
  pickUpLong: any;
  pickupCompanyName: any;
  pickupComplex: any;
  pickUpUnitNo: any;
  firstName: any;
  phone: any;
  mobile: any;
  surname: any;
  dropContactFirstname: any;
  dropContactPhone: any;
  pickUpAddress: any;
  dropOfflocation: any;
  vehicleType: any;
  tempAmount: any;
  comment: any;
  bucketStatus: any;
  numberOfLabours: any;
  mass: any;
  pickUpPCode: any;
  pickUpAmt: any;
  airlineAmt: any;
  dropOffAmt: any;
  fromSuburb: any;
  listOfBucketBookingObjs: any;
  bookingBucketList: any;
  type: any;
  transportMode: any;
  pickUpProvince: any;
  expectedDeliveryDate: any;
  selectedBucketBooking: any;

  constructor(private router: Router, private sharedService: SharedService, private bookService: BookingService) { }

  ngOnInit() {
    this.url = this.sharedService.getPayFastUrl();
    this.retail = JSON.parse(localStorage.getItem('retail'));
    console.log(this.retail);
    this.userToken = JSON.parse(localStorage.getItem('userInfo'));
    console.log(this.userToken);
    this.bookingData = JSON.parse(localStorage.getItem('BookingData'));
    console.log(this.bookingData);
    this.bucketBooking = JSON.parse(localStorage.getItem('bucketBooking'));
    console.log(this.bucketBooking);
    //this.getBucketbook(this.userToken.token, this.bucketBooking.oid);
    this.selectedBucketBooking = JSON.parse(localStorage.getItem('selectedRetailBucketBooking'));
    console.log(this.bucketBooking);
  }

  public getBucketbook(token: string, bucketOid: string) {
    this.bookService.getBucketBook(token, bucketOid).subscribe(
        (res: Response) => {
          localStorage.setItem('selectedRetailBucketBooking', JSON.stringify(res));
          localStorage.setItem('bucketBooking', JSON.stringify(res));
          this.booking = res;
          this.isExpressBooking = this.booking.vehicleType;
          this.bookingTime = this.booking.time;
          this.noBookings = this.booking.bookings.length;
          var datePipe = new DatePipe('en-US');
          let value = datePipe.transform(this.booking.date, 'yyyy-MM-dd');
          this.bookingDate = value;
        },
        (error) => {
          console.log(error);
        }, () => {
          var lastIndexOfBucket: [] = this.booking.bookings
          if (lastIndexOfBucket.length > 1) {
            this.setBucketBookingData(lastIndexOfBucket.length - 1)
          } else {
            this.setBucketBookingData(0)
          }
        }
      );
  }

  public setBucketBookingData(lastIndexOfBucket: number) {
    this.pickupCompanyName = this.booking.bookings[lastIndexOfBucket].pickUp.companyName;
    this.pickupComplex = this.booking.bookings[lastIndexOfBucket].pickUp.complex;
    this.pickUpUnitNo = this.booking.bookings[lastIndexOfBucket].pickUp.unitNo;
    this.firstName = this.booking.bookings[lastIndexOfBucket].pickUp.firstName;
    this.phone = this.booking.bookings[lastIndexOfBucket].pickUp.phone;
    this.mobile = this.booking.bookings[lastIndexOfBucket].contact
    this.surname = this.booking.bookings[lastIndexOfBucket].pickUp.lastName;
    this.dropContactFirstname = this.booking.bookings[lastIndexOfBucket].dropOff.firstName;
    this.dropContactPhone = this.booking.bookings[lastIndexOfBucket].dropOff.phone;
    this.pickUpAddress = this.booking.bookings[lastIndexOfBucket].pickUpAddress;
    this.dropOfflocation = this.booking.bookings[lastIndexOfBucket].dropOffAddress;
    this.vehicleType = this.booking.bookings[lastIndexOfBucket].vehicleType;
    this.tempAmount = this.booking.price;
    this.comment = this.booking.bookings[lastIndexOfBucket].comment;
    this.pickUpCoordinateLocation = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate;
    this.bucketStatus = this.booking.status
    this.numberOfLabours = this.bookingData != null ? this.bookingData.labour : 0;
    this.pickUpLong = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[1];
    this.pickUpLat = this.booking.bookings[lastIndexOfBucket].pickUpCoordinate.coordinates[0];
    this.departureCoordinate = { "lat": this.pickUpLat, "lng": this.pickUpLong };
    this.mass = this.booking.bookings[lastIndexOfBucket].itemMass;
    this.pickUpPCode = this.booking.bookings[lastIndexOfBucket].pickUpPCode;
    this.pickUpAmt = this.booking.bookings[lastIndexOfBucket].pickUpAmt;
    this.airlineAmt = this.booking.bookings[lastIndexOfBucket].airlineAmt;
    this.dropOffAmt = this.booking.bookings[lastIndexOfBucket].dropOffAmt;
    this.fromSuburb = this.booking.bookings[lastIndexOfBucket].fromSuburb;
    this.listOfBucketBookingObjs = this.booking.bookings;
    this.bookingBucketList = this.listOfBucketBookingObjs.length;
    this.type = this.booking.bookings[lastIndexOfBucket].type;
    this.transportMode = this.booking.bookings[lastIndexOfBucket].transportMode;
    this.pickUpProvince = this.booking.bookings[lastIndexOfBucket].province;
    this.expectedDeliveryDate = this.booking.bookings[lastIndexOfBucket].expectedDeliveryDate;
  }


}
