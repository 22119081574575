import { Component, OnInit } from '@angular/core';
import {NgForm} from '@angular/forms';
import {AdminService} from "../../services/admin.service";
import {LoaderService} from "../../services/loader.service";
import {Router} from "@angular/router";


declare var $: any;
@Component({
  selector: 'app-admin-integrated-company',
  templateUrl: './admin-integrated-company.component.html',
  styleUrls: ['./admin-integrated-company.component.css']
})
export class AdminIntegratedCompanyComponent implements OnInit {
  adminUser: any;
  company: any;
  companies: any = [];
  companyNames = [
    'DSV',
    'Courier Guy',
    'DPD Laser',
    'Droppa',
    'SkyNet',
    'Uber'
  ];
  droppaCommission = 0;
  droppaMargin = 0;
  carrierCommission = 0;
  status = 'ACTIVE';
  companyName = '';

  operation = '';

  constructor(private adminService: AdminService, private loaderService: LoaderService, private router: Router) { }

  ngOnInit() {
    this.adminUser = JSON.parse(localStorage.getItem('userInfo'));
    this.getCarriers();
  }

  getCarriers() {
    this.adminService.getCarriers(this.adminUser.token)
      .subscribe(
        (res) => {
          this.companies = res;
        },
        (err) => {
          this.loaderService.removeloader();
          console.log(err);
          if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
            this.router.navigate(['/logon']);
          }
        }
      );
  }

  createConfiguration() {
    if (this.companyName !== '' && this.carrierCommission > 0 && this.droppaMargin > 0 && this.droppaCommission > 0) {
      let carrierName = '';
      switch (this.companyName) {
        case 'DSV': carrierName = 'DSV'; break;
        case 'Courier Guy': carrierName = 'COURIER_GUY'; break;
        case 'DPD Laser': carrierName = 'DPD_LASER'; break;
        case 'Droppa': carrierName = 'DROPPA'; break;
        case 'SkyNet': carrierName = 'SKYNET'; break;
        case 'Uber': carrierName = 'UBER'; break;
      }
      const obj = {
        name: carrierName,
        insuranceAmount: this.carrierCommission,
        insuranceAmountMargin: this.droppaCommission,
        status: this.status,
        priceMargin: this.droppaMargin
      };

      this.adminService.createConfiguration(this.adminUser.token, obj)
        .subscribe(
          (res) => {
            $('#createCompanyModal').modal('hide');
            this.getCarriers();
          },
          (err) => {
            $('#createCompanyModal').modal('hide');
            this.loaderService.removeloader();
            console.log(err);
            if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
              this.router.navigate(['/logon']);
            }
          }
        );
    }
  }

  updateConfiguration() {
    if (this.companyName !== '' && this.carrierCommission > 0 && this.droppaMargin > 0 && this.droppaCommission > 0) {
      const obj = {
        insuranceAmount: this.carrierCommission,
        insuranceAmountMargin: this.droppaCommission,
        status: this.status,
        priceMargin: this.droppaMargin
      };

      this.adminService.updateConfiguration(this.adminUser.token, obj, this.company.oid)
        .subscribe(
          (res) => {
            $('#createCompanyModal').modal('hide');
            this.getCarriers();
          },
          (err) => {
            $('#createCompanyModal').modal('hide');
            this.loaderService.removeloader();
            console.log(err);
            if (err.statusText === 'Unknown Error' || err.statusText === 'Internal Server Error') {
              this.router.navigate(['/logon']);
            }
          }
        );
    }
  }

  openModal(operation: string, company?: any) {
    if (company) {
      this.company = company;
      this.companyName = company.name;
      this.droppaMargin = company.priceMargin;
      this.carrierCommission = company.insuranceAmount;
      this.droppaCommission = company.insuranceAmountMargin;
      this.status = company.status;
    } else {
      this.companyName = '';
      this.droppaMargin = 0;
      this.carrierCommission = 0;
      this.droppaCommission = 0;
      this.status = 'ACTIVE';
    }
    this.operation = operation;
    $('#createCompanyModal').modal('show');
  }
}
